/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { 
  useDispatch, 
  } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { textSort } from '../../helpers/textSort';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

import { Button, Col, Layout, Modal, Row, Table, } from 'antd';

import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/pluse-litl-icon.svg';


import { deleteRecommendationMaps } from '../../data/store/instruments/instrumentsActions';
import CustomSortIcon from '../../components/CustomSortIcon/CustomSortIcon';

import './style.scss';
import ModalDeleteModel from './ModalDeleteModel';

const { Header } = Layout;

const RecommendationMapsList = ({recommendation}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const query = useQuery(search);
  const history = useHistory();
  
  const [deleteKey, setDeleteKey] = useState(null);

  const tabs = query.get('tabs');

  useEffect(() => {
    if(!tabs) {
      history.replace(`${pathname}?tabs=active`);
    }
  }, [tabs]);
  
  
  const geMapById = (id) => {
    return recommendation?.find(el => el.id === id)?.name;
  };

  const handleCreate = () => {
    history.push('/quant/approved-instruments/edit/new', { key: 'edit' });
  };

  const hendleEdit = (id) => {
    history.push(`/quant/approved-instruments/edit/${id}`, { key: 'edit' });
  };

  const handleDelete = (key) => {
    console.log('🚀 ~ handleDelete ~ key:', key);
    setDeleteKey(key);
  };

  const onDeleteInstrument = () => {
    dispatch(deleteRecommendationMaps(+deleteKey));
    handleCloseDelModal();
  };

  const handleCloseDelModal = () => {
    setDeleteKey(null);
  };

  const columns = [
    {
      title: t('MAP'),
      dataIndex: 'name',
      className: 'name first-column-p24',
      render: (value, record) => {
        return (
          <>
            <p className='model-table-name'>{value}</p>
            <p className='model-table-subname'>{dayjs(record?.update_time * 1000).format('DD/MM/YYYY')} at {dayjs(record?.update_time * 1000).format('HH:mm')}</p>
          </>
        );
      },
      sorter: (a, b) => textSort(a.name, b.name),
      sortIcon: (props) => <CustomSortIcon {...props} />,
      width: '17%',
    },
    {
      title: t('DESCRIPTION'),
      dataIndex: 'description',
      className: 'asset-class',
      width: '17%',
    },
    {
      title: t('ACTIONS'),
      align: 'center',
      dataIndex: 'operation',
      width: '8%',
      render: (_, record) => {
        return (
        <div className="cell-button-wrapper">
          <Button
            type='text'
            onClick={() => hendleEdit(record.id)} >
            <Edit />
          </Button>
          <Button
            type='text'
            onClick={() => handleDelete(record.id)}>
            <Delete />
          </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="instrument-wrapper">
        <div className="">
          <Header className="headerStyle suggested">
            <h1 className='suggested-portfolios-title'>{t('MAPS')}</h1>
            <Row className="suggested-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button
                  type="text"
                  className={`overview-btn ${
                    tabs === 'active' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push(`${pathname}?tabs=active`)}>
                  {t('ACTIVE_MAPS')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${
                    tabs === 'draft' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() => history.push(`${pathname}?tabs=draft`)}>
                  {t('DRAFT_MAPS')}
                </Button>
              </Col>
            </Row>
          </Header>
          <div className="top-btn-wrapper">
            <Button
              icon={<Pluse/>}
              iconPosition='end'
              className="portfolio-cancel-btn success-btn"
              onClick={handleCreate}
            >
              {t('CREATE_MAP')}
            </Button>
          </div>
          {tabs === 'active' &&
            <div className='client portfolio-table suggestions-table'>
            <Table
              rowKey={(record) => record.id}
              style={{ width: '100%' }}
              dataSource={recommendation?.filter(i=> i.is_complete)}
              columns={columns}
              size='middle'
              pagination={false}
            />
          </div>
          }
          {tabs === 'draft' &&
            <div className='client portfolio-table suggestions-table'>
            <Table
              rowKey={(record) => record.id}
              style={{ width: '100%' }}
              size='middle'
              dataSource={recommendation?.filter(i=> !i.is_complete)}
              columns={columns}
              pagination={false}
            />
          </div>
          }
        </div>
      </div>
      <ModalDeleteModel
        deleteMap={deleteKey}
        handleCloseDelModal={handleCloseDelModal}
        onDeleteInstrument={onDeleteInstrument}
        text={'TEXT_DELETION_MAP'}
        name={geMapById(deleteKey) || '----'}
      />
    </>
  );
};

export default RecommendationMapsList;

RecommendationMapsList.propTypes = {
  recommendation: PropTypes.array,
};