import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Table } from 'antd';
import {
  numberWithSpaces,
  numberWithSpacesInter,
} from '../../../helpers/formatInteger';

import { formatPercentage } from '../../../helpers/formatPercentage';
import { decimalSeparator } from '../../../helpers/decimalSeparator';

import { textSort } from '../../../helpers/textSort';
import './style.scss';
import CustomSortIcon from '../../../components/CustomSortIcon/CustomSortIcon';

export const ClientHoldings = ({
  currentPortfolio,
  cashes,
  tabs,
}) => {
  const { t } = useTranslation('');

  const [tableColumns, setTableColumns] = useState();

  const getCashInstrument = (id) => {
    const cash = cashes?.find((i) => i.ID === id);
    return cash;
  };

  const percentage = (value, isText) => {
    const percentage =
      Math.round(
        ((value * 100) /
          currentPortfolio
            ?.map((i) => i.value)
            .reduce((acc, el) => acc + el, 0)) *
          100,
      ) / 100;
    return isText ? `${percentage} %` : percentage;
  };

  const data = currentPortfolio
    ?.map((row) => {
      return {
        key: row.ID,
        financialInstrumentName: row?.instrument_name ?? row?.name,
        instrument_id: row?.instrument_id,
        amount: row?.amount,
        cashValue: row?.amount * getCashInstrument(row?.instrument_id)?.latest_value_sek,
        ISIN: row?.isin,
        unitsNumber: row?.units_number,
        latest_value_sek: row?.latest_value_sek,
        latest_value_time: row.latest_value_time,
        currency_id: row?.currency_id ?? 1,
        percentage: percentage(row.value),
        portfolio_code: row?.portfolio_code,
        value: row.value,
        valueText: numberWithSpaces(row.value),
        fee: row?.fee ?? 0,
        total_value: row.total_value,
        feeText: row?.fee ? row.fee + ' %' : '0 %',
        assetClass: row.financial_asset?.name,
        financialAssetCategory: row.financial_asset_category?.name || 'TBD',
        categoryID: row.financial_asset_category?.ID,
      };
    })
    .sort((a, b) => b.value - a.value);

  const total = data.reduce((acc, current) => current.value + acc, 0);

  const getWeight = (value) => {
    return Math.round((value / total) * 100 * 100) / 100;
  };

  useEffect(() => {
    if (currentPortfolio?.length) {
      const defaultColumns =
        tabs === 1
          ? [
              {
                title: t('INSTRUMENT'),
                dataIndex: 'financialInstrumentName',
                className: 'holdings-instrument',
                width: '20%',
                sorter: (a, b) =>
                  textSort(
                    a.financialInstrumentName,
                    b.financialInstrumentName,
                  ),
                sortIcon: (props) => <CustomSortIcon {...props} />,
              },
              {
                title: t('PORTFOLIO'),
                dataIndex: 'portfolio_code',
                className: 'holdings-data',
                width: '8%',
                align: 'center',
                sorter: (a, b) => a.portfolio_code - b.portfolio_code,
                sortDirections: ['descend', 'ascend'],
                sortIcon: (props) => <CustomSortIcon {...props} />,
              },
              {
                title: t('UNITS'),
                dataIndex: 'unitsNumber',
                className: 'holdings-data unitsNumber',
                align: 'right',
                width: '10%',
                sorter: (a, b) => a.unitsNumber - b.unitsNumber,
                sortDirections: ['descend', 'ascend'],
                sortIcon: (props) => <CustomSortIcon {...props} />,
                render: (value) => {
                  return value ? numberWithSpacesInter(value, 4) : '';
                },
              },
              {
                title: t('VALUE') + ' kr',
                dataIndex: 'value',
                className: 'holdings-data value',
                width: '10%',
                align: 'right',
                render: (value) => {
                  return `${numberWithSpacesInter(value, 2)}`;
                },
                sorter: (a, b) => a.value - b.value,
                sortDirections: ['descend', 'ascend'],
                sortIcon: (props) => <CustomSortIcon {...props} />,
              },
              {
                title: t('WEIGHT'),
                dataIndex: 'weight',
                className: 'holdings-data value',
                width: '10%',
                align: 'right',
                render: (_, record) => {
                  return `${formatPercentage(getWeight(record.value))}`;
                },
                sorter: (a, b) => a.value - b.value,
                sortDirections: ['descend', 'ascend'],
                sortIcon: (props) => <CustomSortIcon {...props} />,
              },
              {
                title: t('FEE'),
                dataIndex: 'fee',
                className: 'holdings-fee',
                width: '10%',
                align: 'center',
                render: (value) => {
                  return (
                    <p>
                      {formatPercentage(value) ||
                        `${'0.00'.replace('.', decimalSeparator())} %`}
                    </p>
                  );
                },
                sorter: (a, b) => a.fee - b.fee,
                sortDirections: ['descend', 'ascend'],
                sortIcon: (props) => <CustomSortIcon {...props} />,
              },
              {
                title: t('ASSET_CLASS'),
                dataIndex: 'assetClass',
                width: '14%',
                sorter: (a, b) => textSort(a.assetClass, b.assetClass),
                sortIcon: (props) => <CustomSortIcon {...props} />,
                render: (value, record) => {
                  return (
                    <>
                      <p className="holdings-assetclass-cell-asset">{value}</p>
                      <p className="holdings-assetclass-cell-category">
                        {record.financialAssetCategory}
                      </p>
                    </>
                  );
                },
              },
            ]
          : tabs === 2
          ? [
            {
              title: t('INSTRUMENT'),
              dataIndex: 'instrument_id',
              className: 'holdings-instrument',
              width: '20%',
              render: (value) => {
                return getCashInstrument(value)?.name ?? '- - -'; 
              },
              sorter: (a, b) =>
                textSort(
                  getCashInstrument(a.name),
                    getCashInstrument(b.name),
                ),
              sortIcon: (props) => <CustomSortIcon {...props} />,
            },
            {
              title: t('PORTFOLIO'),
              dataIndex: 'portfolio_code',
              className: 'holdings-data',
              width: '8%',
              align: 'center',
              sorter: (a, b) => a.portfolio_code - b.portfolio_code,
              sortDirections: ['descend', 'ascend'],
              sortIcon: (props) => <CustomSortIcon {...props} />,
            },

            {
              title: t('VALUE'),
              dataIndex: 'cashValue',
              className: 'holdings-data value',
              width: '10%',
              align: 'right',
              render: (value) => {
                return `${numberWithSpacesInter(value, 2)} kr`;
              },
              sorter: (a, b) => a.value - b.value,
              sortDirections: ['descend', 'ascend'],
              sortIcon: (props) => <CustomSortIcon {...props} />,
            },
          ]
          : [];
      setTableColumns(defaultColumns);
    }
  }, [currentPortfolio]);

  return (
    <div className="client portfolio-table">
      <Table
        rowKey={() => uuidv4()}
        columns={tableColumns}
        dataSource={data}
        size="middle"
        pagination={false}
      />
    </div>
  );
};

ClientHoldings.propTypes = {
  currentPortfolio: PropTypes.array,
  cashes: PropTypes.array,
  tabs: PropTypes.number,
};
