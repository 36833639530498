/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Checked } from '../../../assets/dot-menu-checked.svg';
import { ReactComponent as ListCollapse } from '../../../assets/list-collapse-icon.svg';

import './style.scss';

const CustomMenu = ({ items, collapsed, setUserId, appMenuID, setAppMenuID }) => {
  const history = useHistory();

  const [hideList, setHideList] = useState(false);

  useEffect(() => {
    if(appMenuID && appMenuID !== '2') {
      setHideList(+appMenuID >= 10);
    }
  }, [appMenuID]);
  

  const handleClientMenu = (key, path) => {
    const point = items.find(i => i.key === key);
    if(point?.children) {
      setHideList(!hideList);
    } else {
      setAppMenuID(key);
      +key < 10 && setHideList(false);
    }
    setUserId(null);
    history.replace(path);
  };

  return (
    <div className={`custom-menu-wrapper ${collapsed ? ' collapsed' : ''}`}>
      {items.map((i) => {
        if(i?.path) {
          return (
            <Button
              type="text"
              key={i.key}
              className={`custom-menu-btn ${appMenuID === i.key ? 'selected' : ''}`}
              onClick={() => handleClientMenu(i.key, i.path)}
            >
              {collapsed ? null : appMenuID === i.key ? (
                <Checked />
              ) : (
                <div className="menu-icon-wrap">{i.icon}</div>
              )}
              {collapsed ? i.icon : i.label}
            </Button>
          );
        } else if(i?.children) {
          return (
            <div key={i.key} >
              <div 
                className='main-menu-btn-wrapper'
                onClick={() => handleClientMenu(i.key, i.path)}
                >
                <Button
                  type="text"
                  key={i.key}
                  className='custom-menu-btn main-menu-btn'
                >
                  {collapsed ? null : appMenuID === i.key ? (
                    <Checked />
                  ) : (
                    <div className="menu-icon-wrap">{i.icon}</div>
                  )}
                  {collapsed ? i.icon : i.label}
                </Button>
                <ListCollapse className={hideList ? 'visible-list' : 'hidden-list'}/>
              </div>
              <div className={`submenu-wrapper ${!hideList ? 'hidden' : ''}`}>
                {i?.children.map(sub => {
                  return (
                    <Button
                      type="text"
                      key={sub.key}
                      className={`custom-menu-btn ${appMenuID === sub.key ? 'selected' : ''}`}
                      onClick={() => handleClientMenu(sub.key, sub.path)}
                    >
                      {collapsed ? null : appMenuID === sub.key ? (
                        <Checked />
                      ) : (
                        <div className="menu-icon-wrap">{sub.icon}</div>
                      )}
                      {collapsed ? sub.icon : sub.label}
                    </Button>
                  );
                })
                }
              </div>
            </div>

          );
          
        }
        })}
    </div>
  );
};

export default CustomMenu;

CustomMenu.propTypes = {
  items: PropTypes.array,
  collapsed: PropTypes.bool,
  setUserId: PropTypes.func,
  appMenuID: PropTypes.string,
  setAppMenuID: PropTypes.func,
};
