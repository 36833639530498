/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { clearPrimitives, getModelPrimitives, setModelPrimitives, tempDownloadPrimitives, uploadModelPrimitives } from '../../data/store/instruments/instrumentsActions';
import { steps } from './ModelItems';

import { Button, Col, Form, Layout, Row } from 'antd';
import Container from '../../components/Container';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { SortArrow } from '../Clients/ClientSummary';

import { ReactComponent as File } from '../../assets/file-icon.svg';
import { ReactComponent as Upload } from '../../assets/upoad-icon.svg';
import { ReactComponent as Complete } from './img/complete-icon.svg';

const { Header } = Layout;
const ModelPrimitives = ({model, current, isNewModel}) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  
  const inputXlsx = useRef(null);
  const assets = useSelector((state) => state.instrumentsReducer.assets);
  const primitives = useSelector((state) => state.instrumentsReducer.primitives);
  const primitivesUploaded = useSelector((state) => state.instrumentsReducer.primitivesUploaded);

  const [storedPrimitives, setStoredPrimitives] = useState(null);

  const getPoint = (key) => {
    return steps.find((i) => i.key === key)?.value;
  };

  useEffect(() => {
    if(primitivesUploaded?.length && primitives) {
      const getVariance = (type, id) => primitives.find( i => i.asset_class_1_id === id && i.entity === type);

      let uploadData = [];
      const  mean_linear_return = primitivesUploaded.map(i =>{ 
        const item = getVariance(1, i.asset_class_id);
        console.log('🚀 ~ useEffect ~ item:', item);
        let itemForQuant = {};
        if(item) {
          itemForQuant = {...item};
        } else {
          itemForQuant = {
            entity: 1,
            p1: 0,
            p2: 0,
            asset_class_1_id: i.asset_class_id,
          };
        }
        itemForQuant.value = i.mean_linear_return ? i.mean_linear_return / 100 : item?.value || 0;
        return itemForQuant;
      });
      console.log('🚀 ~ useEffect ~ mean_linear_return.every(i => !i.value ):', mean_linear_return.every(i => !i.value ));

      if(mean_linear_return.every(i => !i.value )) {
        const  mean_log_return = primitivesUploaded.map(i => {
          const item = getVariance(2, i.asset_class_id);
          let itemForQuant = {};
          if(item) {
            itemForQuant = {...item};
          } else {
            itemForQuant = {
              entity: 2,
              p1: 0,
              p2: 0,
              asset_class_1_id: i.asset_class_id,
            };
          }
          itemForQuant.value = i.mean_log_return ? i.mean_log_return / 100 : item?.value || 0;
          return itemForQuant;
        });
        if(mean_log_return.some(i => !!i.value )) uploadData = uploadData.concat(mean_log_return);
      } else {
        if(mean_linear_return.some(i => !!i.value )) uploadData = uploadData.concat(mean_linear_return);
      }

      const  variance = primitivesUploaded.map(i => {
        const item = getVariance(3, i.asset_class_id);
        let itemForQuant = {};
        if(item) {
          itemForQuant = {...item};
        } else {
          itemForQuant = {
            entity: 3,
            adjustment: 0,
            value: 0,
            p1: 0,
            p2: 0,
            asset_class_1_id: i.asset_class_id,
          };
        }
        itemForQuant.adjustment = i.variance ? i.variance / 100 - itemForQuant.value : item?.adjustment || 0;
        return itemForQuant;
      });

      const  volatility = primitivesUploaded.map(i => {
        const item = getVariance(4, i.asset_class_id);
        let itemForQuant = {};
        if(item) {
          itemForQuant = {...item};
        } else {
          itemForQuant = {
            entity: 4,
            adjustment: 0,
            value: 0,
            p1: 0,
            p2: 0,
            asset_class_1_id: i.asset_class_id,
          };
        }
        if(variance.every(i => !i.adjustment )) {
          itemForQuant.adjustment = i.volatility ? i.volatility / 100 - itemForQuant.value : item?.adjustment || 0;
        }
        return itemForQuant;
      });
      uploadData = uploadData.concat(volatility, variance);
      //type 61
      const  weightRange = primitivesUploaded.map(i => {
        const item = getVariance(61, i.asset_class_id);
        let itemForQuant = {};
        if(item) {
          itemForQuant = {...item};
        } else {
          itemForQuant = {
            entity: 61,
            adjustment: 0,
            value: 0,
            p1: 0,
            p2: 0,
            asset_class_1_id: i.asset_class_id,
          };
        }
        itemForQuant.p1 = i.p1 ? i.p1 / 100 : item?.p1 || 0;
        itemForQuant.p2 = i.p2 ? i.p2 / 100 : item?.p2 || 0;
        itemForQuant.value = i.value ? i.value / 100 : item?.value || 0;
        return itemForQuant;
      });
      if(weightRange.some(i => !!i.p1 )) uploadData = uploadData.concat(weightRange);
      console.log('uploadData', uploadData);
      setStoredPrimitives(uploadData);
    } 
  }, [primitivesUploaded]);

  useEffect(() => {
  
    return () => {
      dispatch(clearPrimitives());
    };
  }, []);

  useEffect(() => {
    if(primitives) {
      const data = primitives?.filter(i => [1, 2, 3, 4, 61].includes(i.entity));
      setStoredPrimitives(data);
    } else if(!primitives && model?.id){
      dispatch(getModelPrimitives(model.id));
    }
  
  }, [primitives, model]);
  

  const getAssetById = (id) => {
    const asset = assets.find(asset => asset.ID === id);
    return {name: asset?.name ?? '', category: asset?.financialAssetCategory.name ?? ''};
  };

  const [sortValue, setSortValue] = useState(null);
  const [sortClass, setSortClass] = useState(null);
  const [sortAllocation, setSortAllocation] = useState(null);
  
  const meanReturnOptions = [
    {value: 1,
      label: t('MEAN_LINEAR_RETURN')},
    {value: 2,
      label: t('MEAN_LOG_RETURN')},
    {value: 3,
      label: t('VARIANCE')},
    {value: 4,
      label: t('STANDARD_DEV_VOLATILITY')},
    {value: 61,
      label: t('WEIGHT_RANGE_VOLATILITY_ADJUSTMENT')},
  ];
  const [primitivesSet, setPrimitivesSet] = useState(null);
  const [primitivesType, setPrimitivesType] = useState(1);
  const [lockedSet, setLockedSet] = useState({'1': false, '2': false, '3': false, '4': false, '61': false});

  useEffect(() => {
    console.log('lockedSet', lockedSet);
  }, [lockedSet]);
  

  useEffect(() => {
    if(primitivesType && storedPrimitives) {
      const data = storedPrimitives?.filter( i=> i.entity === +primitivesType);
      
      if(!data?.length && (primitivesType === 1 || primitivesType === 2 || primitivesType === 61)) {
        const data = assets?.filter(i=> !i?.is_virtual)?.map(i => ({
          entity: +primitivesType,
          value: 0,
          p1: 0,
          p2: 0,
          asset_class_1_id: i.ID,
        }));
        const newPrimitives = [...storedPrimitives, ...data];
        setStoredPrimitives(newPrimitives);
        return;
        
      }
      const isBlanck = data?.every(i => {
        if(primitivesType === 3 || primitivesType === 4) {
          return i.adjustment === 0;
        } else {
          return i.value === 0;
        }
      });
      const newLockedSet = {...lockedSet};

      if(primitivesType === 1 && isBlanck) {
        newLockedSet['2'] = false;
      } else if(primitivesType === 1){
        newLockedSet['2'] = true;
      }
      if(primitivesType === 2 && isBlanck) {
        newLockedSet['1'] = false;
      } else if(primitivesType === 2){
        newLockedSet['1'] = true;
      }
      
      if(primitivesType === 3 && isBlanck) {
        newLockedSet['4'] = false;
      } else if(primitivesType === 3){
        newLockedSet['4'] = true;
      }
      if(primitivesType === 4 && isBlanck) {
        newLockedSet['3'] = false;
      } else if(primitivesType === 4){
        newLockedSet['3'] = true;
      }
      
      setLockedSet(newLockedSet);
      setPrimitivesSet(data);
    }
  }, [primitivesType, storedPrimitives]);

  const hendleChangeValue = (id, value, asset, field ) => {
    value = !isNaN(Number(value.replace(',', '.'))) ? Number(value.replace(',', '.')) : 0;
    const clonData = cloneDeep(storedPrimitives);
    if(id) {
      const newData = clonData.map(i => {
        if(i.id === id) {
          let  data = {...i};
          data.adjustment = (value - i.value * 100) / 100;
          return data;
        } else {
          return i;
        }
      });
      setStoredPrimitives(newData);
    } else {
      const newData = clonData.map(i => {
        if(i.asset_class_1_id === asset && i.entity === primitivesType) {
          let  data = {...i};
          data[field] = value / 100;
          return data;
        } else {
          return i;
        }
      });
      setStoredPrimitives(newData);
    }
  };

  const handleSort = (coll) => {
    if (coll === 'value') {
      setSortClass(null);
      setSortAllocation(null);
      if (sortValue !== null) {
        if (sortValue) {
          setSortValue(false);
        } else {
          setSortValue(null);
        }
      } else {
        setSortValue(true);
      }
    } else if (coll === 'class') {
      setSortValue(null);
      setSortAllocation(null);
      if (sortClass !== null) {
        if (!sortClass) {
          setSortClass(true);
        } else {
          setSortClass(null);
        }
      } else {
        setSortClass(false);
      }
    } else if (coll === 'allocation') {
      setSortValue(null);
      setSortClass(null);
      if (sortAllocation !== null) {
        if (!sortAllocation) {
          setSortAllocation(true);
        } else {
          setSortAllocation(null);
        }
      } else {
        setSortAllocation(false);
      }
    }
  };


  const uploadPrimitives = (event) => {
    const file = event.target.files[0];
    dispatch(uploadModelPrimitives({id: model?.id, file }));
    if (inputXlsx.current) {
      console.log('Clear input');
      inputXlsx.current.value = null;
    }
  };

  const handleUpload = () => {
    if (inputXlsx.current) {
      inputXlsx.current.click();
    }
  };

    const download = () => {
      dispatch(tempDownloadPrimitives({id: model?.id}));
    };

  const handleCancel = () => {
    history.replace(`${pathname}?step=index-series`);
  };

  const handleClearAll = () => {
    const clonData = cloneDeep(storedPrimitives);
    if(primitivesType === 3 || primitivesType === 4) {
      const newData = clonData.map(i => {
        if(i.entity === primitivesType) {
          const el = {...i, adjustment: 0};
              return el;
        } else {
            return i;
        }
      });
      setStoredPrimitives(newData);
    } else {
      const newData = clonData.map(i => {
        if(i.entity === primitivesType) {
          const el = {...i, value: 0, p1: 0, p2: 0};
              return el;
        } else {
            return i;
        }
      });
      setStoredPrimitives(newData);
    }
  };
  
  const handleContinue = () => {
    history.push(`${pathname}?step=${getPoint(current + 1)}`);
  };

  const onSubmit = (data) => {
    dispatch(setModelPrimitives({callback: handleContinue, id: model?.id, data: storedPrimitives }));
  };

  return (
    <>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className="overview-btn">
              {t(isNewModel ? 'NEW_MODEL' : 'UPDATE_MODEL')}
            </Button>
          </Col>
        </Row>
        <div className="top-btn-wrapper">
          <Button
            type="text"
            className="portfolio-cancel-btn"
            onClick={handleCancel}
          >
            {t('BACK_TO_INDEX_SERIES')}
          </Button>
          <Button
            icon={<Complete/>}
            iconPosition='end'
            onClick={() => form.submit()}
            className="portfolio-cancel-btn complete-btn"
          >
            {t('CONTINUE')}
          </Button>
        </div>
      </Header>
      <Row justify={'space-between'}>
        <Col span={12}>
          <h2 style={{margin: '24px 0'}} className="model-container-title">{t('MODEL_PRIMITIVES')}</h2>
        </Col>
        <Col span={12} className="titlerow-btn-wrapper download-series">
          <a onClick={download} className="download-import-temp">
            {t('DOWNLOAD_THE_TEMPLATE_EXCEL')}
            <File />
          </a>
          <Button
            type="text"
            className="portfolio-cancel-btn model-uploud"
            icon={<Upload />}
            iconPosition={'end'}
            onClick={handleUpload}
          >
            {t('UPLOAD_FROM_EXEL')}
          </Button>
          <input
            ref={inputXlsx}
            type="file"
            accept={'.xlsx'}
            className="portfolio-creat-download-input"
            onChange={uploadPrimitives}
          />
        </Col>
      </Row>
      <Form
        form={form}
        name="primitives-form"
        layout="vertical"
        onFinish={onSubmit}
        className="instrument-form"
        autoComplete="off"
      >
      <div className='primitives-tabs-wrapper'>
      {
        meanReturnOptions.map( i => (
          <div 
            key={i.value} 
            className={`primitives-tabs-item ${i.value === primitivesType ? 'active' : ''}`}
            onClick={() => setPrimitivesType(i.value)}
            >
            {t(i.label)}
          </div>
        ))
      }
      </div>
        <Row wrap={false} className="primitives-header">
          <Col span={primitivesType === 61 ? 6 : 8} className="primitives-title-class contrast">
            {t('ASSET_CLASS')}
            <div onClick={() => handleSort('class')}>
              <SortArrow
                direction={
                  sortClass ? 'up' : sortClass !== null ? 'down' : ''
                }
              />
            </div>
          </Col>
          {primitivesType === 61
          ? (
            <>
              <Col span={5} className="primitives-title-value">
                {t('p1')}
              </Col>
              <Col span={5} className="primitives-title-value">
                {t('p2')}
                <div onClick={() => handleSort('value')}>
                  <SortArrow
                    direction={
                      sortValue ? 'up' : sortValue !== null ? 'down' : ''
                    }
                  />
                </div>
              </Col>
              <Col span={5} className="primitives-title-value">
                {t('VALUE')}
                <div onClick={() => handleSort('value')}>
                  <SortArrow
                    direction={
                      sortValue ? 'up' : sortValue !== null ? 'down' : ''
                    }
                  />
                </div>
              </Col>
            </>
          )
          : primitivesType === 3 || primitivesType === 4
            ? (
                <>
                  <Col span={8} className="primitives-title-value">
                    {t('QUANT_VALUE')}
                    <div onClick={() => handleSort('value')}>
                      <SortArrow
                        direction={
                          sortValue ? 'up' : sortValue !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={4} className="primitives-title-override">
                    {t('OVERRIDE')}
                  </Col>
                </>
              )
            : (
                <Col span={12} className="primitives-title-value">
                  {t('VALUE')}
                </Col>
              )

          }
          <Col span={primitivesType === 61 ? 3 : 4} className="asset-class-title-allocation">
            <Button
              className="portfolio-details-btn primitives-clear-btn"
              onClick={handleClearAll}
            >
              {t('CLEAR_ALL')}
            </Button>
          </Col>
        </Row>
        {primitivesSet &&
          primitivesSet?.map((el, index) => {
            return (
              <Row key={el.asset_class_1_id} wrap={false} className={'primitives-row'}>
                <Col span={primitivesType === 61 ? 6 : 8} className="primitives-title-class">
                  <div className=''>
                    <p className='primitives-cell-name'>{getAssetById(el.asset_class_1_id)?.name}</p>
                    <p className='primitives-cell-category'>{getAssetById(el.asset_class_1_id)?.category}</p>
                  </div>
                </Col>
                {primitivesType === 61
                ? (
                  <>
                    <Col span={5} className="primitives-title-value">
                      <div className=''>
                        <CustomInputNumber
                          width={'132px'}
                          value={(el?.p1 * 100).toFixed(2)}
                          percent
                          precision={2}
                          min={0}
                          onBlur={(e) =>
                            hendleChangeValue(null, e.target.value, el?.asset_class_1_id, 'p1')
                          }
                          className="custom-inputnumber model-series"
                          controls={false}
                        />
                      </div>
                    </Col>
                    <Col span={5} className="primitives-title-value">
                      <div className=''>
                        <CustomInputNumber
                          width={'132px'}
                          value={(el?.p2 * 100).toFixed(2)}
                          percent
                          precision={2}
                          min={0}
                          onBlur={(e) =>
                            hendleChangeValue(null, e.target.value, el?.asset_class_1_id, 'p2')
                          }
                          className="custom-inputnumber model-series"
                          controls={false}
                        />
                      </div>
                    </Col>
                    <Col span={5} className="primitives-title-value">
                      <div className=''>
                        <CustomInputNumber
                          width={'132px'}
                          value={(el?.value * 100).toFixed(2)}
                          percent
                          precision={2}
                          min={0}
                          onBlur={(e) =>
                            hendleChangeValue(null, e.target.value, el?.asset_class_1_id, 'value')
                          }
                          className="custom-inputnumber model-series"
                          controls={false}
                        />
                      </div>
                    </Col>
                  </>
                )
                : (primitivesType === 3 || primitivesType === 4)
                  ? (
                    <>
                      <Col span={8} className="primitives-title-value cell">
                        <div className=''>{(el?.value * 100).toFixed(2)}</div>
                      </Col>
                      <Col span={7} className="primitives-title-override cell">
                        <div className=''>
                          <CustomInputNumber
                            disabled={lockedSet[primitivesType]}
                            width={'132px'}
                            percent
                            precision={2}
                            min={0}
                            value={el?.adjustment ? + ((el?.value + el?.adjustment) * 100).toFixed(2) : 0}
                            onBlur={(e) =>
                              hendleChangeValue(el.id, e.target.value, 'value')
                            }
                            className="custom-inputnumber model-series"
                            controls={false}
                          />
                        </div>
                      </Col>
                    </>
                  )
                  : (
                    <>
                      <Col span={12} className="primitives-title-value cell">
                        <div className=''>
                          <CustomInputNumber
                            disabled={lockedSet[primitivesType]}
                            width={'132px'}
                            percent
                            precision={2}
                            min={0}
                            value={(el?.value * 100).toFixed(2)}
                            onBlur={(e) =>
                              hendleChangeValue(null, e.target.value, el?.asset_class_1_id, 'value')
                            }
                            className="custom-inputnumber model-series"
                            controls={false}
                          />
                        </div>
                      </Col>
                    </>
                  )
                }
                <Col span={primitivesType === 61 ? 3 : 4} className="asset-class-title-allocation">
                
                </Col>
              </Row>
            );
          })

        }
      </Form>
    </>
  );
};

export default ModelPrimitives;

ModelPrimitives.propTypes = {
  model: PropTypes.object,
  current: PropTypes.number,
  isNewModel: PropTypes.bool,
};