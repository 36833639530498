import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { EditableContext } from './MapsInstrumetnTable';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import { textSort } from '../../../helpers/textSort';

const EditableCell = ({
  title,
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  instrumentList,
  getInstrumentItem,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if(dataIndex === 'weight_in' || dataIndex === 'weight_out') {
      const {numerator, denominator} = record[dataIndex];
      const data = {
        ... record[dataIndex],
        [dataIndex]: denominator === 1 || denominator === 0 ? numerator.toString() : `${numerator}/${denominator}`,
      };
      form.setFieldsValue(data);
    } else if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
    
  }, [dataIndex, record]);

  const getWeight = (weit, type) => {
    if(typeof weit === 'string') {
      const num =  weit.includes('/') ? Number(weit.split('/')[0]) : weit;
      const den =  weit.includes('/') ? Number(weit.split('/')[1]) : 1;
      return type === 'in' 
      ? {numIn: num, denIn: den} 
      : type === 'out' 
        ? {numOut: num, denOut: den}
        : {num, den};
    } else {
      return type === 'in' 
      ? {numIn: weit.numerator, denIn: weit.denominator} 
      : type === 'out' 
        ? {numOut: weit.numerator, denOut: weit.denominator}
        : {numerator: weit.numerator, denominator: weit.denominator};
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const instrument = getInstrumentItem(values.asset_id);
      if(dataIndex === 'asset_id') {
        const newData = {...record, ...values, isin: instrument?.isin, asset_class_id: instrument?.financialAssetID};
        handleSave(newData);
      } else if(dataIndex === 'prio'){
        if(record?.prio === 10){
          const {numIn, denIn} = getWeight(record.weight_in, 'in');
          const {numOut, denOut} = getWeight(record.weight_out, 'out');
          const data = {
            ...record,
            ...values,
            group_no: instrument?.financialAssetID,
            weight_in: {numerator: +numIn, denominator: +denIn},
            weight_out: {numerator: +numOut, denominator: +denOut},
          };
          handleSave(data);
          form.setFieldsValue({...data, weight_in: 1, weight_out: 1});
        } else {
          const {numIn, denIn} = getWeight(record.weight_in, 'in');
          const {numOut, denOut} = getWeight(record.weight_out, 'out');
          const data = {
            ...record,
            ...values,
            group_no: null,
            weight_in: {numerator: +numIn, denominator: +denIn},
            weight_out: {numerator: +numOut, denominator: +denOut},
          };
          handleSave(data);
          form.setFieldsValue({...data, weight_in: 1, weight_out: 1});
        }

      } else if(dataIndex === 'weight_in') {
        const {numIn, denIn} = getWeight(values.weight_in, 'in');
        const {numOut, denOut} = getWeight(record.weight_out, 'out');
        const data = {
          ...record,
          ...values,
          weight_in: {numerator: +numIn, denominator: +denIn},
          weight_out: {numerator: +numOut, denominator: +denOut},
        };
        handleSave(data);
      } else if(dataIndex === 'weight_out'){
        const {numIn, denIn} = getWeight(record.weight_in, 'in');
        const {numOut, denOut} = getWeight(values.weight_out, 'out');
        const data = {
          ...record,
          ...values,
          weight_in: {numerator: +numIn, denominator: +denIn},
          weight_out: {numerator: +numOut, denominator: +denOut},
        };
        handleSave(data);
      } else {
        const {numIn, denIn} = getWeight(record.weight_in, 'in');
          const {numOut, denOut} = getWeight(record.weight_out, 'out');
        handleSave({
          ...record,
          ...values,
          weight_in: {numerator: +numIn, denominator: +denIn},
          weight_out: {numerator: +numOut, denominator: +denOut},
        });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  if (editing) {
    return (
      <td {...restProps}>
        {record?.prio === 10 &&
        ['weight_in', 'weight_out'].includes(dataIndex) ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <Input
              className="custom-input full-width"
              style={{ width: '100%' }}
              onBlur={save}
              placeholder={record[title]}
            />
          </Form.Item>
        ) : record?.prio === 10 && dataIndex === 'group_no'
        ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <CustomInputNumber
                className="custom-inputnumber"
                style={{ width: '100%' }}
                min={1}
                max={999}
                step={1}
                onBlur={save}
                onChange={save}
                onStep={save}
                onPressEnter={save}
                placeholder={record[dataIndex]}
              />
          </Form.Item>
        )
        : dataIndex === 'asset_id' ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <CustomSelect
              showSearch
              width="300px"
              className="custom-select"
              onSelect={save}
              placeholder={record[title]}
              filterOption={(inputValue, option) => {
                return inputValue.length > 2
                  ? option.children?.props?.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  : true;
              }}
              notFoundContent={<></>}
            >
              {instrumentList
                ?.slice()
                ?.sort((a, b) => textSort(a.name, b.name))
                ?.map(({ ID, name }) => (
                  <Select.Option key={ID} value={ID}>
                    {name}
                  </Select.Option>
                ))}
            </CustomSelect>
          </Form.Item>
        ) : dataIndex === 'prio' && record.asset_id 
          ? (
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
            >
              <CustomInputNumber
                className="custom-inputnumber"
                style={{ width: '100%' }}
                min={1}
                max={10}
                step={1}
                onBlur={save}
                onChange={save}
                onStep={save}
                onPressEnter={save}
                placeholder={record[dataIndex]}
              />
            </Form.Item>
            ) 
          : (
            children
        )}
      </td>
    );
  } else {
    return <td {...restProps}>{children}</td>;
  }
};

export default EditableCell;

EditableCell.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  getInstrumentItem: PropTypes.func,
  instrumentList: PropTypes.array,
};
