/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { emailRules, nameRules } from '../../../helpers/validation';
import {
  createAdvisor,
  getAdvisors,
  updateAdvisor,
} from '../../../data/store/advisors/advisorAction';
import {
  createClientToAccount,
  getAccountsList,
} from '../../../data/store/account/accountActions';
import { textSort } from '../../../helpers/textSort';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';

import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import CustomPhoneInput from '../../../components/CustomPhoneInput/CustomPhoneInput';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { ReactComponent as Delete } from '../../../assets/delete-black-icon.svg';
import { ReactComponent as Plus } from '../../../assets/pluse-black-icon.svg';
import { ReactComponent as Cross } from '../../../assets/cross-btn-icon.svg';

import ModalUnsaveData from '../../../components/ModalUnsaveData/ModalUnsaveData';
import ModalClearIdentification from '../../../components/ModalClearIdentification/ModalClearIdentification';
import { isEqual } from 'lodash';
import { updateUserData } from '../../../data/store/user/userActions';

const { Header } = Layout;

const InvateUserForm = ({ needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const { t } = useTranslation('');
  const { userId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const users = useSelector((state) => state.advisorReducer.users.list);
  const accounts = useSelector((state) => state.accountReducer.accounts.list);

  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalClearIN, setModalClearIN] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [isChangedData, setIsChangedData] = useState(false);
  const [isChangedRole, setIsChangedRole] = useState(false);
  const [isChangedPhone, setIsChangedPhone] = useState(false);

  useEffect(() => {
    console.log('isChangedData', isChangedData);
  }, [isChangedData]);

  useEffect(() => {
    console.log('isChangedRole', isChangedRole);
  }, [isChangedRole]);

  useEffect(() => {
    console.log('needSave', needSave);
  }, [needSave]);

  const initialValue = {
    email: '',
    first_name: '',
    last_name: '',
    role: '',
  };

  const statusList = [
    { id: 1, status: 'USER_STATUS_ACTIVE', label: 'ACTIVE' },
    { id: 2, status: 'USER_STATUS_DEACTIVATED', label: 'DEACTIVE' },
    { id: 3, status: 'USER_STATUS_AWAIT_APPROVE', label: 'AWAIT_APPROVE' },
    {
      id: 4,
      status: 'USER_STATUS_RESET_PASSWORD_CODE_SENT',
      label: 'RESET_PASSWORD_CODE_SENT',
    },
    {
      id: 5,
      status: 'USER_STATUS_VERIFY_EMAIL',
      label: 'USER_STATUS_VERIFY_EMAIL',
    },
  ];

  const getRole = (role) => {
    const clientRole = ['CLIENT_NOT_SECURE', 'CLIENT'];
    const adviserRole = ['ADVISER_NOT_SECURE', 'ADVISER'];
    const admin = ['SUPERADMIN_NOT_SECURE', 'SUPERADMIN'];
    if (clientRole.includes(role)) {
      return 'CLIENT';
    }
    if (adviserRole.includes(role)) {
      return 'ADVISER';
    }
    if (admin.includes(role)) {
      return 'SUPERADMIN';
    }
  };

  const getAdvisorData = useMemo(() => {
    if (userId) {
      const user = users?.find((i) => i.id === +userId);
      return user;
    } else return null;
  }, [users]);

  const getStatus = (status) => {
    if (userId) {
      const label = statusList.find((i) => i.status === status)?.label;
      return t(label);
    } else return status;
  };

  useEffect(() => {
    dispatch(getAdvisors({ limit: -1, offset: 0 }));
    if (!userId) {
      dispatch(getAccountsList());
      form.setFieldsValue(initialValue);
    }
  }, []);

  useEffect(() => {
    let data = initialValue;
    if (userId && getAdvisorData) {
      data = {
        ...getAdvisorData,
        role: getRole(getAdvisorData.role),
        status: getStatus(getAdvisorData.status),
      };
    }
    form.setFieldsValue(data);
  }, [users]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const role = Form.useWatch('role', form);
  const phonesArray = Form.useWatch('phones', form);

  const handleReset = () => {
    form.resetFields();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    history.replace('/users/users-list');
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    form.resetFields();
    closeModal();
    history.push(path);
  };

  const handleClearNumber = () => {
    console.log('Clear Identification number');
    setModalClearIN(userId);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleValueChange = async () => {
    const allValues = await form.getFieldsValue();
    console.log('🚀 ~ handleValueChange ~ allValues:', allValues);
    console.log('🚀 ~ handleValueChange ~ userId:', userId);
    if (!userId && Object.values(allValues).every((i) => !!i)) {
      try {
        await form.validateFields();
        setIsValid(true);
        setNeedSave(true);
      } catch (errorInfo) {
        setIsValid(false);
        setNeedSave(false);
      }
    } else {
      setIsValid(false);
      setNeedSave(false);
    }
    
    const { role, identification_number, phones, ...rest } = allValues;
    if (userId && Object.values(rest).every((i) => !!i)) {
      const oldProfile = {
        last_name: getAdvisorData.last_name,
        first_name: getAdvisorData.first_name,
        email: getAdvisorData.email,
        status: getStatus(getAdvisorData.status),
      };
      console.log('🚀 ~ handleValueChange ~ oldProfile:', oldProfile);
      setIsChangedData(!isEqual(oldProfile, rest));
      setIsChangedRole(!isEqual(getRole(getAdvisorData.role), role));
      setIsChangedPhone(!isEqual(getAdvisorData.phones, phones));

      try {
        await form.validateFields();
        setIsValid(true);
        setNeedSave(
          !isEqual(oldProfile, rest) ||
            !isEqual(getRole(getAdvisorData.role), role) ||
            !isEqual(getAdvisorData.phones, phones),
        );
      } catch (errorInfo) {
        setIsValid(false);
        setNeedSave(false);
      }
    }
  };

  const filterOption = (inputValue, option) => {
    const value = inputValue.toString();
    if (value?.length > 2) {
      return option?.children
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    } else {
      return option;
    }
  };

  const onCreate = (fields) => {
    const finish = () => {
      setNeedSave(false);
      form.resetFields();
      history.replace('/users/users-list');
    };
    if (!userId) {
      if (fields.role === 'CLIENT') {
        const { account, ...rest } = fields;
        dispatch(
          createClientToAccount({
            data: { accountId: account, client: rest },
            closeModal: finish,
          }),
        );
      } else {
        dispatch(
          createAdvisor({ data: fields, callback: null, history, finish }),
        );
      }
    } else {
      const { phones, role, ...rest } = fields;
      dispatch(
        updateUserData({
          id: userId,
          isChangedRole,
          isChangedPhone,
          isChangedData,
          role,
          phones,
          data: rest,
          finish,
        }),
      );
    }
  };

  return (
    <div className="instrument-wrapper instrument-block-wrap">
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className={'overview-btn header-btn'}>
              {userId ? t('EDIT_USER') : t('INVITE_USER')}
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="top-btn-wrapper">
        <Button
          type="text"
          className="portfolio-cancel-btn"
          icon={<Cross />}
          iconPosition="end"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disabled={!isValid || !needSave}
          type="default"
          className="portfolio-save-btn"
          onClick={handleSubmit}
        >
          {!userId ? t('INVITE') : t('SAVE')}
        </Button>
      </div>
      <div>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '4px', padding: '0px 40px' }}
        >
          <Col>
            <h2 className="portfolio-header-title">
              {userId ? t('EDIT_USER_INFORMATION') : t('USER_INVITATION')}
            </h2>
          </Col>
        </Row>
        <div className="edit-user-wrapper">
          <div className='new-user-card'>
            <h2 className='new-user-card-name'>
              {userId ? `${getAdvisorData?.first_name ?? ''} ${getAdvisorData?.last_name ?? ''}`: t('NEW_USER')}
            </h2>
            <h3 className='new-user-card-note'>
              {t(userId ? getRole(getAdvisorData?.role) : 'PLEASE_PROVIDE_INFORMATION')}
            </h3>
          </div>
          <Form
            form={form}
            name="instrument-form"
            layout="vertical"
            onFinish={onCreate}
            className="edit-usere-form"
          >
            <div>
              <Row jastify="space-between" align="middle">
                <Col span={12}>
                  <Form.Item label={t('USER_ROLE')} name="role" className="">
                    <Radio.Group
                      disabled={userId && role === 'CLIENT'}
                      className="checkbox-block"
                      onChange={handleValueChange}
                    >
                      <Space direction="horizontal" align="center">
                        <CustomRadioButtom disabled={userId} value="CLIENT">
                          {t('CLIENT_ROLE')}
                        </CustomRadioButtom>
                        <CustomRadioButtom value="ADVISER">
                          {t('ADVISER_ROLE')}
                        </CustomRadioButtom>
                        <CustomRadioButtom value="SUPERADMIN">
                          {t('SUPERADMIN_ROLE')}
                        </CustomRadioButtom>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {userId && (
                    <Form.Item 
                      label={t('STATUS')} 
                      name="status">
                      <Input
                        width="360px"
                        readOnly
                        className="custom-input full-width"
                        placeholder={t('STATUS')}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Divider className='new-account-divider'/>
              <Row className='edit-user-form-row'>
                <Col className='column'>
                  <Form.Item
                    className="custom-required"
                    label={t('FIRST_NAME')}
                    name="first_name"
                    rules={nameRules}
                    required={false}
                  >
                    <Input
                      placeholder={t('FIRST_NAME')}
                      onChange={handleValueChange}
                      className="custom-input full-width"
                    />
                  </Form.Item>
                </Col>
                <Col className='column'>
                  <Form.Item
                    className="custom-required"
                    label={t('LAST_NAME')}
                    name="last_name"
                    rules={nameRules}
                    required={false}
                  >
                    <Input
                      placeholder={t('LAST_NAME')}
                      onChange={handleValueChange}
                      className="custom-input full-width"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider className='new-account-divider'/>
              <Row className='edit-user-form-row'>
                <Col className={`column ${!userId && role !== 'CLIENT' ? 'full-width': '' }`}>
                  <Form.Item
                    label={t('EMAIL')}
                    style={{marginBottom: '0'}}
                    className="custom-required"
                    name="email"
                    required={false}
                    rules={emailRules}
                  >
                    <Input
                      placeholder={t('EMAIL')}
                      onChange={handleValueChange}
                      className="custom-input full-width"
                    />
                  </Form.Item>
                </Col>
                  {userId ? (
                    <Col className='column identification-number' >
                      <Form.Item
                        label={t('IDENTIFICATION_NUMBER')}
                        className="identification_number full-width"
                        name="identification_number"
                        required={false}
                      >
                        <Input
                          readOnly
                          placeholder={t('IDENTIFICATION_NUMBER')}
                          className="custom-input full-width"
                        />
                      </Form.Item>
                      <Delete
                        className="buket-delete-btn"
                        onClick={handleClearNumber}
                      />
                    </Col>
                  ) : !userId && role === 'CLIENT' ? (
                    <Col className='column'>
                      <Form.Item
                        label={t('ACCOUNT')}
                        name="account"
                      >
                        <CustomSelect
                          width="100%"
                          className="custom-select"
                          showSearch
                          placeholder={t('ACCOUNT')}
                          onChange={handleValueChange}
                          filterOption={(inputValue, option) =>
                            filterOption(inputValue, option)
                          }
                          rules={[
                            {
                              required: true,
                              message: `${t('SELECT_ACCOUNT')}`,
                            },
                          ]}
                        >
                          {accounts
                            ?.sort((a, b) => textSort(a.name, b.name))
                            ?.map(({ id, name, type }) => (
                              <Select.Option key={id} value={id}>
                                {name + ' -- ' + type}
                              </Select.Option>
                            ))}
                        </CustomSelect>
                      </Form.Item>
                    </Col>
                  ) : null}
              </Row>
              {userId && (
                <>
                  <Divider />
                  <Row style={{ gap: '16px'}}>
                    <Form.List name="phones">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Col 
                              key={key} 
                              className='users-phones-col'
                              style={{ width: '318px', display: 'flex', position: 'relative' }} >
                              <Form.Item
                                {...restField}
                                key={key}
                                label={`${t('MOBILE')}`}
                                className="form-phone custom-required"
                                name={[name, 'phone']}
                                rules={[
                                  {
                                    required: true,
                                    message: `${t('ENTER_PHONE_NUMBER')}`,
                                  },
                                ]}
                                required={false}
                              >
                                <CustomPhoneInput
                                  dropdownClassName="onboarding-custom-inputnumber-dropdown settings"
                                  nameCode={[name, 'country_code']}
                                  onChange={handleValueChange}
                                />
                              </Form.Item>
                              <Delete
                                className="buket-delete-btn"
                                onClick={() => remove(name)}
                              />
                            </Col>
                          ))}
                          {phonesArray?.length < 3 && (
                            <Col>
                              <Button
                                type="text"
                                icon={<Plus />}
                                iconPosition="end"
                                className="phone-add-button"
                                onClick={() =>
                                  add({ country_code: '', phone: '' })
                                }
                              >
                                {t('ADD_MORE_PHONE_NUMBERS')}
                              </Button>
                            </Col>
                          )}
                        </>
                      )}
                    </Form.List>
                  </Row>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
      <ModalClearIdentification
        open={modalClearIN}
        closeModal={() => setModalClearIN(null)}
      />
    </div>
  );
};

export default InvateUserForm;

InvateUserForm.propTypes = {
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
