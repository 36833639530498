import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { Input } from 'antd';
import { ReactComponent as Search } from '../../assets/search-icon.svg';

import './style.scss';
// const { Option } = Select;

const CustomSearchInput = ({ setFocus, ...props }) => {
  const inputRef = useRef(null);
  const { width = '360px', height = '48px', theme = 'light', className = '', ...restProps } = props;

  useEffect(() => {
    if (setFocus && inputRef.current) {
      inputRef.current.focus({
        cursor: 'start',
      });
    }
  }, [setFocus]);

  const sharedProps = { ref: inputRef };

  return (
    <div 
      className={theme === 'light' ? 'custom-input-wrap light-theme' : 'custom-input-wrap dark-theme'}
      style={{ position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
      <Input
        {...sharedProps}
        className={`custom-input custom-search-input ${className}`}
        style={{ width, height }}
        {...restProps}
      />
      <Search style={{ position: 'absolute', top: '5px', right: '16px' }}/>
    </div>
  );
};

export default CustomSearchInput;

CustomSearchInput.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  setFocus: PropTypes.bool,
  theme: PropTypes.string,
  className: PropTypes.string,
};
