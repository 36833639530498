/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Button, Form, Row, Table } from 'antd';
import { textSort } from '../../../helpers/textSort';

import { ReactComponent as Pluse } from '../../../assets/add-icon.svg';
import { ReactComponent as Delete } from '../../../assets/delete-black-icon.svg';
import EditableCell from './EditableCell';
import CustomSortIcon from '../../../components/CustomSortIcon/CustomSortIcon';

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const MapsInstrumetnTable = ({ selectedInstruments, data, setData }) => {
  const { t } = useTranslation('');

  const { assets, currencyList } = useSelector(
    (state) => state.instrumentsReducer,
  );
  const instruments = useSelector(
    (state) => state.instrumentsReducer.instruments?.list,
  );
  
  useEffect(() => {
    setData(selectedInstruments);
  }, [selectedInstruments]);
  
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ editingKey:', editingKey);
  }, [editingKey]);

  const isEditing = (record) =>
    record?.id === editingKey || record?.key === editingKey;

  const getInstrumentItem = (id) => {
    if (id) {
      return instruments.find((i) => i.ID === id);
    } else {
      return '- - -';
    }
  };

  const getClassItem = (id) => {
    if (id) {
      return assets?.find((i) => i.ID === id)?.name ?? '- - -';
    } else {
      return '- - -';
    }
  };

  const getCurrencyName = (id) => {
    if (id) {
      const instrument = instruments.find((i) => i.ID === id);
      return (
        currencyList?.find((i) => i.id === instrument?.currency_id)?.name ??
        '- - -'
      );
    } else {
      return '- - -';
    }
  };

  const [columns, setColumns] = useState(null);

  const handleEdit = (record) => {
    setEditingKey(record?.id ?? record.key);
  };

  const handleDeleteModal = (id) => {
    const newData = data?.filter((i) => typeof id === 'string' ? i.key !== id : i.id !== id);
    setEditingKey('');
    setData(newData);
  };

  const getWeightIn = (value) => {
    const {numerator, denominator} = value;
    return denominator === 1 || denominator === 0 
      ? numerator.toString() 
      : `${numerator}/${denominator}`;
  };

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        dataIndex: 'asset_id',
        className: 'asset_id first-column-p24',
        editable: true,
        width: '18%',
        render: (value) => (value ? getInstrumentItem(value)?.name : ''),
        sorter: (a, b) =>
          textSort(getInstrumentItem(a.asset_id)?.name, getInstrumentItem(b.asset_id)?.name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'asset_class_id',
        editable: true,
        width: '12%',
        render: (value) => getClassItem(value),
        sorter: (a, b) =>
          textSort(
            getClassItem(a.asset_class_id),
            getClassItem(b.asset_class_id),
          ),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('ISIN'),
        dataIndex: '',
        editable: true,
        width: '10%',
        render: (_, record) => getInstrumentItem(record.asset_id)?.isin,
        sorter: (a, b) =>
          textSort(getInstrumentItem(a.asset_id)?.isin, getInstrumentItem(b.asset_id)?.isin),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('CURRENCY'),
        dataIndex: '',
        editable: true,
        width: '8%',
        render: (_, record) => getCurrencyName(record.asset_id),
        sorter: (a, b) => textSort(getCurrencyName(a.asset_id), getCurrencyName(b.asset_id)),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('PRIORITY'),
        dataIndex: 'prio',
        editable: true,
        width: 100,
        sorter: (a, b) => a.prio - b.prio,
        sortDirections: ['descend', 'ascend'],
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('GROUP_NO'),
        dataIndex: 'group_no',
        editable: true,
        sorter: (a, b) => a.group_no - b.group_no,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        width: 90,
      },
      {
        title: t('WEIGHT_IN'),
        dataIndex: 'weight_in',
        render: (value) => getWeightIn(value),
        editable: true,
        width: 90,
      },
      {
        title: t('WEIGHT_OUT'),
        dataIndex: 'weight_out',
        render: (value) => getWeightIn(value),
        editable: true,
        width: 90,
      },
      {
        title: t('ACTIONS'),
        dataIndex: 'operation',
        className: 'operation',
        width: '4%',
        render: (_, record) => (
          <>
            <Button
              icon={<Delete />}
              type="text"
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteModal(record?.id || record.key);
              }}
            />
          </>
        ),
      },
    ];
    setColumns(columns);
  }, [data, instruments, assets, currencyList, editingKey]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleAdd = () => {
    const newData = {
      key: uuidv4(),
      asset_class_id: null,
      asset_id: '',
      group_no: null,
      prio: null,
      weight_in: {numerator: 1, denominator: 1},
      weight_out: {numerator: 1, denominator: 1},
    };

    data?.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleSave = (el) => {
    const newData = data.map((i) =>
      (i?.id && i?.id === el.id) || (i?.key && i?.key === el.key) ? el : i,
    );
    setData(newData);
  };

  const editColumns = columns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        getInstrumentItem,
        instrumentList: instruments?.filter((i) => i.financialAssetID !== 31),
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className='client portfolio-table'>
      <Table
        style={{width: '100%'}}
        rowKey={(record) => record?.id || record?.key}
        dataSource={data}
        size='middle'
        columns={editColumns}
        components={components}
        pagination={false}
        rowHoverable={false}
        onRow={(record) => ({
          onClick: () => handleEdit(record),
        })}
      />
      <Row style={{ padding: '0 24px 36px' }} justify={'end'}>
        <Button
          type="text"
          className="holdings-add-btn add-instrument"
          onClick={handleAdd}
          icon={<Pluse />}
          iconPosition={'end'}
        >
          {t('ADD')}
        </Button>
      </Row>
    </div>
  );
};

export default MapsInstrumetnTable;

MapsInstrumetnTable.propTypes = {
  mapsId: PropTypes.string,
  selectedInstruments: PropTypes.array,
  data: PropTypes.array,
  setData: PropTypes.func,
};
