/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Divider, Flex, Form, Input, Modal, Radio, Row, Space, Spin } from 'antd';
import { updateMapsInfo } from '../../data/store/instruments/instrumentsActions';
import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
import { CustomTextarea } from '../../components/CustomTextfield/CustomTextfield';
import { isEqual } from 'lodash';

const ModalStatusChangeModel = ({modalStatusOpen, handleStatusChangeClose}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { modelList, isLoading } = useSelector((state) => state.instrumentsReducer);
  const [isNeedSave, setNeedSave] = useState(false);

  const getModelById = () => {
    return modelList?.find(i => i.id === modalStatusOpen);
  };
  
  const validate = () => {
    const oldValue = {name: getModelById()?.name, description: getModelById()?.description, status: getModelById()?.status};
    const values = form.getFieldsValue();
    const needSave = !isEqual(oldValue, values);
    return needSave;
  };

  useEffect(() => {
    if(modalStatusOpen) {
      const model = getModelById();
      form.setFieldsValue(model);
      setNeedSave(validate());
    }
  }, [modalStatusOpen]);
  
  const handleChange = () => {
    setNeedSave(validate());
  };

  const handleSubmit = (value) => {
    const oldInfo = getModelById();
    const {status, ...rest} = value;
    const changeStatus = status !== getModelById()?.status;
    const changeInfo = rest?.name !== oldInfo?.name || rest?.description !== oldInfo?.description;
    if (changeInfo || changeStatus) {
      dispatch(updateMapsInfo({id: modalStatusOpen, data: rest, 
        status, callback: handleStatusChangeClose, changeInfo,
        changeStatus}));
    }
  };

  return (
    <Modal
      width={700}
      className="modal-content-paper model"
      open={modalStatusOpen}
      onCancel={handleStatusChangeClose}
      footer={null}
      zIndex={1600}>
      <Form
        form={form}
        name="new-model-form"
        layout="vertical"
        onFinish={handleSubmit}
        className="instrument-form"
        autoComplete="off"
        onChange={handleChange}
        disabled={isLoading}
      >
        <Row justify="start">
          <Col className="modal-title">{t('CONFIRM_CHANGE_INFO')}</Col>
        </Row>
        <Divider className='modal-divider'/>
        <Row>
          <Col span={24}>
            <Form.Item
              className='custom-required'
              label={t('NAME')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('PLEASE_ENTER_A_NAME'),
                },
              ]}
              required={false}>
              <Input
                placeholder={t('NAME')}
                className='custom-input full-width'/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name='description'
              className="onboarding-textarea-purpose"
              rules={[{ required: true, message: t('ENTER_DESCRIPTION') }]}
              required={false}
              label={t('DESCRIPTION')}>
              <CustomTextarea width={'100%'} rows={4} placeholder={t('ENTER_DESCRIPTION')} />
            </Form.Item>
          </Col>
        </Row>
          <Form.Item
            name='status'
            className="onboarding-textarea-purpose custom-required"
            rules={[{ required: true, message: t('ENTER_DESCRIPTION') }]}
            required={false}
            label={t('SELECT_STATUS')}>
            <Radio.Group
              className='customer-radio'>
              <Space
                direction="vertical"
                align="start"
                className="">
                <CustomRadioButtom value='development'>{t('DEVELOPMENT')}</CustomRadioButtom>
                <CustomRadioButtom value='staging'>{t('STAGING')}</CustomRadioButtom>
                <CustomRadioButtom value='production'>{t('PRODUCTION')}</CustomRadioButtom>
                <CustomRadioButtom value='succeeded'>{t('SUCCEEDED')}</CustomRadioButtom>
                <CustomRadioButtom value='deleted' disabled>{t('DELETED')}</CustomRadioButtom>
              </Space>
            </Radio.Group>
          </Form.Item>
        <Row justify="end" style={{marginTop: '50px'}}>
          <Col>
            <Button
              className="modal-action-btn cancel-btn"
              onClick={handleStatusChangeClose}
            >
              {t('CANCEL')}
            </Button>
            {
              isLoading
              ? <Flex align="center" justify="center" style={{display: 'flex', width: '154.36px', height: '40px'}}>
                  <Spin size="large" />
                </Flex>
              : <Button
                  disabled={isLoading || !isNeedSave} 
                  className="modal-action-btn" 
                  onClick={() => form.submit()}>
                  {t('CHANGE_INFO')}
                </Button>
            }
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalStatusChangeModel;

ModalStatusChangeModel.propTypes = {
  modalStatusOpen: PropTypes.number,
  handleStatusChangeClose: PropTypes.func,
};
