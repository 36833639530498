/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAdvisorById,
  getTwoFactorSecret,
  updateAdvisorPassword,
  updateUserPhones,
  userProfileUpdate,
} from '../../data/store/user/userActions';
import {
  emailRules,
  nameRules,
  passwordRules,
} from '../../helpers/validation';

import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Switch,
} from 'antd';
import ModalTwoFactror from '../../components/Modal2FA/ModalTwoFactror';
import Container from '../../components/Container';

import Loading from '../../components/Loading';

import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
// import { ReactComponent as Delete } from '../../assets/delete-icon.svg';
import { ReactComponent as WarningDot } from '../../assets/warning-dot-icon.svg';
import { ReactComponent as Cross } from '../../assets/cross-btn-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-white-icon.svg';
import { ReactComponent as Plus } from '../../assets/pluse-black-icon.svg';
import PersonalNumberRow from './PersonalNumberRow/PersonalNumberRow';

import CustomPhoneInput from '../../components/CustomPhoneInput/CustomPhoneInput';
import CustomPasswordInput from '../../components/CustomPasswordInput/CustomPasswordInput';
import { isEqual } from 'lodash';

import './style.scss';

const ProfileClient = () => {
  const dispatch = useDispatch();
  const advisor = useSelector((state) => state.userReducer.advisor);
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  const { t, i18n } = useTranslation('');
  const [form] = Form.useForm();

  const [language, setLanguage] = useState(null);
  const [newpass, setNewpass] = useState(null);
  const [confirmPass, setConfirmPass] = useState(null);
  const [isProfileValid, setIsProfileValid] = useState(false);
  const [isValidPass, setIsValidPass] = useState(false);

  useEffect(() => {
    setLanguage(i18n.language);
  }, []);

  const { first_name, last_name, phones } = advisor || {};
  const { email, id, identification_number, is_2fa } = advisor;

  const phonesArray = Form.useWatch('phones', form);

  const handleFieldChange = (_, allValue) => {
    const { email, first_name, last_name, phones } = allValue;
    const currentInfo = {email, first_name, last_name};
    const storedInfo = {email: advisor?.email, first_name: advisor?.first_name, last_name: advisor?.last_name};
    const isValidInfo = Object.values(currentInfo).every(i => !!i);
    const needSaveInfo = !isEqual(currentInfo, storedInfo);

    const isValidPhones = phones?.every(i => !!i.country_code && !!i.phone);
    const needSavePhones = !isEqual(phones, advisor.phones);
    setIsProfileValid((isValidInfo && needSaveInfo) || (isValidPhones && needSavePhones));
  };

  const initData = () => {
    const data = {
      first_name,
      last_name,
      phones,
      email,
    };
    form.setFieldsValue(data);
    handleFieldChange('', data);
  };

  useEffect(() => {
    if (advisor) {
      initData(initData);
    }
  }, [advisor]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const [alert, setAlert] = useState({ value: '', type: '' });
  const [open2FAModal, setOpen2FAModal] = useState(false);

  const successAlert = (value) => {
    setAlert(value);
    setTimeout(() => {
      setAlert('');
    }, 3500);
  };

  useEffect(() => {
    if (Object.keys(advisor).length === 0) {
      dispatch(getAdvisorById());
    }
  }, []);
  // 201801022383
  const [twofactor, setTwofactor] = useState(is_2fa);

  useEffect(() => {
    setTwofactor(is_2fa);
  }, [is_2fa]);

  const hendleChangeTwoAuth = () => {
    if (!is_2fa) {
      setOpen2FAModal(true);
      dispatch(getTwoFactorSecret());
    }
  };

  const hendleCloseModal = () => {
    setOpen2FAModal(false);
  };

  const handleSetPass = (event, type) => {
    const { value } = event.target;
    if (type === 'new') {
      setNewpass(value);
      setIsValidPass(
        value === confirmPass &&
          !!value.match(
            /^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/,
          ),
      );
    }
    if (type === 'confirm') {
      setConfirmPass(value);
      setIsValidPass(
        newpass === value &&
          !!value.match(
            /^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/,
          ),
      );
    }
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = () => {
    form.submit();
  };
  const onSubmit = (value) => {
    const {phones, ...rest} = value;
    dispatch(userProfileUpdate({ data: rest, id }));
    dispatch(updateUserPhones({ phones: phones || [], id }));
  };
  const handleSubmitChangePass = () => {
    if (isValidPass) {
      dispatch(
        updateAdvisorPassword({ id: id, fields: { password: newpass } }),
      );
    }
  };

  return (
    <div className="settings profile-wrap" style={{ position: 'relative' }}>
      <Row
        justify={'start'}
        align={'middle'}
        style={{ marginBottom: '', padding: '24px' }}
      >
        <h2 className="client settings-title">{t('SETTINGS')}</h2>
      </Row>
      <div>
      {!identification_number && !is_2fa && (
        <div className='is2fa-warning-wrapper'>
          <p className="client number-is2fa-warning"><WarningDot /> {t('INSTRUCTION')}</p>
        </div>
        )}
        <div style={{ padding: '0 24px', marginBottom: '24px' }}>
          <div className='settings-container'>
            <PersonalNumberRow
              successAlert={successAlert}
              value={identification_number}
            />
          </div>
        </div>
        <div style={{ padding: '0 24px', marginBottom: '24px' }}>
          <div className='settings-container'>
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ maxWidth: '1128px', marginBottom: '0', padding: '0' }}
            >
              <Col style={{ display: 'flex', alignItems: 'center', gap: '48px' }}>
                <h2 className="client setting-header-title">{t('ENABLE_2FA')}</h2>
              </Col>
              <Col style={{display: 'flex', alignItems: 'center'}}>
                <Switch
                  id='twoAuth-swich'
                  className={
                    is_2fa
                      ? 'client twoAuth-swich-button twoAuth-swich-button_off'
                      : 'client twoAuth-swich-button'
                  }
                  onChange={hendleChangeTwoAuth}
                  checked={twofactor}
                  disabled={is_2fa}
                >
                </Switch>
                  <label htmlFor="twoAuth-swich">
                    {t(!is_2fa ? 'OFF' : 'ON')}
                  </label>
              </Col>
            </Row>
          </div>
        </div>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '24px', padding: '16px 40px 0' }}
        >
          <Col>
            <h2 className="client suport-header-title">{t('PROFILE')}</h2>
          </Col>
        </Row>
        {alert.type && (
          <Alert
            message={alert.value}
            type={alert.type}
            className="profile-alert"
          />
        )}

        <Form
          form={form}
          name="client-profile"
          layout="vertical"
          onFinish={onSubmit}
          onValuesChange={handleFieldChange}
          style={{ padding: '0 24px' }}
        >
          <div className='settings-container profile' style={{ marginBottom: '24px'}}>
            <Row justify={'start'} style={{ gap: '48px' }}>
              <Col style={{ width: '360px' }}>
                <Form.Item
                  label={t('FIRST_NAME')}
                  className="custom-required full-width"
                  placeholder="First name"
                  name="first_name"
                  maxLength={50}
                  rules={nameRules}
                  required={false}
                >
                  <Input
                    autoFocus
                    autoComplete="off"
                    className="custom-input full-width"
                  />
                </Form.Item>
              </Col>
              <Col style={{ width: '360px' }}>
                <Form.Item
                  label={t('LAST_NAME')}
                  className="custom-required full-width"
                  placeholder="Last name"
                  name="last_name"
                  maxLength={50}
                  rules={nameRules}
                  required={false}
                >
                  <Input autoComplete="off" className="custom-input full-width" />
                </Form.Item>
              </Col>
            </Row>
            <Divider className="profile-divider" />
            <Row justify={'start'} style={{ gap: '48px', maxWidth: '1128px' }}>
              <Col style={{ width: '360px' }}>
                <Form.Item
                  label={t('EMAIL')}
                  className="custom-required full-width"
                  placeholder="Email"
                  name="email"
                  maxLength={50}
                  rules={emailRules}
                  required={false}
                >
                  <Input autoComplete="off" className="custom-input full-width" />
                </Form.Item>
              </Col>
              <Form.List name='phones'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Col key={key} style={{ width: '360px', display: 'flex', position: 'relative' }}>
                        <Form.Item
                          {...restField}
                          key={key}
                          label={`${t('MOBILE')}`}
                          className="form-phone custom-required"
                          name={[name, 'phone']}
                          rules={[{
                            required: true,
                            message: `${t('ENTER_PHONE_NUMBER')}`,
                          }]}
                          required={false}
                        >
                          <CustomPhoneInput
                            dropdownClassName="onboarding-custom-inputnumber-dropdown settings"
                            nameCode={[name, 'country_code']}
                            width='100%'
                          />
                        </Form.Item>
                        <Delete
                          className='profile-delete-btn delete-phone'
                          onClick={() =>  remove(name)} />
                      </Col>
                    ))}
                    {phonesArray?.length < 3 && (
                      <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          type="text"
                          icon={<Plus />}
                          iconPosition="end"
                          className="phone-add-button"
                          onClick={() => add({ country_code: '', phone: '' })}
                        >
                          {t('ADD_MORE_PHONE_NUMBERS')}
                        </Button>
                      </Col>
                    )}
                  </>
                )}
                </Form.List>
            </Row>
            <Row style={{marginTop: '24px'}}>
              <Col className="btn-wrapper">
                <Button
                  className="client-outline-btn"
                  onClick={initData}
                  icon={<Cross/>}
                  iconPosition='end'
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={!isProfileValid}
                  onClick={handleSubmit}
                  className="client-primary-btn"
                >
                  {t('SAVE')}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <div style={{ padding: '0 24px' }}>
          <div className='settings-container' style={{ marginBottom: '24px'}}>
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ maxWidth: '1128px', marginBottom: '0', padding: '0' }}
            >
              <Col>
                <h2 className="client setting-header-title">{t('LANGUAGE')}</h2>
              </Col>
              <Col className="instrument-button-wraper">
                <Radio.Group
                  className=""
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <Space direction="horizontal">
                    <CustomRadioButtom value="en">
                      {t('ENGLISH')}
                    </CustomRadioButtom>
                    <CustomRadioButtom value="sw">
                      {t('SVENSKA')}
                    </CustomRadioButtom>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </div>
        </div>
        <Form
          name="change-pass"
          layout="vertical"
          style={{ padding: '0 24px' }}
        >
          <div className='settings-container' style={{ marginBottom: '24px'}}>
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ maxWidth: '1128px', marginBottom: '41px', padding: '0' }}
            >
              <Col>
                <h2 className="client setting-header-title">{t('CHANGE_PASSWORD')}</h2>
              </Col>
            </Row>
            <Row
              className="setting-password-row"
              justify={'start'}
              align={'top'}
              style={{ gap: '48px', maxWidth: '1128px', flexWrap: 'nowrap' }}
            >
              <Col>
                <Form.Item
                  className="client-input-label"
                  label={t('PASSWORD')}
                  placeholder={t('PASSWORD')}
                  name="password"
                  maxLength={50}
                  initialValue={newpass}
                  rules={passwordRules}
                  required={false}
                >
                  <CustomPasswordInput
                    width={360}
                    autoComplete="off"
                    className=""
                    onChange={(value) => handleSetPass(value, 'new')}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="client-input-label"
                  label={t('CONFIRM_PASSWORD')}
                  placeholder={t('CONFIRM_PASSWORD')}
                  name="password-confirm"
                  maxLength={50}
                  initialValue={confirmPass}
                  rules={passwordRules}
                  required={false}
                >
                  <CustomPasswordInput
                    width={360}
                    autoComplete="off"
                    className=""
                    onChange={(value) => handleSetPass(value, 'confirm')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                style={{ marginTop: '24px' }}
                disabled={!isValidPass}
                onClick={handleSubmitChangePass}
                className="client-primary-btn"
              >
                {t('CHANGE_PASSWORD')}
              </Button>
            </Row>
          </div>
        </Form>
        
        <ModalTwoFactror
          open2FAModal={open2FAModal}
          closeModal={hendleCloseModal}
        />
        <Loading loading={isLoading} />
      </div>
    </div>
  );
};

export default ProfileClient;
