import { Button, Col, Progress, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { oneDecimalWithSpaces } from '../../../helpers/formatFractional';
import { getPieData } from '../../../hooks/getPieData';
import { getAsseData } from '../../../hooks/getAsseData';

const OptimAssetsClass = ({currentData, optimalData, cashesList, currencyList}) => {
  const { t } = useTranslation('');

    const [pieCurrentData, setPieCurrentData] = useState(null);
    const [pieOptData, setPieOptData] = useState(null);

    const [asseCurrentData, setAsseCurrentData] = useState(null);
    const [asseOptData, setAsseOptData] = useState(null);

  useEffect(() => {
      if (currentData && currencyList) {
        const data = getPieData([currentData], cashesList);
        setPieCurrentData(data);
      }
      if (currentData && currencyList) {
        const assetData = getAsseData([currentData], currencyList);
        setAsseCurrentData(assetData);
      }
    }, [currentData, cashesList, currencyList]);

  useEffect(() => {
      if (optimalData && currencyList) {
        const data = getPieData([optimalData], cashesList);
        setPieOptData(data);
      }
      if (optimalData && currencyList) {
        const assetData = getAsseData([optimalData], currencyList);
        setAsseOptData(assetData);
      }
    }, [ optimalData, cashesList, currencyList]);

const handleTabs = () => {
  setTabs(tabs ? 0 : 1);
};

  const [tabs , setTabs  ] = useState(0);
  return (
    <div className='class-side-wrap'>
      <Row className='class-side-headers' justify={'space-between'} style={{padding: '24px'}}>
        <Col>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
            onClick={handleTabs}
          >
            {t('CATEGORY')}
          </Button>
          <Button
          type='text'
          className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
          onClick={handleTabs}
          >
            {t('CLASS')}
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <div className='class-category-wrapper'>
        {tabs === 0 && pieCurrentData?.category?.sort((a, b) => b.y - a.y)?.map(item => (
          <div className='client table-wrapper' key={item.name}>
            <div className='table-date-row'>
              <div>
                <p className='table-date-name'>{item.name?.toUpperCase()}</p>
                <p className='table-date-persent'>{(item.y * 100 / pieCurrentData.dataTotal).toFixed(2)}%</p>
              </div>
              <div className='table-date-value'>{`${oneDecimalWithSpaces(item.y)} kr`}</div>
            </div>
            <Progress 
              className='table-date-progress'
              percent={(item.y * 100 / pieCurrentData.dataTotal).toFixed(2)} 
              showInfo={false} 
              strokeColor={'#FB7B34'}
              size={{height: 2}}
            />
          </div>
        ))
        }
      </div>
      <div className='class-category-wrapper'>
        {tabs === 0 && pieOptData?.category?.sort((a, b) => b.y - a.y)?.map(item => (
          <div className='client table-wrapper' key={item.name}>
            <div className='table-date-row'>
              <div>
                <p className='table-date-name'>{item.name?.toUpperCase()}</p>
                <p className='table-date-persent opt'>{(item.y * 100 / pieOptData.dataTotal).toFixed(2)}%</p>
              </div>
              <div className='table-date-value'>{`${oneDecimalWithSpaces(item.y)} kr`}</div>
            </div>
            <Progress 
              className='table-date-progress'
              percent={(item.y * 100 / pieOptData.dataTotal).toFixed(2)} 
              showInfo={false} 
              strokeColor={'#6467C3'}
              size={{height: 2}}
            />
          </div>
        ))
        }
      </div>
      <div className='class-category-wrapper'>
        {tabs === 1 && asseCurrentData?.assetClass?.sort((a, b) => b.y - a.y)?.map(item => (
          <div className='client table-wrapper' key={item.name}>
            <div className='client table-wrapper'>
              <div className='table-date-row'>
                <div>
                  <p className='table-date-name'>{item.name?.toUpperCase()}</p>
                  <p className='table-date-persent'>{(item.y * 100 / asseCurrentData.dataTotal).toFixed(2)}%</p>
                </div>
                <div className='table-date-value'>{`${oneDecimalWithSpaces(item.y)} kr`}</div>
              </div>
              <Progress 
                className='table-date-progress'
                percent={(item.y * 100 / asseCurrentData.dataTotal).toFixed(2)} 
                showInfo={false} 
                strokeColor={'#FB7B34'}
                size={{height: 2}}
              />
            </div>
          </div>
        ))
        }
      </div>
      <div className='class-category-wrapper'>
        {tabs === 1 && asseOptData?.assetClass?.sort((a, b) => b.y - a.y)?.map(item => (
          <div className='client table-wrapper' key={item.name}>
            <div className='client table-wrapper'>
              <div className='table-date-row'>
                <div>
                  <p className='table-date-name'>{item.name?.toUpperCase()}</p>
                  <p className='table-date-persent opt'>{(item.y * 100 / asseOptData.dataTotal).toFixed(2)}%</p>
                </div>
                <div className='table-date-value'>{`${oneDecimalWithSpaces(item.y)} kr`}</div>
              </div>
              <Progress 
                className='table-date-progress'
                percent={(item.y * 100 / asseOptData.dataTotal).toFixed(2)} 
                showInfo={false} 
                strokeColor={'#6467C3'}
                size={{height: 2}}
              />
            </div>
          </div>
        ))
        }
      </div>
    </div>
  );
};


export default OptimAssetsClass;

OptimAssetsClass.propTypes = {
  currentData: PropTypes.object,
  optimalData: PropTypes.object,
  cashesList: PropTypes.array,
  currencyList: PropTypes.array,
};