import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import OnboardingPlanning from './OnboardingPlanning';
import { getGooleClientId } from '../../data/store/account/accountActions';

const OnboardingWithGoogle = ({userId, setAccountInfo, accountInfo, url}) => {
  const dispatch = useDispatch();
  const { googleClientId } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!googleClientId || googleClientId === '127645881125') {
      dispatch(getGooleClientId());
    }
  }, [googleClientId, dispatch]);

  return (
    <GoogleOAuthProvider clientId={googleClientId || '127645881125'}>
      <OnboardingPlanning 
        userId={userId} 
        setAccountInfo={setAccountInfo} 
        accountInfo={accountInfo} 
        url={url}
      />
    </GoogleOAuthProvider>
  );
};

export default OnboardingWithGoogle;

OnboardingWithGoogle.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  accountInfo: PropTypes.object,
  setAccountInfo: PropTypes.func,
};