/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  activateAdvisor,
  deactivateAdvisor,
  deleteAdvisor,
  getAdvisors,
  getUsersForAdvisor,
  reinviteAdvisor,
} from '../../data/store/advisors/advisorAction';

import { Row, Button, Table, Col, Layout, Modal, Divider } from 'antd';

import { ReactComponent as Filter } from '../../assets/filter-icon.svg';
import Container from '../../components/Container';
import CustomSearchInput from '../../components/CustomSearchInput/CustomSearchInput';

import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/pluse-litl-icon.svg';
import { ReactComponent as Deactiv } from './img/deactivate-icon.svg';
import { ReactComponent as Reinvite } from './img/reinvite-icon.svg';
import { ReactComponent as Activate } from './img/activate-icon.svg';
import { ReactComponent as Approve } from './img/approve-icon.svg';

import { textSort } from '../../helpers/textSort';
import ModalFilterUsers from '../../components/ModalFilterUsers/ModalFilterUsers';

import './style.scss';
import CustomSortIcon from '../../components/CustomSortIcon/CustomSortIcon';

const { Header } = Layout;
const RoleList = {
  'CLIENT': 'CLIENT_ROLE',
  'CLIENT_NOT_SECURE': 'CLIENT_ROLE',
  'SUPERADMIN': 'SUPERADMIN_ROLE',
  'ADVISER': 'ADVISER_ROLE',
  'ADVISER_NOT_SECURE': 'ADVISER_ROLE',
};

const Users = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();

  const users = useSelector((state) => state.advisorReducer?.users?.list);

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [filter, setFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(getAdvisors({ limit: -1, offset: 0 }));
  }, []);

  useEffect(() => {
    setData(users?.sort((a,b) => textSort(a.first_name + ' ' + a.last_name, b.first_name + ' ' + b.last_name)));
  }, [users]);

  useEffect(() => {
    if(filter) {
      const filteredUsers = users.filter(i => (filter.role.includes(i.role) || filter.role.includes('All')) &&
      (filter.status.includes(i.status) || filter.status.includes('All')));
      setData(filteredUsers);
    } else {
      setData(users);
    }
  }, [filter]);

  const handlDeactivate = (key) => {
    dispatch(deactivateAdvisor(key));
  };

  const handlActivate = (key) => {
    dispatch(activateAdvisor({ id: key, history: null }));
  };

  const handlReSendInvite = (id) => {
    dispatch(reinviteAdvisor(id));
  };
  const handlApproval = (key) => {
    dispatch(activateAdvisor({ id: key, history: null }));
  };

  const hendleEdit = (id) => {
    history.push(`/users/edit-user/${id}`);
  };

  const getAdvisorNAme = (id) => {
    const find = users?.find(i => i.id === id);
    if (find) {
      return `${find?.first_name} ${find?.last_name}`;
    } else {
      return '';
    }
  };

  const handleDeleteMOdal = (id) => {
    setDeleteId(id);
    setOpenModalDelete(true);
    console.log('handleDelete');
  };

  const cancelWithoutDelete = () => {
    setDeleteId(null);
    setOpenModalDelete(false);
  };

  const onSubmitDelete = () => {
    console.log('handleDelete');
    dispatch(deleteAdvisor(deleteId));
    cancelWithoutDelete();
    setDeleteId(null);
  };

  const getStatusStyle = (value) => {
    if (value === 'USER_STATUS_AWAIT_APPROVE') return 'yellow';
    if (value === 'USER_STATUS_ACTIVE') return 'green';
    if (value === 'USER_STATUS_DEACTIVATED') return 'red';
    if (value === 'USER_STATUS_DEACTIVATED') return 'red';
    if (value === 'USER_STATUS_RESET_PASSWORD_CODE_SENT') return 'purple';
    return 'purple';
  };

  const getStatusAction = (value) => {
    if (value === 'USER_STATUS_AWAIT_APPROVE') {
      return {
        action: handlApproval,
        title: t('APPROVE'),
        style: 'approve',
        icon: <Approve />
      };
    }
    if (value === 'USER_STATUS_ACTIVE') {
      return {
        action: handlDeactivate,
        title: t('DEACTIVATE'),
        style: 'deactivate',
        icon: <Deactiv />
      };
    }
    if (value === 'USER_STATUS_DEACTIVATED') {
      return {
        action: handlActivate,
        title: t('ACTIVATE'),
        style: 'active',
        icon: <Activate />
      };
    }
    if (value === 'USER_STATUS_RESET_PASSWORD_CODE_SENT') {
      return {
        action: handlReSendInvite,
        title: t('RE_INVITE'),
        style: 'active',
        icon: <Reinvite />
      };
    }
    return null;
  };

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'first_name',
      className: 'name first-column-p24',
      width: '15%',
      render: (value, record) => `${value} ${record?.last_name}`,
      sorter: (a, b) => textSort(a.first_name + ' ' + a.last_name, b.first_name + ' ' + b.last_name),
      sortIcon: (props) => <CustomSortIcon {...props} />,
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      width: '10%',
      sorter: (a, b) => textSort(a.email, b.email),
      sortIcon: (props) => <CustomSortIcon {...props} />,
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phones',
      width: '15%',
      render: (value) => (
        value?.map(
          (i) => (
            <span key={uuidv4()} style={{ display: 'block', marginBottom: '3px' }}>
              {i.country_code} {i.phone}
            </span>
          )
        ) || ''
      ),
    },
    {
      title: t('ROLE_HEADER'),
      dataIndex: 'role',
      width: '10%',
      render: (value) => t(RoleList[value]),
      sorter: (a, b) => textSort(a.role, b.role),
      sortIcon: (props) => <CustomSortIcon {...props} />,
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      width: '15%',
      render: (value) => {
        return (
          <span className={`status-advisor ${getStatusStyle(value)}`}>
            {value === 'USER_STATUS_AWAIT_APPROVE'
              ? t('AWAITING_APPROVAL')
              : value === 'USER_STATUS_ACTIVE'
                ? t('ACTIVE')
                : value === 'USER_STATUS_DEACTIVATED'
                  ? t('NOT_ACTIVE')
                  : t('NOT_ACTIVATED_YET')}
          </span>
        );
      },
      sorter: (a, b) => textSort(getStatusStyle(a.status), getStatusStyle(b.status)),
      sortIcon: (props) => <CustomSortIcon {...props} />,
    },
    {
      title: t('ACTIONS'),
      dataIndex: 'operation',
      className: 'operation',
      width: '20%',
      render: (_, record) => {
        return (
          <div
            className="cell-button-wrapper"
            style={{ justifyContent: 'flex-start' }}
          >
            {getStatusAction(record.status) && (
              <Button
                type="text"
                icon={getStatusAction(record.status)?.icon}
                iconPosition='end'
                className={`action-btn users-action ${
                  getStatusAction(record.status)?.style
                }`}
                onClick={() =>
                  getStatusAction(record.status)?.action(record.id)
                }
              >
                {getStatusAction(record.status)?.title}
              </Button>
            )}
            <Button type="text" onClick={() => hendleEdit(record.id)}>
              <Edit />
            </Button>
            {(getStatusStyle(record.status) === 'red' || getStatusStyle(record.status) === 'purple') && (
              <Button type="text" onClick={() => handleDeleteMOdal(record.id)}>
                <Delete />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value?.length > 2) {
      const searched = users?.filter(
        (i) =>
          (i.first_name + ' ' + i.last_name)
            .toLowerCase()
            .includes(value?.toLowerCase()) ||
          i.ISIN?.toLowerCase().includes(value?.toLowerCase())
      );
      setData(searched);
    } else {
      setData(users);
    }
    setFilter(null);
  };

  const handeleFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCreate = () => {
    history.push('/users/invite-advisor');
  };

  return (
    <div className="instrument-wrapper instrument-block-wrap">
      <div className="top-btn-wrapper">
        <Button
          type="text"
          icon={<Pluse />}
          iconPosition='end'
          onClick={handleCreate}
          className="portfolio-details-btn optimise-btn user-btn">
          {t('INVITE_USER')}
        </Button>
      </div>
      <Header className="headerStyle suggested contrast">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className="overview-btn header-btn">
              {t('USERS')}
            </Button>
          </Col>
        </Row>
        <Row className="subheader-row">
          <Col>
            <h2 className="subheader-title">{t('USERS_LIST')}</h2>
          </Col>
          <Col className="instrument-button-wraper">
            <CustomSearchInput
              className='account-select-search suggested'
              placeholder={t('SEARCH_LC')}
              onChange={handleSearch}
            />
            <Button
              className="filter-btn"
              type="default"
              onClick={handeleFilter}
              icon={<Filter />}
              iconPosition="end"
            >
              {t('FILTER')}
              {filter && data?.length
                ? (
                <span className="filter-counter">{data?.length}</span>
                  )
                : (
                    ''
                  )}
            </Button>
          </Col>
        </Row>
      </Header>
      <div  style={{ width: '100%', marginTop: '176px' }}>
        <div className="client portfolio-table" style={{ width: '100%' }}>
          <Table
            dataSource={data}
            columns={columns}
            size='middle'
            pagination={false}
          />
        </div>
      </div>
      <Modal
        width={700}
        className="modal-content-paper"
        open={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        footer={null}
        zIndex={1600}>
        <Row justify="start">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Divider className='modal-divider'/>
        <Row justify="start" className="modal-text-wrapper">
          <Col>
            <p className="modal-description">{t('MODAL_DELETE_TEXT')}</p>
            <p className="modal-name">{getAdvisorNAme(deleteId)}</p>
          </Col>
        </Row>

        <Row justify="end" style={{marginTop: '50px'}}>
          <Col>
            <Button
              className="modal-action-btn cancel-btn"
              onClick={cancelWithoutDelete}
            >
              {t('CANCEL')}
            </Button>
            <Button 
              style={{marginLeft: '8px'}}
              className="modal-action-btn delete-btn" 
              onClick={onSubmitDelete}>
              {t('DELETE')}
            </Button>
          </Col>
        </Row>
      </Modal>
      <ModalFilterUsers
        setFilter={setFilter}
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
      />
    </div>
  );
};

export default Users;
