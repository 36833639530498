/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { Button, Form, Radio, Space } from 'antd';

import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { NextCategory } from '../data/NextCategory';

import { ReactComponent as Confirm } from '../img/confirm-icon.svg';
import { PurposeMatter } from '../data/InitCategoryData';


const CategoryPurposeMatter = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  function useQuery() {
      return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const category = query.get('category');
    const tab = query.get('tab');

  useEffect(() => {
      if(tab === undefined) {
        history.push(`${url}?category=client-information&tab=0`);
      }
    }, [tab]);

  useEffect(() => {
    if (onboardingData) {
      const result = _.merge({},PurposeMatter, onboardingData.result );
      form.setFieldsValue(result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = () => {
    const allValues = form.getFieldsValue(true);
    const result = _.merge({}, onboardingData.result, allValues);
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
        const path = NextCategory(category);
        history.push(`${pathname}?category=${path}`);
    
  };

  return (
    <div className="card-right-wrap"  style={{ paddingTop: '56px', paddingBottom: '24px',}}>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="">
        <Form.Item
          name={['invest_target', 'additional_questions', 'investment_purpose']}
          className="onboarding-textarea-purpose custom-required"
          label={t('WHAT_IS_THE_PURPOSE_GOAL')}
          // rules={[{ required: true, message: t('ENTER_DATA') }]}
          required={false}>
          <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_ANSWER')} maxLength={800}/>
        </Form.Item>
        <Form.Item
          name={['invest_target', 'additional_questions', 'heart_affairs']}
          className="onboarding-textarea-purpose"
          label={t('HEART_AFFAIRS')}>
          <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_ANSWER')} maxLength={800}/>
        </Form.Item>
        <Form.Item
          label={t('QUALITATIVE_GOALS')}
          name={['invest_target', 'additional_questions', 'qualitative_goals']}
          className="m-top-12 m-bottom-0 custom-required "
          // rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}
          >
          <Radio.Group className="checkbox-block">
            <Space direction="vertical" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value="qualitative_goals_option1">
                {t('NONE_RETURN_FOCUS')}
              </CustomRadioButtom>
              <CustomRadioButtom value="qualitative_goals_option2">
                {t('BASIC_SUSTAINABILITY')}
              </CustomRadioButtom>
              <CustomRadioButtom value="qualitative_goals_option3">
                {t('SUSTAINABILITY_IN_DEPTH')}
              </CustomRadioButtom>
              <CustomRadioButtom value="qualitative_goals_option4">
                {t('STRONG_IMPACT')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('SUSTAINABLE_VIEW')}
          name={['invest_target', 'additional_questions', 'sustainable_view']}
          className="m-top-12 m-bottom-0 custom-required"
          // rules={[{ required: true, message: t('SELECT_YOUR_OPTION') }]}
          required={false}>
          <Radio.Group className="checkbox-block">
            <Space direction="vertical" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value="sustainable_view_option1">
                {t('MY_FOCUS_IN_INVESTMENTS')}
              </CustomRadioButtom>
              <CustomRadioButtom value="sustainable_view_option2">
                {t('I_WANT_INTEGRATE_SUSTAINABILITY')}
              </CustomRadioButtom>
              <CustomRadioButtom value="sustainable_view_option3">
                {t('SUSTAINABILITY_IS_VERY_IMPORTANT')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['invest_target', 'additional_questions', 'other_aspects']}
          className="onboarding-textarea-purpose m-top-12"
          label={t('THERE_OTHER_ASPECTS_WORKING')}>
          <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_ANSWER')} />
        </Form.Item>
        <Button
          icon={<Confirm />}
          iconPosition='end'
          type='text'
          className="start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('CONFIRM')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryPurposeMatter;

CategoryPurposeMatter.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
