import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Modal, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteModelLabel } from '../../data/store/instruments/instrumentsActions';

const ModelDeleteModalLabel = ({openModalDelete, closeOpenModalDelete, labels}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const getLabel = (id) => labels?.find(i => i.id === id);

  const onSubmitDelete = () => {
    dispatch(deleteModelLabel({id: getLabel(openModalDelete)?.asset_class_model_id, labelId: openModalDelete }));
    closeOpenModalDelete();
  };

  return (
    <Modal
        width={700}
        className="modal-content-paper"
        open={openModalDelete}
        onCancel={closeOpenModalDelete}
        footer={null}
        zIndex={1600}>
        <Row justify="start">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Divider className='modal-divider'/>
        <Row justify="start" className="modal-text-wrapper">
          <Col>
            <p className="modal-description">{t('MODAL_DELETE_LABEL')}</p>
            <p className="modal-name">{getLabel(openModalDelete)?.name}</p>
          </Col>
        </Row>
        <Row justify="end" style={{marginTop: '50px'}}>
        <Col>
          <Button
            className='modal-action-btn cancel-btn'
            onClick={closeOpenModalDelete}
          >
            {t('CANCEL')}
          </Button>
          <Button 
            style={{marginLeft: '8px'}} 
            className="modal-action-btn delete-btn" 
            onClick={onSubmitDelete}>
            {t('DELETE')}
          </Button>
        </Col>
        </Row>
      </Modal>
  );
};

export default ModelDeleteModalLabel;

ModelDeleteModalLabel.propTypes = {
  openModalDelete: PropTypes.number,
  closeOpenModalDelete: PropTypes.func,
  labels: PropTypes.array,
};