/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  // useDispatch,
  useSelector,
} from 'react-redux';

import { Button, Layout, Typography } from 'antd';

import LogoType from '../../assets/lt_full_logo.png';

import { ReactComponent as LogoDark } from '../../assets/life-logo-dark.svg';
import { ReactComponent as TogglerLeft } from '../../assets/double-arrows-left.svg';
import { ReactComponent as TogglerRight } from '../../assets/double-arrows-right.svg';
import { ReactComponent as ListCollapse } from '../../assets/list-collapse-icon.svg';
import { ReactComponent as SelectAcc } from '../../assets/select-acc-icon.svg';
import { ReactComponent as Logout } from '../../assets/logout-icon.svg';

import { ReactComponent as Owerview } from '../../assets/owerview-menu-icon.svg';
import { ReactComponent as Lifetiming } from '../../assets/lifetiming-menu-icon.svg';
import { ReactComponent as Document } from '../../assets/document-menu-icon.svg';
import { ReactComponent as Suggested } from '../../assets/suggested-menu-icon.svg';
import { ReactComponent as Details } from '../../assets/details-menu-icon.svg';
import { ReactComponent as Checked } from '../../assets/dot-menu-checked.svg';


import { ReactComponent as Quant } from '../../assets/menu-quant-icon.svg';
import { ReactComponent as Model } from '../../assets/menu-model-icon.svg';
import { ReactComponent as Maps } from '../../assets/menu-maps-icom.svg';
import { ReactComponent as InstrumentMapping } from '../../assets/menu-instrument-mapping-icon.svg';
import { ReactComponent as AssetClass } from '../../assets/menu-assetclass-icon.svg';
import { ReactComponent as Users } from '../../assets/menu-users-icon.svg';
import { ReactComponent as OrderInstraction } from '../../assets/menu-orderinstraction-icon.svg';
import { ReactComponent as Support } from '../../assets/menu-support-icon.svg';
import { ReactComponent as Settings } from '../../assets/menu-settings-icon.svg';

import { logOut } from '../../data/store/user/userActions';
import CustomSearchInput from '../CustomSearchInput/CustomSearchInput';
import { textSort } from '../../helpers/textSort';

import ButtonAdd from '../ButtonAdd/ButtonAdd';
import CustomMenu from './CustomMenu/CustomMenu';

import './style.scss';

const { Sider } = Layout;
const { Text } = Typography;

const SideBar = ({
  userId,
  setUserId,
  needSave,
  setSaveAlert,
  setOptAlert,
  optStart,
  theme,
}) => {
  const { t } = useTranslation('');
  const containerRef = useRef(null);
  const containerNavRef = useRef(null);
  const { pathname, state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [hideList, setHideList] = useState(true);
  const [clientMenu, setClientMenu] = useState(null);
  const [appMenuID, setAppMenuID] = useState(null);


  const users = useSelector((state) => state.accountReducer.accounts?.list);
  const isLoadingClient = useSelector((state) => state.accountReducer.isLoading);
  const isLoadingPortfolio = useSelector((state) => state.portfolioReducer.isLoading);
  const { role, first_name, last_name } = useSelector((state) => state.userReducer.advisor);

  const isLoading = useMemo(() => {
    return isLoadingClient || isLoadingPortfolio;
  }, [isLoadingClient, isLoadingPortfolio]);

  const [filteredUsers, setFilteredUsers] = useState(null);

  useEffect(() => {
    console.log('needSave', needSave);
  }, [needSave]);

  useEffect(() => {
    if (users) setFilteredUsers(users);
  }, [users]);

  const handleFiletUsers = (event) => {
    const { value } = event.target;
    if (users?.length && value?.length > 2) {
      if (isNaN(+value)) {
        const filtered = users?.filter(user => user?.name.toLowerCase().includes(value.toLowerCase()));
        setFilteredUsers(filtered);
      } else if (value?.length >= 3) {
        const filtered = users?.filter(user => user?.portfolio?.some(i => i?.toString()?.includes(value)));
        setFilteredUsers(filtered);
      }
    } else {
      setFilteredUsers(users);
    }
  };

  useEffect(() => {
    if (state?.from) {
      setHideList(false);
    }
  }, [state]);

  useEffect(() => {
    if (userId) {
      setCurrentUser(userId);
      const container = containerRef.current;
      container.scrollTo({ top: 0, behavior: 'instant' });
      setHideList(true);
    } else if (pathname !== '/accounts') {
      setCurrentUser(null);
      setHideList(true);
    } else if (!userId) {
      setCurrentUser(null);
      setHideList(true);
    }
  }, [userId]);

  const clientMenuItem = [
    {
      key: 11,
      label: t('OVERVIEW'),
      path: `/accounts/${currentUser}/portfolio`,
      icon: <Owerview className='account-menu-item'/>
    },
    {
      key: 12,
      label: t('LIFETIMING'),
      path: `/accounts/${currentUser}/lifetiming`,
      icon: <Lifetiming className='account-menu-item'/>
    },
    {
      key: 13,
      label: t('DOCUMENTS'),
      path: `/accounts/${currentUser}/documents`,
      icon: <Document className='account-menu-item'/>
    },
    {
      key: 14,
      label: t('SUGGESTED_PORTFOLIOS'),
      path: `/accounts/${currentUser}/suggested-portfolios`,
      icon: <Suggested className='account-menu-item'/>
    },
    {
      key: 16,
      label: t('ACCOUNT_DETAILS'),
      path: `/accounts/${currentUser}/inform/details`,
      icon: <Details className='account-menu-item'/>
    },
  ];
  const clientMenuItemAdv = [
    {
      key: 11,
      label: t('OVERVIEW'),
      path: `/accounts/${currentUser}/portfolio`,
      icon: <Owerview className='account-menu-item'/>
    },
    {
      key: 12,
      label: t('LIFETIMING'),
      path: `/accounts/${currentUser}/lifetiming`,
      icon: <Lifetiming className='account-menu-item'/>
    },
    {
      key: 13,
      label: t('DOCUMENTS'),
      path: `/accounts/${currentUser}/documents`,
      icon: <Document className='account-menu-item'/>
    },
    {
      key: 14,
      label: t('SUGGESTED_PORTFOLIOS'),
      path: `/accounts/${currentUser}/suggested-portfolios`,
      icon: <Suggested className='account-menu-item'/>
    },
    {
      key: 16,
      label: t('ACCOUNT_DETAILS'),
      path: `/accounts/${currentUser}/inform/details`,
      icon: <Details className='account-menu-item'/>
    },
  ];

  const items = [
    {
      key: '2',
      icon: <Quant />,
      label: t('QUANT'),
      children: [
        {
          key: '21',
          label: t('MODEL'),
          icon: <Model />,
          path: '/quant/model'
        },
        {
          key: '23',
          label: t('RECOMMENDATION_MAPS'),
          icon: <Maps />,
          path: '/quant/approved-instruments'
        },
        // {
        //   key: '24',
        //   label: t('INVESTOR_BIAS'),
        //   path: '/quant/investor-bias'
        // },
        {
          key: '25',
          label: t('INSTRUMENT_MAPPING'),
          icon: <InstrumentMapping />,
          path: '/quant/asset/list'
        },
        {
          key: '26',
          label: t('ASSET_CLASSES'),
          icon: <AssetClass />,
          path: '/quant/asset-classes'
        },
      ],
    },
    {
      key: '3',
      icon: <Users />,
      label: t('USERS'),
      path: '/users/users-list',
    },
    {
      key: '6',
      icon: <OrderInstraction />,
      label: t('ORDER_INSTRUCTIONS'),
      path: '/admin-order-instructions/approved',
    },
    {
      key: '4',
      icon: <Support />,
      label: t('SUPPORT_LC'),
      path: '/support',
    },
    {
      key: '5',
      icon: <Settings />,
      label: t('SETTINGS'),
      path: '/settings',
    },
  ];
  const itemsAdv = [
    {
      key: '4',
      icon: <Support />,
      label: t('SUPPORT_LC'),
      path: '/support',
    },
    {
      key: '5',
      icon: <Settings />,
      label: t('SETTINGS'),
      path: '/settings',
    },
  ];

  useEffect(() => {
    const suffix = pathname.split('/').at(-1);
    const preCategory = pathname.split('/').at(1) + '/' + pathname.split('/').at(2);
    const preffix = pathname.split('/').at(3);
    const preffixOne = pathname.split('/').at(1);
    let key = null;
    if (suffix === 'portfolio') key = 11;
    if (['portfolio-information', 'holdings', 'fin-planning', 'constraints', 'fixation']
      .includes(suffix)) key = 11;
    if (suffix === 'lifetiming' || preffix === 'lifetiming') key = 12;
    if (suffix === 'documents') key = 13;
    if (suffix === 'suggested-portfolios' || preffix === 'suggested-portfolios') key = 14;
    if (suffix === 'order-instructions') key = 15;
    if (preffixOne === 'accounts') {
      if(suffix === 'details' ) key = 16;
      if(['accounts', 'onboarding'].every(i => pathname?.split('/').includes(i)) ) key = 16;
    }
    if(key) {
      setClientMenu(key);
      setAppMenuID(null);
    }

    let keyApp = null;
    if (preCategory === 'quant/model')  keyApp = '21';
    if (preCategory === 'quant/approved-instruments') keyApp = '23';
    if (preCategory === 'quant/asset') keyApp = '25';
    if (preffixOne === 'asset-classes') keyApp = '26';
    if (preffixOne === 'users') keyApp = '3';
    if (preffixOne === 'admin-order-instructions') keyApp = '6';
    if (preffixOne === 'support') keyApp = '4';
    if (preffixOne === 'settings') keyApp = '5';
    if(keyApp) {
      setAppMenuID(keyApp);
      setUserId(null);
      setClientMenu(null);
      setCurrentUser(null);
    }

  }, [currentUser, pathname]);

  const getUserName = () => {
    const roleUser = role === 'SUPERADMIN' ? t('ADMIN') : t('ADVISOR');
    const nameUser = first_name || last_name
      ? `${first_name} ${last_name}`
      : '';
    return { roleUser, nameUser };
  };

  const scrollToCenter = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const selectedElement = container.querySelector('.selected-account');
      if (selectedElement) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = selectedElement.getBoundingClientRect();
        const scrollTop =
          elementRect.top -
          containerRect.top -
          containerRect.height / 2 +
          elementRect.height / 2;
        container.scrollTo({ top: scrollTop, behavior: 'smooth' });
      }
    }
  }, [hideList]);

  const toggleHiddenList = () => {
    if (!needSave && !optStart && !isLoading) {
      if (currentUser) {
        if (!hideList) {
          const container = containerRef.current;
          container.scrollTo({ top: 0, behavior: 'instant' });
        } else {
          setFilteredUsers(users);
          setTimeout(() => {
            scrollToCenter();
          }, 1000);
        }
      }
      setCollapsed(false);
      setHideList(!hideList);
    } else {
      if (needSave) setSaveAlert({ flag: true, path: '/clients', state: { from: 'unsave' } });
      if (optStart) setOptAlert({ flag: true, path: '/clients', state: { from: 'unsave' } });
    }
  };

  const handleCollapsedBar = () => {
    if(!collapsed) {
      setHideList(true);
    }
    setCollapsed(!collapsed);
  };

  const selectUser = (id) => {
    setAppMenuID(null);
    setUserId(id);
    const container = containerRef.current;
    container.scrollTo({ top: 0, behavior: 'instant' });
    setHideList(true);
    history.push(`/accounts/${id}/portfolio`);
  };

  const handleClientMenu = (key, path) => {
    if (needSave) {
      setSaveAlert({ flag: true, path });
      return;
    }
    if (optStart) {
      setOptAlert({ flag: true, path });
      return;
    }
    setClientMenu(key);
    history.push(path);
  };

  const handleAddUser = () => {
    history.push('/accounts/new-account');
  };

  const getAccount = useMemo(() => {
    const account = (users && users?.find( i => i.id === userId)) ?? {};
    return account;
  }, [users, userId]);

  const getShortName = (name) => {
    const nameShort = name?.split(' ')?.map( i => i ? i[0]?.toUpperCase() : '')?.join('').slice(0, 2) ?? '- -';
    console.log('🚀 ~ getShortName ~ nameShort:', nameShort);
    return nameShort;
  };

  const clientMenuButton = useMemo(() => {
    return role === 'SUPERADMIN' ? clientMenuItem : clientMenuItemAdv;
  }, [role, currentUser]);

  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <Sider
      id="advisers" 
      width="280" 
      className="sider-style"
      collapsible
      collapsed={collapsed}
      trigger={null} 
    >
      <div className="logo-wrapper client">
        {!collapsed && (
          <NavLink to={'/'} className="logotype">
            {theme === 'dark' ? <LogoDark /> : <img src={LogoType} alt="" />}
          </NavLink>
        )}
        <Button
          type="text"
          icon={!collapsed ? <TogglerLeft /> : <TogglerRight />}
          onClick={handleCollapsedBar}
          className="toggle-btn"
          style={{
            fontSize: '16px',
            width: 18,
            height: 64,
          }}
        />
      </div>
      <div className="client-wrapper">
      <div>
        <div className={collapsed ? 'account-select-wrap collapsed' : 'account-select-wrap'}>
          {!collapsed && <p className="account-title">{t('ACCOUNT')}</p>}
        <div className={`account-title-btn ${!hideList ? 'uncollapsed' : ''} ${isLoading ? 'desabled' : ''}`} >
          <div className={collapsed ? 'account-select-btn  collapsed': 'account-select-btn'}
            onClick={toggleHiddenList}>
            {
              currentUser
              ? <Text ellipsis={true}>
                  {!collapsed
                    ? getAccount?.name ?? '- - -'
                    : getShortName(getAccount?.name)}
                </Text>
              : <Text ellipsis={true}>{collapsed 
                  ? <SelectAcc /> 
                  : t('SELECT_ACCOUNT')}
                </Text>
            }
            <ListCollapse />
          </div>
          <div className={`client-box ${!hideList ? 'uncollapsed' : ''}`}>
            {!hideList && !collapsed &&
                <CustomSearchInput
                  className='account-select-search'
                  width='224px'
                  height='32px'
                  setFocus
                  onChange={handleFiletUsers}
                  />
            }
            <div
              ref={containerRef}
              className={`account-button-wrapper ${hideList ? 'hidden' : ''}`}
            >
              {filteredUsers &&
                filteredUsers
                  .slice()
                  .sort((a, b) => textSort(a.name, b.name))
                  .map((item) => {
                    return (
                      <Button
                        key={item.id}
                        type='text'
                        onClick={() => selectUser(item.id)}
                        className={`account-list-item ${
                          currentUser === item.id ? 'selected-account' : ''
                        }`}
                      >
                        <Text ellipsis={true}>
                          {`${item.name}`}
                        </Text>
                      </Button>
                    );
                  })}
            </div>
            {!hideList && (
              <ButtonAdd
                title='ADD_ACCOUNT'
                marginTop={'8px'}
                onClick={handleAddUser}
              />
            )}
          </div>
        </div>
        <div
          ref={containerNavRef}
          className={`client-nav-wrapper ${!hideList ? 'hidden' : ''}`}>
          {currentUser && clientMenuButton.map(i => {
            return (
            <Button
              type='text'
              key={i.key}
              className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
              onClick={() => handleClientMenu(i.key, i.path)}
              >
              {collapsed 
                ? null 
                : clientMenu === i.key
                  ? <Checked />
                  : <div className='menu-icon-wrap'>{i.icon}</div>
              }
              {collapsed ? i.icon : i.label}
            </Button>
            );
          })
          }
        </div>
        </div>
        <CustomMenu
          userId={userId}
          setUserId={setUserId} 
          appMenuID={appMenuID}
          setAppMenuID={setAppMenuID}
          items={role === 'SUPERADMIN' ? items : itemsAdv} 
          collapsed={collapsed}/>
      </div>
        <div className="client-bottom-box">
          {!collapsed && (
            <div className="client-box-text-wraper">
              <p className="client-text-name">{getUserName().nameUser}</p>
              <p className="client-text-role">{getUserName().roleUser}</p>
            </div>
          )}
          <div className="client-logout-link" onClick={handleLogout}>
            <Logout />
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default SideBar;

SideBar.propTypes = {
  userId: PropTypes.number,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  saveAlert: PropTypes.object,
  optAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setOptAlert: PropTypes.func,
  optStart: PropTypes.bool,
  theme: PropTypes.string,
};
