// export const BASE_URL = 'https://quant-test.citroneer.com/v1/api';
export const IMPORT_TMP_URL = 'https://quant-test.citroneer.com/importTemplateENG.xlsx';
export const API_URLS = {
  CHECK_TOKEN: '/v1/auth/check-token',
  LOGIN: '/v1/auth/login',
  LOGIN_V3: '/v3/auth/login',
  REGISTER: '/v1/auth/register',
  REGISTER_V3: '/v3/auth/register',
  OAUTH_CONFIG_V3: '/v3/auth/config',
  FORGOT_PASSWORD_REQUEST: '/v1/auth/reset-password',
  FORGOT_PASSWORD_RESET: '/v1/auth/reset-password-verify-code',
  VERIFY_EMAIL: '/v1/auth/verify-email',
  CREATE_ADVISER: '/v1/users',
  ADVISORS: '/v1/users',
  ADVISORS_V3: '/v3/users',
  ADVISOR: '/v1/users',
  ADVISOR_V3: '/v3/users',
  ADVISOR_REINVITE: '/v1/auth/advisor/resend-registration-email',
  UPDATE_EMAIL: '/v1/users',
  ACTIVATE_ADVISER: '/v1/users',
  UPDATE_PASSWORD: '/v1/users',
  UPDATE_NAME: '/v1/users',
  UPDATE_PHONE: '/v1/users',
  ALL_ADVISER: '/v1/users?limit=10&offset=0',
  UPDATE_ADVISER: '/v1/users',
  UPDATE_ADVISER_V2: '/v2/users',
  UPDATE_ADVISER_V3: '/v3/users',
  CONFIRM_EMAIL: '/v1/confirm',
  CREATE_CLIENT: '/v1/clients',
  SET_CLIENT_TYPE: '/v1/clients',
  ACCOUNTS_V3: '/v3/accounts',
  CLIENT: '/v1/clients',
  CLIENT_V2: '/v2/clients',
  CLIENT_V3: '/v3/clients',
  UPDATE_CLIENT: '/v2/clients',
  V2_CLIENT: '/v2/clients',
  INVITE: '/v1/invite',
  SELECTED_EXPORT: '/v1/exports',
  // CLIENT: '/v1/users',
  ALL_PORTFOLIO: '/v1/clients',
  PORTFOLIO: '/v1/portfolio',
  PORTFOLIO_V3: '/v3/portfolio',
  INSTRUMENTS: '/v1/assetInstruments?searchParam=qwe1',
  ALL_INSTRUMENTS: '/v1/assetInstruments',
  PORTFOLIO_HISTORY: '/v1/portfolio',
  SEARCH: '/v1/assetInstruments/search',
  CREATE_PORTFOLIO_FULL: '/v1/portfolio',
  CREATE_PORTFOLIO_DRAFT: '/v1/portfolio',
  UPDATE_PORTFOLIO: '/v1/portfolio',
  DELETE_PORTFOLIO: '/v1/portfolio',
  EXPORT_PORTFOLIO: '/v1/portfolio',
  // IMPORT_PORTFOLIO: '/v1/imports/portfolio',
  IMPORT_PORTFOLIO: '/v1/imports/v2/portfolio',
  CHANGE_EMAIL: '/v1/auth/change-registration-email',
  RESEND_EMAIL: '/v1/auth/resend-registration-email',
  ASSETS: '/v1/assetClasses',
  ASSETS_CATEGORIES: '/v1/assetCategories/',
  UPDATE_ASSETS: '/v1/assets',
  UPDATE_INSTRUMENT: '/v1/assetInstruments',
  GET_CURRENCY: '/v1/currency',
  GET_DIVERSIFICATION: '/v1/diversification',
  REPORTS: '/v1/clients',
  DOWNLOAD_REPORT: '/v1/reports',
  DOWNLOAD_REPORT_V2: '/v2/reports',
  DOWNLOAD_TEMP: '/v1/static/import_template.xlsx',
  CREATE_REPORT: '/v1/reports',
  CREATE_INSTRUMENT: '/v1/assetInstruments',
  OPTIMIZATION: '/v1/portfolio',
  OPTIMIZATION_V2: '/v2/portfolio',
  OPTIMIZATION_V3: '/v3/portfolio',
  INSTRUMENTS_TBD: '/v1/assetRequests',
  BANKID_AUTH: '/v1/bankid/auth',
  BANKID_SIGN: '/v1/bankidauth/sign',
  BANKID_SIGN_ATTACH: '/v1/bankidauth/sign/attach',
  SIGN_COLLECT: '/v1/bankidauth/collect/',
  SIGN_COLLECT_ATTACH: '/v1/bankidauth/collect/attach',
  BANKID_COLLECT: '/v1/bankidauth/bind',
  BANKID_LOGIN_COLLECT: '/v1/auth/collect',
  BANKID_CANCEL_COLLECT: '/v1/bankid/cancel',
  GET_CHECKBIZ_COMPANY: 'checkbiz/company?number=',
  GET_CHECKBIZ_PERSONAL: 'checkbiz/personal?number=',
  GET_SECRET: '/v1/2fa/secret',
  SECRET_BIND: '/v1/2fa/bind',
  CANDIDATE: '/v1/candidate',
  //
  CREATE_ASSET_CLASS: '/v1/assetClasses',
  UPLOAD_IMAGE: '/v1/financialplan',
  ALL_APPROVED: '/v1/offers',
  PORTFOLIOS: '/v1/portfolios',
  ONBOARDING: '/v2/onboarding',
  MODEL_V3: '/v3/class/model',
  CLASS_MODEL_V3: '/v3/model',
  USER_SEARCH_V3: '/v3/accounts/usersearch',
  RECOMENDATION_MAPS: '/v3/instrument/recomendation',
};
