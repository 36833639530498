import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import { clearPortfolioProspecting, createPortfolioTaskAnalysis, createPortfolioTaskProspecting } from '../../../data/store/portfolio/portfolioActions';

import { ReactComponent as Pluse } from '../../../assets/cross-btn-icon.svg';
import { ReactComponent as Settings } from '../../../assets/settings-btn-icon.svg';
import { ReactComponent as Check } from '../../../assets/check-white-icon.svg';

import { ColorDataItem } from '../../ClientOverview/ClientPortfolioList/ClientPortfolioListItem';
import { numberWithSpaces } from '../../../helpers/formatInteger';
import { LineBasic } from '../../../components/charts/LineBasic/LineBasic';
import OptimAssetsClass from '../OptimAssetsClass/OptimAssetsClass';
import { AreaRange } from '../../../components/charts/AreaRange/AreaRange';
import { BarColumnBasic } from '../../../components/charts/BarColumnBasic/BarColumnBasic';
import OptimChartHeader from '../OptimChartHeader/OptimChartHeader';

import './style.scss';

const NewOptimizationStep = ({
  userId,
  portfolioId,
  settings,
  setPDFSource,
  optimalPortfolio,
  setOptimalPortfolio,
  optAlert,
  setOptAlert,
  setOptStart,
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isLoading,
    analyzedPortfolio,
    analyzedPortfolioDot,
    prospectPortfolioSet,
  } = useSelector((state) => state.portfolioReducer);
  const currentPortfolio = useSelector(state => state.portfolioReducer.portfolioById);
  const cashesList = useSelector(state => state.instrumentsReducer?.cashes?.list);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  
  // eslint-disable-next-line no-unused-vars
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [tabs , setTabs ] = useState(0);

  useEffect(() => {
    console.log('In opt analyzedPortfolio', analyzedPortfolio);
  }, [analyzedPortfolio]);
  
  
  const handleTabs = (tab) => {
    setTabs(tab);
  };

  useEffect(() => {
    setOptStart(true);
    if (!settings) {
      history.push(
        `/accounts/${userId}/optimization/${portfolioId}/opt-settings`,
      );
    }
    const storName = sessionStorage.getItem('selectedPortfolio');
    if (storName && settings && analyzedPortfolio && prospectPortfolioSet) {
      setOptimalPortfolio(JSON.parse(storName));
    } else {
      sessionStorage.removeItem('selectedPortfolio');
    }
    return () => {
      setOptimalPortfolio(null);
      // dispatch(clearPortfolioProspecting());
    };
  }, []);

  useEffect(() => {
    setOptimalPortfolio(null);
    if (settings && Object.keys(settings)?.length) {
      const data = {
        ...settings,
        include_financial_plan: !!+settings?.include_financial_plan,
        monetary_basis: +settings?.monetary_basis,
      };
      setTimeout(() => {
        !isLoading &&
          dispatch(createPortfolioTaskProspecting({ portfolioId, data }));
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (optAlert?.flag) setModalCancelVisible(true);
  }, [optAlert]);

  const getProspectPortfolioSet = useMemo(() => {
    return prospectPortfolioSet?.prospect_portfolios
      ?.map((i) => {
        return { name: i.id, y: i.mean_return, x: i.volatility, cvar: i.cvar, idiosync_risk: i.idiosync_risk };
      })
      .sort((a, b) => a.x - b.x);
  }, [prospectPortfolioSet]);

  const choosePortfolio = (data) => {
    console.log('🚀 ~ choosePortfolio ~ data:', data);
    sessionStorage.setItem(
      'selectedPortfolio',
      JSON.stringify(data.options),
    );
    setOptimalPortfolio(data.options);
  };
    
  useEffect(() => {
    if (
      optimalPortfolio &&
      settings &&
      analyzedPortfolio &&
      prospectPortfolioSet
    ) {
      dispatch(
        createPortfolioTaskAnalysis({
          portfolioId,
          include_financial_plan: !!+settings?.include_financial_plan,
          name: optimalPortfolio.name,
          fees: {
            ...settings.fee_flags,
            withdrawal_amount: settings.withdrawal_amount,
            deposit_amount: settings.deposit_amount,
            reserved_amount: settings.reserved_amount,
          },
        }),
      );
    }
  }, [optimalPortfolio]);

  const portfolioTotalValueOpt = useMemo(() => {
    if (prospectPortfolioSet && optimalPortfolio?.name) {
      return prospectPortfolioSet?.prospect_portfolios
        ?.find((i) => i.id === optimalPortfolio?.name)
        ?.items.reduce((acc, el) => acc + el.value, 0);
    } else {
      return 0;
    }
  }, [prospectPortfolioSet, optimalPortfolio]);

  const optimalData = useMemo(() => (
    {
      portfolio_content: prospectPortfolioSet?.prospect_portfolios
        ?.find((i) => i.id === optimalPortfolio?.name)?.items,
        unlisted_content: [],
        portfolio_cashes: [],
        total_value: portfolioTotalValueOpt
    }
  ), [prospectPortfolioSet, optimalPortfolio] );

  const handleReset = () => {
    setOptAlert({ flag: false, path: '' });
    setOptStart(false);
    history.push(`/accounts/${userId}/portfolio`);
  };

  const getChartData = (type) => {
    if (type === 'data1') {
      return (
        analyzedPortfolio?.projection?.map(
          (i) => [i?.year, i?.value_sek] ?? null,
        ) ?? []
      );
    }
    if (type === 'data2') {
      return (
        analyzedPortfolioDot?.projection?.map((i) => [i?.year, i?.value_sek]) ??
        []
      );
    }
    if (type === 'data3') {
      return analyzedPortfolio?.risk?.map((i) => [i?.year, i?.value_sek]) ?? [];
    }
    if (type === 'data4') {
      return (
        analyzedPortfolioDot?.risk?.map((i) => [i?.year, i?.value_sek]) ?? []
      );
    }
    if (type === 'data5') {
      return (
        analyzedPortfolio?.wealth_appreciation?.map((i) => [
          i?.year,
          i?.value,
          {
            return_amount: i?.return_amount,
            deposit_fee_amount: i?.deposit_fee_amount,
            product_fee_amount: i?.product_fee_amount,
            advisory_fee_amount: i?.advisory_fee_amount,
            deposit_amount: i?.deposit_amount,
            withdrawal_amount: i?.withdrawal_amount,
            tax_amount: i?.tax_amount,
          },
        ]) ?? []
      );
    }
    if (type === 'data6') {
      return (
        analyzedPortfolioDot?.wealth_appreciation?.map((i) => [
          i?.year,
          i?.value,
          {
            return_amount: i?.return_amount,
            deposit_fee_amount: i?.deposit_fee_amount,
            product_fee_amount: i?.product_fee_amount,
            advisory_fee_amount: i?.advisory_fee_amount,
            deposit_amount: i?.deposit_amount,
            withdrawal_amount: i?.withdrawal_amount,
            tax_amount: i?.tax_amount,
          },
        ]) ?? []
      );
    }
  };

  const handleBack = () => {
    setOptStart(false);
    sessionStorage.removeItem('selectedPortfolio');
    dispatch(clearPortfolioProspecting());
    setPDFSource([]);
    history.goBack();
  };

  const handleNext = () => {
    const template = captureForPDF();
    setPDFSource({
      page1_graph: template?.partOneOne,
      page1_cards: template?.partOneTwo,
      page2_graph: template?.partTwo,
      page3: template?.partThree,
      page4_line_graph_allocation: template?.partFour,
      page4_round_graph_destribution: template?.partFive,
    });
    setTimeout(() => {
      history.push(
        `/accounts/${userId}/optimization/${portfolioId}/transactions`,
      );
    }, 500);
  };

  const captureForPDF = () => {
    const container1 = document
      .getElementById('pdf-part-1')
      ?.innerHTML.replaceAll('"', '\'');
    const partOneOne = `<div class='opt-inner-wrapper' id='pdf-part-1'>${container1}</div>`;
    const container11 = document
      .getElementById('pdf-part-1-1')
      ?.innerHTML.replaceAll('"', '\'');
    const partOneTwo = `<div class='opt-data-wrap' id='pdf-part-1-1'>${container11}</div>`;
    const container2 = document
      .getElementById('pdf-part-2')
      ?.innerHTML.replaceAll('"', '\'');
    const partTwo = `<div class='opt-charts-wrapper' id='pdf-part-2'>${container2}</div>`;
    const container3 =
      document.getElementById('pdf-part-3')?.innerHTML.replaceAll('"', '\'') ||
      '';
    const partThree = container3
      ? `<div class='opt-charts-wrapper' id='pdf-part-3'>${container3}</div>`
      : '';
    const container4 = document
      .getElementById('pdf-part-4')
      ?.innerHTML.replaceAll('"', '\'');
    const partFour = `<div class='opt-charts-wrapper' id='pdf-part-4'>${container4}</div>`;
    const container5 = document
      .getElementById('pdf-part-5')
      ?.innerHTML.replaceAll('"', '\'');
    const partFive = `<div class='opt-charts-wrapper' id='pdf-part-5'>${container5}</div>`;

    return {
      partOneOne,
      partOneTwo,
      partTwo,
      partThree,
      partFour,
      partFive,
    };
  };

  return (
    <div className='optimization-component-wrapper charts-page'>
      <div className="top-btn-wrapper">
        <Button
          type="text"
          className="portfolio-cancel-btn"
          icon={<Pluse />}
          iconPosition="end"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          icon={<Settings />}
          iconPosition="end"
          className="optim-settings-btn"
          onClick={handleBack}
        >
          {t('SETTINGS')}
        </Button>
        <Button
          icon={<Check />}
          iconPosition="end"
          disabled={!optimalPortfolio?.name || isLoading}
          onClick={handleNext}
          className="optim-continue-btn"
        >
          {t('TRANSACTIONS')}
        </Button>
      </div>
      <section className='prospects-sections-wrapper'>
        <div className='current-portfolio-info portfolio-card'>
          <div>
            <p className="portfolio-card-name">
              {currentPortfolio?.name}  {' '}
              <span className="portfolio-card-code">{currentPortfolio?.portfolio_code}</span>
            </p>
          </div>
          <div>
            <p className="portfolio-card-value">{`${numberWithSpaces(Math.round(currentPortfolio?.total_value))} kr`}</p>
            <Row justify={'end'} className="portfolio-card-text">
              <Col className="">
                <ColorDataItem
                  isSelected={true}
                  text={t('RETURN')}
                  value={currentPortfolio?.return ?? ''}
                />
              </Col>
              <Col className="">
                <ColorDataItem
                  isSelected={true}
                  text={t('VOLATILITY')}
                  value={currentPortfolio?.vol}
                />
              </Col>
              <Col className="">
                <ColorDataItem
                  isSelected={true}
                  text={t('CVaR')}
                  value={currentPortfolio?.cvar}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className='prospect-line-chart'>
          <LineBasic
            data={getProspectPortfolioSet}
            currentPortfolioData={[
              {
                key: 'current',
                y: Math.round(analyzedPortfolio?.mean_return * 10) / 10,
                x: Math.round(analyzedPortfolio?.volatility * 10) / 10,
                cvar: Math.round(analyzedPortfolio?.cvar * 10) / 10,
                idiosync_risk: analyzedPortfolio?.idiosync_risk
              },
            ]}
            selectPoint={(data) => choosePortfolio(data)}
            point={optimalPortfolio?.name}
            analyzedPortfolio={!!analyzedPortfolio}
        />
        </div>
      </section>
      <section className='compare-sections-wrapper'>
        <div className='charts-tabs-block'>
            <div className=''>
              <Row>
                <Col>
                  <Button
                    type='text'
                    className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
                    onClick={() => handleTabs(0)}
                  >
                    {t('PORTFOLIO_DEVELOPMENT')}
                  </Button>
                  {settings?.include_financial_plan === '1' &&
                    <Button
                    type='text'
                    className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
                    onClick={() => handleTabs(1)}
                  >
                    {t('WEALTH_APPRECIATION')}
                  </Button>}
                </Col>
              </Row>
            </div>
            <div style={{minHeight: '300px'}}>
              {
                tabs === 0 && optimalPortfolio &&
                <AreaRange
                  data1={getChartData('data1')}
                  data2={getChartData('data2')}
                  data3={getChartData('data3')}
                  data4={getChartData('data4')}
                />
              }
              {
                tabs === 1 && optimalPortfolio &&
                <BarColumnBasic
                  data1={getChartData('data5')}
                  data2={getChartData('data6')}
                />
              }
            </div>
          <OptimChartHeader
            current={currentPortfolio}
            optimal={optimalPortfolio }
          />
        </div>
        <div className='class-category-block'>
          <OptimAssetsClass
            cashesList={cashesList}
            currencyList={currencyList}
            currentData={currentPortfolio}
            optimalData={optimalData}
          />
        </div>
      </section>
    </div>
  );
};

export default NewOptimizationStep;

NewOptimizationStep.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  setPDFSource: PropTypes.func,
  settings: PropTypes.object,
  optimalPortfolio: PropTypes.object,
  setOptimalPortfolio: PropTypes.func,
  optAlert: PropTypes.object,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};