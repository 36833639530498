import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Col, Form, Layout, Row } from 'antd';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

import { clearRecommendationMapById, getCurrency, getDiversification, getInstrumentsAll, getRecommendationMapById } from '../../data/store/instruments/instrumentsActions';
import MapCreation from './MapCreation';
import { ReactComponent as Pluse } from '../../assets/cross-btn-icon.svg';
import { ReactComponent as Check } from '../../assets/valid-check-icon.svg';

const { Header } = Layout;

const RecommendationMapsItem = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch('');
  const [form] = Form.useForm();
  const { search, pathname } = useLocation();
  const { mapsId } = useParams();
  const history = useHistory();
  const query = useQuery(search);

  const tab = query.get('tab');

  const [checkModal, setCheckModal] = useState(false);
    const [data, setData] = useState(null);
  
  useEffect(() => {
    dispatch(getDiversification());
    dispatch(getCurrency());
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'without-cashes' }));
  }, [dispatch]);

  useEffect(() => {
    if (mapsId !== 'new') {
      dispatch(getRecommendationMapById(mapsId));
    } else {
      dispatch(clearRecommendationMapById());
    }
  }, [mapsId]);

  useEffect(() => {
    if (!tab) {
      history.push(`${pathname}?tab=map-creation`);
    }
  }, [tab]);

  const handleCancel = () => {
    history.replace('/quant/approved-instruments/list');
  };

  const validData = useMemo(() => {
    const response = data?.every(i => !!i?.prio);
    console.log('🚀 ~ validData ~ response:', response);
    return response;
  }, [data]);

  const handleCheck = () => {
    setCheckModal(true);
  };
  const closeModal = () => {
    setCheckModal(false);
  };

    return (
    <>
      <div className="instrument-wrapper">
        <div className="">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button type="text" className="overview-btn ant-btn-active">
                  {t(mapsId === 'new' ? 'CREATE_MAP' : 'EDIT_MAP')}
                </Button>
              </Col>
            </Row>
            <div className="top-btn-wrapper">
            <Button 
                type="text" 
                className="portfolio-cancel-btn" 
                icon={<Pluse />}
                iconPosition='end'
                onClick={handleCancel}>
                {t('CANCEL')}
              </Button>
              <Button
                disabled={!data?.length || !validData}
                icon={<Check/>}
                iconPosition='end'
                className="portfolio-cancel-btn success-btn"
                onClick={handleCheck}
              >
                {t('CHECK')}
              </Button>
            </div>
          </Header>
          <h1 className='recomend-maps-title'  style={{marginTop: '80px'}}>{t('MAPS')}</h1>
          <MapCreation
            mapsId={mapsId}
            form={form}
            data={data}
            setData={setData}
            open={checkModal}
            closeModal={closeModal}
          />
          
        </div>
      </div>
    </>
  );
};

export default RecommendationMapsItem;

RecommendationMapsItem.propTypes = {
  recommendation: PropTypes.array,
};
