import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from 'antd';

import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { NextCategory } from '../data/NextCategory';

import { ReactComponent as Confirm } from '../img/confirm-icon.svg';
import { ReactComponent as CheckRisk } from '../img/risk-chech-icon.svg';

const RiskProfile = ({
  t,
  riskLevelItem,
  selected,
  recommended,
  setSelected,
}) => {
  const { value, label, volatility, color } = riskLevelItem;
  const handleSelect = () => {
    setSelected(value);
  };
  return (
    <div
      className={`risk-level-item-wrapper ${selected === value ? 'selected' : ''} ${recommended === value ? 'recommended' : ''}`}
      onClick={handleSelect}
    >
      <div className="risk-level">
        <CheckRisk className="check-round" />
        <span>{t(label)}</span>
        <p className="title-recomended">{t('RECOMMENDED')}</p>
      </div>
      <div className="risk-level-mrm" style={{ background: color }}>
        {value}
      </div>
      <div className="risk-level-volatility">{volatility}</div>
    </div>
  );
};

const CategoryRiskProfile = ({ userId, t, onboardingData }) => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const history = useHistory();

  const [selected, setSelected] = useState(null);

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const category = query.get('category');

  const risk_level = useMemo(() =>  onboardingData?.risk_level ?? 0, [onboardingData]);

  useEffect(() => {
    if (onboardingData?.result?.risk_profile) {
      setSelected(onboardingData.result.risk_profile);
    }
  }, [onboardingData]);

  const options = [
    {
      value: 1,
      label: 'VERY_LOW_RISK',
      volatility: '< 0,5%',
      color: '#6467C3',
    },
    {
      value: 2,
      label: 'LOW_RISK',
      volatility: '≥ 0.5% och < 5,0%',
      color: '#6467C3BF',
    },
    {
      value: 3,
      label: 'MEDIUM_LOW_RISK',
      volatility: '≥ 5.0% och < 12%',
      color: '#6467C380',
    },
    {
      value: 4,
      label: 'MEDIUM_RISK',
      volatility: '≥ 12% och < 20%',
      color: '#FB7B3440',
    },
    {
      value: 5,
      label: 'MEDIUM_HIGH_RISK',
      volatility: '≥ 20% och < 30%',
      color: '#FB7B3480',
    },
    {
      value: 6,
      label: 'HIGH_RISK',
      volatility: '≥ 30% och < 80%',
      color: '#FB7B34BF',
    },
    {
      value: 7,
      label: 'VERY_HIGH_RISK',
      volatility: '≥ 80%',
      color: '#FB7B34',
    },
  ];

  const handleSaveOnbording = () => {
    const result = { ...onboardingData.result, risk_profile: selected };
    dispatch(
      setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }),
    );
    const path = NextCategory(category);
    history.push(`${pathname}?category=${path}`);
  };

  return (
    <div
      className="card-right-wrap risk-profile"
      style={{ paddingTop: '56px', paddingBottom: '24px' }}
    >
        <div className='risk-level-item-wrapper'>
          <div className="risk-level-header">
            {t('RISK_CLASS')}
          </div>
          <div className="risk-level-mrm-header">
            {t('MRM')}
          </div>
          <div className="risk-level-volatility-header">
            {t('VOLATILITY (ANNUAL)')}
          </div>
        </div>
        {options.map((i) => (
          <RiskProfile
            key={i.value}
            t={t}
            riskLevelItem={i}
            selected={selected}
            setSelected={setSelected}
            recommended={risk_level}
          />
        ))}
      <div className="risk-warning-wrapper">{t('RECOMMENDED_RISK_LEVEL_ONE')}</div>
      <div style={{margin: '0'}} className="risk-warning-wrapper">{t('RECOMMENDED_RISK_LEVEL_TWO')}</div>
      <div className='risk-home-bias-wrap'>
        <div className='risk-home-bias-title'>{t('HOME_BIAS')}</div>
        <div className='risk-home-bias-value'>Yes, 10%</div>
      </div>
      <Button
        icon={<Confirm />}
        iconPosition="end"
        type="text"
        className="start-onbording-btn"
        onClick={handleSaveOnbording}
      >
        {t('CONFIRM')}
      </Button>
    </div>
  );
};

export default CategoryRiskProfile;

CategoryRiskProfile.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
};

RiskProfile.propTypes = {
  riskLevelItem: PropTypes.object,
  selected: PropTypes.number,
  recommended: PropTypes.number,
  t: PropTypes.func,
  setSelected: PropTypes.func,
};
