/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { OnboardingQustions } from './data/OnboardingQuestions';
import { OnboardingPoints } from './data/OnboardingPoints';
import { Button } from 'antd';

import ScriptItem from './OnboardingScriptList/ScriptItem';
import MicRecorder from '../../components/AudioRecord/MicRecorder/MicRecorder';

import { ReactComponent as Collapse } from '../../assets/collapse-onboarfing-icon.svg';
import { ReactComponent as Start } from './img/record-icon-start.svg';
import { ReactComponent as Pause } from './img/record-pause-icon.svg';
import { ReactComponent as Stop } from './img/record-stop-icon.svg';
import { ReactComponent as Script } from './img/script-icon.svg';

// import { ReactComponent as Recording_01 } from '../../assets/rerording-key-1.svg';
import { ReactComponent as Dot } from '../../assets/record-timer-dot.svg';

import AudioRecordIcon from '../../components/AudioRecordIcon/AudioRecordIcon';
import { useDispatch } from 'react-redux';
import { uploadOnboardingAudio } from '../../data/store/account/accountActions';
import AudioPlayer from '../../components/Player/AudioPlayer';

import './style.scss';

const CategoryItem = ({ t, name, selected }) => {
  return (
    <div className={selected ? 'category-item selected' : 'category-item'}>
      <div className="category-colunm">
        <p className="category-name">{t(name)}</p>
      </div>
    </div>
  );
};
const recorder = new MicRecorder({ bitRate: 128 });

const OnboardingScript = ({ userId, currentLang, onbordingType, onbordingId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [mp3Url, setMp3Url] = useState(null);
  const [time, setTime] = useState(0);

  useEffect(() => {
    console.log('🚀 ~ OnboardingScript ~ mp3Url:', mp3Url);
  }, [mp3Url]);

  useEffect(() => {
    console.log('isRecording', isRecording);
    
  }, [isRecording]);

  useEffect(() => {
    console.log('isPaused', isPaused);
  }, [isPaused]);
  
  

  useEffect(() => {
    let interval;
    if (isRecording && !isPaused) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording, isPaused]);

  const startRecording = () => {
    recorder.start().then(() => {
      setIsRecording(true);
      !isPaused && setTime(0);
      setIsPaused(false);
      !isPaused && console.log('Started');
      isPaused && console.log('Continued');
    }).catch(error => console.error(error));
  };

  const stopRecording = () => {
    console.log('🚀 ~ stopRecording ~ recorder:', recorder);
    if(isRecording || isPaused) {
      recorder.stop().getMp3().then(([buffer, blob]) => {
        const url = URL.createObjectURL(blob);
        setMp3Url(url);
        console.log(blob);
        const dateNow = Date.now();
        const dateNowText = dayjs(dateNow).format('YYYY-MM-DD');
        const file = new File(buffer, `onboarding_clientID-${userId}_${dateNowText}.mp3`, {
          type: blob.type,
          lastModified: dateNow
        });
        const link = document.createElement('a');
        link.href = url;
        link.download = `onboarding_clientID-${userId}_${dateNowText}.mp3`;
        link.click();
        dispatch(uploadOnboardingAudio({clientId: userId, id: onbordingId, file}));
        console.log('🚀 ~ recorder.stop ~ file:', file);
  
        setIsRecording(false);
        setIsPaused(false);
      }).catch(error => console.error(error));

    }
  };

  const pauseRecording = () => {
    if (isPaused) {
      startRecording();
    } else {
      recorder.pause().then(() => console.log('Paused'));
    }
    setIsPaused((prevState) => !prevState);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  function useQuery () {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const category = query.get('category');

  const questions = useMemo(
    () => OnboardingQustions?.find((i) => i.key === category),
    [category]
  );

  useEffect(() => {
    if (!category) {
      history.push(`${url}?category=client-information`);
    }
  }, []);

  useEffect(() => {
    if (category && category !== 'recorder') {
      const newCategory = OnboardingPoints.find((i) => i.value === category);
      setSelectedCategory(newCategory);
    }
  }, [category]);

  const [selectedCategory, setSelectedCategory] = useState();

  const handleSelectCategory = (value) => {
    if (value !== 'type-of-account') {
      history.push(`${url}?category=${value}`);
    }
  };

  const handleStartScript = () => {
    history.push(`${url}?category=client-information`);
  };
  const handleCloseScript = () => {
    history.push(`${url}?category=recorder`);
  };

  useEffect(() => {
    console.log('selectedCategory', selectedCategory);
    
  }, [selectedCategory]);
  

  return (
    <>
      <div className="onbording-card-wrapper">
        {category !== 'recorder'
          ? (
            <div className="onbording-card card-left">
              <div>
                <div className="onbording-card-fill script">
                  <Button
                    type="text"
                    icon={<Collapse />}
                    className="onbording-collapse-btn"
                  />
                  <div className="onbording-step-wrapper">
                    <h2 className="onbording-card-title">{t('ONBOARDING_SCRIPT')}</h2>
                    <p className="onbording-card-description">
                      {t('FIND_ALL_QUESTIONS')}
                    </p>
                  </div>
                </div>
                <div className="onboarding-category-wrap">
                  {OnboardingPoints.map((i) => {
                    return (
                      <div
                        key={i.key}
                        className='onboarding-category-item'
                        onClick={() => handleSelectCategory(i.value, i.label)}
                      >
                        <CategoryItem
                          name={i.label}
                          selected={selectedCategory?.value === i.value}
                          t={t}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="left-footer">
                <p className="left-footer-link">{t('NEED_HELP')}</p>
              </div>
            </div>
            )
          : (
            <div className="onbording-card card-left">
              <div className="onbording-card-fill">
                <Button
                  type="text"
                  icon={<Collapse />}
                  className="onbording-collapse-btn"
                />
                <div className="onbording-step-wrapper">
                  <h2 className="onbording-card-title">{t('ONBOARDING')}</h2>
                  <p className="onbording-card-description">
                    {t('PLEASE_USE_SCRIPT')}
                  </p>
                </div>
                </div>
                <div className="left-footer">
                  <p className="left-footer-link">{t('NEED_HELP')}</p>
                </div>
            </div>
            )}

        <div className="onbording-card card-right">
          {category !== 'recorder' ? (
            <>
              <ScriptItem
                questions={questions}
                currentLang={currentLang}
                t={t}
                url={url}
                selectedCategoryName={selectedCategory?.label ?? ''}
                type={onbordingType}
                userId={userId}
              />
              {onbordingType === 'offline' &&
              <div className='recorder-wrapper mini'>
                <div className='microfone-zone'>
                  <AudioRecordIcon width={80} height={80} action={isRecording && !isPaused}/>
                </div>
                <div className='button-zone'>
                  <div className='recording-timer-wrapper'>
                    <div className='recording-timer-box'>
                      <Dot />
                      <span className='recording-timer'>{formatTime(time)}</span>
                    </div>
                  </div>
                  <div className='player-btn-mini-wrap'>
                    <div className='player-btn'>
                      {
                        !isRecording || isPaused
                          ? <Start
                              onClick={startRecording}/>
                          : <Pause
                              onClick={pauseRecording}/>
                      }
                    </div>
                    <div className='player-btn'>
                      <Stop onClick={stopRecording}/>
                    </div>
                  </div>

                </div>
              </div>
              }
              {onbordingType === 'offline' &&
                <div className='back-to-record-btn'>
                  <Button
                    type="default"
                    className="edit-btn"
                    onClick={handleCloseScript}>
                    {t('CLOSE')}
                  </Button>
                </div>
              }
            </>
          )
          : (
            <div style={{ paddingTop: '72px', marginBottom: '24px', overflowY: 'auto'}}>
              <h2 className="onbording-card-header">{t('YOUR_SCHEDULED_MEETING')}</h2>
              <h3 className="onbording-card-question">{t('WANT_TO_START_A_PERSONAL')}</h3>
              <div className='onbording-meeting-card-wrapper'>
              <h4 className="onbording-meeting-card-title">{t('YOUR_SCHEDULED_MEETING')}</h4>
              <h2 className="onbording-player-status">
                {isRecording && !isPaused
                  ? t('YOUR_RECORDING_IN_PROCESSING')
                  : isPaused 
                    ? t('YOUR_RECORDING_IS_PAUSED')
                    : t('YOUR_RECORDING_IS_STOPPED')
                }
              </h2>
                <div className='recorder-wrapper' style={{marginTop: '24px'}}>
                    <div className='player-btn'>
                      {
                        !isRecording || isPaused
                          ? <Start
                              onClick={startRecording}/>
                          : <Pause
                              onClick={pauseRecording}/>
                      }
                    </div>
                    <div>
                      <AudioRecordIcon width={312} height={312} action={isRecording && !isPaused}/>
                    </div>
                    <div className='player-btn'>
                      <Stop onClick={stopRecording}/>
                    </div>
                  </div>
                  <div className='recording-timer-wrapper'>
                    <div className='recording-timer-box'>
                    <Dot />
                    <span className='recording-timer'>{formatTime(time)}</span></div>
                    <Button
                      type='text'
                      icon={<Script />}
                      iconPosition='end'
                      className="onboarding-script-btn"
                      onClick={handleStartScript}
                    >
                      {t('SCRIPT')}
                    </Button>
                  </div>
              </div>
            </div>
            )
          }
        </div>
      </div>
      <div className="audioplayer-wrapper">
          {mp3Url && (
            <AudioPlayer
              src={mp3Url}
              minimal={true}
              width={850}
              trackHeight={40}
              barWidth={1}
              gap={1}
              visualise={true}
              backgroundColor="#000000"
              barColor="#FFFFFF"
              barPlayedColor="#D9D9D9"
              skipDuration={2}
              showLoopOption={true}
              showVolumeControl={true}
            />
          )}
      </div>
    </>
  );
};

export default OnboardingScript;

OnboardingScript.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  currentLang: PropTypes.string,
  onbordingType: PropTypes.string,
  onbordingId: PropTypes.number,
};
// t, name, status, selected
CategoryItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
  selected: PropTypes.bool,
};
