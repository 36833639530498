/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Input, Radio, Row, Space } from 'antd';

import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import { ReactComponent as Delete } from '../../../assets/delete-round-icon.svg';
import { ReactComponent as Eye } from '../../../assets/eye-icon.svg';
import { ReactComponent as Pluse } from '../../../assets/cross-btn-icon.svg';
import { ReactComponent as Round } from './img/round-pic.svg';

import ModalDeleteAccount from '../ModalDeleteAccount';

import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import {
  nameRules,
  organizationNumberRules,
} from '../../../helpers/validation';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { updateAccount } from '../../../data/store/account/accountActions';
import ModalUnsaveData from '../../../components/ModalUnsaveData/ModalUnsaveData';

const AccountInformation = ({
  accountById,
  owner,
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const {userById} = useSelector((state) => state.userReducer);
  const [deleteAccount, setDeleteAccount] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

    const type = Form.useWatch('type', form);
    const name = Form.useWatch('name', form);
    const code = Form.useWatch('company_number', form);

  useEffect(() => {
    if(accountById) {
      form.setFieldsValue(accountById || {});
    }
  }, [accountById, accountById]);

    const validateForm = () => {
      const hasErrors = form
        .getFieldsError()
        .some((field) => field.errors.length > 0);
        const currentValue = form.getFieldsValue();
        const storedValue = type === 'private' 
        ? {type: accountById?.type, name: accountById?.name, description: accountById?.description}
        : {type: accountById?.type, company_name: accountById?.company_name, company_number: accountById?.company_number, description: accountById?.description};
        const isNeedSave = !isEqual(currentValue, storedValue);
        const result = !hasErrors && !!type && type === 'corporate' ? !!code: !!name && isNeedSave;
        setIsFormValid(result);
        setNeedSave(result);
    };
  
    useEffect(() => {
      setSaveAlert({ flag: false, path: '' });
      validateForm();
    }, []);

  useEffect(() => {
    form.setFieldsValue(accountById);
  }, [accountById]);

  const handleCancelForm = () => {
    form.setFieldsValue(accountById);
  };

  const handleDeleteModal = () => {
    setDeleteAccount(accountById.id);
  };

  const handleCloseDelModal = () => {
    setDeleteAccount(null);
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const handleOwnerLink = () => {
    history.push(`/users/edit-user/${owner.id}`);
  };

  useEffect(() => {
    console.log('🚀 ~ saveAlert:', saveAlert);
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    form.submit();
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    path && history.push(path);
  };

  const onSubmit = (fields) => {
    dispatch(updateAccount({accountId: accountById.id, data: fields}));
  };

  return (
    <div className='account-info-wrapper'>
      <Row justify={'space-between'} className="account-info-header">
        <Col span={12}>
          <h3 className="portfolio-info-title">{t('ACCOUNT_INFORMATION')}</h3>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <Button
            type="text"
            className="round-button"
            icon={<Delete />}
            onClick={handleDeleteModal}
          />
        </Col>
      </Row>
      <div className="account-card">
      <div className="account-owner-label-wrap">
        <div className="account-owner-label">
          <Round />
          <span>{t('OWNER')}</span>
        </div>
        <Eye 
          className='account-owner-link'
          onClick={handleOwnerLink}
          />
      </div>
        <div>
          <p className="account-owner-name">
            {`${owner?.first_name ?? ''} ${owner?.last_name ?? ''}`}
          </p>
          <p className="account-owner-personalId">
            <span className="owner-personalId">{t('PERSONAL_ID')}</span>
            <span className="owner-personalId-number">
              {t('NOT_AVAILABLE')}
            </span>
          </p>
        </div>
      </div>
      <div className='account-contact-wrap'>
        <h3 className='account-contact-inform'>{t('CONTACT_INFORMATION')}</h3>
        <p className="account-contact-text post">Kungsholmsstrand 127, 112 34 Stockholm</p>
        {userById?.phones?.length > 0 
          ? (userById?.phones.map( (tel, index) => (
            <p key={index} className="account-contact-text phone">
              {`${tel?.country_code ?? ''} ${tel?.phone}`}
            </p>
          ))
            
          )
          : null
        }
        <p className="account-contact-text email">{userById?.email}</p>
      </div>

      <div className="account-creator-info-wrapper">
        <div className="account-creator-info-left">
          <h3 className="details-create">{t('CREATED_AT')}</h3>
          <p className="details-date">
            {dayjs(accountById?.created_at ?? null)
              ?.format('DD MMM YYYY')
              .toUpperCase()}
          </p>
        </div>
        <div className="account-creator-info-right">
          <h3 className="details-create">{t('UPDATED_AT')}</h3>
          <p className="details-date">
            {dayjs(accountById?.updated_at ?? null)
              .format('DD MMM YYYY')
              .toUpperCase()}
          </p>
        </div>
      </div>

      <Form
        id="create-portfolio"
        disabled={false}
        form={form}
        onFinish={onSubmit}
        validateTrigger={['onSubmit', 'onBlur']}
        layout="vertical"
        autoComplete="off"
        onValuesChange={validateForm}
        style={{ paddingTop: '28px' }}
      >
        <Form.Item name="type" className="">
          <Radio.Group className="checkbox-block">
            <Space direction="horizontal" align="center" className='account-spaces'>
              <CustomRadioButtom value="private">
                {t('PRIVATE')}
              </CustomRadioButtom>
              <CustomRadioButtom value="corporate">
                {t('CORPORATE')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Divider className='account-divider'/>
        <div className='account-inf-form'>
          {type === 'private' ? (
            <Form.Item
              label={t('NAME')}
              name="name"
              className="custom-required"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('PLEASE_ENTER_A_NAME'),
                },
              ]}
            >
              <Input placeholder={t('NAME')} className="custom-input full-width" />
            </Form.Item>
          ) : type === 'corporate' ? (
            <div>
              <Form.Item
                label={t('COMPANY_NAME')}
                name="company_name"
                className="custom-required"
                required={false}
                rules={[
                  {
                    required: true,
                    message: t('PLEASE_ENTER_A_NAME'),
                  },
                ]}
              >
                <Input placeholder={t('COMPANY_NAME')} className="custom-input full-width" />
              </Form.Item>
              <Form.Item
                label={t('COMPANY_NUMBER')}
                name="company_number"
                className="custom-required"
                rules={organizationNumberRules}
                required={false}
              >
                <Input
                  placeholder={t('COMPANY_NUMBER')}
                  className="custom-input full-width"
                />
              </Form.Item>
            </div>
          ) : (
              <Form.Item
                label={t(' ')}
                name="name"
                rules={nameRules}
                required={false}
              >
                <Input
                  disabled={!type}
                  placeholder={t('NAME')}
                  className="custom-input full-width"
                />
              </Form.Item>
          )}
          <Form.Item
            name="description"
            className="onboarding-textarea-purpose"
            label={t('DESCRIPTION')}
          >
            <CustomTextarea
              width={'100%'}
              rows={5}
              placeholder={t('DESCRIPTION')}
            />
          </Form.Item>
        </div>
        <div className="account-btn-wrapper">
          <Button 
            type="text" 
            className="portfolio-cancel-btn" 
            icon={<Pluse />}
            iconPosition='end'
            onClick={handleCancelForm}>
            {t('CANCEL')}
          </Button>
          <Button
            type="link"
            className="optimise-btn"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            {t('SAVE')}
          </Button>
        </div>
      </Form>
      <ModalDeleteAccount
        t={t}
        history={history}
        name={accountById?.name ?? '- - -'}
        deleteAccountId={deleteAccount}
        closeModal={handleCloseDelModal}
      />
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default AccountInformation;

AccountInformation.propTypes = {
  accountById: PropTypes.object,
  owner: PropTypes.object,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setNeedSave: PropTypes.func,
};
