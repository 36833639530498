import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Row } from 'antd';
import ModalInvateClient from '../ModalInvateClient/ModalInvateClient';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './style.scss';
import ButtonAdd from '../../../components/ButtonAdd/ButtonAdd';
import RelatedPersons from '../RelatedPersons/RelatedPersons';
import DeleteModal from '../DeleteModal';

const AccountRelated = ({ accountId }) => {
  const { t } = useTranslation('');

  const availableUsers = useSelector((state) => state.advisorReducer.availableUsers);
  const relatedUsers = useSelector((state) => state.accountReducer?.relatedUsers);
  
  const [relatedPersons, setRelatedPersons] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);

  useEffect(() => {
    if(relatedUsers) {
      const users = relatedUsers.map(i=> ({...i, isOwner: i.role === 'OWNER'}));
      setRelatedPersons(users);
    }
  }, [relatedUsers]);
  
  const [clientInvate, setClientInvate] = useState(null);

  const handleInviteClient = () => {
    setClientInvate(+accountId);
  };

  const handleCloseInvateModal = () => {
    setClientInvate(null);
  };

  return (
    <div className='account-related-wrapper'>
      <Row className='related-header-row'>
        <h2 className="account-related-title">{t('RELATED_PERSONS')}</h2>
        <ButtonAdd
          title='INVITE_CLIENT'
          height='32px'
          onClick={handleInviteClient}
        />
      </Row>
      <RelatedPersons
        accountId={accountId}
        users={availableUsers}
        relatedPersons={relatedPersons}
        setRelatedPersons={setRelatedPersons}
        setDeleteModal={setDeleteModal}
      />
      <ModalInvateClient
        open={clientInvate}
        closeModal={handleCloseInvateModal}
      />
      <DeleteModal
        accountId={+accountId}
        modalDeleteModel={deleteModal}
        handleDeleteClose={() => setDeleteModal(null)}
      />
    </div>
  );
};

export default AccountRelated;

AccountRelated.propTypes = {
  accountId: PropTypes.string,
  needSave: PropTypes.bool,
  setSaveAlert: PropTypes.func,
};
