import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Form, Input, Modal, Row, Select } from 'antd';
import { CustomTextarea } from '../../components/CustomTextfield/CustomTextfield';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { createModel } from '../../data/store/instruments/instrumentsActions';

const ModelModalNew = ({modelList, modalOpen, handleClose}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isClone, setIsClone] = useState(false);

  const handleToggleClone = () => {
    if(isClone) form.setFieldValue('parent', undefined);
    setIsClone(!isClone);
  };

  const closeModal = () => {
    form.resetFields();
    setIsClone(false);
    handleClose();
  };

  const handleSubmit = (data) => {
    if(!data?.parent) delete data.parent;
    console.log('submit', data);
    dispatch(createModel({history, callback: closeModal, data}));

  };

  return (
    <Modal
      width={700}
      className="modal-content-paper custom-modal model"
      open={modalOpen}
      onCancel={closeModal}
      zIndex={1620}
      footer={false}
      destroyOnClose
      >
      <div>
        <h1 className='modal-header-title'>{t('ADD_MODEL')}</h1>
        <div className={'model-card'} style={{marginBottom: '40px'}}>
          <p className="modal-text modal-result-short">
            {t('NEW_MODEL')}
          </p>
          <p className="modal-text modal-result-footer">
            {t('PROVIDE_MODEL_NAME')}
          </p>
        </div>
        <Form
          form={form}
          name="onboarding-form"
          layout="vertical"
          onFinish={handleSubmit}
          className=""
          autoComplete="off"
        >
        <Form.Item
          className='custom-required'
          label={t('NAME')}
          name="name"
          rules={[
            {
              required: true,
              message: t('PLEASE_ENTER_A_NAME'),
            },
          ]}
          required={false}>
          <Input
            placeholder={t('NAME')}
            className='custom-input full-width'/>
        </Form.Item>
        <Form.Item
          name='description'
          className="onboarding-textarea-purpose custom-required"
          rules={[{ required: true, message: t('ENTER_DESCRIPTION') }]}
          required={false}
          label={t('DESCRIPTION')}>
          <CustomTextarea width={'100%'} rows={4} placeholder={t('ENTER_DESCRIPTION')} />
        </Form.Item>
        <div className='clone-checkbox-wrapper model-clone' >
          <CustomCheckbox 
            checked={isClone}
            onChange={handleToggleClone}
          >{t('CLONE_MODEL')}</CustomCheckbox>
        </div>
        {isClone &&
          <Form.Item
            name="parent"
            rules={[{ required: isClone, message: t('ENTER_DESCRIPTION') }]}
            required={false}
          >
            <CustomSelect
              width={'100%'}
              disabled={!isClone}
              placeholder={t('SELECT_AN_OPTION')}
              className="custom-select"
              >
              {modelList?.map(i => (
                <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
              ))}
            </CustomSelect>
          </Form.Item>}
          <Row justify='end' className="modal-footer-btn-wrapper" style={{gap: '8px', marginTop: '12px'}}>
            <Button
              className="modal-action-btn cancel-btn return" 
              onClick={closeModal}>
              {t('CANCEL')}
            </Button>
            <Button 
              className="modal-action-btn save-btn asset-model" 
              onClick={() => form.submit()}> 
              {t('SUBMIT')}
            </Button>
          </Row>
        </Form>
      </div>
      </Modal>
  );
};

export default ModelModalNew;

ModelModalNew.propTypes = {
  modelList: PropTypes.array,
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};