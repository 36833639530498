import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as SortDownIcon } from './down-icon.svg';
import { ReactComponent as SortUpIcon } from './up-icon.svg';

import './style.scss';

const CustomSortIcon = ({sortOrder}) => {
  return (
    <div className='custom-sort-icon'>
      <SortUpIcon className={sortOrder === 'ascend' ? 'selected-sort' : ''} />
      <SortDownIcon className={sortOrder === 'descend' ? 'selected-sort' : ''}/>
    </div>
  );
};

export default CustomSortIcon;

CustomSortIcon.propTypes = {
    sortOrder: PropTypes.string,
};
