import React from 'react';

import PropTypes from 'prop-types';

import { Button, Col, Divider, Modal, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteAccount } from '../../data/store/account/accountActions';

const ModalDeleteAccount = ({name, t, history, deleteAccountId, closeModal}) => {
  const dispatch = useDispatch();

  const afterDel = () => {
    closeModal();
    history.push('/accounts');
  };

  const submitDelete = () => {
    console.log('deleteAccountId', deleteAccountId);
    dispatch(deleteAccount(deleteAccountId, afterDel));
    
  };
  
  return (
    <Modal
      width={700}
      className="modal-content-paper"
      open={deleteAccountId}
      onCancel={closeModal}
      footer={null}
      zIndex={1600}
    >
      <Row justify="start">
        <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
      </Row>
      <Divider className='modal-divider'/>
      <Row justify="start" className="modal-text-wrapper">
        <Col>
          <p className="modal-description">{t('MODAL_DELETE_ACCOUNT')}</p>
          <p className="modal-name">{name}</p>
        </Col>
      </Row>

      <Row justify="end" style={{marginTop: '50px'}}>
        <Button
          className="modal-action-btn cancel-btn"
          onClick={closeModal}
        >
          {t('CANCEL')}
        </Button>
        <Button 
          style={{marginLeft: '8px'}}
          className="modal-action-btn delete-btn" 
          onClick={submitDelete}>
          {t('DELETE')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalDeleteAccount;

ModalDeleteAccount.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  name: PropTypes.string,
  deleteAccountId: PropTypes.number,
  closeModal: PropTypes.func,
};
