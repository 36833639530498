/* eslint-disable camelcase */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Modal, Row } from 'antd';
import { executeApprovedPortfolio, getCandidatTransactions } from '../../data/store/account/accountActions';
import TableBuySell from './TableBuySell';

import {ReactComponent as Update} from '../../assets/update-icon.svg';
import {ReactComponent as Quantify} from '../../assets/quantify-icon.svg';

const ModalTransaction = ({
  openModalTransaction,
  closeModalTransaction,
  approved,
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const [tab, setTab] = useState('buy');
  const [setstepModal, setSetstepModal] = useState(0);

  const handleCloseModal = () => {
    setSetstepModal(0);
    setTab('buy');
    closeModalTransaction();
  };

  const buy = useSelector(
    (state) => state.accountReducer.transactionButSell?.buy
  );
  const sell = useSelector(
    (state) => state.accountReducer.transactionButSell?.sell
  );
  const setId = useSelector(
    (state) => state.accountReducer.transactionButSell?.id
  );
  const { isLoading } = useSelector((state) => state.accountReducer);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);

  const getCandidate = () => {
    const candidat = approved?.find((i) => i.id === openModalTransaction);
    return candidat;
  };

  const getCurrencyName = (id) => {
    return currencyList?.find( i => i.id === id)?.name ?? '';
  };

  const handleTabsSelect = (tag) => {
    setTab(tag);
  };

  const handleUpdate = () => {
    dispatch(
      getCandidatTransactions({
        portfolio_id: getCandidate()?.portfolio_id,
        candidatId: openModalTransaction,
      })
    );
  };

  const handleExecute = () => {
    setSetstepModal(1);
  };

  const handleExecuteAction = () => {
    const data = {
      callBack: closeModalTransaction,
      candidatId: openModalTransaction,
      portfolioId: getCandidate()?.portfolio_id
    };
    dispatch(executeApprovedPortfolio(data));
  };

  return (
    <Modal
      width={setstepModal ? 700 : 1272}
      className="modal-translation custom-modal"
      open={openModalTransaction}
      onCancel={closeModalTransaction}
      destroyOnClose={true}
      forceRender={false}
      zIndex={1600}
      footer={null}
    >
      {setstepModal === 0
        ? (
        <>
          <Row className="modal-transaction-header">
            <Col className="transaction-tab-wrapper">
              <Button
                type="text"
                className={`transaction-btn overview-btn ${
                  tab === 'buy' ? 'ant-btn-active' : ''
                }`}
                onClick={() => handleTabsSelect('buy')}
              >
                {t('BUY')}
              </Button>
              <Button
                type="text"
                className={`transaction-btn overview-btn ${
                  tab === 'sell' ? 'ant-btn-active' : ''
                }`}
                onClick={() => handleTabsSelect('sell')}
              >
                {t('SELL')}
              </Button>
            </Col>
            <Col className="modal-action-btn-wrap">
              <Button
                icon={<Update/>}
                iconPosition='end'
                className="portfolio-cancel-btn cancel-btn"
                onClick={handleUpdate}
              >
                {t('UPDATE')}
              </Button>
              <Button
                icon={<Quantify/>}
                iconPosition='end'
                className="portfolio-cancel-btn success-btn"
                onClick={handleExecute}
              >
                {t('QUANTIFY')}
              </Button>
            </Col>
          </Row>
          <div className="transaction-table-wrap">
            {(buy || sell) && (
              <TableBuySell
                tab={tab}
                setId={setId}
                dataBuySell={tab === 'buy' ? buy : sell}
                isLoading={isLoading}
                currencyName={getCurrencyName}
              />
            )}
          </div>
        </>
          )
        : (
        <div>
          <h1 className='modal-header-title'>{t('CONFIRM_QUANTIFICATION')}</h1>
          <Divider className='filter-divider' style={{marginTop: '32px'}}/>
          <Row justify="start" className="modal-text-wrapper">
            <Col>
              <p className="modal-advisor-text modal-question-text">{t('WANT_ORDER_EXECUTED')}</p>
              <p className="modal-advisor-name modal-item-name">{getCandidate()?.name}</p>
            </Col>
          </Row>
          <Row justify='end' className="modal-footer-btn-wrapper" style={{marginTop: '48px'}}>
            <Col style={{display: 'flex', gap: '16px'}}>
              <Button
                className="portfolio-cancel-btn cancel-btn"
                onClick={handleCloseModal}
              >
                {t('CANCEL')}
              </Button>
              <Button
                icon={<Quantify/>}
                iconPosition='end'
                className="modal-action-btn success-btn"
                onClick={handleExecuteAction}
              >
                {t('QUANTIFY')}
              </Button>
            </Col>
          </Row>
        </div>
        )}
    </Modal>
  );
};

export default ModalTransaction;

ModalTransaction.propTypes = {
  approved: PropTypes.array,
  openModalTransaction: PropTypes.number,
  closeModalTransaction: PropTypes.func,
};
