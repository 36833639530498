import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button, Progress, Skeleton } from 'antd';
import { ReactComponent as Cross } from '../../assets/grey-cross.svg';

const ReportDone = (props) => {
  const { interval, isReportOptPrepare, getReportName, closeTooltip, downloadReport } = props;
  const { t } = useTranslation('');
  const [point, setPoint] = useState(0);
  const [statusProgres, setStatusProgres] = useState(null);

  const intervalRef = useRef(null);

  useEffect(() => {
    if (typeof isReportOptPrepare === 'number') {
      clearInterval(intervalRef.current);
      setPoint(100);
      setStatusProgres('success');
    } else if (typeof isReportOptPrepare === 'boolean' && !isReportOptPrepare) {
      clearInterval(intervalRef.current);
      setStatusProgres('exception');
    }

    return () => {
      console.log('unmount');
      clearInterval(intervalRef.current);
    };
  }, [isReportOptPrepare]);

  useEffect(() => {
    if (point > interval && isReportOptPrepare === true) {
      clearInterval(intervalRef.current);
      setStatusProgres('exception');
    }
  }, [point]);

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setPoint(prevPoint => prevPoint + 1);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className='report-done-wrapper'>
    <div
      className='close-cross-btn'
      onClick={closeTooltip}><Cross /></div>
      <div className='report-done-loader'>
        <Progress
          type="circle"
          showInfo={statusProgres}
          status={statusProgres}
          steps={50}
          percent={Math.ceil(point * 100 / interval)}
          strokeColor="#6467C3"
          trailColor="#24201F"
          strokeWidth={10}
          size={[72, 72]}
          gapDegree={0}
        />
      </div>
      <div className='report-done-data'>
        {
          statusProgres === 'success'

            ? (<>
              <p className='report-done-data-header'>{t('DONE')}...</p>
              <p className='report-done-data-name'>{getReportName(isReportOptPrepare)}</p>
              <Button
                className='download-btn'
                onClick={downloadReport(isReportOptPrepare)}
                >{t('DOWNLOAD')}</Button>
            </>)
            : statusProgres === 'exception'
              ? (
                <>
                  <p className='report-done-data-header'>{t('REPORT_NOT_READY')}...</p>
                  <p className='report-done-data-name'>{t('TRY_LATER')}</p>
                </>
                )
              : (
                <>
                  <p className='report-done-data-header'>{t('CREATING_REPORT')}...</p>
                  <Skeleton
                    title={{ width: '100%' }}
                    paragraph={false}
                    active
                    loading={true}
                  />
                </>
                )

        }

      </div>
    </div>
  );
};

export default ReportDone;

ReportDone.propTypes = {
  closeTooltip: PropTypes.func,
  getReportName: PropTypes.func,
  downloadReport: PropTypes.func,
  interval: PropTypes.number,
  isReportOptPrepare: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.oneOf([null])
  ]),
};
