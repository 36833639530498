import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Modal, Row } from 'antd';

const ModalDeleteModel = ({  deleteMap, handleCloseDelModal, onDeleteInstrument, name, text }) => {
  const { t } = useTranslation('');

  return (
    <Modal
        width={700}
        className="modal-content-paper custom-modal"
        open={deleteMap}
        onCancel={handleCloseDelModal}
        footer={null}
        destroyOnClose={true}
        zIndex={1600}>
        <div>
          <h1 className='modal-header-title' >{t('MODAL_DELETE_TITLE')}</h1>
          <Divider className='filter-divider' style={{marginTop: '32px'}}/>
          <Row justify="start" className="modal-text-wrapper">
            <Col>
              <p className="modal-advisor-text modal-question-text">{t(text)}</p>
              <p className="modal-advisor-name modal-item-name">{name}</p>
            </Col>
          </Row>
        </div>
        <Row justify='end' className="modal-footer-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={onDeleteInstrument}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalDeleteModel;

ModalDeleteModel.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  deleteMap: PropTypes.number,
  handleCloseDelModal: PropTypes.func,
  onDeleteInstrument: PropTypes.func,
};
