import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { downloadTEMP, getAvailablePortfolioCode, saveFullPortfolio } from '../../data/store/portfolio/portfolioActions';

import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd';

import ButtonAdd from '../ButtonAdd/ButtonAdd';
import { ReactComponent as Temp } from '../../assets/file-icon.svg';
import { ReactComponent as Attach } from '../../assets/attach-icon.svg';

import './style.scss';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomInputNumber from '../CustomInputNumber/CustomInputNumber';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import { getRecommendationMaps } from '../../data/store/instruments/instrumentsActions';

const ModalNewPortfolio = ({ open, closeModal, userId }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const inputXlsx = useRef(null);
  const [fileUploaded, setFileUploaded] = useState(null);

  const { recommendation } = useSelector((state) => state.instrumentsReducer);
  const { identification_number, id } = useSelector((state) => state.userReducer?.advisor);
  const { portfolioCodeList, isLoadingCode } = useSelector((state) => state.portfolioReducer);
  const isChecked = Form.useWatch('is_sync_active', form);

  const [portfolioTypeList, setPortfolioTypeList] = useState(null);

  useEffect(() => {
    dispatch(getRecommendationMaps());
  }, []);

  useEffect(() => {
    const list = recommendation?.filter( i => i?.is_complete);
    setPortfolioTypeList(list ?? []);
  }, [recommendation]);
  
  

  useEffect(() => {
    console.log('isChecked', isChecked);
    if (!isChecked) {
      form.setFieldValue('portfolio_code', '');
      form.validateFields(['portfolio_code']);
    } else {
      dispatch(getAvailablePortfolioCode({ advisorId: id }));
    }
  }, [isChecked]);

  const download = () => {
    dispatch(downloadTEMP());
  };

  const handleUpload = () => {
    if (inputXlsx.current) {
      inputXlsx.current.click();
    }
  };

  const uploadPortfolio = (event) => {
    const file = event.target.files[0];
    setFileUploaded(file);
  };

  const deleteUploadedFile = () => {
    setFileUploaded(null);
  };
  
  const handleChangeTaxation = (value) => {
    if (+value === 2) {
      form.setFieldValue('tax_rate', '0.888');
    } else {
      form.setFieldValue('tax_rate', '');
    }
  };

  const filterOption = (inputValue, option) => {
    const value = inputValue.toString();
    if (value?.length > 2) {
      return (
        option?.children.toString().toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return option;
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    closeModal();
  };

  const onSubmit = (data) => {
    if (Object.keys(data)?.length) {
      const newPortfolio = {
        data: {
          ...data,
          tax_rate: +data.tax_rate,
          portfolio_code: data?.portfolio_code ? +data.portfolio_code : null,
          portfolio_type: +data.portfolio_type,
          deposit_fee: +data.deposit_fee,
          advisory_fee: +data.advisory_fee,
          taxation: +data.taxation,
          account_id: +userId,
        },
        history,
        closeModal: handleCloseModal,
        userId,
      };
      if (fileUploaded) {
        dispatch(saveFullPortfolio({ ...newPortfolio, file: fileUploaded }));
      } else {
        dispatch(saveFullPortfolio({...newPortfolio}));
      }
    }
  };

  return (
    <Modal
      width={700}
      className="modal-portfolio"
      open={open}
      onCancel={handleCloseModal}
      footer={null}
      closable={false}
      zIndex={1620}
      destroyOnClose
    >
      <Row justify="start" align={'middle'}>
        <Col className="modal-newportfolio-title">{t('CREATE_PORTFOLIO')}</Col>
      </Row>
      <div className="color-box-header">
        <Row justify={'end'} style={{ width: '100%' }}>
          <Col>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '16px',
              }}
            >
              <ButtonAdd
                title="ADD_PORTFOLIO"
                height="24px"
                theme="light"
                onClick={handleUpload}
              />
            </div>
            <input
              ref={inputXlsx}
              type="file"
              accept={'.xlsx'}
              className="portfolio-creat-download-input"
              onChange={uploadPortfolio}
            />
            {fileUploaded && (
              <Tooltip
                placement="top"
                title={<span style={{ color: '#000000' }}>{t('CLEAR')}</span>}
                color="white"
              >
                <div className="uploaded-name" onClick={deleteUploadedFile}>
                  <Attach />
                  {fileUploaded?.name}
                </div>
              </Tooltip>
            )}
            <div onClick={download} className="download-import-temp">
              {t('DOWNLOAD_THE_TEMPLATE_EXCEL')}
              <Temp />
            </div>
          </Col>
        </Row>
      </div>
      <Form
        id="create-portfolio"
        form={form}
        onFinish={onSubmit}
        validateTrigger={['onSubmit', 'onBlur']}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          layout="vertical"
          label={t('NAME')}
          name="name"
          required={false}
          rules={[
            {
              required: true,
              message: t('PLEASE_ENTER_A_NAME'),
            },
          ]}
        >
          <Input className="custom-input full-width"/>
        </Form.Item>
        <Row className="portfolio-form-row wrapper-portfolio-number">
          <div className="portfolio-number">
            <Form.Item
              label={t('PORTFOLIO_NUMBER')}
              name="portfolio_code"
              required={false}
              rules={
                isChecked
                  ? [
                      {
                        required: true,
                        message: t('PLEASE_ENTER_PORTFOLIO_NUMBER'),
                      },
                    ]
                  : [
                      {
                        required: false,
                      },
                    ]
              }
            >
              <CustomSelect
                width="100%"
                disabled={!isChecked || isLoadingCode}
                loading={isLoadingCode}
                showSearch
                placeholder={t('PORTFOLIO_NUMBER')}
                className="custom-select"
                filterOption={(inputValue, option) =>
                  filterOption(inputValue, option)
                }
              >
                {portfolioCodeList?.map((code, index) => {
                  return (
                    <Select.Option key={index} value={code}>
                      {code}
                    </Select.Option>
                  );
                })}
              </CustomSelect>
            </Form.Item>
            <p className="portfolio_code-helper">
              {t('ENTER_PORTFOLIO_ID_FROM_FA')}
            </p>
          </div>
          <div className="portfolio-number-sync">
            <Tooltip
              placement="top"
              title={
                !identification_number ? (
                  <span style={{ color: '#000000' }}>
                    {t('TO_SYNC_PORTFOLIOS')}
                  </span>
                ) : null
              }
              color="white"
            >
              <Form.Item name="is_sync_active" valuePropName="checked">
                <Checkbox
                  disabled={!identification_number}
                  className="experience-background-cell center experience-background-checkbox my-details-checkbox"
                >
                  {t('ACTIVATE_SYNCHRONIZATION')}
                </Checkbox>
              </Form.Item>
            </Tooltip>
          </div>
        </Row>
        <Row className="portfolio-form-row wrapper-portfolio-tax">
          <div className="portfolio-taxation">
            <Form.Item
              label={t('TAXATION')}
              name="taxation"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('PLEASE_SELECT_METHOD_TAXATION'),
                },
              ]}
            >
              <CustomSelect
                width="100%"
                className="custom-select"
                onChange={(value) => handleChangeTaxation(value)}
              >
                <Select.Option value="1">{t('CAPITAL_GAIN')}</Select.Option>
                <Select.Option value="2">{t('FLAT_RATE')}</Select.Option>
              </CustomSelect>
            </Form.Item>
          </div>
          <div className="portfolio-taxrate">
            <Form.Item
              label={t('TAX_RATE')}
              name="tax_rate"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('PLEASE_ENTER_TAX_RATE'),
                },
              ]}
            >
              <CustomInputNumber
                width={'100%'}
                percent={true}
                decimalSeparator={decimalSeparator()}
                precision={3}
                className="custom-inputnumber"
                min={0}
                step={0.001}
                controls={false}
              />
            </Form.Item>
          </div>
        </Row>
        <Row className="portfolio-form-row">
          <Col style={{width: '120px'}}>
            <Form.Item
              label={t('DEPOSIT_FEE')}
              name="deposit_fee"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('PLEASE_ENTER_DEPOSIT_FEE'),
                },
              ]}
            >
              <CustomInputNumber
                width={'120px'}
                percent={true}
                decimalSeparator={decimalSeparator()}
                precision={2}
                className="custom-inputnumber"
                min={0}
                step={0.01}
                controls={false}
              />
            </Form.Item>
          </Col>
          <Col style={{width: '120px'}}>
            <Form.Item
              label={t('ADVISORY_FEE')}
              name="advisory_fee"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('PLEASE_ENTER_ADVISORY_FEE'),
                },
              ]}
            >
              <CustomInputNumber
                width={'120px'}
                percent={true}
                decimalSeparator={decimalSeparator()}
                precision={2}
                className="custom-inputnumber"
                min={0}
                step={0.01}
                controls={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t('PORTFOLIO_TYPE')}
          name="portfolio_type"
          required={false}
          rules={[
            {
              required: true,
              message: t('CHOOSE_PORTFOLIO_TYPE'),
            },
          ]}
        >
          <CustomSelect width="100%" className="custom-select">
          {
            portfolioTypeList?.map(el => (
            <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>

            ))
          }
          </CustomSelect>
        </Form.Item>
        <Row className="modal-newportfolio-button-wrapper">
          <Button
            type='text' 
            className='modal-action-btn cancel-btn' 
            onClick={handleCloseModal}>
            {t('CANCEL')}
          </Button>
          <Button
            type='default'
            className='modal-action-btn save-btn' 
            onClick={() => form.submit()}>
            {t('CONTINUE')}
          </Button>
        </Row>
      </Form>

    </Modal>
  );
};

export default ModalNewPortfolio;

ModalNewPortfolio.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  userId: PropTypes.string,
};
