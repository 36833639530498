import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';

import { ReactComponent as RecordingActive } from './img/rerording-key-active.svg';
import { ReactComponent as RecordingStoped } from './img/rerording-key-stoped.svg';

const AudioRecordIcon = ({width, height, speed = 0, action}) => {
  console.log('🚀 ~ AudioRecordIcon ~ speed:', speed);
  return (
    <div className='microfone-zone'>
    {action
        ? <RecordingActive width={width} height={height}/>
        : <RecordingStoped width={width} height={height}/>
    
    }
      
    </div>
  );
};

export default AudioRecordIcon;

AudioRecordIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    speed: PropTypes.number,
    action: PropTypes.bool,
  };