import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, DatePicker } from 'antd';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { ReactComponent as Picker } from '../../assets/date-picker-icon.svg';
import { ReactComponent as Copy } from '../../assets/copy-icon.svg';
import { ReactComponent as Collapse } from '../../assets/collapse-onboarfing-icon.svg';
import { ReactComponent as Confirm } from './img/confirm-icon.svg';
import { ReactComponent as Script } from './img/script-icon.svg';
import { ReactComponent as OrangeDot } from './img/orang-dot.svg';

import { rescheduleOnboarding } from '../../data/store/account/accountActions';
import ModalReschedule from './ModalReschedule/ModalReschedule';

import './style.scss';

const OnboardingPlanningComplete = ({ userId, url }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { onbording } = useSelector((state) => state.accountReducer);
  const [date, setDate] = useState(null);
  const [modalRescheduleVisible, setModalRescheduleVisible] = useState(null);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if(onbording) {
      setTimeout(() => {
        setDate(null);
      }, 2000);
    }
  }, [onbording]);
  

  const [copySuccess, setCopySuccess] = useState(false);

  const guest = useMemo(() => {
    const guest = onbording?.members?.split(' ');
    return guest;
  }, [onbording]);

  const handleStartScript = () => {
    history.push(`${url}/script`);
  };
  const handleStartRecord = () => {
    history.push(`${url}/script?category=recorder`);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(onbording?.meeting_link)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
      .catch((err) => {
        console.log('🚀 ~ handleCopy ~ err:', err);
        setCopySuccess(false);
      });
  };

  const handleReschedule = (value) => {
    if (onbording?.type === 'offline') {
      console.log('Selected Time: ', dayjs(value).format());
      setDate(value);
      dispatch(
        rescheduleOnboarding({
          clientId: userId,
          id: onbording.id,
          start_date: dayjs(value).format(),
        }),
      );
      // setDate(null);
    } else {
      setModalRescheduleVisible(value);
    }
  };

  const handleModalRescheduleClose = () => {
    setDate(null);
    setModalRescheduleVisible(null);
  };

  return (
    <div className="onbording-card-wrapper">
      <div className="onbording-card card-left">
        <div className="onbording-card-fill">
          <Button
            type="text"
            icon={<Collapse />}
            className="onbording-collapse-btn"
          />
          <div className="onbording-step-wrapper">
            <h2 className="onbording-card-title">{t('ONBOARDING')}</h2>
            <p className="onbording-card-description">
              {t('PLEASE_USE_SCRIPT')}
            </p>
          </div>
        </div>
        <div className="left-footer">
          <p className="left-footer-link">{t('NEED_HELP')}</p>
        </div>
      </div>

      <div className="onbording-card card-right">
        {onbording?.type === 'online' ? (
          <div style={{ paddingTop: '72px', marginBottom: '24px', overflowY: 'auto'}}>
          <h2 className="onbording-card-header">{t('YOUR_SCHEDULED_MEETING')}</h2>
          <h3 className="onbording-card-question">{t('YOUR_MEETING_DETAILS')}</h3>
            <div className='onbording-meeting-card-wrapper online'>
              <div>
                <h4 className="onbording-meeting-card-title">{t('YOUR_SCHEDULED_MEETING')}:</h4>
                <p className="onbording-meeting-card-date">
                  {dayjs(onbording?.start_date ?? '').format('dddd MMM D, YYYY')}
                </p>
                <p className="onbording-meeting-card-hour">
                  {dayjs(onbording?.start_date ?? '').format('HH:mm')}
                </p>
            </div>
            <div>
              <p className="onbording-card-subtitle">{t('GUESTS')}</p>
              {guest?.map((guest, index) => {
                return (
                  <p key={index} className="onbording-card-meeting-link">
                    <OrangeDot className='orange-dot'/>
                    {guest}
                  </p>
                );
              })}

              <p className="onbording-card-subtitle" style={{marginTop: '32px', marginBottom: '8px'}}>{t('MEETING_LINK')}</p>
              <p className="onbording-card-meeting-link">
                <a
                  href={onbording?.meeting_link}
                  target="_blank"
                  className=""
                  rel="noreferrer"
                >
                  {onbording?.meeting_link}
                </a>
                {!copySuccess ? (
                  <Copy onClick={handleCopy} />
                ) : (
                  <span className="onbording-card-copied">{t('COPIED')}</span>
                )}
              </p>
              <div className='card-footer-wrapper' style={{marginTop: '30px'}}>
                <div className="custom-datepicker-wrap">
                  <DatePicker
                    name="newMeetingDate"
                    className="date-picker-btn reschedule"
                    showTime={{ format: 'HH:mm', secondStep: 60 }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={t('RESCHEDULE')}
                    suffixIcon={<Picker />}
                    onChange={(value) => handleReschedule(value)}
                    value={date}
                  />
                </div>
                <div className="custom-btn-wrap" style={{textAlign: 'right'}}>
                  <Button
                    icon={<Script />}
                    iconPosition='end'
                    className="onboarding-script-btn"
                    onClick={handleStartScript}
                  >
                    {t('SCRIPT')}
                  </Button>
                </div>

              </div>
            </div>
          </div>
          </div>
        ) : onbording?.type === 'offline' || onbording?.type === '' ? (
          <div style={{ overflowY: 'auto'}}>
          <div style={{ paddingTop: '72px', marginBottom: '24px'}}>
            <h2 className="onbording-card-header">{t('YOUR_SCHEDULED_MEETING')}</h2>
            <h3 className="onbording-card-question">{t('YOUR_MEETING_DETAILS')}</h3>

            <div className='onbording-meeting-card-wrapper'>
              <h4 className="onbording-meeting-card-title">{t('YOUR_SCHEDULED_MEETING')}:</h4>
              <p className="onbording-meeting-card-date">
                {dayjs(onbording?.start_date ?? '').format('dddd MMM D, YYYY')}
              </p>
              <p className="onbording-meeting-card-hour">
                {dayjs(onbording?.start_date ?? '').format('HH:mm')}
              </p>
              <div className='onbording-record-img'/>
              <div className="reschedule-wrapper">
                <DatePicker
                  name="newMeetingDate"
                  className="date-picker-btn reschedule"
                  showTime={{ format: 'HH:mm', secondStep: 60 }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={t('RESCHEDULE')}
                  suffixIcon={<Picker />}
                  onChange={(value) => handleReschedule(value)}
                  value={date}
                />
              </div>
            </div>
          </div>
          </div>
        ) : null}
        {onbording?.type !== 'online' &&
          <Button
            icon={<Confirm />}
            iconPosition='end'
            type='text'
            className="start-onbording-btn"
            onClick={handleStartRecord}
          >
            {t('START_RECORDING')}
          </Button>
        }
      </div>
      <ModalReschedule
        clientId={userId}
        modalRescheduleVisible={modalRescheduleVisible}
        handleModalRescheduleClose={handleModalRescheduleClose}
        oldTime={onbording?.start_date}
        id={onbording?.id}
      />
    </div>
  );
};

export default OnboardingPlanningComplete;

OnboardingPlanningComplete.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
};
