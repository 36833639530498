export const portfolioOptimizationEN = {
  MIN_MAX: 'Min/Max',
  PORTFOLIO_TYPE: 'Portfolio Type',
  PRODUCT_CHOICE_TEXT: 'Choose if the client should have a Quant-portfolio or a 4-fund portfolio. Citroneer recommends the 4-fund portfolio to clients that have less than 3 000 000 kr in their total portfolio. This is to avoid unnecessarily high transaction costs for small positions in specific instruments.',
  SELECTION_PORTFOLIO_TYPE: 'Selection of portfolio type*',
  QUANT: 'Quant',
  FOUR_FUND: '4-Fund',
  ETHICAL_CONSIDERATIONS: 'Ethical considerations in product selection*',
  EXCLUDE_HOLDINGS: 'Exclude Holdings',
  EXCLUDE_HOLDINGS_CONTENT: 'If there are any holdings in the selected portfolios that the client does not want to include in the optimization, you can exclude specific holdings here. By checking the "Exclude holdings" box, you get the opportunity to check which holdings the customer does not want to be included in the optimization.',
  PORTFOLIO_CONSTRAINTS: 'Portfolio Constraints',
  STRATEGY_CONSTRAINTS_CONTENT: 'State whether there is any investor bias that should apply to the optimization. Investor bias refers to whether the client has any investment bias regarding equity asset classes that are not included in Citroneer\'s Equity Strategy Basket, for example Swedish Stocks. If the client has any investment biases, state the percentage of their portfolio that is affected by each bias. The total sum of the customer\'s investment biases cannot exceed 25% of their portfolio.',
  INVESTOR_BIAS: 'Investor bias',
  ADD_INVESTOR_BIAS: 'Add Investor Bias',
  ADD_CONSTRAINT: 'Add constraint',
  ENETR_BIAS_NAME: 'Enter name',
  PERCENTAGE: 'Percentage',
  ENTER_PERCENTAGE: 'Enter percentage',
  PRODUCT_NAME_ISIN: 'Product name & ISIN',
  SETTINGS: 'Settings',
  BACK_TO_SETTINGS: 'Back to Settings',
  CAPITAL_PLANNING_REAL: 'Real',
  CAPITAL_PLANNING_NOMINAL: 'Nominal',
  CAPITAL_PLANNING: 'Capital planning',
  CHOOSE_ALTERNATIVE: 'Choose alternative',
  AGE: 'Age',
  ENTER_AGE: 'Enter age',
  TIME_HORIZON: 'Time Horizon',
  FEES: 'Fees',
  PRODUCT_FEES: 'Product fees',
  DEPOSIT_ADVISORY_FEES: 'Deposit- and advisory fees',
  DEPOSIT_FEES: 'Deposit fees',
  DEPOSIT_OPT_FEE: 'Deposit fee at Citroneer',
  ENTER_FEE: 'Enter fee',
  ENTER_THE_DEPOSIT_FEE: 'Enter the deposit fee',
  ENTER_THE_ADVISORY_FEE: 'Enter the advisory fee',
  ADVISORY_FEES: 'Advisory fees',
  ADVISORY_OPT_FEE: 'Advisory fee at Citroneer',
  INCLUDE: 'Include',
  TYPE_OF_CLIENT: 'Type of client',
  EXISTING: 'Existing',
  POTENTIAL: 'Potential',
  DEPOSIT_FEE: 'Deposit fee',
  ADVISORY_FEE: 'Advisory fee ',
  INCLUDE_DEPOSIT_FEES: 'Include deposit fees',
  INCLUDE_ADVISORY_FEES: 'Include advisory fees',
  DEPOSITS: 'Deposits',
  ADD_DEPOSIT: 'Add deposit',
  TYPE: 'Type',
  CHOOSE_TYPE: 'Choose type',
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
  ONE_TIME: 'One-time',
  DESCRIPTION: 'Description',
  ENTER_DESCRIPTION: 'Enter description',
  AMOUNT: 'Amount',
  ENTER_AMOUNT: 'Enter amount',
  START: 'Start',
  ENTER_START: 'Enter start',
  END: 'End',
  ENTER_END: 'Enter end',
  WITHDRAWALS: 'Withdrawals',
  ADD_WITHDRAWALS: 'Add withdrawal',
  PORTFOLIO_DEVELOPMENT: 'Portfolio Development',
  WEALTH_APPRECIATION: 'Wealth Appreciation',
  PORTFOLIO_SIGNATURE: 'Portfolio Signature',
  SUGGESTED_PORTFOLIO: 'Suggested Portfolio',
  PORTFOLIO_OPTIMIZATION: 'Portfolio Optimization',
  FINANCIAL_PLANNING: 'Financial Planning',
  ASSET_ALLOCATION_CURRENT_PORTFOLIO: 'Asset Allocation Current Portfolio',
  ASSET_ALLOCATION_OPTIMAL_PORTFOLIO: 'Asset Allocation Optimal Portfolio',
  ASSET_ALLOCATION: 'Asset Class Allocation',
  ASSET_CLASS: 'Asset Class',
  GENERAL: 'General',
  DIVERSIFICATION: 'Diversification',
  DEFENSIVE_BASKET: 'Defensive Basket',
  SWEDISH_GOVERNMENT_BONDS: 'Swedish Government Bonds',
  AMERICAN_GOVERNMENT_BONDS: 'American Government Bonds',
  JAPANESE_GOVERNMENT_BONDS: 'Japanese Government Bonds',
  GOLD: 'Gold',
  EQUITY_BASKET: 'Equity Basket',
  DEFENSIVE_STOCKS_MIN: 'Defensive Stocks min',
  DEFENSIVE_STOCKS_MAX: 'Defensive Stocks max',
  SMALL_CAP_STOCKS: 'Small Cap Stocks',
  MOMENTUM_STOCKS: 'Momentum Stocks',
  VALUE_STOCKS: 'Value Stocks',
  BOND_BASKET: 'Bond Basket',
  EMERGING_MARKET_STOCKS: 'Emerging Market Stocks',
  EMERGING_MARKET_BONDS: 'Emerging Market Bonds',
  HIGH_YIELD: 'High Yield Bonds',
  INVESTMENT_GRADE: 'Investment Grade Bonds',
  EFFICIENT_FRONTIER: 'Efficient Frontier',
  NUMBER_PORTFOLIOS_GRAPH: 'No. of portfolios in graph',
  CURRENT_PORTFOLIO: 'Current Portfolio',
  SAVE_OR_DISCARD: 'Do you want to save or discard the changes?',
  SAVE_CHANGES: 'Save changes',
  DO_NOT_SAVE: 'Do not save',
  DO_YOU_WANT_SAVE: 'Do you want to save your changes to the portfolio?',
  OPTIMIZED_PORTFOLIO: 'Optimized Portfolio',
  PLEASE_ENTER_A_NAME: 'Please enter a name',
  PLEASE_ENTER_PORTFOLIO_NUMBER: 'Please enter a portfolio number',
  PLEASE_SELECT_METHOD_TAXATION: 'Please select a method of taxation',
  PLEASE_ENTER_TAX_RATE: 'Please enter a tax rate',
  PLEASE_ENTER_DEPOSIT_FEE: 'Please enter a deposit fee',
  PLEASE_ENTER_ADVISORY_FEE: 'Please enter an advisory fee',
  CHOOSE_CAPITAL_PLANNING: 'Choose capital planning',
  AGE_IS_REQUIRED: 'Age is required',
  GO_BACKWARD: 'Go backward',
  GO_FORWARD: 'Go forward',
  START_OPTIMIZE: 'Start optimize',
  RECOMMENDED_PORTFOLIO: 'Recommended Portfolio',
  SHOULD_ANY_AMOUNT: 'Should any amount of the portfolio be withdrawn?',
  SHOULD_ANY_AMOUNT_DEPOSITED: 'Should any amount be deposited to the portfolio?',
  INCLUDE_THE_FINANCIAL_PLAN: 'Do you want to include the financial plan in the calculations? *',
  MONETARY_BASIS: 'What monetary basis would you like to use for the calculations? *',
  MONET_BASIS: 'Monetary basis*',
  NOMINAL: 'Nominal',
  REAL: 'Real',
  TRANSACTIONS: 'Transactions',
  SUGGEST_TRANSACTIONS: 'Suggest Transactions',
  INFO_TEXT: 'Choose the dot on the graph to proceed for optimization',
  RETURN: 'Return',
  VOLATILITY: 'Volatility',
  DISTRIBUTION: 'Asset Category Allocation',
  APPROVE_TRANSACTIONS: 'Approve transactions',
  WARNING_OPT: 'Warning',
  WARNING_OPT_TEXT: 'You are about to leave optimization flow. All entered information will be lost. Are you sure about this?',
  CONTINUE_OPTIMIZATION: 'Continue optimization',
  LEAVE_OPTIMIZATION_FLOW: 'Leave optimization flow',
  TIME_HORIZON_YEARS: 'Time Horizon (years)',
  WEALTH_MKR: 'Wealth (mkr)',
  CREATING_REPORT: 'Creating Report',
  DONE: 'Done',
  DOWNLOAD: 'Download',
  REPORT_NOT_READY: 'Report not ready',
  TRY_LATER: 'Try later',
  CASH_LEVEL: 'Net Cash Amount',
  CASH_REMAINING: 'Cash Remaining',
  CASH_WITHDRAWAL: 'Cash Withdrawal',
  VOLATILITY_TOOLTIP: ' of the total portfolio volatility constitutes idiosyncratic risk.',
  SAVE_CONTINUE: 'Save & Continue',
  WITHDRAWAL_AMOUNT: 'Withdrawal amount',
  DEPOSIT_AMOUNT: 'Deposit amount',
  INCLUDE_FINANCIAL_PLAN: 'Include financial plan?*',
  INCLUDE_FEES: 'Include fees?',
  ADVISORY_FEE_AT_CITRONEER: 'Advisory fee at Citroneer *',
  SUGGESTED_INVESTMENTS: 'Suggested Investments',
  SEARCH:'Search',
};
