/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Modal, Row, Table } from 'antd';
import { numberWithSpaces } from '../../../helpers/formatInteger';
import { formatPercentage } from '../../../helpers/formatPercentage';

import { getPreCandidate } from '../../../data/store/portfolio/portfolioActions';

import { ReactComponent as Pluse } from '../../../assets/cross-btn-icon.svg';
import { ReactComponent as Check } from '../../../assets/check-white-icon.svg';

import './style.scss';

export const StepTransactions = ({ pdfSource = {}, setPDFSource, portfolioId, userId, setOptStart, optAlert, setOptAlert }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { analyzedPortfolioDot, prospectPortfolioSet } = useSelector((state) => state.portfolioReducer);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const [tabs , setTabs ] = useState(0);

  const stepsArray = sessionStorage.getItem('stepsArray')
    ? JSON.parse(sessionStorage.getItem('stepsArray'))
    : null;

  const optimalPortfolio = sessionStorage.getItem('selectedPortfolio')
    ? JSON.parse(sessionStorage.getItem('selectedPortfolio'))
    : null;

  useEffect(() => {
    if ((!optimalPortfolio || !prospectPortfolioSet) && !!stepsArray?.length) {
      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  }, [stepsArray]);

  const buyData = useMemo(() => {
    
    if (analyzedPortfolioDot?.transactions?.buy) {
      const data = analyzedPortfolioDot?.transactions?.buy;
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      return data?.map(i => {
        return { ...i, share: i.value / summ * 100 };
      });
    } return [];
  }, [analyzedPortfolioDot]);

  const sellData = useMemo(() => {
    if (analyzedPortfolioDot?.transactions?.sell) {
      const data = analyzedPortfolioDot?.transactions?.sell;
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      return data?.map(i => {
        return { ...i, share: i.value / summ * 100 };
      });
    } return [];
  }, [analyzedPortfolioDot]);

  const columnsBuy = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => {
        return value
          ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr`
          : '';
      },
    },
    {
      title: t('SHARE_PR'),
      dataIndex: 'share',
      className: 'share',
      align: 'right',
      render: (value) => value ? formatPercentage(value) : '',
    },
  ];
  const columnsSell = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => { return value ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr` : ''; }
    },
    {
      title: t('SHARE_PR'),
      dataIndex: 'share',
      className: 'share',
      align: 'right',
      render: (value) => value ? formatPercentage(value) : '',
    },
  ];

  useEffect(() => {
    if (optAlert?.flag) setModalCancelVisible(true);
  }, [optAlert]);

  const handleTabs = (tab) => {
    setTabs(tab);
  };

  const closeModal = () => {
    setOptAlert({ flag: false, path: '' });
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = optAlert.path;
    setOptStart(false);
    closeModal();
    history.push(path);
  };

  const handleNext = () => {
    const container7 = document
      .getElementById('pdf-part-buy')
      ?.innerHTML.replaceAll('"', '\'');
    const partSeven = `<div class='opt-charts-wrapper' id='pdf-part-7'>${container7}</div>`;
    const container7_selll = document
      .getElementById('pdf-part-sell')
      ?.innerHTML.replaceAll('"', '\'');
    const partSell = `<div class='opt-charts-wrapper' id='pdf-part-7'>${container7_selll}</div>`;
    setOptStart(false);
    const parts = {
      ...pdfSource,
      page5_table_buy: partSeven,
      page5_table_sell: partSell,
    };
    setPDFSource(parts);
    dispatch(getPreCandidate({ portfolioId, transactionId: analyzedPortfolioDot?.transactions.id }));
    setTimeout(() => {
      history.push(
        `/accounts/${userId}/optimization/${portfolioId}/suggested`
      );
    }, 500);
  };

  const handleBack = () => {
    setOptStart(false);
    sessionStorage.removeItem('selectedPortfolio');
    setPDFSource([]);
    history.goBack();
  };

  const handleReset = () => {
    setOptAlert({ flag: false, path: '' });
    setOptStart(false);
    history.push(`/accounts/${userId}/portfolio`);
  };

  return (
    <div className='optimization-component-wrapper transaction-page'>
      <div className="top-btn-wrapper">
        <Button
          type="text"
          className="portfolio-cancel-btn"
          icon={<Pluse />}
          iconPosition="end"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          className="optim-settings-btn"
          onClick={handleBack}
        >
          {t('BACK_TO_OPTIMISE')}
        </Button>
        <Button
          icon={<Check />}
          iconPosition="end"
          disabled={!optimalPortfolio?.name}
          onClick={handleNext}
          className="optim-continue-btn"
        >
          {t('SUGGESTED_PORTFOLIO')}
        </Button>
      </div>
      <Row className='transaction-tabs-wrapper'>
        <Col>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
            onClick={() => handleTabs(0)}
          >
            {t('BUY_TRANSACTIONS')}
          </Button>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
            onClick={() => handleTabs(1)}
          >
            {t('SELL_TRANSACTIONS')}
          </Button>
        </Col>
      </Row>
      {tabs === 0 &&
        <div className="transaction-inner-wrapper" id='pdf-part-buy'>
          <div className="client portfolio-table">
            <Table
              size="middle"
              rowKey={() => uuidv4()}
              columns={columnsBuy}
              rowHoverable={false}
              dataSource={analyzedPortfolioDot?.transactions?.buy
                ? buyData
                : []}
              pagination={false}
            />
          </div>
        </div>
      }
      {tabs === 1 &&
        <div className="transaction-inner-wrapper" id='pdf-part-sell'>
          <div className="client portfolio-table">
            <Table
              size="middle"
              rowKey={() => uuidv4()}
              columns={columnsSell}
              dataSource={analyzedPortfolioDot?.transactions?.sell
                ? sellData
                : []}
              rowHoverable={false}
              pagination={false}
            />
          </div>
        </div>
      }
      <Modal
        width={650}
        className='modal-content-paper'
        open={modalCancelVisible}
        onCancel={closeModal}
        footer={null}
        zIndex={1620}
      >
      <Row justify='center'>
        <Col className='modal-title'>
          {t('WARNING_OPT')}
        </Col>
      </Row>
      <Row justify='center' className='modal-text-wrapper'>
        <Col span={18}>
          <p className='modal-text'>{t('WARNING_OPT_TEXT')}</p>
        </Col>
      </Row>

      <Row
        className='modal-button-wrapper'
      >
        <Button className='modal-action-btn cancel-btn' onClick={cancelWithoutChange}>
          {t('LEAVE_OPTIMIZATION_FLOW')}
        </Button>
        <Button className='modal-action-btn save-btn' onClick={closeModal}>
          {t('CONTINUE_OPTIMIZATION')}
        </Button>
      </Row>
      </Modal>

    </div>
  );
};

StepTransactions.propTypes = {
  pdfSource: PropTypes.object,
  setPDFSource: PropTypes.func,
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  optAlert: PropTypes.object,
  setOptStart: PropTypes.func,
  setOptAlert: PropTypes.func,
};
