import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { deleteSuggestions } from '../../data/store/account/accountActions';

const ModalDeleteCandidat = ({ userId, modalDeleteCandidat, setModalDeleteCandidat, candidatType, name, text }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const handleCloseDelModal = () => {
    setModalDeleteCandidat(0);
  };

  const handleDelete = () => {
    dispatch(deleteSuggestions({ userId, candidatId: modalDeleteCandidat, candidatType }));
    setModalDeleteCandidat(0);
  };

  return (
    <Modal
        width={700}
        className="modal-content-paper custom-modal"
        open={modalDeleteCandidat}
        onCancel={handleCloseDelModal}
        footer={null}
        destroyOnClose={true}
        zIndex={1600}>
        <div>
          <h1 className='modal-header-title' >{t('MODAL_DELETE_TITLE')}</h1>
          <Divider className='filter-divider' style={{marginTop: '32px'}}/>
          <Row justify="start" className="modal-text-wrapper">
            <Col>
              <p className="modal-advisor-text modal-question-text">{t(text)}</p>
              <p className="modal-advisor-name modal-item-name">{name}</p>
            </Col>
          </Row>
        </div>
        <Row justify='end' className="modal-footer-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={handleDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalDeleteCandidat;

ModalDeleteCandidat.propTypes = {
  userId: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  candidatType: PropTypes.string,
  modalDeleteCandidat: PropTypes.number,
  setModalDeleteCandidat: PropTypes.func,
};
