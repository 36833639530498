import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Fraction from 'fraction.js';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Modal, Row } from 'antd';

import {ReactComponent as Edit} from './img/editing-icon.svg';

import './style.scss';


const ModalCheckModel = ({ open, closeModal, onOk, data, mapsId}) => {
  const { t } = useTranslation('');

  const { assets} = useSelector((state) => state.instrumentsReducer);

  const [result, setResult] = useState(null);

  useEffect(() => {
    console.log('result', result);
  }, [result]);

  const getAssetName = (id) => assets.find(i => i.ID === id)?.name ?? '';

  const handleError = useMemo(() => {
    let data = {};
    const errorClass = result?.assetPart.filter(i => i.result === false);
    if(errorClass?.length) {
      data.class = errorClass.map( i => `Asset class ${getAssetName(i.asset_class_id)} sum > 1`);
    }
    const lowClass = result?.assetPart.filter(i => typeof i.result === 'string');
    if(lowClass?.length) {
      data.lowClass = lowClass.map( i => `Asset class ${getAssetName(i.asset_class_id)} sum < 1 (${i.result})`);
    }

    const errorGroup = result?.groupPart.filter(i => i.result === false);
    if(errorGroup?.length) {
      data.group = errorGroup.map( i => `Group no ${i.group_no} sum > 1`);
    }
    const lowGroup = result?.groupPart.filter(i => typeof i.result === 'string');
    if(lowGroup?.length) {
      data.lowGroup = lowGroup.map( i => `Group no ${i.group_no} sum < 1 (${i.result})`);
    }
    return data;
  }, [result, assets]);

  const sumFractions = (arr) => {
    let sum = new Fraction(0);
    for (const fraction of arr) {
      sum = sum.add(new Fraction(fraction));
    }
    if (sum.equals(1)) return true;
    if (sum > 1) return false;
    return sum.toFraction();
  };
  

  useEffect(() => {
    if(open) {
      let assetClassArray = [];
      let groupArray = [];
      data?.forEach( i => {
        console.log('🚀 ~ useEffect ~ i:', i);
        i?.prio === 10 && !assetClassArray.includes(i.asset_class_id) && assetClassArray.push(i.asset_class_id);
      });
      data?.forEach( i => {
        i?.prio === 10 && !groupArray.includes(i.group_no) && i.group_no !== null && groupArray.push(i.group_no);
      });

      const dataPrio = data.filter( i => i.prio === 10);

      let assetPart = [];
      assetClassArray.forEach(i => {
        const item = dataPrio?.filter(el => el.asset_class_id === i)
        ?.map(i =>{
          const {denominator, numerator} = i.weight_in;
          return denominator === 1 || denominator === 0 ? numerator.toString() : `${numerator}/${denominator}`;
        });
        assetPart.push({asset_class_id: i, result: sumFractions(item)});
      });

      let groupPart = [];
      groupArray.forEach(i => {
        const item = dataPrio?.filter(el => el.group_no === i)
        ?.map(i => {
          const {denominator, numerator} = i.weight_out;
          return denominator === 1 || denominator === 0 ? numerator.toString() : `${numerator}/${denominator}`;
        });
        groupPart.push({group_no: i, result: sumFractions(item)});
      });
      setResult({assetPart, groupPart});
    }
  
    return () => {
      setResult(null);
    };
  }, [open]);

  const isValid = useMemo(() => {
    return (result?.assetPart
            .every(i => i.result === true) &&
            result?.groupPart.every(i => i.result === true));
  }, [result]);
      

  return (
    <Modal
      width={700}
      className="modal-content-paper custom-modal check"
      open={open}
      onCancel={closeModal}
      footer={null}
      zIndex={1620}
      destroyOnClose
    >
      <div>
        <h1 className='modal-header-title'>{t('CHECK_HEADER')}</h1>
        <div className={`veryfication-card ${isValid ? 'successed' : ''}`}>
          <p className="modal-text modal-result-short">
            {t(isValid ? 'SUCCESS_VALIDATION' : 'FAILED_VALIDATION')}
          </p>
          <p className="modal-text modal-result-footer">
            {t('CURRENT_MAP_IS_CHECKED')}
          </p>
        </div>
        <p className='modal-header-subtitle'>{t('VLIADATION_RESULT')}</p>
        <p className='modal-header-subtitle-result'>{t(isValid ? 'CHECK_RESULT_ONE' : 'CHECK_RESULT_ERROR')}</p>
        <Row justify="start" className="modal-text-wrapper">
          <Col className="modal-result-column" span={24}>
            {result?.assetPart.filter(i => i.result !== true)?.length > 0 &&
              <p style={{marginTop: '6px'}} className="modal-text modal-item-name">{t('CHECK_RESULT_ERROR_IN')}</p>
            }
              <ul>
                {handleError?.class?.map(el => (
                  <li key={uuidv4()} className="modal-text modal-item-name list">{el}</li>
                ))}
                {handleError?.lowClass?.map(el => (
                  <li key={uuidv4()} className="modal-text modal-item-name list">{el}</li>
                ))}
              </ul>
            {result?.groupPart.filter(i => i.result !== true)?.length > 0 &&
              <p style={{marginTop: '6px'}} className="modal-text modal-item-name">{t('CHECK_RESULT_ERROR_OUT')}</p>
            }
            <ul>
              {handleError?.group?.map(el => (
                <li key={uuidv4()} className="modal-text modal-item-name list">{el}</li>
              ))}
              {handleError?.lowGroup?.map(el => (
                <li key={uuidv4()} className="modal-text modal-item-name list">{el}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>

      <Row justify='end' className="modal-footer-btn-wrapper" style={{gap: '8px', marginTop: '12px'}}>
        <Button
          icon={<Edit />}
          iconPosition='end' 
          className="modal-action-btn cancel-btn return" 
          onClick={closeModal}>
          {t('RETURN_TO_EDITING')}
        </Button>
        <Button 
          className="modal-action-btn save-btn maps" 
          onClick={onOk} 
          disabled={!(result?.assetPart.every(i => i.result === true) && result?.groupPart.every(i => i.result === true))}>
          {t(`${mapsId === 'new' ? 'CREATE_MAP' : 'UPDATE'}`)}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalCheckModel;

ModalCheckModel.propTypes = {
  mapsId: PropTypes.string,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  cancelWithoutChange: PropTypes.func,
  onOk: PropTypes.func,
  data: PropTypes.array,
};
