export const OnboardingPoints = [
  {
    key: 2,
    value: 'client-information',
    label: 'CLIENT_INFORMATION',
  },
  {
    key: 3,
    value: 'purpose-matter',
    label: 'PURPOSE_MATTER'
  },
  {
    key: 4,
    value: 'financial-situation',
    label: 'FINANCIAL_SITUATION'
  },
  {
    key: 5,
    value: 'basic-prerequisites-for-management',
    label: 'BASIC_PREREQUISITES_FOR_MANAGEMENT'
  },
  {
    key: 6,
    value: 'know-your-client',
    label: 'KNOW_YOUR_CLIENT'
  },
  {
    key: 7,
    value: 'risk-tolerance',
    label: 'RISK_TOLERANCE'
  },
  {
    key: 8,
    value: 'risk-profile',
    label: 'RISK_PROFILE'
  },
  {
    key: 9,
    value: 'experience-background',
    label: 'EXPERIENCE_BACKGROUND'
  },
  // {
  //   key: 10,
  //   value: 'type-of-advice',
  //   label: 'TYPE_OF_ADVICE'
  // },
  // {
  //   key: 11,
  //   value: 'assignment-agreement',
  //   label: 'ASSIGNMENT_AGREEMENT'
  // },
  // {
  //   key: 12,
  //   value: 'signing',
  //   label: 'SIGNING'
  // },
];
