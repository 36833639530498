/* eslint-disable no-undef */
import { colorsCategory } from '../helpers/colors';

export const getAsseData = (data, currencyList) => {

  if (!data) return {};
  const getTotalSek = (id, amount) => {
    const total = currencyList?.find(i => i.id === id)?.rate * amount;
    return total;
  };
  
  const dataTotal = data.reduce((acc, item) => acc + item.total_value, 0);
  let assetClass = [];
  for (const item of data) {
    item?.portfolio_content?.concat(item.unlisted_content)?.forEach(el => {
      if(!el) {
        return;
      }
      const name = el.financial_asset?.name ?? el.financialAsset?.name;
      if (assetClass.some(i => i?.name === name)) {
        assetClass = assetClass.map(i => i.name === name
          ? { ...i, y: i?.y + el.value, weight: i?.weight + (el.value * 100) / dataTotal }
          : i);
      } else if (name) {
        assetClass.push(
          {
            name,
            y: el.value,
            weight: (el.value * 100 / dataTotal),
            color: colorsCategory[name] ? colorsCategory[name] : 'lightgrey',
          }
        );
      }
    });
    let cashesSum = 0;
    item.portfolio_cashes?.forEach(el => {
      cashesSum = cashesSum + getTotalSek(el.currency_id, el.amount);
    });
    if (assetClass.some(i => i?.name === 'Likviditet') && cashesSum) {
      assetClass = assetClass.map(i => i.name === 'Likviditet'
        ? { ...i, y: i?.y + cashesSum, weight: i.weight + (cashesSum * 100) / dataTotal }
        : i);
    } else if (cashesSum) {
      assetClass.push(
        {
          name: 'Likviditet',
          y: cashesSum,
          weight: (cashesSum * 100) / dataTotal,
          color: colorsCategory.Likviditet
            ? colorsCategory.Likviditet
            : 'lightgrey',
        }
      );
    }
  }
  const sortData = assetClass.sort((a, b) => {
    return b?.y - a?.y;
  });
  const asseData = { assetClass: sortData, dataTotal };
  return asseData;
};
