import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Select } from 'antd';
import { EditableContext } from './AssetClassManagement';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

const EditableCell = ({
  title,
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  assetCategories,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {dataIndex === 'name'
            ? (
            <Input
              className='custom-input full-width'
              style={{ width: '100%' }}
              onChange={save}
              placeholder={record[title]}
            />
              )
            : dataIndex === 'financialAssetCategoryID'
              ? (
              <CustomSelect
                width="360px"
                className="custom-select"
                onSelect={save}
                placeholder={record[title]}
              >
              {assetCategories
                ?.slice()
                ?.sort((a, b) => textSort(a.name, b.name))
                ?.map(({ ID, name }) => (
                <Select.Option key={ID} value={ID}>
                  {name}
                </Select.Option>
                ))

              }

              </CustomSelect>
                )
              : (
              <td {...restProps}>
                {children}
              </td>
                )
            }
        </Form.Item>
      </td>
    );
  } else {
    return (
      <td {...restProps}>
        {children}
      </td>);
  }
};

export default EditableCell;

EditableCell.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  assetCategories: PropTypes.array,
};
