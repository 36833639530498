import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { searchParams } from '../../data/store/portfolio/portfolioActions';
import { AutoComplete, Form, Tooltip } from 'antd';
import { EditableContext } from './Portfolio';
import { formatDate } from '../../helpers/formatDate';
import CustomInputNumber from '../CustomInputNumber/CustomInputNumber';

const EditableCellNew = ({
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  data,
  setTarget,
  decimalSeparator,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const form = useContext(EditableContext);
  const { instruments } = useSelector((state) => state.portfolioReducer);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log('🚀 ~ save ~ values:', values);
      if (dataIndex === 'units_number') {
        const newData = {
          ...record,
          ...values,
          value:
            values?.units_number *
            (values?.latest_value_sek || record?.latest_value_sek),
          units_number: Math.trunc(values.units_number * 10000) / 10000,
        };
        console.log('🚀 ~ save ~ newData:', newData);
        handleSave(newData);
      } else if (dataIndex === 'latest_value') {
        const newData = {
          ...record,
          ...values,
          latest_value_sek: !record?.financial_asset_id ? values?.latest_value : record?.latest_value_sek,
          value:
            values?.units_number *
            (values?.latest_value || record?.latest_value),
        };
        console.log('🚀 ~ save ~ newData:', newData);
        handleSave(newData);
      } else {
        const instrumentsObj = instruments?.list?.filter((instrument) => {
          if (dataIndex === 'isin') {
            return instrument.isin === Object.values(values)[0];
          } else if (dataIndex === 'instrument_name') {
            return instrument.name === Object.values(values)[0];
          } else {
            return null;
          }
        });
        instrumentsObj.length
          ? handleSave({
            ...record,
            ...values,
            currency_id: instrumentsObj[0].currency_id,
            latest_value:
                Math.round(instrumentsObj[0].latest_value * 100) / 100,
            latest_value_sek:
              Math.round(instrumentsObj[0].latest_value_sek * 100) / 100,
            isin: instrumentsObj[0].isin,
            finstrument_name: instrumentsObj[0].name,
            financial_instrument_id: instrumentsObj[0].ID,
            fee: instrumentsObj[0].fee,
            asset_class: instrumentsObj[0].financialAsset.name,
            financial_asset_id: instrumentsObj[0].financialAsset.ID,
            financial_asset_category_id:
                instrumentsObj[0].financialAsset.financialAssetCategoryID,
          })
          : handleSave({
            ...record,
            ...values,
            isin: 'N/A',
            asset_class: 'TBD',
            financial_asset_id: null,
            financial_asset_category_id: null,
            financial_instrument_id: null,
          });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const renderOptions = useCallback(() => {
    if (Object.keys(instruments).length === 0) {
      return [];
    } else {
      const dataId = data.map((el) => el.instrument_name);
      const filterInstruments = instruments.list.filter(
        (el) => !dataId.includes(el.name),
      );
      const options = filterInstruments.map((el) => ({
        value:
          dataIndex === 'isin'
            ? el.isin === 'NOT_APPLICABLE'
              ? 'N/A'
              : el.isin
            : el.name,
        key: el.ID,
      }));
      return options.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0,
      );
    }
  }, [instruments]);

  const onChange = useCallback(
    (value) => {
      dispatch(searchParams(value || ' '));
    },
    [dispatch, data],
  );

  if (
    editing &&
    !(
      dataIndex === 'latest_value' &&
      record.isin &&
      record.isin !== 'N/A'
    ) &&
    !(
      dataIndex === 'latest_value' &&
      record.financialInstrumentName === 'Cash'
    )
  ) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {['units_number', 'latest_value'].includes(dataIndex)
            ? (
            <CustomInputNumber
              className='custom-inputnumber'
              decimalSeparator={decimalSeparator}
              style={{ width: '100%' }}
              min={0}
              onBlur={save}
              onChange={save}
              onInput={save}
              placeholder={record[dataIndex]}
            />
              )
            : !record?.id
                ? (
              <AutoComplete
                className="custom-select"
                popupClassName='custom-dropmenu-wrapper'
                popupMatchSelectWidth={360}
                style={{ width: '100%' }}
                options={renderOptions()}
                onBlur={save}
                onSelect={save}
                onKeyPress={(v) => v.code === 'Enter' && save()}
                onChange={onChange}
                placeholder={record[dataIndex]}
                filterOption={(inputValue, option) =>
                  typeof inputValue === 'string'
                    ? option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                    : option.value.indexOf(inputValue) !== -1
                }
              />
                  )
                : (
              <td {...restProps} onClick={setTarget}>
                {children}
              </td>
                  )
            }
        </Form.Item>
      </td>
    );
  } else {
    return dataIndex !== 'latest_value'
      ? (
      <td {...restProps} onClick={setTarget}>
        {children}
      </td>
        )
      : (
      <Tooltip
        placement="top"
        title={
          <span style={{ color: '#000000' }}>
            {formatDate(record?.latest_value_time)}
          </span>
        }
        color="white"
      >
        <td {...restProps} onClick={setTarget}>
          {children}
        </td>
      </Tooltip>
        );
  }
};

export default EditableCellNew;

EditableCellNew.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  setTarget: PropTypes.func,
  data: PropTypes.array,
  decimalSeparator: PropTypes.string,
};
