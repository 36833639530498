import React, { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { Button, Form, Radio, Space } from 'antd';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { NextCategory } from '../data/NextCategory';
import { BasicPrerequisites } from '../data/InitCategoryData';

import { ReactComponent as Confirm } from '../img/confirm-icon.svg';


const CategoryBasicPrerequisites = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { search, pathname } = useLocation();
  const history = useHistory();

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const category = query.get('category');

  useEffect(() => {
    if (onboardingData) {
      const result = _.merge({},BasicPrerequisites, onboardingData.result );
      
      form.setFieldsValue(result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = () => {
    const allValues = form.getFieldsValue(true);
    const result = _.merge({}, onboardingData.result, allValues);
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
    const path = NextCategory(category);
    history.push(`${pathname}?category=${path}`);
    
  };

  return (
    <div className="card-right-wrap"  style={{ paddingTop: '56px', paddingBottom: '24px',}}>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="" >
        <Form.Item
          label={t('ABLE_TO_LIQUIDATE_THE_HOLDINGS')}
          name={['prerequisites', 'general_questions', 'liquidate_holdings']}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group className="checkbox-block">
            <Space direction="horizontal" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['prerequisites', 'general_questions', 'future_generation']}
          className="onboarding-textarea-purpose"
          label={t('ANY_THOUGHTS_ON_HOW_FUTURE')}>
          <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} maxLength={200}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['prerequisites', 'general_questions', 'time_horizon']}
          label={t('TIME_HORIZON')}>
          <CustomInputNumber width={'100%'} placeholder='0,00' min={1} />
        </Form.Item>
        <Form.Item
          name={['prerequisites', 'general_questions', 'commentary']}
          className="onboarding-textarea-purpose"
          label={t('COMMENT_TIME_HORIZON')}>
          <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} maxLength={200} />
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['prerequisites', 'general_questions', 'buffer_capital']}
          label={t('BUFFER_CAPITAL')}>
          <CustomInputNumber width={'100%'} placeholder='0,00' min={0}/>
        </Form.Item>

        <Button
          icon={<Confirm />}
          iconPosition='end'
          type='text'
          className="start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('CONFIRM')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryBasicPrerequisites;

CategoryBasicPrerequisites.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
