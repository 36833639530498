import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Form, Modal, Row, Select } from 'antd';
import CustomSelect from '../CustomSelect/CustomSelect';

import { textSort } from '../../helpers/textSort';
import './style.scss';

const ModalFilterUsers = ({ setFilter, openFilter, handleCloseFilter }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('');

  const roleList = [
    {id: 1, role: 'CLIENT', label: 'CLIENT_ROLE'},
    {id: 2, role: 'CLIENT_NOT_SECURE', label: 'CLIENT_NOT_SECURE'},
    {id: 3, role: 'ADVISER', label: 'ADVISER_ROLE'},
    {id: 4, role: 'ADVISER_NOT_SECURE', label: 'ADVISER_NOT_SECURE'},
    {id: 5, role: 'SUPERADMIN', label: 'SUPERADMIN_ROLE'},
    {id: 6, role: 'SUPERADMIN_NOT_SECURE', label: 'SUPERADMIN_NOT_SECURE'},
  ];

  const statusList = [
    {id: 1, status: 'USER_STATUS_ACTIVE', label: 'ACTIVE'},
    {id: 2, status: 'USER_STATUS_DEACTIVATED', label: 'DEACTIVE'},
    {id: 3, status: 'USER_STATUS_AWAIT_APPROVE', label: 'AWAIT_APPROVE'},
  ];

  const initVAlue = {
    role: ['All'],
    status: ['All'],
  };

  useEffect(() => {
    form.setFieldsValue(initVAlue);
  }, []);

  const handleRest = () => {
    form.resetFields();
    form.setFieldsValue(initVAlue);
    setFilter(null);
  };

  const handleCancel = () => {
    handleCloseFilter();
  };

  const handleChangeSelect = () => {
    const values = form.getFieldsValue();
    if (values?.role.length > 1) values.role = values.role.filter(i => i !== 'All');
    if (values?.role.length === 0 || values?.role.length === roleList.length) values.role = ['All'];

    if (values?.status.length > 1) values.status = values.status.filter(i => i !== 'All');
    if (values?.status.length === 0 || values?.status.length === statusList.length) values.status = ['All'];
    form.setFieldsValue(values);
  };

  const onSubmit = () => {
    const value = form.getFieldsValue();
    const configFilter = { ...value };
    setFilter(configFilter);
    handleCloseFilter();
  };

  return (
    <Modal
      width={408}
      className="filter-modal"
      centered
      open={openFilter}
      onCancel={handleCancel}
      footer={false}
      zIndex={1500}
    >
      <Form
        form={form}
        name="filter-config"
        layout="vertical"
        onFinish={onSubmit}
      >
        <div>
          <Row className="profile-data-row">
            <Col>
              <Form.Item
                label={t('ROLE_HEADER')}
                name="role"
                required={false}
              >
                <CustomSelect
                  mode={'multiple'}
                  maxTagCount='responsive'
                  maxTagTextLength={12}
                  onChange={handleChangeSelect}
                  className="custom-select"
                  >
                    <Select.Option value="All">{t('ALL')}</Select.Option>
                    {roleList?.sort((a, b) => textSort(a.status, b.status))?.map(i => (
                    <Select.Option key={i.id} value={i.role}>{t(i.label)}</Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </Row>
          <Divider className='filter-divider' />
          <Row className="profile-data-row">
            <Col>
              <Form.Item
                label={t('STATUS')}
                name="status"
                required={false}
              >
                <CustomSelect
                  mode={'multiple'}
                  maxTagCount='responsive'
                  className="custom-select"
                  maxTagTextLength={12}
                  onChange={handleChangeSelect}
                >
                  <Select.Option value="All">{t('ALL')}</Select.Option>
                  {statusList?.sort((a, b) => textSort(a.status, b.status))?.map(i => (
                    <Select.Option key={i.id} value={i.status}>{t(i.label)}</Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </Row>
          <Divider className='filter-divider'/>
          
          <Row className="modal-newportfolio-button-wrapper">
            <Button
              type='text' 
              className='modal-action-btn cancel-btn' 
              onClick={handleRest}>
              {t('RESET')}
            </Button>
            <Button
              type='default'
              className='modal-action-btn save-btn' 
              onClick={onSubmit}>
              {t('APPLY')}
            </Button>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalFilterUsers;

ModalFilterUsers.propTypes = {
  openFilter: PropTypes.bool,
  handleCloseFilter: PropTypes.func,
  setFilter: PropTypes.func,
};
