import React from 'react';

import PropTypes from 'prop-types';

import { InputNumber } from 'antd';
import { ReactComponent as Persent } from '../../assets/persent-icon.svg';

import './style.scss';

const CustomInputNumber = (props) => {
  const { percent, suffix, width, ...respProps } = props;
  const className = percent
    ? (props?.className ?? '') + ' ' + 'custom-inputnumber persent'
    : (props?.className ?? '') + ' ' + 'custom-inputnumber';
  return (
    <div style={{ position: 'relative' }}>
    <InputNumber
      style={{ width, maxWidth: width }}
      {...respProps}
      className={className}
      decimalSeparator=','
    />
      {percent && <Persent style={{ position: 'absolute', top: '5px', right: '16px' }}/>}
      {suffix &&
        <div className='custom-inputnumber-suffix'>{suffix?.toUpperCase()}</div>
      }
    </div>
  );
};

export default CustomInputNumber;

CustomInputNumber.propTypes = {
  percent: PropTypes.bool,
  suffix: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};
