import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numberWithSpaces } from '../../../helpers/formatInteger';

export const BarColumnBasic = ({ data1, data2 }) => {
  const getAddData = (chart, key, name) => {
    return chart === 'Current Portfolio'
      ? data1.find(i => i[0] === key)[2][name]
      : data2.find(i => i[0] === key)[2][name];
  };
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'column',
      animation: false,
      height: 482,
      backgroundColor: 'transparent',
    },
    title: {
      text: null
    },
    // legend: {
    //   enabled: true,
    // },
    xAxis: {
      type: 'category',
      crosshair: true,
      title: {
        text: 'Age',
        style: {
          fontSize: '16px',
          color: '#4D4D4D'
        }
      },
      labels: {
        style: {
          fontSize: '15px',
          color: '#4D4D4D'
        }
      },
    },

    yAxis: {
      gridLineColor: 'rgba(0, 0, 0, 0)',
      title: {
        text: 'Wealth (mkr)',
        style: {
          fontSize: '16px',
          color: '#4D4D4D'
        }
      },
      labels: {
        style: {
          fontSize: '15px',
          color: '#4D4D4D'
        }
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">Year {point.key}</span><table>',
      pointFormatter: function () {
        return `<tr><td style="color:${this.series.color};padding:0">${this.series.name}: </td>
          <td style="padding:0"><b> ${numberWithSpaces(Math.round(this.y))} mkr</b></td></tr>
          <tr><td style="color:${this.series.color};padding:5 0 0 0">Return amount : </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'return_amount')))} </b></td></tr>
          <tr><td style="color:${this.series.color};padding:0">Deposit fee amount: </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'deposit_fee_amount') * 100) / 100)} </b></td></tr>
          <tr><td style="color:${this.series.color};padding:0">Product fee amount: </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'product_fee_amount') * 100) / 100)} </b></td></tr>
          <tr><td style="color:${this.series.color};padding:0">Advisory fee amount: </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'advisory_fee_amount') * 100) / 100)} </b></td></tr>
          <tr><td style="color:${this.series.color};padding:0">Deposit amount: </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'deposit_amount')))} </b></td></tr>
          <tr><td style="color:${this.series.color};padding:0">Withdrawal amount: </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'withdrawal_amount')))} </b></td></tr>
          <tr><td style="color:${this.series.color};padding:0">Tax amount: </td>
          <td style="padding:0"><b>${numberWithSpaces(Math.round(getAddData(this.series.name, this.x, 'tax_amount')))} </b></td></tr>`;
      },
      footerFormat: '</table>',
      shared: false,
      useHTML: true
    },
    plotOptions: {
      series: {
        animation: true
      },
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'Current Portfolio',
        data: data1,
        color: '#FB7B34',
      },
      {
        name: 'Optimized Portfolio',
        data: data2,
        color: '#6367C3',
      },
    ],
    legend: {
      enabled: true,
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 0,
      itemStyle: {
        fontSize: '16px',
        color: '#4D4D4D'
      }
    },
  };

  return (
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

BarColumnBasic.propTypes = {
  data1: PropTypes.array,
  data2: PropTypes.array
};
