/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Modal, Radio, Row, Space } from 'antd';
import { downloadPdfReport, implementePortfolio } from '../../data/store/account/accountActions';
import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';

const ModalDownloadReport = ({
  openModalDownloadReport,
  closeModalDownloadReport,
}) => {
  const { t, i18n } = useTranslation('');
  const dispatch = useDispatch();

  const { isDownload } = useSelector((state) => state.accountReducer);
  const [typeReport, setTypeReport] = useState('pdf');

  useEffect(() => {
    console.log('typeReport', typeReport);
    
  }, [typeReport]);
  

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  const onChangeType = (e) => {
    const { value} = e.target;
    setTypeReport(value);
  };

  const handleDownloud = () => {
    const data = {
      ...openModalDownloadReport,
      callBack: closeModalDownloadReport,
      lang: currentLang,
      type: typeReport
    };
    console.log('🚀 ~ handleDownloud ~ data:', data);
    dispatch(downloadPdfReport(data));
  };

  return (
    <Modal
      width={700}
      className="modal-content-paper custom-modal"
      open={openModalDownloadReport}
      onCancel={isDownload ? closeModalDownloadReport : null}
      destroyOnClose={true}
      zIndex={1600}
      footer={null}
    >
      <div>
        <h1 className='modal-header-title'>{t('CONFIRM_DOWNLOUD_REPORT')}</h1>
        <Divider className='filter-divider' style={{marginTop: '32px'}}/>
        <p className="modal-advisor-text modal-question-text" style={{marginTop: '24px'}}>{t('SELECT_FILE_FORMAT_FOR_DOWNLOAD')}</p>
        <Row justify="start" className="modal-text-wrapper">
          <Col>
          <Radio.Group onChange={onChangeType} value={typeReport} className='type-download'>
            <Space direction="horizontal">
              <CustomRadioButtom 
                className={'client-signup'}
                value="excel">{t('Excel')}</CustomRadioButtom>
              <CustomRadioButtom 
                className={'client-signup'}
                value="pdf">{t('PDF')}</CustomRadioButtom>
            </Space>
          </Radio.Group>
          </Col>
        </Row>
      </div>
      <Row justify='end' className="modal-footer-btn-wrapper">
        <Button
          disabled={isDownload}
          className="modal-action-btn cancel-btn"
          onClick={closeModalDownloadReport}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disabled={isDownload}
          className="modal-action-btn save-btn"
          onClick={handleDownloud}
        >
          {t('DOWNLOAD')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalDownloadReport;

ModalDownloadReport.propTypes = {
  openModalDownloadReport: PropTypes.object,
  closeModalDownloadReport: PropTypes.func,
};
