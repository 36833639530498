import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { textSort } from '../../helpers/textSort';
import dayjs from 'dayjs';
import { clearModel, getModelList } from '../../data/store/instruments/instrumentsActions';

import { Button, Col, Layout, Row, Table, Tooltip } from 'antd';
import ModelModalNew from './ModelModalNew';
import ModalDeleteModel from './ModalDeleteModel';
import ModalActivateModel from './ModalActivateModel';
import ModalStatusChangeModel from './ModalStatusChangeModel';
import CustomSortIcon from '../../components/CustomSortIcon/CustomSortIcon';

import { ReactComponent as Power } from '../../assets/power-icon.svg';
import { ReactComponent as Change } from '../../assets/change-icon.svg';
import { ReactComponent as Eye } from '../../assets/eye-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/pluse-litl-icon.svg';

import { useQuery } from '../../hooks/useQuery';
import './style.scss';

const { Header } = Layout;
const ModelList = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const query = useQuery(search);

  const tabs = query.get('tabs');
  
    useEffect(() => {
      if(!tabs) {
        history.replace(`${pathname}?tabs=current`);
      }
    }, [tabs]);

  useEffect(() => {
    dispatch(clearModel());
    dispatch(getModelList());
  }, []);
  

  const modelList = useSelector((state) => state.instrumentsReducer.modelList);
  const [tableColumns, setTableColumns] = useState(null);
  const [tableRecentModelsColumns, setTableRecentModelsColumns] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalActivateModel, setModalActivateModel] = useState(null);
  const [modalDeleteModel, setModalDeleteModel] = useState(null);
  const [modalStatusOpen, setModalStatusOpen] = useState(null);

  const handleView = (id) => {
    history.push(`/quant/model/item-view/${id}`);
  };

  const handleDelete = (id) => {
    setModalDeleteModel(id);
    
  };

  const handleDeleteClose = () => {
    setModalDeleteModel(null);
  };

  const handleActivateClose = () => {
    setModalActivateModel(null);
  };

  const handleStatusChangeClose = () => {
    setModalStatusOpen(null);
  };

  const handleCreateModel = () => {
    setModalOpen(true);
    // history.push('/quant/model/item/new-item');
  };

  const handlMakeActive = (id) => {
    setModalActivateModel(id);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handlChangeStatus = (id) => {
    setModalStatusOpen(id);
  };

  useEffect(() => {
    const columns = [
      {
        title: t('MODEL'),
        className: 'exclude-holdings exclude-name model-name first-column-p24',
        dataIndex: 'name',
        width: '40%',
        render: (value, record) => {
          return (
            <>
              <p className='model-table-name'>{value}</p>
              <p className='model-table-subname'>{dayjs(record?.createAt).format('DD/MM/YYYY')} at {dayjs(record?.created_at).format('HH:mm')}</p>
            </>
          );
        },
        sorter: (a, b) => textSort(a.name, b.name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('DESCRIPTION'),
        className: 'model-table-return',
        dataIndex: 'description',
        align: 'left',
        width: '40%',
      },
      {
        title: t('ACTIONS'),
        className: 'exclude-holdings exclude-operation model-actions',
        dataIndex: 'operation',
        align: 'center',
        width: '20%',
        render: (_, record) => (
          <Tooltip
              color={'#423A38'}
              placement="top"
              title={<span >{t('VIEW')}</span>}
            >
            <Button
              icon={<Eye />}
              type="text"
              onClick={() => handleView(record.id)}
            />
          </Tooltip>
        ),
      },
    ];
    setTableColumns(columns);
  }, [modelList]);

  useEffect(() => {
    const columns = [
      {
        title: t('MODEL'),
        className: 'exclude-holdings exclude-name model-name first-column-p24',
        dataIndex: 'name',
        width: '30%',
        render: (value, record) => {
          return (
            <>
              <p className='model-table-name'>{value}</p>
              <p className='model-table-subname'>{dayjs(record?.created_at).format('DD/MM/YYYY')} at {dayjs(record?.created_at).format('HH:mm')}</p>
            </>
          );
        },
        sorter: (a, b) => textSort(a.name, b.name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('DESCRIPTION'),
        className: 'model-table-return',
        dataIndex: 'description',
        align: 'left',
        width: '30%',
      },
      {
        title: t('STATUS'),
        className: 'model-table-return status',
        dataIndex: 'status',
        align: 'left',
        width: '20%',
        sorter: (a, b) => textSort(a.status, b.status),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('ACTIONS'),
        className: 'exclude-holdings model-actions',
        dataIndex: 'operation',
        align: 'center',
        width: '20%',
        render: (_, record) => (
          <div className="cell-button-wrapper">
            <Tooltip
              color={'#423A38'}
              placement="top"
              title={<span >{t('CHANGE_INFO')}</span>}
            >
              <Button
              icon={<Change />}
              type="text"
              onClick={() => handlChangeStatus(record.id)}
            />
            </Tooltip>
            <Tooltip
              color={'#423A38'}
              placement="top"
              title={<span >{t('MAKE_DEFAULT')}</span>}
            >
              <Button
              icon={<Power />}
              type="text"
              onClick={() => handlMakeActive(record.id)}
            />
            </Tooltip>
            <Tooltip
              color={'#423A38'}
              placement="top"
              title={<span >{t('VIEW')}</span>}
            >
              <Button
              icon={<Eye />}
              type="text"
              onClick={() => handleView(record.id)}
            />
            </Tooltip>
            <Tooltip
              color={'#423A38'}
              placement="top"
              title={<span >{t('DELETE')}</span>}
            >
              <Button
              icon={<Delete />}
              type="text"
              onClick={() => handleDelete(record.id)}
            />
            </Tooltip>
            
          </div>
        ),
      },
    ];
    setTableRecentModelsColumns(columns);
  }, [modelList]);

  return (
    <div className="instrument-wrapper">
      <div className="" >
        <Header className="headerStyle suggested">
          <h1 className='suggested-portfolios-title'>{t('MODEL')}</h1>
          <Row className="suggested-tabs-wrapper">
            <Col className="tabs-tab-wrapper" span={18}>
              <Button
                type="text"
                className={`overview-btn ${
                  tabs === 'current' ? 'ant-btn-active' : ''
                }`}
                onClick={() =>
                  history.push(`${pathname}?tabs=current`)}>
                {t('CURRENT_MODEL')}
              </Button>
              <Button
                type="text"
                className={`overview-btn ${
                  tabs === 'recent' ? 'ant-btn-active' : ''
                }`}
                onClick={() => history.push(`${pathname}?tabs=recent`)}>
                {t('RECENT_MODELS')}
              </Button>
            </Col>
          </Row>
          <div className="top-btn-wrapper">
          <Button
            icon={<Pluse/>}
            iconPosition='end'
            className="portfolio-cancel-btn success-btn"
            onClick={handleCreateModel}
          >
            {t('CREATE_MODEL')}
          </Button>
        </div>
        </Header>
        {tabs === 'current' &&
          <div className='client portfolio-table suggestions-table'>
            <Table
              rowKey={(record) => record.id}
              className='exclude-holdings-table'
              size='middle'
              dataSource={modelList?.filter(i => i.is_default)}
              columns={tableColumns}
              pagination={false}
            />
          </div>
        }
        {tabs === 'recent' &&
        <div className='client portfolio-table suggestions-table'>
          <Table
            rowKey={(record) => record.id}
            className='exclude-holdings-table'
            size='middle'
            dataSource={modelList?.filter(i => !i.is_default)}
            columns={tableRecentModelsColumns}
            pagination={false}
          />
        </div>
        }
      </div>
      <ModelModalNew
        modelList={modelList} 
        modalOpen={modalOpen}
        handleClose={handleClose}/>
      <ModalDeleteModel
        modalDeleteModel={modalDeleteModel} 
        handleDeleteClose={handleDeleteClose}/>
      <ModalActivateModel
        modalActivateModel={modalActivateModel}
        handleActivateClose={handleActivateClose}
      />
      <ModalStatusChangeModel
        modalStatusOpen={modalStatusOpen}
        handleStatusChangeClose={handleStatusChangeClose}
      />
    </div>
  );
};

export default ModelList;