import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom';

import Portfolio from '../../components/Portfolio/Portfolio';
import PortfolioEditInf from './PortfolioEditInf';
import { clearCandidates, getPortfolioById, saveFullPortfolio, postStepOptimisationPortfolio } from '../../data/store/portfolio/portfolioActions';

import PortfolioConstraints from './PortfolioConstraints';
import { Button, Col, Form, Layout, Row } from 'antd';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';

import { ReactComponent as Pluse } from '../../assets/cross-btn-icon.svg';

const { Header } = Layout;

const PortfolioRoute = ({ userId, needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const { portfolioId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('');
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const [formInfo] = Form.useForm();
  const [formConstr] = Form.useForm();

  const { portfolioById } = useSelector((state) => state.portfolioReducer);
  
  const [data, setData] = useState([]);
  const [dataUnlisted, setDataUnlisted] = useState([]);
  const [dataCashes, setDataCashes] = useState([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [needSaveInfo, setNeedSaveInfo] = useState(false);
  const [needSaveHoldings, setNeedSaveHoldings] = useState(false);
  const [needSaveConstr, setNeedSaveConstr] = useState(false);

  const [selectedAssetClass, setSelectedAssetClass] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [dataExclude, setDataExclude] = useState(null);
  
  useEffect(() => {
    console.log('dataUnlisted', dataUnlisted);
    
  }, [dataUnlisted]);
  
  useEffect(() => {
    return () => {
      dispatch(clearCandidates());
    };
  }, []);
  
  useEffect(() => {
    setNeedSave(needSaveInfo || needSaveHoldings || needSaveConstr);
  }, [needSaveInfo, needSaveHoldings, needSaveConstr]);

  useEffect(() => {
    dispatch(getPortfolioById({ portfolioId }));
  }, [portfolioId]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const suffix = useMemo(() => {
    return pathname.split('/').at(-1);
  }, [pathname]);

  const handleTab = (path) => {
    if (needSave) {
      setSaveAlert({ flag: true, path });
    } else {
      history.push(`${url}/${path}`);
    }
  };

    const dataSourcePortfolio = useMemo(() => {
      if (portfolioById && Object.keys(portfolioById).length) {
        return {
          id: portfolioById.id,
          name: portfolioById.name,
          portfolio_code: portfolioById.portfolio_code,
          portfolio_type: portfolioById?.portfolio_type,
          last_sync_time: portfolioById.last_sync_time,
          is_sync_active: portfolioById.is_sync_active,
          taxation: portfolioById.taxation.toString(),
          tax_rate: portfolioById.tax_rate.toString(),
          deposit_fee: portfolioById.deposit_fee.toString(),
          advisory_fee: portfolioById.advisory_fee.toString(),
          createdAt: portfolioById?.created_at?.toString(),
          updatedAt: portfolioById?.updated_at?.toString(),
        };
      } else {
        return {};
      }
    }, [portfolioById]);

  const handleCancel = () => {
    history.push(`/accounts/${userId}/portfolio`);
  };
  
  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    dispatch(getPortfolioById({ portfolioId }));
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    const component = pathname.split('/').at(-1);
    if(component === 'holdings') {
      formInfo.submit();
    } else if(component === 'constraints') {
      formConstr.submit();
      formInfo.submit();
    }
  };

  const onSaveHoldings = () => {
    if (
      (data && data.length) ||
      (dataUnlisted && dataUnlisted.length) ||
      (dataCashes && dataCashes.length)
    ) {
      const validListed =
        data?.filter(
          (i) =>
            !(
              !i.units_number ||
              !i.latest_value ||
              !i.instrument_name
            )
        ) || [];
      const validUnlisted =
        dataUnlisted?.filter(
          (i) =>
            !(
              !i.units_number ||
              !i.latest_value_sek ||
              !i.name ||
              !i.financial_asset_id
            )
        ) || [];
      const validCashes =
        dataCashes?.filter((i) => !(!i.name || !i.amount)) || [];
      const newData = validListed.map((el) => {
        return {
          ...el,
          isin: el.isin === 'N/A' ? 'NOT_APPLICABLE' : el.isin,
          currency_id: el?.currency_id || 1,
          latest_value: Number(el.latest_value),
          latest_value_sek: Number(el.latest_value_sek),
          value: Number(el.latest_value_sek) * Number(el.units_number),
          units_number: Number(el.units_number),
          fee: Number(el.fee),
        };
      });
      const newDataUnlisted = validUnlisted.map((el) => {
        return {
          ...el,
          name: el.name,
          units_number: Number(el.units_number),
          currency_id: 1,
          latest_value_sek: Number(el.latest_value_sek),
          value: Number(el.latest_value_sek) * Number(el.units_Number),
          financial_asset_id: Number(el.financial_asset_id),
        };
      });
      const newDataCashes = validCashes.map((el) => {
        const newData = {
          name: el.name,
          amount: el.amount,
          currency_id: el.currency_id,
          instrument_id: el.instrument_id,
        };
        if (el?.ID) {
          newData.id = el.ID;
        }
        return newData;
      });

      const dataNew = {
        content: newData,
        cashes: newDataCashes,
        unlisted_content: newDataUnlisted,
      };

      return dataNew;
    } else {
      const dataNew = {
        content: portfolioById.portfolio_content,
        cashes: portfolioById.portfolio_cashes,
        unlisted_content: portfolioById.unlisted_content,
      };
      return dataNew;
    }
  };

  const onSubmitConstr = (data) => {
    const fixation = selectedInstruments
      .map(i => {
        const { id, instrument_id, units_number, fixed } = i;
        return (
          {
            instrument_id,
            internal_id: id,
            units_number,
            fixed,
          }
        );
      });
    const excludeList = dataExclude.map(i => i.id);
    const excludeAssetsList = selectedAssetClass.map(i => i.id);
    const newData = {
      metadata: {
        exclude_instrument: excludeList,
        exclude_asset_class: excludeAssetsList,
      },
      bias: data?.investor_bias ?? null,
      fixation,
    };
    dispatch(postStepOptimisationPortfolio({ portfolioId, data: newData, history, path: saveAlert.path }));
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
  };

  
    const onSubmit = (data) => {
      if (Object.keys(data)?.length) {
        const newPortfolio = {
          data: {
            ...data,
            ...onSaveHoldings(),
            account_id: +userId,
            advisory_fee: +data.advisory_fee,
            deposit_fee: +data.deposit_fee,
            is_sync_active: !!data.is_sync_active,
            portfolio_code: +data.portfolio_code,
            portfolio_type: +data.portfolio_type,
            status: 'COMPLETE',
            tax_rate: +data.tax_rate,
            taxation: +data.taxation,
          },
          history,
          userId,
          portfolioId: dataSourcePortfolio?.id,
          path: saveAlert.path,
          modalClose: closeModal,
        };
        dispatch(saveFullPortfolio(newPortfolio));
      }
      setNeedSave(false);
      setSaveAlert({ flag: false, path: '' });
      closeModal();
    };

  return (
    <>
      <div className='portfolio-wrapper'>
        <Header className='headerStyle'>
          <Row className="overview-tabs-wrapper">
            <Col className='tabs-tab-wrapper' span={18}>
              <Button
                type="text"
                className={`overview-btn ${suffix === 'holdings' ? 'ant-btn-active' : ''}`}
                onClick={() => handleTab('holdings')}>
                {t('HOLDINGS')}
              </Button>
              <Button
                type="text"
                className={`overview-btn ${suffix === 'constraints' ? 'ant-btn-active' : ''}`}
                onClick={() => handleTab('constraints')}>
                {t('PORTFOLIO_SETTINGS')}
              </Button>
            </Col>
            <Col className="tabs-btn-wrapper" span={6}>
              <Button 
                type="text" 
                className="portfolio-cancel-btn" 
                icon={<Pluse />}
                iconPosition='end'
                onClick={handleCancel}>
                {t('CANCEL')}
              </Button>
              <Button
                type="default"
                className="portfolio-save-btn"
                onClick={handleSubmit}
                disabled={!needSave}
              >
                {t('SAVE')}
              </Button>
            </Col>
          </Row>
        </Header>
        <div className='porfolio-info-actions-wrapper'>
          <div className='porfolio-info-wrapper'>
            <PortfolioEditInf
              form={formInfo}
              onSubmit={onSubmit}
              dataSourcePortfolio={dataSourcePortfolio}
              userId={userId}
              portfolioId={portfolioId}
              setNeedSaveInfo={setNeedSaveInfo}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
            />
          </div>
          <div className='porfolio-actions-wrapper'>
            <Switch>
              <Route exact path={`${url}/holdings`}>
                <Portfolio
                  userId={userId}
                  portfolioId={portfolioId}
                  setNeedSaveHoldings={setNeedSaveHoldings}
                  saveAlert={saveAlert}
                  setSaveAlert={setSaveAlert}
                  data={data}
                  dataUnlisted={dataUnlisted}
                  dataCashes={dataCashes}
                  setData={setData}
                  setDataUnlisted={setDataUnlisted}
                  setDataCashes={setDataCashes}
                />
              </Route>
              <Route exact path={`${url}/constraints`}>
                <PortfolioConstraints
                  userId={userId}
                  form={formConstr}
                  onSubmit={onSubmitConstr}
                  selectedAssetClass={selectedAssetClass}
                  setSelectedAssetClass={setSelectedAssetClass}
                  selectedInstruments={selectedInstruments}
                  setSelectedInstruments={setSelectedInstruments}
                  dataExclude={dataExclude}
                  setDataExclude={setDataExclude}
                  portfolioId={portfolioId}
                  setNeedSaveConstr={setNeedSaveConstr}
                  setNeedSave={setNeedSave}
                  saveAlert={saveAlert}
                  setSaveAlert={setSaveAlert}
                />
              </Route>
              <Route exact path="*">
                <Redirect to={`${url}/holdings`} />
              </Route>
            </Switch>
            <ModalUnsaveData
              open={modalCancelVisible}
              closeModal={closeModal}
              cancelWithoutChange={cancelWithoutChange}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioRoute;

PortfolioRoute.propTypes = {
  userId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
