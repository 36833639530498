/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// import dayjs from 'dayjs';
import {
  Button,
  Col,
  Row,
  Typography,
  Modal,
} from 'antd';
import {
  clearCandidates,
  exportSelectedPortfolio,
  getAllPortfolio,
} from '../../data/store/portfolio/portfolioActions';
import {
  getCurrency,
  getInstrumentsAll,
} from '../../data/store/instruments/instrumentsActions';
import { getPieData } from '../../hooks/getPieData';
import { getAsseData } from '../../hooks/getAsseData';

// import SkeletonClientInfo from './Skeleton/SkeletonClientInfo';

import ClientTopSwitcher from '../../components/ClientTopSwitcher/ClientTopSwitcher';
import { textSort } from '../../helpers/textSort';
import ClientPortfolioList from '../ClientOverview/ClientPortfolioList/ClientPortfolioList';
import ButtonAdd from '../../components/ButtonAdd/ButtonAdd';
import ModalNewPortfolio from '../../components/ModalNewPortfolio/ModalNewPortfolio';
import BattonOptimise from '../../components/BattonOptimise/BattonOptimise';
import PortfolioTab from '../ClientOverview/PortfolioTab/PortfolioTab';
import FooterHoldings from '../ClientOverview/FooterHoldings/FooterHoldings';
import ClientAssets from '../ClientOverview/PortfolioTab/ClientAssets';

import './style.scss';

const { Title } = Typography;
// const { Paragraph } = Typography;

export const SortArrow = ({ direction }) => {
  return (
    <div className={'sortArroy'}>
      <svg
        viewBox="0 0 1024 1024"
        width="12px"
        height="11px"
        fill={
          direction === null
            ? '#00000091'
            : direction === 'up'
              ? '#fb7b34'
              : '#7E716D'
        }
      >
        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
      </svg>
      <svg
        viewBox="0 0 1024 1024"
        width="12px"
        height="11px"
        fill={
          direction === null
            ? '#00000091'
            : direction === 'down'
              ? '#fb7b34'
              : '#7E716D'
        }
      >
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
      </svg>
    </div>
  );
};

const ClientSummary = ({ userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('');
  
  const portfolios = useSelector(state => state.portfolioReducer.portfolioList?.list);
  const { isLoading } = useSelector((state) => state.portfolioReducer);
  const cashes = useSelector((state) => state.instrumentsReducer?.cashes?.list);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  const totalInit = {
    cash: 0,
    financial: 0,
    cashWaight: 0,
    finWaight: 0
  };

  const [switcher, setSwitcher] = useState(0);
  
  const switchertext = ['TOTAL', 'SPLIT'];

  const [tabs , setTabs ] = useState(1);

  const handleClick = () => {
    const seter = switcher == 0 ? 1 : 0;
    setSwitcher(seter);
  };
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [openModalNew, setOpenModalNew] = useState(false);

  const closeModal = () => {
    setOpenModalNew(false);
  };

  const footerData = useMemo(() => {
    const getCashInstrumentRate = (id) => {
      const cash = cashes?.find((i) => i.ID === id);
      return cash?.latest_value_sek;
    };
    let total = 0;
    if(tabs === 1) {
      selectedPortfolio?.forEach( item => {
        total += item.portfolio_content.reduce((acc, current) =>  acc + current.value, 0);
        total += item.unlisted_content.reduce((acc, current) =>  acc + current.value, 0);
      });
    } else if(tabs === 2) {
      selectedPortfolio?.forEach( item => {
        total += item.portfolio_cashes?.reduce((acc, current) =>  acc + current.amount * getCashInstrumentRate(current.instrument_id), 0) ?? 0;
      });
    }
    const footerData = {total};
    if(selectedRowKeys?.length === 1) {
      footerData.return = selectedPortfolio[0]?.return;
      footerData.cvar = selectedPortfolio[0]?.cvar;
      footerData.vol = selectedPortfolio[0]?.vol;
    }
    return footerData;
  }, [selectedPortfolio, tabs]);

  const [portfolioContent, setPortfolioContent] = useState([]);
  const [portfolioContentCash, setPortfolioContentCash] = useState([]);
  const [totalPortfolio, setTotalPortfolio] = useState(totalInit);
  const [pieData, setPieData] = useState(null);
  const [asseData, setAsseData] = useState(null);
  const [assetView, setAssetView] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [sortName, setSortName] = useState(null);
  const [sortClass, setSortClass] = useState(null);
  const [sortAllocation, setSortAllocation] = useState(null);

  useEffect(() => {
    if (selectedPortfolio && cashes) {
      const data = getPieData(selectedPortfolio, cashes);
      setPieData(data);
    }
    if (selectedPortfolio && currencyList) {
      const assetData = getAsseData(selectedPortfolio, currencyList);
      setAsseData(assetData);
    }
  }, [selectedPortfolio, cashes, currencyList]);

  useEffect(() => {
    if (portfolios) {
      const selected = portfolios?.filter((i) => selectedRowKeys.includes(i.id));
      setSelectedPortfolio(selected);
    }
    const portfolioContent = [];
    const portfolioContentCash = [];

    let total_value = 0;
    let totalValueFeeAcc = 0;
    let financial_total_value = 0;
    let cash_total_value = 0;

    const getCashData = (id) => {
      const ccc = cashes?.find((i) => i.ID === id);
      return ccc;
    };
    const getCashRate = (id) => {
      return currencyList?.find((i) => i.id === id)?.rate;
    };
    if (selectedRowKeys?.length) {
      selectedRowKeys.forEach((key) => {
        const portfolio = portfolios?.find((i) => i.id === key);

        total_value = total_value + portfolio?.total_value ?? 0;
        financial_total_value = financial_total_value + (portfolio?.financial_total_value ?? 0);
        cash_total_value = cash_total_value + (portfolio?.cash_total_value ?? 0);
        totalValueFeeAcc = (portfolio?.total_fee ?? 0) * (portfolio?.total_value ?? 0);

        const listed =
          portfolio?.portfolio_content?.map((i) => {
            return { ...i, portfolio_code: portfolio.portfolio_code };
          }) || [];
        const unListed =
          portfolio?.unlisted_content?.map((i) => {
            return {
              ...i,
              ISIN: '- - - -',
              portfolio_code: portfolio.portfolio_code,
            };
          }) || [];
        const cashes =
          portfolio?.portfolio_cashes?.map((i) => {
            return {
              ...i,
              instrumentName: getCashData(i.instrument_id)?.name,
              ISIN: '- - - -',
              unitsNumber: i.amount,
              latest_value_sek: getCashRate(i.currency_id),
              latest_value_time: Date.now(),
              value: i.amount * getCashRate(i.currency_id),
              fee: 0,
              financialAsset: getCashData(i.instrument_id)?.financialAsset,
              financialAssetCategory: getCashData(i.instrument_id)
                ?.financialAsset?.financialAssetCategory,
              portfolio_code: portfolio.portfolio_code,
            };
          }) || [];
        portfolioContent.push(...listed, ...unListed);
        portfolioContentCash.push(...cashes);
      });

      const waight = total_value ? cash_total_value * 100 / total_value : 0;
      setPortfolioContent(portfolioContent);
      setPortfolioContentCash(portfolioContentCash);
      setTotalPortfolio({
        totalValue: total_value,
        totalValueFee: totalValueFeeAcc / total_value,
        cash: cash_total_value,
        financial: financial_total_value,
        cashWaight: waight,
        financialWaight: 100 - waight
      });
    } else {
      setPortfolioContent([]);
      setPortfolioContentCash([]);
      setTotalPortfolio(totalInit);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    if (portfolios?.length) {
      const defaultSelect = portfolios.sort((a, b) => textSort(a.created_at, b.created_at, -1))[0];
      setSelectedRowKeys([defaultSelect?.id]);
    }
  }, [portfolios]);

  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
    dispatch(getCurrency());

    return () => {
      dispatch(clearCandidates());
    };
  }, []);

  useEffect(() => {
    dispatch(getAllPortfolio(userId));
  }, [userId]);

  const getExternalIDs = () => {
    const extId = selectedRowKeys
      .map((key) => portfolios?.find((i) => i.id === key)?.external_id)
      ?.join(', ');
    return `${extId}`;
  };

  const handleOptimise = () => {
    const [portfolioId] = selectedRowKeys;
    history.push(`/accounts/${userId}/optimization/${portfolioId}/opt-settings`);
  };

  const disableOptimise = useMemo(() => {
    if (selectedRowKeys?.length !== 1) return true;

    if (selectedRowKeys?.length === 1) {
      const isDepr = portfolios?.find(i => i.id === selectedRowKeys[0])?.status === 'ACTIVE_DEPRECATED';
      return isDepr;
    }
  }, [selectedRowKeys]);

  const handleEdit = () => {
    const [portfolioId] = selectedRowKeys;
    history.push(`${pathname}/${portfolioId}/holdings`);
  };
  const getPortfolioForChart = useMemo(() => {
    if (selectedRowKeys?.length && portfolios?.length) {
      const selected = portfolios?.filter((i) => selectedRowKeys.includes(i.id));
      return selected.reduce((acc, current) => current.total_value + acc, 0);
    } else return 0;
  }, [selectedRowKeys, portfolios]);

  const onExport = () => {
    dispatch(
      exportSelectedPortfolio({
        portfolio_ids: selectedRowKeys,
        format: { key: 'xlsx' },
      })
    );
    setOpenExport(false);
  };

  const handleCreateModal = () => {
    history.push(`/accounts/${userId}/new-portfolio`);
  };

  useEffect(() => {
    if (asseData) {
      if (sortClass || sortAllocation) {
        setAsseData({
          ...asseData,
          assetClass: asseData.assetClass.sort((a, b) => a.y - b.y),
        });
      } else if (sortName || sortName !== null) {
        const direction = sortName ? 1 : -1;
        setAsseData({
          ...asseData,
          assetClass: getAsseData(
            selectedPortfolio,
            currencyList
          )?.assetClass?.sort((a, b) => textSort(a.name, b.name, direction)),
        });
      } else {
        setAsseData({
          ...asseData,
          assetClass: asseData.assetClass.sort((a, b) => b.y - a.y),
        });
      }
    }
  }, [sortClass, sortAllocation, sortName]);

  const handleExportSelected = () => {
    setOpenExport(true);
  };

  const getPortfolioNames = () => {
    const selectedPortfolio = portfolios
      ?.filter((i) => selectedRowKeys?.includes(i.id))
      ?.map((i) => i.name);
    return selectedPortfolio?.join(', ');
  };

  return (
    <div className="portfolio-summary-container">
      {portfolios && !portfolios?.length
        ? (
        <>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '', padding: '24px' }} >
            <Col className='client portfolio-counter-wrap'>
              <h2 className="client settings-title">{t('PORTFOLIOS')}</h2>
              <span className='portfolio-counter'>{portfolios?.length ?? 0}</span>
            </Col>
          </Row>
          <div className="noportfolio-wrapper">
            <div className="noportfolio-container">
              <h2 className="noportfolio-title">{t('NO_PORTFOLIO')}</h2>
              <p className="noportfolio-text">{t('NOPORTFOLIO_TEXT')}</p>
              <ButtonAdd
                  title='ADD_PORTFOLIO'
                  height='32px'
                  onClick={() => setOpenModalNew(true)}
                />
            </div>
          </div>
        </>
          )
        : (
        <>
          <div className="component-wrapper" style={{ position: 'relative' }}>
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ marginBottom: '', padding: '24px' }}
            >
              <Col className='client portfolio-counter-wrap'>
                <h2 className="client settings-title">{t('PORTFOLIOS')}</h2>
                <span className='portfolio-counter'>{portfolios?.length ?? 0}</span>
              </Col>
              <Col style={{display: 'flex', gap: '16px'}}>
                {selectedRowKeys?.length === 1 && 
                  <BattonOptimise 
                    height='32px'
                    title={'OPTIMISE'}
                    onClick={handleOptimise}
                    />}
                <ClientTopSwitcher
                  text={switchertext}
                  switcher={switcher}
                  handleClick={handleClick}
                />
                <ButtonAdd
                  title='ADD_PORTFOLIO'
                  height='32px'
                  onClick={() => setOpenModalNew(true)}
                />
              </Col>
            </Row>
            <ClientPortfolioList
              portfolios={portfolios}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              isLoading={isLoading}
            />
            <div className='summary-data-wrapper'>
              <div className='portfolio-side-wrap advisot-flow'>
                <PortfolioTab
                  selectedRowKeys={selectedRowKeys}
                  setSelectedPortfolio={setSelectedPortfolio}
                  portfolios={portfolios}
                  tabs={tabs}
                  setTabs={setTabs}
                  handleEdit={handleEdit}
                />
                <FooterHoldings
                  width={'100%'} 
                  totalOnly={selectedRowKeys?.length !== 1}
                  data={footerData}
                />
              </div>
              <ClientAssets 
                list={portfolios}
                cashes={cashes}
                currencyList={currencyList}
                selectedRowKeys={selectedRowKeys}
                selectedPortfolio={selectedPortfolio}
                setSelectedRowKeys={setSelectedRowKeys}
              />
            </div>
          </div>
        </>
          )}
      <ModalNewPortfolio 
        open={openModalNew}
        closeModal={closeModal}
        userId={userId}
        />
      <Modal
        width={650}
        className="modal-content-paper"
        open={openExport}
        onCancel={() => setOpenExport(false)}
        footer={null}
        zIndex={1600}
      >
        <Row justify="center">
          <Col className="modal-title">
            {selectedRowKeys?.length > 1
              ? t('EXPORT_PORTFOLIOS')
              : t('EXPORT_PORTFOLIO')}
          </Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">
              {selectedRowKeys?.length > 1
                ? t('EXPORT_PORTFOLIOS_PROMT')
                : t('EXPORT_PORTFOLIO_PROMT')}
            </p>
            <p className="modal-advisor-name" style={{ fontWeight: '600' }}>
              {getPortfolioNames()}
            </p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={() => setOpenExport(false)}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn save-btn" onClick={onExport}>
            {t('EXPORT')}
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

export default ClientSummary;

ClientSummary.propTypes = {
  userId: PropTypes.string,
};

SortArrow.propTypes = {
  direction: PropTypes.string,
};
