import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Divider, Flex, Modal, Row, Spin } from 'antd';
import { setModelDefault } from '../../data/store/instruments/instrumentsActions';

const ModalActivateModel = ({modalActivateModel, handleActivateClose}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { modelList, isLoading } = useSelector((state) => state.instrumentsReducer);

  const getModelById = () => {
    return modelList?.find(i => i.id === modalActivateModel)?.name;
  };

  const handleSubmit = () => {
    dispatch(setModelDefault({id: modalActivateModel, callback: handleActivateClose}));
  };

  return (
    <Modal
        width={700}
        className="modal-content-paper"
        open={modalActivateModel}
        onCancel={handleActivateClose}
        footer={null}
        zIndex={1600}>

        <Row justify="start">
          <Col className="modal-title">{t('CONFIRM_ACTIVATION')}</Col>
        </Row>
        <Divider className='modal-divider'/>
        <Row justify="start" className="modal-text-wrapper">
          <Col>
            <p className="modal-description">{t('MODAL_ACTIVATE_MODAL')}</p>
            <p className="modal-name">{getModelById()}</p>
          </Col>
        </Row>
        <Flex align="center" justify="center" style={{display: 'flex', width: '100%', height: '32px', marginTop: '24px'}}>
          {
            isLoading &&
              <Spin size="large" />
          }
        </Flex>
        <Row justify="end" style={{marginTop: '50px'}}>
          <Col>
            <Button
              className='modal-action-btn cancel-btn'
              onClick={handleActivateClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              disabled={isLoading} 
              className="modal-action-btn save-btn" 
              onClick={handleSubmit}>
              {t('MAKE_DEFAULT')}
            </Button>
          </Col>
        </Row>
      </Modal>
  );
};

export default ModalActivateModel;

ModalActivateModel.propTypes = {
  modalActivateModel: PropTypes.number,
  handleActivateClose: PropTypes.func,
};
