/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import _ from 'lodash';

export const validateCategiry = (onboardingData) => {
  const validateInit = {
    'client-information': 'empty',
    'purpose-matter': 'empty',
    'financial-situation': 'empty',
    'basic-prerequisites-for-management': 'empty',
    'know-your-client': 'empty',
    'risk-tolerance': 'empty',
    'risk-profile': 'empty',
    'experience-background': 'empty',
  };
  const validateInitDigit = {
    'client-information': [0, 0],
    'purpose-matter': [0, 0],
    'financial-situation': [0, 0],
    'basic-prerequisites-for-management': [0, 0],
    'know-your-client': [0, 0],
    'risk-tolerance': [0, 0],
    'risk-profile': [0, 1],
    'experience-background': [0, 0],
  };

  const countNonEmptyValues = (obj, excludeKeys = []) => {
    return _.reduce(
      obj,
      (count, value, key) => {
        if (excludeKeys.includes(key)) return count;

        if (_.isObject(value) && !_.isArray(value)) {
          return count + countNonEmptyValues(value, excludeKeys);
        }

        return (
          count +
          (!_.isNil(value) &&
          !(typeof value === 'string' && value.trim() === '')
            ? 1
            : 0)
        );
      },
      0,
    );
  };

  if (onboardingData) {
    let array;
    if (onboardingData && onboardingData?.result?.client_info) {
      const {
        result: { client_info },
      } = onboardingData;
      let rowData = _.merge({}, client_info);

      rowData.common.contact_data.phones = rowData.common.contact_data.phones
        ?.length
        ? true
        : '';
      array = !client_info
        ? [undefined]
        : Object.values(rowData?.common?.contact_data).concat(
            Object.values(client_info?.common?.main_info),
          );
      const fillCount = array.filter((i) => i !== '')?.length ?? 0;

      validateInit['client-information'] = array.every((i) => !!i)
        ? 'full'
        : array.some((i) => !!i)
          ? 'partially'
          : 'empty';
      validateInitDigit['client-information'] = [fillCount, array?.length ?? 0];
    } else {
      validateInit['client-information'] = 'empty';
      validateInitDigit['client-information'] = [0, 0];
    }
    //-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.invest_target) {
      const {
        result: { invest_target },
      } = onboardingData;
      const fillCount =
        Object.values(invest_target?.additional_questions)?.filter(
          (i) => i !== '',
        )?.length ?? 0;
      const array = !invest_target
        ? [undefined]
        : [
            !!invest_target?.additional_questions?.investment_purpose,
            !!invest_target?.additional_questions?.qualitative_goals,
            !!invest_target?.additional_questions?.sustainable_view,
          ];
      validateInit['purpose-matter'] = array.every((i) => !!i)
        ? 'full'
        : array.some((i) => !!i)
          ? 'partially'
          : 'empty';
      validateInitDigit['purpose-matter'] = [fillCount, 5];
    } else {
      validateInit['purpose-matter'] = 'empty';
      validateInitDigit['purpose-matter'] = [0, 0];
    }
//-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.fin_state) {
      const revenueAndExpenditure = {
        ...onboardingData?.result?.fin_state?.revenue_and_expenditure,
      };
      delete revenueAndExpenditure.commentary;
      let arrayMandatory = Object.values(revenueAndExpenditure);
      let arrayNonMandatory = [
        onboardingData?.result?.fin_state?.revenue_and_expenditure?.commentary,
      ];

      const financialAssets = {
        ...onboardingData?.result?.fin_state?.financial_assets,
      };
      delete financialAssets.commentary;
      arrayMandatory = arrayMandatory.concat(Object.values(financialAssets));
      arrayNonMandatory = arrayNonMandatory.concat([
        onboardingData?.result?.fin_state?.financial_assets?.commentary,
      ]);

      const fixedAssets = {
        ...onboardingData?.result?.fin_state?.fixed_assets,
      };
      delete fixedAssets.commentary;
      arrayMandatory = arrayMandatory.concat(Object.values(fixedAssets));
      arrayNonMandatory = arrayNonMandatory.concat([
        onboardingData?.result?.fin_state?.fixed_assets?.commentary,
      ]);

      const shoulder = { ...onboardingData?.result?.fin_state?.shoulder };
      delete shoulder.commentary;
      arrayMandatory = arrayMandatory.concat(Object.values(shoulder));
      arrayNonMandatory = arrayNonMandatory.concat([
        onboardingData?.result?.fin_state?.shoulder.commentary,
      ]);

      const investableСapital = {
        ...onboardingData?.result?.fin_state?.investable_capital,
      };
      delete investableСapital.non_covered_elements;
      arrayMandatory = arrayMandatory.concat(Object.values(investableСapital));
      arrayNonMandatory = arrayNonMandatory.concat([
        onboardingData?.result?.fin_state.investable_capital
          ?.non_covered_elements,
      ]);

      validateInit['financial-situation'] = arrayMandatory.every(
        (i) => !!i || i === 0 || i === false,
      )
        ? 'full'
        : arrayNonMandatory.some((i) => !!i) ||
            arrayMandatory.some((i) => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
      const revenue_and_expenditure = Object.values(
        onboardingData.result.fin_state.revenue_and_expenditure,
      );
      const financial_assets = Object.values(
        onboardingData.result.fin_state.financial_assets,
      );
      const fixed_assets = Object.values(
        onboardingData.result.fin_state.fixed_assets,
      );
      const shoulderData = Object.values(
        onboardingData.result.fin_state.shoulder,
      );
      const investable_capital = Object.values(
        onboardingData.result.fin_state.investable_capital,
      );

      const arrayTotal = revenue_and_expenditure.concat(
        financial_assets,
        fixed_assets,
        shoulderData,
        investable_capital,
      );
      const fillCount = arrayTotal?.filter((i) => i !== '')?.length ?? 0;

      validateInitDigit['financial-situation'] = [ fillCount, 0];
    } else {
      validateInit['financial-situation'] = 'empty';
      validateInitDigit['financial-situation'] = [0, 0];
    }
//-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.prerequisites) {
      const generalQuestions = {
        ...onboardingData?.result?.prerequisites?.general_questions,
      };
      delete generalQuestions.future_generation;
      delete generalQuestions.commentary;
      const arrayMandatory = Object.values(generalQuestions);
      const arrayNonMandatory = [
        onboardingData?.result?.prerequisites?.general_questions
          ?.future_generation,
        onboardingData?.result?.prerequisites?.general_questions?.commentary,
      ];

      validateInit['basic-prerequisites-for-management'] = arrayMandatory.every(
        (i) => !!i || i === 0 || i === false,
      )
        ? 'full'
        : arrayNonMandatory.some((i) => !!i) ||
            arrayMandatory.some((i) => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
      const data = onboardingData?.result?.prerequisites?.general_questions;
      const fillCount =
        Object.values(data)?.filter((i) => i !== '')?.length ?? 0;

      validateInitDigit['basic-prerequisites-for-management'] = [ fillCount, 0];
    } else {
      validateInit['basic-prerequisites-for-management'] = 'empty';
      validateInitDigit['basic-prerequisites-for-management'] = [0, 0];
    }
//-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.kyc) {
      const citizenshipAndResidence = {
        ...onboardingData?.result?.kyc?.citizenship_and_residence,
      };
      delete citizenshipAndResidence.citizenship_other;
      let arrayMandatory = Object.values(citizenshipAndResidence);
      const arrayNonMandatory = [
        onboardingData?.result?.kyc.citizenship_and_residence
          ?.citizenship_other,
      ];

      const abroad = { ...onboardingData?.result?.kyc.abroad };
      arrayMandatory = arrayMandatory.concat(Object.values(abroad));

      const savingPurpose = {
        ...onboardingData?.result?.kyc?.purpose_of_engagement?.savings_purpose,
      };
      arrayMandatory = arrayMandatory.concat(Object.values(savingPurpose));

      if (
        onboardingData?.result?.kyc.purpose_of_engagement
          ?.describe_other_purpose
      ) {
        const describeOtherPurpose = [
          onboardingData?.result?.kyc?.purpose_of_engagement
            ?.describe_other_purpose,
        ];
        arrayMandatory = arrayMandatory.concat(
          Object.values(describeOtherPurpose),
        );
      }

      if (onboardingData?.result?.kyc.purpose_of_engagement?.comment_on_proxy) {
        const commentOnProxy = [
          onboardingData?.result?.kyc?.purpose_of_engagement?.comment_on_proxy,
        ];
        arrayMandatory = arrayMandatory.concat(Object.values(commentOnProxy));
      }

      if (
        onboardingData?.result?.kyc.purpose_of_engagement?.describe_other_behalf
      ) {
        const describeOtherBehalf = [
          onboardingData?.result?.kyc?.purpose_of_engagement
            ?.describe_other_behalf,
        ];
        arrayMandatory = arrayMandatory.concat(
          Object.values(describeOtherBehalf),
        );
      }

      const behalf = [
        onboardingData?.result?.kyc?.purpose_of_engagement?.behalf,
      ];
      arrayMandatory = arrayMandatory.concat(Object.values(behalf));

      const occupation = { ...onboardingData?.result?.kyc?.occupation };
      arrayMandatory = arrayMandatory.concat(Object.values(occupation));

      const capitalTransfer = {
        ...onboardingData?.result?.kyc?.capital_transfer,
      };
      delete capitalTransfer.money_origin;
      delete capitalTransfer.bank_capital;
      arrayMandatory = arrayMandatory.concat(Object.values(capitalTransfer));
      const moneyOrigin = {
        ...onboardingData?.result?.kyc?.capital_transfer?.money_origin,
      };
      arrayMandatory = arrayMandatory.concat(Object.values(moneyOrigin));
      const bankCapital = {
        ...onboardingData?.result?.kyc?.capital_transfer?.bank_capital,
      };
      arrayMandatory = arrayMandatory.concat(Object.values(bankCapital));

      const depositsWithdrawals = {
        ...onboardingData?.result?.kyc?.deposits_withdrawals,
      };
      arrayMandatory = arrayMandatory.concat(
        Object.values(depositsWithdrawals),
      );

      const politicalPosition = {
        ...onboardingData?.result?.kyc?.political_position,
      };
      arrayMandatory = arrayMandatory.concat(Object.values(politicalPosition));

      let length = 0;
      length += countNonEmptyValues(
        onboardingData.result.kyc.citizenship_and_residence,
      );
      length += countNonEmptyValues(onboardingData.result.kyc.abroad);
      length += countNonEmptyValues(
        onboardingData.result.kyc.purpose_of_engagement,
        ['savings_purpose'],
      );
      length += _.isEmpty(
        onboardingData.result.kyc?.purpose_of_engagement?.savings_purpose,
      )
        ? 0
        : 1;
      length += countNonEmptyValues(onboardingData.result.kyc.occupation);
      length += countNonEmptyValues(
        onboardingData.result.kyc.capital_transfer,
        ['money_origin', 'bank_capital'],
      );
      length += _.isEmpty(
        onboardingData.result.kyc.capital_transfer?.money_origin,
      )
        ? 0
        : 1;
      length += _.isEmpty(
        onboardingData.result.kyc.capital_transfer?.bank_capital,
      )
        ? 0
        : 1;
      length += countNonEmptyValues(
        onboardingData.result.kyc.deposits_withdrawals,
      );
      length += countNonEmptyValues(
        onboardingData.result.kyc.political_position,
      );

      validateInitDigit['know-your-client'] = [length, 32];

      validateInit['know-your-client'] = arrayMandatory.every(
        (i) => !!i || i === 0 || i === false,
      )
        ? 'full'
        : length === 0
          ? 'empty'
          : 'partially';
    } else {
      validateInit['know-your-client'] = 'empty';
      validateInitDigit['know-your-client'] = [0, 0];
    }
//-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.risk_tolerance) {
      const riskTolerance = { ...onboardingData?.result?.risk_tolerance };
      delete riskTolerance.additional_comment;
      const arrayMandatory = Object.values(riskTolerance);
      const arrayNonMandatory = [
        onboardingData?.result?.risk_tolerance?.additional_comment,
      ];

      validateInit['risk-tolerance'] = arrayMandatory.every(
        (i) => !!i || i === 0 || i === false,
      )
        ? 'full'
        : arrayNonMandatory.some((i) => !!i) ||
            arrayMandatory.some((i) => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';
      const total = countNonEmptyValues(onboardingData.result.risk_tolerance);
      validateInitDigit['risk-tolerance'] = [total, 10];
    } else {
      validateInit['risk-tolerance'] = 'empty';
      validateInitDigit['risk-tolerance'] = [0, 0];
    }
//-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.risk_profile) {
      const riskProfile = { ...onboardingData?.result?.risk_profile };

      validateInit['risk-profile'] = riskProfile ? 'full' : 'empty';
      validateInitDigit['risk-profile'] = [!onboardingData?.result?.risk_profile ? 0 : 1, 1];
    } else {
      validateInit['risk-profile'] = 'empty';
      validateInitDigit['risk-profile'] = [0, 1];
    }
//-------------------------------------------------------------//
    if (onboardingData && onboardingData?.result?.experience) {
      const general_info = {
        ...onboardingData?.result?.experience?.general_info,
      };
      let arrayMandatory = Object.values(general_info);

      const assetType = {
        ...onboardingData?.result?.experience?.assets_type_knowledge
          ?.asset_type,
      };
      for (const el in assetType) {
        arrayMandatory = arrayMandatory.concat(Object.values(assetType[el]));
      }

      let arrayNonMandatory = [
        onboardingData?.result?.experience?.assets_type_knowledge
          ?.additional_comment,
      ];

      arrayMandatory = arrayMandatory.concat(
        Object.values([
          onboardingData?.result?.experience?.portfolio_management
            ?.management_experience,
        ]),
      );
      arrayNonMandatory = arrayNonMandatory.concat([
        onboardingData?.result?.experience?.portfolio_management
          ?.portfolio_trained,
        onboardingData?.result?.experience?.portfolio_management
          ?.portfolio_understanding,
        onboardingData?.result?.experience?.portfolio_management
          ?.additional_comment,
      ]);

      arrayMandatory = arrayMandatory.concat([
        onboardingData?.result?.experience?.risk_of_loss?.loss_risk,
      ]);

      validateInit['experience-background'] = arrayMandatory.every(
        (i) => !!i || i === 0 || i === false,
      )
        ? 'full'
        : arrayNonMandatory.some((i) => !!i) ||
            arrayMandatory.some((i) => !!i || i === 0 || i === false)
          ? 'partially'
          : 'empty';

      let total = countNonEmptyValues(onboardingData.result.experience, ['assets_type_knowledge']);
      const asset = onboardingData.result?.experience?.assets_type_knowledge?.asset_type;
      const assetArrey = Object.values(asset).filter(i => (i?.deals_number || i?.deals_number === 0) && i?.asset_experience);
      total += assetArrey ? assetArrey.length : 0;

      validateInitDigit['experience-background'] = [total, 0];
    } else {
      validateInit['experience-background'] = 'empty';
      validateInitDigit['experience-background'] = [0, 0];
    }
  }
  return { validateInit, validateInitDigit };
};
