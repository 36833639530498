export const AuthSW = {
  LOG_IN: 'Logga in',
  WARNING_MESSAGE: 'Lämna aldrig ut dina inloggningsuppgifter och logga aldrig in på uppmaning av någon annan',
  WELCOME_TO_QUANT: 'Välkommen till Quant',
  DO_NOT_HAV_AN_ACCOUNT: 'Har du inget konto?',
  REMEMBER_ME: 'Kom ihåg mig',
  FORGOT_PASSWORD: 'Glömt ditt lösenord',
  SET_USER_PASSWORD: 'Ställ in användarens lösenord',
  TWO_FACTOR: 'Verifiera dig 2FA',
  EMAIL: 'E-post',
  ENTER_EMAIL: 'Ange din e-postadress',
  SUBMIT: 'Skicka in',
  PASSWORD: 'Lösenord',
  ENTER_PASSWORD: 'Ange ditt lösenord',
  REGISTER_NOW: ' Skapa ett konto',
  NEWSROOM: 'Nyhetsrum',
  ABOUT_US: 'Om oss',
  OUR_SERVICES: 'Våra tjänster',
  CAREER: 'Karriär',
  WHAT_IS_QUANT: 'Vad är Quant?',
  CONTACT: 'Kontakt',
  LEGAL_INFORMATION: 'Juridisk information',
  PRIVACY_POLICY: 'Integritetspolicy',
  BECOME_A_PART: 'Bli en del av vår rådgivarekår',
  FIRST_NAME: 'Förnamn',
  LAST_NAME: 'Efternamn',
  PHONE: 'Telefon',
  CONFIRM_PASSWORD: 'Bekräfta lösenord',
  SHOW_PASSWORD: 'Visa lösenord',
  TERM_AND_CONDITIONS: 'Användarvillkor',
  USER_TERM_AND_CONDITIONS: 'Användarvillkor',
  AGREEMENT_TEXT: 'Jag har läst, förstått och godkänner ovanstående villkor',
  REGISTER: 'Registrera',
  ROLE: 'Roll*',
  ADVISOR: 'Rådgivare',
  ADVISORS: 'RÅDGIVARE',
  ADMIN: 'Administration',
  CLIENT: 'Klient',
  CONFIRMATION_EMAIL_TEXT: 'Du har fått ett bekräftelsemeddelande till den e-postadress du angav. Bekräfta din e-postadress för att fortsätta',
  CHANGE_EMAIL: 'Ändra e-postadress',
  SEND_CONFIRMATION_EMAIL: 'Skicka bekräftelsemail igen',
  SIMULATION_CONFIRM_REGISTRATION: 'Simulering bekräftar registrering',
  REGISTRATION: 'Registrering',
  SEND_EMAIL: 'Skicka e-post',
  BACK_TO_LOGIN: 'Tillbaka till inloggningen',
  A_YOU_A_NEW_CUSTOMER: 'Är du en ny kund?',
  SENT_INSTRUCTION_MESSAGE: 'Vi har skickat instruktioner eller återställt ditt lösenord',
  NO_EMAIL_FROM_US: 'Inget e-postmeddelande från oss',
  CHECK_YOUR_SPAM: 'kolla i din skräppost',
  CHECK_YOUR_SPELLING: 'Kontrollera stavningen av din e-post',
  WAIT_AND_TRY_AGAIN: 'Vänta i 15 minuter och försök igen',
  SIMULATE_SEND_MESSAGE: 'Simulera skicka meddelande',
  YOU_NEW_PASSWORD: 'Ditt nya lösenord',
  CHANGE_PASSWORD: 'Ändra lösenord',
  SET_UP_PASSWORD: 'Ställ in lösenord',
  YOU_ARE_DONE: 'Du är färdig',
  THANKS_FOR_APPLYING_ONE: 'Din registrering har slutförts.',
  THANKS_FOR_APPLYING_TWO: 'Välkommen till Lifetiming Analytics!',
  GO_TO_HOME_PAGE: 'Gå till hemsidan',
  CLIENTS: 'KUNDER',
  SUPPORT: 'SUPPORT',
  SEARCH: 'Sök',
  SEARCH_LC: 'SÖK',
  LOG_OUT: 'LOGGA UT',
  SELECT_PERSON: 'Välj en kund',
  CREATE_NEW_CLIENT: 'Skapa ett nytt kundkonto',
  CHOSE_ACCOUNT_TEXT: 'Välj om du vill skapa ett alias-konto eller ett fullständigt konto',
  ALIAS_ACCOUNT: 'Alias-konto',
  COMPLETE_ACCOUNT: 'Komplett konto',
  MOVE_ON: 'Gå vidare',
  CREATE_CLIENT_ACCOUNT: 'Skapa ny klient',
  CREATE_ACCOUNT: 'Skapa nytt konto',
  NAME: 'Namn',
  NEED_SUPPORT: 'Behöver du support?',
  PLEASE_CONTACT_CITRONEER_ADMIN: 'Vänligen kontakta Citroneer Admin',
  PLEASE_CONTACT_CITRONEER: 'Vänligen kontakta Citroneer',
  MY_PROFILE: 'Min profil',
  PROFILE: 'Profil',
  SAVE: 'Spara',
  INSTRUMENTS: 'INSTRUMENT',
  MOBILE: 'Mobil',
  MY_CLIENTS: 'Kunder',
  AWAITING_APPROVAL: 'Väntar Godkännande',
  USER_NOT_ACTIVE: 'Användaren är inte aktiv',
  CONFIRM_ERROR_MESSAGE: 'Förlåt, du kunde inte verifiera din e-post.',
  CONFIRM_SUCCESS_MESSAGE: 'Din e-post är verifierad!',
  VERIFY_YOUR_TWO_FACTOR: 'Verifiera dina inställningar för tvåfaktorsautentisering (2FA)',
  VERIFY: 'Kontrollera',
  AUTHENTICATION_CODE: 'Autentiseringskod',
  CODE_FOOTER: 'Öppna din tvåfaktorsautentiseringsapp (TOTP) eller webbläsartillägg för att se din autentiseringskod.',
  BACK_TO_CITRONEERS: ' Tillbaka till Citroneers hemsida',
  FORGOT_YOUR_PASSWORD: 'Glömt lösenordet',
  LOGIN_WITH: 'Logga in med BankID',
  DONT_HAVE_ACCOUNT: 'Har du inget konto?',
  SIGN_UP_NOW: 'Anmäl dig nu',
  ASSET_UNIVERSE: 'Tillgångsuniversum',
  ADVISORS_LC: 'Rådgivare',
  SUPPORT_LC: 'Stöd',
  NOT_ACTIVE: 'Inte aktiv',
  NOT_ACTIVATED_YET: 'Inte aktiverat ännu',
  DEACTIVATE: 'Avaktivera',
  DONT_HAVE_AN_ACCOUNT: 'Har du inget konto?',
  INSTRUCTIONS_HAS_BEEN_SENT: 'Instruktioner har skickats',
  INSTRUCTIONS_HAS_BEEN_SENT_TEXT: 'Vi har skickat instruktioner för att återställa ditt lösenord till:',
  CONFIRM_YOUR_EMAIL: 'Bekräfta din email',
  CONFIRM_YOUR_EMAIL_TEXT: 'Du har fått ett bekräftelsemail till den e-postadress du angett, vänligen bekräfta din e-post för att fortsätta',
  CONFIRM_BANKID: 'Bekräfta BankID',
  PAGE_NOT_FOUND: 'Sidan hittas inte',
  BACK_HOME: 'Tillbaka hem',
};
