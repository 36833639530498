import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import ProcessingPending from './ProcessingPending';
import { Button } from 'antd';

import { ReactComponent as Collapse } from '../../assets/collapse-onboarfing-icon.svg';

import './style.scss';

const OnboardingProcessing = ({ userId, url, meeningId, status }) => {
  const { t } = useTranslation();

  return (
    <div className='onbording-card-wrapper'>
      <div className='onbording-card card-left'>
        <div className="onbording-card-fill">
          <Button
            type="text"
            icon={<Collapse />}
            className="onbording-collapse-btn"
          />
          <div className="onbording-step-wrapper">
            <h2 className='onbording-card-title'>{t('ONBOARDING')}</h2>
            <p className='onbording-card-description'>{t('TO_MAKE_MEETING_TRANSCRIPTION')}</p>
          </div>
        </div>
        <div className="left-footer">
          <p className="left-footer-link">{t('NEED_HELP')}</p>
        </div>
      </div>

      <div className='onbording-card card-right'>
        <div className='card-right-wrap'>
          <div style={{ width: '327px', margin: '0 auto' }}>
            <h2 className='onbording-card-header complet-step'>
              {t('PROCESSING')}
            </h2>
            <p className='onbording-card-subtitle'>{t('MEETING_PROCESSING_LEVEL')}</p>
            <ProcessingPending
              url={url}
              userId={userId}
              interval={30}
              meeningId={meeningId}
              processing={status}
            />
          </div>
        </div>
        </div>
      </div>
  );
};

export default OnboardingProcessing;

OnboardingProcessing.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  meeningId: PropTypes.number,
  status: PropTypes.string,
};
