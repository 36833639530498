import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';

const StepItem = ({t, item, current, length}) => {
  return (
      <div 
        style={{width: `${length ? 100/length : 0}%`}}
        className={`custom-steps-item-wrap ${current ? 'current' : ''}`}>
        {t(item.name)}
      </div>
  );
};
const CustomSteps = ({t, steps, current}) => {
  return (
    <div className='custom-steps-wrapper'>
      {
        steps?.map(i => (
          <React.Fragment key={i.key}>
            <StepItem
              t={t}
              item={i}
              current={i.key === current}
              length={steps?.length ?? 0}
            />
            {steps?.length - 1 >= i.key && <span className='custom-steps-didider'/>}
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default CustomSteps;

CustomSteps.propTypes = {
  t: PropTypes.func,
  steps: PropTypes.array,
  current: PropTypes.number,
};

StepItem.propTypes = {
  t: PropTypes.func,
  item: PropTypes.object,
  current: PropTypes.bool,
  length: PropTypes.number,
};
