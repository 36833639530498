import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { ReactComponent as SelectIcon } from '../../assets/select-input-icon.svg';
import './style.scss';


const CustomSelect = (props) => {
  const { width = '360px', children, animate = true, ...respProps } = props;
  const selectRef = useRef();

  const Drop = (menu) => {
    return (
      <div className="custom-dropmenu-wrapper" style={{ maxWidth: width }}>
        {menu}
      </div>
    );
  };

  return (
    <div ref={selectRef}>
      {animate
        ? (<Select
        style={{ width, maxWidth: width }}
        {...respProps}
        dropdownRender={Drop}
        dropdownStyle={{ width }}
        suffixIcon={<SelectIcon />}
        getPopupContainer={() => selectRef.current}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              children: <MarqueeText>{child.props.children}</MarqueeText>,
            });
          }
          return child;
        })}
      </Select>)
      : (
        <Select
          style={{ width }}
          {...props}
          dropdownRender={Drop}
          dropdownStyle={{ width }}
          suffixIcon={<SelectIcon/>}
          getPopupContainer={() => selectRef.current}
        />
      )
      }
    </div>
  );
};

const MarqueeText = ({ children }) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hovered, setHovered] = useState(false);
  const SPEED = 50; // Скорость движения в пикселях в секунду

  useEffect(() => {
    const textEl = textRef.current;
    const containerEl = containerRef.current;

    if (textEl && containerEl) {
      const hiddenWidth = textEl.scrollWidth - containerEl.clientWidth;
      if (hiddenWidth > 0) {
        setOffset(hiddenWidth + 10);
        setDuration(hiddenWidth / SPEED); // Динамическое время движения
      } else {
        setOffset(0);
        setDuration(0);
      }
    }
  }, [children]);

  return (
    <div
      className="marquee-container"
      ref={containerRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className="marquee-text"
        ref={textRef}
        style={{
          transform: hovered ? `translateX(-${offset}px)` : 'translateX(0)',
          transition: hovered ? `transform ${duration}s linear` : 'none',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node,
  animate: PropTypes.bool,
};
MarqueeText.propTypes = {
  children: PropTypes.node,
};
