import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'antd';

import { ReactComponent as Optimise } from '../../assets/optimise-icon.svg';
import './style.scss';

const { Text } = Typography;

const BattonOptimise = ({
  className, width='', height='', title,
  marginTop='', marginBottom='',
  ...restProp
  }) => {
  const { t } = useTranslation('');

  return (
    <Button
      style={{width, height, marginTop, marginBottom}}
      icon={<Optimise />}
      iconPosition="end"
      className={`optimise-dark-btn ${className ?? ''}`}
      {...restProp}
    >
      <Text>{t(title)}</Text>
    </Button>
  );
};

export default BattonOptimise;

BattonOptimise.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};
