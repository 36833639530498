import React from 'react';

import PropTypes, { bool } from 'prop-types';

import { Flex } from 'antd';
import ClientPortfolioListItem from './ClientPortfolioListItem';
import SkeletonClientInfo from '../../Clients/Skeleton/SkeletonClientInfo';

const ClientPortfolioList = ({ portfolios, selectedRowKeys, setSelectedRowKeys, isLoading }) => {
  if (isLoading) {
    return <SkeletonClientInfo />;
  }
  return (
    <Flex className="client portfolio-list-wrapper">
    {
      portfolios?.slice()?.map(item => {
        return (
          <ClientPortfolioListItem
            key={item.id}
            item={item}
            deprecated={item.status === 'ACTIVE_DEPRECATED'}
            is_locked={item.is_locked}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        );
      })
    }
    </Flex>
  );
};

export default ClientPortfolioList;

ClientPortfolioList.propTypes = {
  portfolios: PropTypes.array,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  isLoading: bool
};
