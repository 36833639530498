/* eslint-disable no-unused-vars */
import { Button, Form, Input, Modal, Row, } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { emailRules, nameRules } from '../../../helpers/validation';

import './style.scss';
import { createClientToAccount } from '../../../data/store/account/accountActions';

const ModalInvateClient = ({open, closeModal}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const close = () => {
    form.resetFields();
    closeModal();
  };

  const onSubmit = (fields) => {
      dispatch(createClientToAccount({ data: {accountId: open, client: fields}, closeModal: close }));
    };

  return (
    <Modal
      width={700}
      className="modal-content-paper custom-modal model"
      open={open}
      onCancel={close}
      footer={null}
      zIndex={1620}
      destroyOnClose={true}
    >
    <div>
      <h1 className='modal-header-title'>{t('CREATE_AND_INVITE_CLIENT')}</h1>
      <div className='new-client-card'>
        <p className="modal-text modal-result-short">
          {t('NEW_CLIENT')}
        </p>
        <p className="modal-text modal-result-footer">
          {t('PROVIDE_CLIENT_INF')}
        </p>
      </div>
    </div>
    <Form
      form={form}
      name="sign-up-form"
      layout="vertical"
      onFinish={onSubmit}
      className="invate-client-form"
    >
      <Form.Item
        label={t('FIRST_NAME')}
        name="first_name"
        rules={nameRules}
        required={false}
        className="custom-required"
      >
        <Input 
          className="custom-input full-width" 
          placeholder={t('FIRST_NAME')} />
      </Form.Item>
      <Form.Item
        label={t('LAST_NAME')}
        name="last_name"
        rules={nameRules}
        required={false}
        className="custom-required"
      >
        <Input 
          className="custom-input full-width" 
          placeholder={t('LAST_NAME')} />
      </Form.Item>
      <Form.Item
        label={t('EMAIL')}
        name="email"
        rules={emailRules}
        required={false}
        className="custom-required"
      >
        <Input
          className="custom-input full-width"
          placeholder={t('EMAIL')}
        />
      </Form.Item>
      <Row justify='end' className="modal-footer-btn-wrapper" style={{gap: '8px', marginTop: '72px'}}>
        <Button
          className="modal-action-btn cancel-btn return" 
          onClick={close}>
          {t('CANCEL')}
        </Button>
        <Button 
          type="submit"
          className="modal-action-btn save-btn asset-model" 
          disabled={
            !form.isFieldsTouched(true) ||
            !!form.getFieldsError().filter(({ errors }) => errors.length).length
          }
          onClick={() => form.submit()}> 
          {t('SUBMIT')}
        </Button>
      </Row>
    </Form>
    </Modal>
  );
};

export default ModalInvateClient;

ModalInvateClient.propTypes = {
  open: PropTypes.number,
  closeModal: PropTypes.func,
};