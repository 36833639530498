import React from 'react';

import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import baner from '../../assets/support-baner.png';

import './style.scss';

const Support = () => {
  const { t } = useTranslation('');

  return (
    <div className="support profile-wrap" style={{ position: 'relative' }}>
      <Row className="support-header-wrap" >
        <h2 className="support-title">{t('SUPPORT_LC')}</h2>
      </Row>
      <div className='client baner-wrapper'>
        <img className='support-baner'src={baner} alt='baner' />
        <div className='suport-text-wrapper'>
          <div className='suport-text-block-left'>
            <h2 className=''>{t('PLEASE_CONTACT_CITRONEER')}</h2>
          </div>
          <div className='suport-text-block-right'>
          <div>
            <p className='support-label'>{t('EMAIL').toUpperCase()}</p>
            <a
              href="mailto:support@citroneer.com"
              className='support-text'>
              support@citroneer.com
            </a>
          </div>
          <div>
            <p className='support-label'>{t('PHONE').toUpperCase()}</p>
            <a
              href="tel:+460790746629"
              className='support-text'>+46 72 944 0129</a>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Support;