/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getRelatedAccount } from '../../data/store/account/accountActions';
import { Form } from 'antd';

import AccountInformation from './AccountInformation/AccountInformation';

import { getUserByID } from '../../data/store/user/userActions';
import AccountRelated from './AccountRelated/AccountRelated';

import './style.scss';

const Account = ({accountId, needSave, setNeedSave, saveAlert, setSaveAlert}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formInfo] = Form.useForm();
  const { pathname } = useLocation();

  const accountById = useSelector((state) => state.accountReducer?.accountById);

  // const suffix = useMemo(() => {
  //     return pathname.split('/').at(4);
  //   }, [pathname]);

  // const handleTab = (path) => {
  //   if (needSave) {
  //     setSaveAlert({ flag: true, path });
  //   } else {
  //     history.push(`/accounts/${accountId}/onboarding`);
  //   }
  // };

  const relatedUsers = useSelector((state) => state.accountReducer?.relatedUsers);

  const owner = useMemo(() => {
    const owner = relatedUsers?.find(user => user.role === 'OWNER') ?? null;
    owner && dispatch(getUserByID(owner.id));
    return owner;
  }, [relatedUsers]);

  useEffect(() => {
    if(accountId) {
      dispatch(getRelatedAccount(accountId));
    }
  }, [accountId]);

  useEffect(() => {
    if(accountId) {
      formInfo.setFieldsValue(accountById || {});
    }
  }, [accountId, accountById]);

  return (
    <div className="account-details-wrapper">
      <AccountInformation
        accountById={accountById}
        owner={owner}
        needSave={needSave}
        setNeedSave={setNeedSave}
        saveAlert={saveAlert}
        setSaveAlert={setSaveAlert}
      />
      <AccountRelated
        accountId={accountId}
      />
    </div>
  );
};

export default Account;

Account.propTypes = {
  accountId: PropTypes.string,
  needSave: PropTypes.bool,
  saveAlert: PropTypes.object,
  setNeedSave: PropTypes.func,
  setSaveAlert: PropTypes.func,
};