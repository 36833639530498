/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';

import { Button, Col, Layout, Row } from 'antd';
import OnboardingRouting from './OnboardingRouting';
import { delOnboarding } from '../../data/store/account/accountActions';
import Account from '../Account/Account';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

const { Header } = Layout;

const ProfileRouting = ({
  userId,
  setUserId,
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const { pathname } = useLocation();
  const history = useHistory();

  const suffix = useMemo(() => {
    console.log('🚀 ~ suffix ~ pathname:', pathname);
    return pathname.split('/').at(4);
  }, [pathname]);

  const handleTab = (path) => {
    if (needSave) {
      setSaveAlert({ flag: true, path });
    } else {
      history.push(`${url}/${path}`);
    }
  };

  const handleDelete = () => {
    console.log('Del onboarding');

    dispatch(delOnboarding(userId));
  };

  return (
    <div className="component-wrapper" style={{ position: 'relative' }}>
      <Header className="headerStyle onboarding">
        <Row justify={'space-between'} className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper">
            <Button
              type="text"
              className={`overview-btn ${suffix === 'details' ? 'ant-btn-active' : ''}`}
              onClick={() => handleTab('details')}
            >
              {t(userId === 'new' ? 'ACCOUNT_CREATION' : 'ACCOUNT')}
            </Button>
            {userId !== 'new' && (
              <Button
                type="text"
                className={`overview-btn ${suffix === 'onboarding' ? 'ant-btn-active' : ''}`}
                onClick={() => handleTab('onboarding')}
              >
                {t('ONBOARDING')}
              </Button>
            )}
          </Col>
          {pathname?.split('/')?.at(4) === 'onboarding' && (
            <Col>
              <Button
                icon={<Delete />}
                iconPosition="end"
                type="text"
                className="onboarding-del-btn"
                onClick={handleDelete}
              >
                {t('DELETE')} {t('ONBOARDING').toLowerCase()}
              </Button>
            </Col>
          )}
        </Row>
      </Header>

      <Switch>
        <Route path={`${url}/details`}>
          <Account
            accountId={userId}
            setUserId={setUserId}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route path={`${url}/onboarding`}>
          <OnboardingRouting userId={userId} />
        </Route>
        <Route exact path={`${url}`}>
          <Redirect to={`${url}/profile`} />
        </Route>
        <Route exact path="*">
          <Redirect to={`${url}/profile`} />
        </Route>
      </Switch>
    </div>
  );
};

export default ProfileRouting;

ProfileRouting.propTypes = {
  userId: PropTypes.string,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
