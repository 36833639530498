import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
// import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, Radio, Select, Space } from 'antd';

import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
import { ReactComponent as Collapse } from '../../assets/collapse-onboarfing-icon.svg';
import { ReactComponent as Confirm } from './img/confirm-icon.svg';

import { postOnboardingData } from '../../data/store/account/accountActions';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

import './style.scss';
import { useGoogleLogin } from '@react-oauth/google';

const langList = [
  { id: 1, name: 'SWEDEN', value: 'sw' },
  { id: 2, name: 'ENGLISH', value: 'en' },
  { id: 3, name: 'RUSSIAN', value: 'ru' },
];

const OnboardingPlanning = ({ userId, accountInfo, setAccountInfo }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const meetingType = Form.useWatch('meetingType', form);
  const meetingDate = Form.useWatch('meetingDate', form);

  const { googleClientId } = useSelector((state) => state.accountReducer);
  const { advisor: {is_google_auth} } = useSelector((state) => state.userReducer);

  const redirectURI = process.env.REACT_APP_REDIRECT_URI;
  console.log('🚀 ~ OnboardingPlanning ~ redirectURI:', redirectURI);


  useEffect(() => {
    form.setFieldsValue({ lang: 'en' });
  }, []);

  useEffect(() => {
    if(googleClientId) {
      console.log('🚀 ~ useEffect ~ googleClientId:', googleClientId);
    }
  }, [googleClientId]);

  const handleStartOnbording = () => {
    form.submit();
  };

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.events',
    access_type: 'offline',
    ux_mode: 'popup',
    prompt: 'consent',
    redirect_uri: redirectURI,
    onSuccess: async (tokenResponse) => {
      resolveAuthCode(tokenResponse.code);
    },
    onError: (error) => {
      console.error('Error auth Google:', error);
      rejectAuthCode(error);
    },
  });

  let resolveAuthCode, rejectAuthCode;
  const loginWithPromise = () => {
    return new Promise((resolve, reject) => {
      resolveAuthCode = resolve;
      rejectAuthCode = reject;
      login();
    });
  };

  const onCreate = async(data) => {
    if(data?.meetingType !== 'online') {
      const newData = {
        account_type: accountInfo?.accountType,
        personal_number: accountInfo?.personalNumber?.toString(),
        type: data?.meetingType,
        start_date:
          meetingType === 'manual' ? null : dayjs(data.meetingDate).format(),
        lang: data?.lang ?? 'en',
      };
      setAccountInfo(newData);
      // history.push(`${url}/planning-complete`);
      dispatch(postOnboardingData({ clientId: userId, ...newData }));
    } else if(!is_google_auth){try {

      const authCode = await loginWithPromise();
      const newData = {
        type: data?.meetingType,
        start_date: dayjs(data.meetingDate).format(),
        lang: data?.lang ?? 'en',
        auth_code: authCode,
      };
      dispatch(postOnboardingData({ clientId: userId, ...newData }));
    } catch (error) {
      console.error('Error auth Google:', error);
    }
    }
  };


  return (
    <div className="onbording-card-wrapper">
      <div className="onbording-card card-left">
      <div className="onbording-card-fill">
        <Button
          type='text' 
          icon={<Collapse />}
          className='onbording-collapse-btn' />
        <div className="onbording-step-wrapper">
          <h2 className="onbording-card-title">{t('ONBOARDING')}</h2>
          <p className="onbording-card-description">
            {t('PLEASE_PROVIDE_MEETING')}
          </p>
        </div>
      </div>
      <div className='left-footer'>
        <p className='left-footer-link'>{t('NEED_HELP')}</p>
      </div>
      </div>

      <div className="onbording-card card-right">
        <div style={{ paddingTop: '72px'}}>
          <h2 className="onbording-card-header">{t('WHAT_TYPE_MEETING')}</h2>
          <h3 className="onbording-card-question">{t('SELECT_THE_TYPE_AND_LANGUAGE')}</h3>
          <Form
            form={form}
            name="onboarding-form-first"
            layout="vertical"
            onFinish={onCreate}
            className="instrument-form">
            <div className="onbording-card-checkbox-wrap">
              <Form.Item name="meetingType">
                <Radio.Group className="checkbox-block">
                  <Space direction="horizontal" align="center" className="">
                    <CustomRadioButtom value="online" className="onbording-card-checkbox">
                      {t('ONLINE')}
                    </CustomRadioButtom>
                    <CustomRadioButtom value="offline" className="onbording-card-checkbox">
                      {t('OFFLINE')}
                    </CustomRadioButtom>
                    <CustomRadioButtom value="manual" className="onbording-card-checkbox">
                      {t('MANUAL')}
                    </CustomRadioButtom>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
            {/* //TODO select lang */}
            {meetingType === 'offline' && (
              <Form.Item
                label={t('THE_EXECUTION_CONSULTANCY')}
                name={['lang']}
                className="custom-required"
                required={false}
              >
                <CustomSelect className="custom-select" width={'100%'}>
                  {langList?.map((i) => (
                    <Select.Option key={i.id} value={i.value}>
                      {t(i.name)}
                    </Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            )}
            {meetingType !== 'manual' && (
              <div className='onbording-datepicker-wrap'>
                <p className="onbording-card-subheader step-two">
                  {t('PLEASE_PROVIDE_MEETING_LINK')}
                </p>
                <Form.Item 
                  name="meetingDate" 
                  className="custom-required custom-datepicker-wrap">
                  <DatePicker
                    className="custom-datepicker"
                    placeholder={t('PLAN_MEETING')}
                    showTime={{ format: 'HH:mm', secondStep: 60 }}
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
        <Button
          icon={<Confirm />}
          iconPosition='end'
          type='text'
          disabled={
            (!meetingDate && meetingType !== 'manual') || !meetingType
          }
          className="start-onbording-btn"
          onClick={handleStartOnbording}
        >
          {t(meetingType === 'manual' ? 'START_ONBOARDING' : 'CONFIRM')}
        </Button>
        {/* <GoogleOAuthProvider clientId="547629006582-51qur7m1ia1ohbo552d9lcrvkalel8n5.apps.googleusercontent.com">
          <GoogleLogin onSuccess={credentialResponse => {
            console.log(credentialResponse);
          }} onError={handleGoogleError} />
        </GoogleOAuthProvider> */}
      </div>
    </div>
  );
};

export default OnboardingPlanning;

OnboardingPlanning.propTypes = {
  userId: PropTypes.string,
  url: PropTypes.string,
  accountInfo: PropTypes.object,
  setAccountInfo: PropTypes.func,
};
