/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import { Button, Col, Form, Radio, Row, Space } from 'antd';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';

import { decimalSeparator } from '../../../helpers/decimalSeparator';
import { numberWithSpaces } from '../../../helpers/formatInteger';
import { useDispatch, useSelector } from 'react-redux';
import { clearPortfolioProspecting } from '../../../data/store/portfolio/portfolioActions';
import { ReactComponent as Pluse } from '../../../assets/cross-btn-icon.svg';
import { ReactComponent as Check } from './check-icon.svg';

import './style.scss';
import { ColorDataItem } from '../../ClientOverview/ClientPortfolioList/ClientPortfolioListItem';

export const StepSettings = ({
  id,
  settings,
  portfolioId,
  setSettings,
  setOptStart,
}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [isPropuctFees, setIsPropuctFees] = useState(false);
  const [isDepositFees, setIsDepositFees] = useState(false);
  const [isAdvisoryFees, setIsAdvisoryFees] = useState(false);
  const [clientType, setClientType] = useState(null);

  const minCash = Form.useWatch('min_cash_per', form);
  const withdrawalAmount = Form.useWatch('withdrawal_amount', form);

  const { portfolioById } = useSelector((state) => state.portfolioReducer);

  const initValue = {
    include_financial_plan: '0',
    monetary_basis: '1',
    fee_flags: {
      include_product_fees: false,
      include_deposit_fees: false,
      include_advisory_fees: false,
    },
    fee_data: {
      deposit_fee: '',
      advisory_fee: '',
    },
    typeOfClient: '',
  };

  const totalCashes = useMemo(() => {
    let persent = 0;
    if (
      Object.keys(portfolioById || {})?.length &&
      portfolioById?.total_value
    ) {
      if (minCash > 0 && minCash <= 100) {
        persent = minCash;
      }

      return Math.round(
        ((portfolioById?.total_value - (withdrawalAmount || 0)) * persent) /
          100,
      );
    } else {
      return 0;
    }
  }, [portfolioById, minCash, withdrawalAmount]);

  useEffect(() => {
    dispatch(clearPortfolioProspecting());
    setOptStart(false);
    const newData = settings || initValue;
    form.setFieldsValue(newData);
    setClientType(newData?.typeOfClient ?? null);
    setIsPropuctFees(newData.fee_flags.include_product_fees ?? true);
    setIsDepositFees(newData.fee_flags.include_deposit_fees ?? true);
    setIsAdvisoryFees(newData.fee_flags.include_advisory_fees ?? true);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleNext = async () => {
    await form.validateFields();
    await form.submit();
  };

  const handleChangeCheckBox = (event) => {
    const { id } = event.target;
    if (id === 'fee_flags_include_product_fees') {
      setIsPropuctFees(event.target.checked);
    }
    if (id === 'fee_flags_include_deposit_fees') {
      if (!event.target.checked) {
        const fields = form.getFieldsValue();
        const { fee_data } = fields;
        if (fee_data && Object.hasOwn(fee_data, 'deposit_fee')) {
          fee_data.deposit_fee = '';
          form.setFieldsValue({ ...fields, fee_data });
        }
      }
      setIsDepositFees(event.target.checked);
    }
    if (id === 'fee_flags_include_advisory_fees') {
      if (!event.target.checked) {
        const fields = form.getFieldsValue();
        const { fee_data } = fields;
        if (fee_data && Object.hasOwn(fee_data, 'advisory_fee')) {
          fee_data.advisory_fee = '';
          form.setFieldsValue({ ...fields, fee_data });
        }
      }
      setIsAdvisoryFees(event.target.checked);
    }
  };

  const handleChangeRadio = async (event) => {
    const { value } = event.target;
    setClientType(value);
    if (value === 'existing') {
      const data = await form.getFieldsValue();
      form.setFieldsValue({
        ...data,
        fee_data: {
          deposit_fee: '',
          advisory_fee: '',
        },
      });
    }
  };

  const onSubmit = (data) => {
    try {
      if (Object.keys(data)?.length && data?.monetary_basis) {
        if (!data?.withdrawal_amount) data.withdrawal_amount = null;
        if (!data?.deposit_amount) data.deposit_amount = null;
        const newData = { ...data };
        newData.reserved_amount = totalCashes;
        setSettings(newData);
        history.push(
          `/accounts/${id}/optimization/${portfolioId}/portfolioOptimization`,
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const depositFee = Form.useWatch(['fee_data', 'deposit_fee'], form);
  const advisoryFee = Form.useWatch(['fee_data', 'advisory_fee'], form);
  const monetaryBasis = Form.useWatch(['monetary_basis'], form);
  const includeFP = Form.useWatch(['include_financial_plan'], form);

  const validation = useMemo(() => {
    const isValidOne = !isDepositFees && !isAdvisoryFees;
    const isValidTwo =
      (isDepositFees && clientType === 'existing') ||
      (isDepositFees &&
        !isAdvisoryFees &&
        clientType === 'potential' &&
        depositFee > 0);
    const isValidThree =
      (isAdvisoryFees && clientType === 'existing') ||
      (isAdvisoryFees &&
        !isDepositFees &&
        clientType === 'potential' &&
        advisoryFee > 0);
    const isValidFour =
      isAdvisoryFees &&
      isDepositFees &&
      clientType === 'potential' &&
      advisoryFee > 0 &&
      depositFee > 0;
    const response =
      !(isValidOne || isValidTwo || isValidThree || isValidFour) ||
      !monetaryBasis ||
      !includeFP;
    return response;
  }, [depositFee, advisoryFee, monetaryBasis, includeFP]);

  const handleReset = () => {
    history.push(`/accounts/${id}/portfolio`);
  };

  return (
    <div className='optimization-component-wrapper'>
      <div>
        <div className="top-btn-wrapper">
          <Button
            type="text"
            className="portfolio-cancel-btn"
            icon={<Pluse />}
            iconPosition="end"
            onClick={handleReset}
          >
            {t('CANCEL')}
          </Button>
          <Button
            disabled={validation}
            onClick={handleNext}
            className="btn-optim optim-continue-btn"
          >
            {t('SAVE_CONTINUE')}
          </Button>
        </div>
        
        <div className="inner-wrapper settings">
          <Form
            className="optimization-settings-form"
            form={form}
            onFinish={onSubmit} >
            <div 
              style={{marginBottom: '48px'}}
              className={'client portfolio-card portfolio-info-card'}>
              <div>
                <p className="portfolio-card-name">
                  {portfolioById?.name}
                  <span className="portfolio-card-code">{portfolioById?.portfolio_code}</span>
                </p>
              </div>
              <div>
                <p className="portfolio-card-value">{`${numberWithSpaces(Math.round(portfolioById?.total_value))} kr`}</p>
                <Row justify={'center'} className="portfolio-card-text">
                  <Col className="">
                    <ColorDataItem
                      isSelected={true}
                      text={t('RETURN')}
                      value={portfolioById?.return}
                    />
                  </Col>
                  <Col className="">
                    <ColorDataItem
                      isSelected={true}
                      text={t('VOLATILITY')}
                      value={portfolioById?.vol}
                    />
                  </Col>
                  <Col className="">
                    <ColorDataItem
                      isSelected={true}
                      text={t('CVaR')}
                      value={portfolioById?.cvar}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <Row
              justify={'space-between'}
              align={'middle'}
              className="settings-row"
            >
              <Col>
                <p className="settings-point-text">{t('WITHDRAWAL_AMOUNT')}</p>
              </Col>
              <Col className="settings-withdrawn-input-wrapper">
                <Form.Item
                  name={'withdrawal_amount'}
                  className="opt-settings-number-item"
                >
                  <CustomInputNumber
                    placeholder="0,00"
                    width={'296px'}
                    className="custom-input"
                    suffix={'sek'}
                    formatter={(value) =>
                      !value ? '' : numberWithSpaces(value)
                    }
                    parser={(value) =>
                      value ? value.replace(/( *)/g, '').replace(/,/g, '.') : ''
                    }
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              justify={'space-between'}
              align={'middle'}
              className="settings-row">
              <Col>
                <p className="settings-point-text">
                  {t('DEPOSIT_AMOUNT')}
                </p>
              </Col>
              <Col className="settings-withdrawn-input-wrapper">
                <Form.Item
                  name={'deposit_amount'}
                  className="opt-settings-number-item"
                >
                  <CustomInputNumber
                    placeholder="0,00"
                    width={'296px'}
                    className="custom-input"
                    suffix={'sek'}
                    formatter={(value) =>
                      !value ? '' : numberWithSpaces(value)
                    }
                    parser={(value) =>
                      value ? value.replace(/( *)/g, '').replace(/,/g, '.') : ''
                    }
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              justify={'space-between'}
              align={'middle'}
              className="settings-row">
              <Col>
                <p className="settings-point-text">{t('MINIMUM_CASH_LEVEL')}</p>
              </Col>
              <Col className="currency-sek-wrapper">
                <Form.Item
                  className="opt-settings-number-item"
                  name="min_cash_per"
                >
                  <CustomInputNumber
                    width={'137px'}
                    className="custom-input"
                    suffix={'%'}
                    decimalSeparator={decimalSeparator()}
                    placeholder="0,00"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    }
                    parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                  />
                </Form.Item>
                <p className='currency-sek-text'>{numberWithSpaces(totalCashes)} SEK</p>
              </Col>
            </Row>
            <Row
              justify={'space-between'}
              align={'middle'}
              className="settings-row"
            >
              <Col>
                <p className="settings-point-text">
                  {t('INCLUDE_FINANCIAL_PLAN')}
                </p>
              </Col>
              <Col>
                <Form.Item
                  name={'include_financial_plan'}
                  className="opt-settings-radio-item"
                >
                  <Radio.Group 
                    className="portfolio-form-radio">
                    <Space direction="horizontal">
                      <Radio.Button value='1'>
                        <span>{t('YES')}</span>
                        <Check className='radio-check'/>
                      </Radio.Button>
                      <Radio.Button value='0'>
                        {t('NO')}
                        <Check className='radio-check'/>
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row
              justify={'space-between'}
              align={'middle'}
              className="settings-row"
            >
              <Col>
                <p className="settings-point-text">{t('MONET_BASIS')}</p>
              </Col>
              <Col>
                <Form.Item
                  name={'monetary_basis'}
                  className="opt-settings-radio-item"
                >
                  <Radio.Group 
                    className="portfolio-form-radio">
                    <Space direction="horizontal">
                      <Radio.Button block={true} value="1">
                        <span>{t('NOMINAL')}</span>
                        <Check className='radio-check'/>
                      </Radio.Button>
                      <Radio.Button block={true} value="2">
                        {t('REAL')}
                        <Check className='radio-check'/>
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row
              justify={'space-between'}
              align={'middle'}
              className="settings-row"
            >
              <Col>
                <p className="settings-point-text">{t('INCLUDE_FEES')}</p>
              </Col>
              <Col>
                <Row gutter={36}>
                  <Col>
                    <Form.Item
                      name={['fee_flags', 'include_product_fees']}
                      valuePropName="checked"
                      className="opt-settings-check-item"
                    >
                      <CustomCheckbox
                        className="round-checkbox"
                        onChange={handleChangeCheckBox}
                      >
                        {t('PRODUCT_FEES')}
                      </CustomCheckbox>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={['fee_flags', 'include_deposit_fees']}
                      valuePropName="checked"
                      className="opt-settings-check-item"
                    >
                      <CustomCheckbox
                        className="round-checkbox"
                        onChange={handleChangeCheckBox}
                      >
                        {t('DEPOSIT_FEES')}
                      </CustomCheckbox>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={['fee_flags', 'include_advisory_fees']}
                      valuePropName="checked"
                      className="opt-settings-check-item"
                    >
                      <CustomCheckbox
                        className="round-checkbox"
                        onChange={handleChangeCheckBox}
                      >
                        {t('ADVISORY_FEES')}
                      </CustomCheckbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="">
              {(isDepositFees || isAdvisoryFees) && (
                <>
                  <Row 
                    justify={'space-between'}
                    align={'middle'}
                    className="settings-row">
                    <Col>
                      <p className="settings-point-text">
                        {t('TYPE_OF_CLIENT')} *
                      </p>
                    </Col>
                    <Col>
                      <Form.Item
                        name={'typeOfClient'}
                        className="opt-settings-radio-item"
                        required={false}
                      >
                        <Radio.Group
                          onChange={handleChangeRadio}
                          className="portfolio-form-radio"
                          value={clientType}
                        >
                          <Space direction="horizontal">
                            <Radio.Button value="existing">
                              <span>{t('EXISTING')}</span>
                              <Check className='radio-check'/>
                            </Radio.Button>
                            <Radio.Button value="potential">
                              <span>{t('POTENTIAL')}</span>
                              <Check className='radio-check'/>
                            </Radio.Button>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    </Row>
                    {clientType === 'potential' && (
                      <>
                        {isDepositFees && (
                          <Row 
                            justify={'space-between'}
                            align={'middle'}
                            className="settings-row">
                            <Col>
                              <p className="settings-point-text">
                                {t('DEPOSIT_OPT_FEE')} *
                              </p>
                            </Col>
                            <Col>
                              <Form.Item
                                className="opt-settings-number-item" 
                                name={['fee_data', 'deposit_fee']}>
                                <CustomInputNumber
                                  width={'296px'}
                                  className="custom-input"
                                  suffix={'sek'}
                                  placeholder={'0.00'}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {isAdvisoryFees && (
                          <Row 
                            justify={'space-between'}
                            align={'middle'}
                            className="settings-row">
                            <Col>
                              <p className="settings-point-text">
                                {t('ADVISORY_FEE_AT_CITRONEER')} *
                              </p>
                            </Col>
                            <Col>
                              <Form.Item 
                                className="opt-settings-number-item"
                                name={['fee_data', 'advisory_fee']}>
                                <CustomInputNumber
                                  width={'296px'}
                                  className="custom-input"
                                  suffix={'sek'}
                                  placeholder={'0.00'}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

StepSettings.propTypes = {
  id: PropTypes.string,
  portfolioId: PropTypes.string,
  settings: PropTypes.object,
  setSettings: PropTypes.func,
  setOptStart: PropTypes.func,
};
