import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Modal, Row } from 'antd';
import PropTypes from 'prop-types';

const ModaldeleteAssetClass = ({modalDeleteVisible, handleCloseDelModal, submitDelete, name}) => {
  const { t } = useTranslation('');

  return (
    <Modal
        width={700}
        className="modal-content-paper"
        open={modalDeleteVisible}
        onCancel={handleCloseDelModal}
        centered
        footer={null}
        zIndex={1600}>
        <Row justify="start">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Divider className='modal-divider'/>
        <Row justify="start" className="modal-text-wrapper">
          <Col>
            <p className="modal-description">{t('MODAL_DELETE_TEXT_ASSET')}</p>
            <p className="modal-name">{name}</p>
          </Col>
        </Row>
        <Row justify="end" style={{marginTop: '50px'}}>
        <Col>
          <Button
            className='modal-action-btn cancel-btn'
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button 
            style={{marginLeft: '8px'}} 
            className="modal-action-btn delete-btn" 
            onClick={submitDelete}>
            {t('DELETE')}
          </Button>
        </Col>
        </Row>
      </Modal>
  );
};

export default ModaldeleteAssetClass;

ModaldeleteAssetClass.propTypes = {
  modalDeleteVisible: PropTypes.bool,
  handleCloseDelModal: PropTypes.func,
  submitDelete: PropTypes.func,
  name: PropTypes.string,
};