import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { Button, Form, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import {
  expectedReturnOptions,
  investmentHorizonOptions,
  investmentPhilosophyOptions,
  lowValueIncreaseOptions,
  mediaInfluenceOptions,
  negativeNewsOptions,
  portfolioEvaluationOptions,
  portfolioValueDropOptions,
  returnTargetOptions,
} from '../data/SelectOptions';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { NextCategory } from '../data/NextCategory';

import { ReactComponent as Confirm } from '../img/confirm-icon.svg';
import { RiskTolerance } from '../data/InitCategoryData';

const CategoryRiskTolerance = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const history = useHistory();

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const category = query.get('category');

  const expected_return = Form.useWatch(['risk_tolerance', 'expected_return'], form);

  useEffect(() => {
    if (onboardingData) {
      const result = _.merge({},RiskTolerance, onboardingData.result );
      form.setFieldsValue(result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = () => {
    const allValues = form.getFieldsValue(true);
    const result = _.merge({}, onboardingData.result, allValues);
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
    const path = NextCategory(category);
    history.push(`${pathname}?category=${path}`);
    
  };

  return (
    <div className="card-right-wrap"  style={{ paddingTop: '56px', paddingBottom: '24px',}}>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className=""
      >
        <Form.Item
          label={t('EXPECTED_RETURN')}
          name={['risk_tolerance', 'expected_return']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {expectedReturnOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        {expected_return === 'expected_return_option5' &&
          <Form.Item
          label={t('RETURN_TARGET')}
          name={['risk_tolerance', 'return_target']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {returnTargetOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        }
        <Form.Item
          label={t('INVESTMENT_HORIZON')}
          name={['risk_tolerance', 'investment_horizon']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {investmentHorizonOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('INVESTMENT_PHILOSOPHY')}
          name={['risk_tolerance', 'investment_philosophy']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {investmentPhilosophyOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('MEDIA_INFLUENCE')}
          name={['risk_tolerance', 'media_influence']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {mediaInfluenceOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('NEGATIVE_NEWS')}
          name={['risk_tolerance', 'negative_news']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {negativeNewsOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('PORTFOLIO_VALUE_DROP')}
          name={['risk_tolerance', 'portfolio_value_drop']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {portfolioValueDropOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('LOW_VALUE_INCREASE')}
          name={['risk_tolerance', 'low_value_increase']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {lowValueIncreaseOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('PORTFOLIO_EVALUATION')}
          name={['risk_tolerance', 'portfolio_evaluation']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            width={'100%'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {portfolioEvaluationOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          name={['risk_tolerance', 'additional_comment']}
          className="onboarding-textarea-purpose"
          label={t('ADDITIONAL_COMMENT')}
        >
          <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} maxLength={200}/>
        </Form.Item>

        <Button
          icon={<Confirm />}
          iconPosition='end'
          type='text'
          className="start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('CONFIRM')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryRiskTolerance;

CategoryRiskTolerance.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
};
