/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ClientHoldings } from './ClientHoldings';

import{ReactComponent as Export} from '../../../assets/export-orange-icon.svg';

import './style.scss';
import ButtonAdd from '../../../components/ButtonAdd/ButtonAdd';

const PortfolioTab = ({tabs, setTabs, selectedRowKeys, portfolios, setSelectedPortfolio, handleEdit}) => {
  const { t } = useTranslation('');
 const totalInit = {
    cash: 0,
    financial: 0,
    cashWaight: 0,
    finWaight: 0
  };
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  const cashes = useSelector((state) => state.instrumentsReducer?.cashes?.list);

  const [portfolioContent, setPortfolioContent] = useState([]);
  const [portfolioContentCash, setPortfolioContentCash] = useState([]);
  const [totalPortfolio, setTotalPortfolio] = useState(totalInit);

 
  
    useEffect(() => {
      if (portfolios) {
        const selected = portfolios?.filter((i) => selectedRowKeys.includes(i.id));
        setSelectedPortfolio(selected);
      }
      const portfolioContent = [];
      const portfolioContentCash = [];
  
      let total_value = 0;
      let totalValueFeeAcc = 0;
      let financial_total_value = 0;
      let cash_total_value = 0;
  
      const getCashData = (id) => {
        const ccc = cashes?.find((i) => i.ID === id);
        return ccc;
      };
      const getCashRate = (id) => {
        return currencyList?.find((i) => i.id === id)?.rate;
      };
      if (selectedRowKeys?.length) {
        selectedRowKeys.forEach((key) => {
          const portfolio = portfolios?.find((i) => i.id === key);
  
          total_value = total_value + portfolio?.total_value ?? 0;
          financial_total_value = financial_total_value + (portfolio?.financial_total_value ?? 0);
          cash_total_value = cash_total_value + (portfolio?.cash_total_value ?? 0);
          totalValueFeeAcc = (portfolio?.total_fee ?? 0) * (portfolio?.total_value ?? 0);
  
          const listed =
            portfolio?.portfolio_content?.map((i) => {
              return { ...i, portfolio_code: portfolio.portfolio_code };
            }) || [];
          const unListed =
            portfolio?.unlisted_content?.map((i) => {
              return {
                ...i,
                ISIN: '- - - -',
                portfolio_code: portfolio.portfolio_code,
              };
            }) || [];
          const cashes =
            portfolio?.portfolio_cashes?.map((i) => {
              return {
                ...i,
                instrumentName: getCashData(i.instrument_id)?.name,
                ISIN: '- - - -',
                unitsNumber: i.amount,
                latest_value_sek: getCashRate(i.currency_id),
                latest_value_time: Date.now(),
                value: i.amount * getCashRate(i.currency_id),
                fee: 0,
                financialAsset: getCashData(i.instrument_id)?.financial_asset,
                financialAssetCategory: getCashData(i.instrument_id)
                  ?.financial_asset?.financial_asset_category,
                portfolio_code: portfolio.portfolio_code,
              };
            }) || [];
          portfolioContent.push(...listed, ...unListed);
          portfolioContentCash.push(...cashes);
        });
  
        const waight = total_value ? cash_total_value * 100 / total_value : 0;
        setPortfolioContent(portfolioContent);
        setPortfolioContentCash(portfolioContentCash);
        setTotalPortfolio({
          totalValue: total_value,
          totalValueFee: totalValueFeeAcc / total_value,
          cash: cash_total_value,
          financial: financial_total_value,
          cashWaight: waight,
          financialWaight: 100 - waight
        });
      } else {
        setPortfolioContent([]);
        setPortfolioContentCash([]);
        setTotalPortfolio(totalInit);
      }
    }, [selectedRowKeys]);

    const handleExportSelected = () => {
      // setOpenExport(true);
    };

  const handleTabs = (tab) => {
    setTabs(tab);
  };

  return (
        <div>
          <Row justify={'space-between'} style={{padding: '24px'}}>
            <Col>
              <Button
                type='text'
                className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
                disabled
                onClick={() => handleTabs(0)}
              >
                {t('STANDINGS')}
              </Button>
              <Button
                type='text'
                className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
                onClick={() => handleTabs(1)}
              >
                {t('HOLDINGS')}
              </Button>
              <Button
                type='text'
                className={`portfolio-tabs-btn ${tabs === 2 ? 'selected' : ''}`}
                onClick={() => handleTabs(2)}
              >
                {t('CASH')}
              </Button>
            </Col>
            <Col style={{display:'flex', gap: '8px'}}>
              {
                selectedRowKeys?.length === 1 &&
                <ButtonAdd
                  title='EDIT'
                  height='32px'
                  onClick={handleEdit}
                />
              }
              <Button
                type="text"
                icon={<Export />}
                iconPosition="end"
                className="client export-btn"
                onClick={handleExportSelected}
              >
                {t('EXPORT_PORT')}
              </Button>
            </Col>
          </Row>
          <ClientHoldings
            currentPortfolio={tabs === 1 ? portfolioContent : portfolioContentCash}
            currencyList={currencyList}
            cashes={cashes}
            tabs={tabs}
          />
        </div>
  );
};

export default PortfolioTab;

PortfolioTab.propTypes = {
    tabs: PropTypes.number,
    setTabs: PropTypes.func,
    selectedRowKeys: PropTypes.array,
    portfolios: PropTypes.array,
    setSelectedPortfolio: PropTypes.func,
    handleEdit: PropTypes.func,
};
