import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';

const CategorySwitcher = ({names, tabs, setTabs, t}) => {
  return (
    <div className='custom-scroll'>
      <div className='category-switcher-wrapper'>
        {
          names.map(i => (
            <div 
              key={i.tab} 
              className={`category-switcher-btn ${tabs === i.tab  ? 'active' : ''}`}
              onClick={() => setTabs(i.tab)}
            >
              {t(i.label)}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default CategorySwitcher;

CategorySwitcher.propTypes = {
  names: PropTypes.array,
  tabs: PropTypes.number,
  setTabs: PropTypes.func,
  t: PropTypes.func,
};