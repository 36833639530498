/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Col, Form, Input, Row } from 'antd';
import Container from '../../components/Container';
import { CustomTextarea } from '../../components/CustomTextfield/CustomTextfield';
import { createRecommendationMaps, getInstrumentsAll, updateRecommendationMaps } from '../../data/store/instruments/instrumentsActions';
import MapsInstrumetnTable from './MapsInstrumetnTable/MapsInstrumetnTable';
import ModalCheckModel from '../../components/ModalCheckModel/ModalCheckModel';

const MapCreation = ({
  // eslint-disable-next-line no-unused-vars
  mapsId,
  form,
  data,
  setData,
  closeModal,
  open
}) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
    return () => {
      form.resetFields();
    };
  }, []);

  const { recommendationById } = useSelector(state => state.instrumentsReducer);

  useEffect(() => {
    if (recommendationById) {
      form.setFieldsValue(recommendationById);
    } else {
      form.setFieldsValue({name: '', description: ''});
    }
  }, [recommendationById]);

  const handleClickFin = () => {
    console.log('🚀 ~ handleClickFin ~ handleClickFin:', 'handleClickFin');
    form.submit();
  };

  const onSubmitMapCreation = (elem) => {
    const newData = {
      ...elem,
      asset_recommendation_mappings: data,
    };
    if(mapsId && mapsId !== 'new') {
      dispatch(updateRecommendationMaps({history, data: newData, mapsId, closeModal}));
    } else {
      dispatch(createRecommendationMaps({history, data: newData, closeModal}));
    }
  };

  return (
    <>
        <Form
          form={form}
          name="onboarding-form"
          layout="vertical"
          onFinish={onSubmitMapCreation}
          className="instrument-form"
          autoComplete="off"
        >
          <Row className="map-name-wrapper">
            <Col className="map-name-column">
              <Form.Item
                className="custom-required recomend-input-wrap"
                label={t('NAME')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('PLEASE_ENTER_A_NAME'),
                  },
                ]}
                required={false}
              >
                <Input placeholder={t('NAME')} className="custom-input full-width recomend-input" />
              </Form.Item>
            </Col>
            <Col className="map-description-column">
              <Form.Item
                name="description"
                className="custom-required recomend-textaria-wrap"
                label={t('DESCRIPTION')}
              >
                <CustomTextarea
                  width={'100%'}
                  rows={3}
                  placeholder={t('ENTER_DESCRIPTION')}
                  maxLength={200}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <h2 className="recomend-maps-title" style={{marginTop: '8px'}}>{t('ASSET_CLASSES')}</h2>
        <MapsInstrumetnTable
          selectedInstruments={recommendationById?.asset_recommendation_mappings}
          data={data}
          setData={setData}
        />
      <ModalCheckModel
        mapsId={mapsId} 
        open={open}
        closeModal={closeModal}
        onOk={handleClickFin}
        data={data}
      />
    </>
  );
};

export default MapCreation;

MapCreation.propTypes = {
  mapsId: PropTypes.string,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  data: PropTypes.array,
  setData: PropTypes.func,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
};
