/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { Button, Col, Form, Input, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';

import countryEN from '../../../helpers/countries/countryEN.json';
import countrySW from '../../../helpers/countries/countrySW.json';
import { textSort } from '../../../helpers/textSort';
import CustomPhoneInput from '../../../components/CustomPhoneInput/CustomPhoneInput';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { NextCategory } from '../data/NextCategory';
import CategorySwitcher from '../CategorySwitcher/CategorySwitcher';

import { ReactComponent as Confirm } from '../img/confirm-icon.svg';
import { ReactComponent as Delete } from '../../../assets/delete-white-icon.svg';
import { ReactComponent as Plus } from '../../../assets/pluse-black-icon.svg';
import { ClientInformation } from '../data/InitCategoryData';

const CategoryClientInformation = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { i18n } = useTranslation('');
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const category = query.get('category');
  const tab = query.get('tab');

  const phonesArray = Form.useWatch(['client_info', 'common', 'contact_data', 'phones'], form);

  useEffect(() => {
    if(tab === undefined) {
      history.push(`${url}?category=client-information&tab=0`);
    }
  }, [tab]);
  

  const [country, setCountry] = useState([]);
  const [tabs, setTabs] = useState(0);

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  useEffect(() => {
    let countryList = [];
    if (currentLang === 'en') {
      countryList = [...countryEN];
    }
    if (currentLang === 'sw') {
      countryList = [...countrySW];
    } else {
      countryList = [...countryEN];
    }
    setCountry(countryList);
  }, [currentLang]);

  useEffect(() => {
    if (onboardingData) {
      const result = _.merge({},ClientInformation, onboardingData.result );
      form.setFieldsValue(result);
      form.setFieldValue(
        ['client_info', 'consultation', 'type'],
        onboardingData?.type === 'online'
          ? 'type_option_2'
          : onboardingData?.type === 'offline'
            ? 'type_option_1'
            : 'type_option_3');
      form.setFieldValue(['client_info', 'consultation', 'date'], dayjs(onboardingData?.start_date));
      form.setFieldValue(
        ['client_info', 'common', 'main_info', 'personal_number'],
        onboardingData?.result?.client_info?.common?.main_info?.personal_number || onboardingData?.personal_number || '');
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = () => {
    const allValues = form.getFieldsValue(true);
    delete allValues.client_info.consultation;
    const result = _.merge({}, onboardingData.result, allValues);

    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
    const path = NextCategory(category);
    history.push(`${pathname}?category=${path}`);
  };

  const names = [
    {tab: 0, label: 'BASIC_INFORMATION'},
    {tab: 1, label: 'CONTACT_DETAILS'},
  ];
  return (
    <div className="card-right-wrap" style={{ paddingTop: '56px', paddingBottom: '24px',}}>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="instrument-form"
        autoComplete="off"
      >
        <CategorySwitcher
          names={names}
          tabs={tabs}
          setTabs={setTabs}
          t={t}
        />
        {tabs === 0 &&
          <>
            <Form.Item
              label={t('FIRST_NAME')}
              name={['client_info', 'common', 'main_info', 'name']}
              className="custom-required"
            >
              <Input placeholder={t('ENTER_FIRST_NAME')} className="custom-input full-width" />
            </Form.Item>
            <Form.Item
              label={t('SURNAME')}
              name={['client_info', 'common', 'main_info', 'last_name']}
              className="custom-required"
            >
              <Input placeholder={t('ENTER_SURNAME')} className="custom-input full-width" />
            </Form.Item>
            <Form.Item
              label={t('SOCIAL_SECURITY_NUMBER')}
              name={['client_info', 'common', 'main_info', 'personal_number']}
              className="custom-required"
            >
              <Input
                placeholder={t('ENTER_SOCIAL_SECURITY_NUMBER')}
                className="custom-input full-width"
              />
            </Form.Item>
            <Form.Item
              label={t('POSTAL_ADDRESS')}
              name={['client_info', 'common', 'main_info', 'address']}
              className="custom-required"
            >
              <Input
                placeholder={t('ENTER_POSTAL_ADDRESS')}
                className="custom-input full-width"
              />
            </Form.Item>
            <Form.Item
              label={t('ZIP_CODE')}
              name={['client_info', 'common', 'main_info', 'zipcode']}
              className="custom-required"
              required={false}
            >
              <Input placeholder={t('ENTER_ZIP_CODE')} className="custom-input full-width" />
            </Form.Item>
            <Form.Item
              label={t('CITY')}
              name={['client_info', 'common', 'main_info', 'city']}
              className="custom-required"
              required={false}
            >
              <Input placeholder={t('ENTER_CITY')} className="custom-input full-width" />
            </Form.Item>
            <Form.Item
              label={t('COUNTRY_COMPANY')}
              name={['client_info', 'common', 'main_info', 'country']}
              className="custom-required"
              required={false}
            >
              <CustomSelect
                width={'100%'}
                showSearch
                placeholder={t('ENTER_COUNTRY')}
                className="custom-select"
                filterOption={(inputValue, option) => {
                  return inputValue.length > 1
                    ? option.children?.props?.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                    : true;
                }}
                notFoundContent={<></>}
              >
                {country
                  ?.slice()
                  ?.sort((a, b) => textSort(a.name, b.name, 1))
                  .map((item) => {
                    return (
                      <Select.Option key={item.alpha3} value={item.alpha3}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </CustomSelect>
            </Form.Item>
          </>

        }
        
        {tabs === 1 && 
        <>
          <Form.List name={['client_info', 'common', 'contact_data', 'phones']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Col key={key}>
                  <Form.Item
                    {...restField}
                    key={key}
                    label={`${t('MOBILE')}`}
                    className="form-phone custom-required"
                    name={[name, 'phone']}
                    rules={[{
                      required: true,
                      message: `${t('ENTER_PHONE_NUMBER')}`,
                    }]}
                    required={false}
                  >
                    <CustomPhoneInput
                      dropdownClassName="onboarding-custom-inputnumber-dropdown"
                      nameCode={[name, 'country_code']}
                      width='520px'
                    />
                  </Form.Item>
                  <Delete
                    className='profile-delete-btn'
                    onClick={() => remove(name)} />
                </Col>
              ))}
              {!phonesArray || phonesArray?.length < 3 && (
                <Col style={{wifth: '100%', textAlign: 'center'}}>
                  <Button
                    type="text"
                    icon={<Plus />}
                    iconPosition="end"
                    className="client profile-data-addbutton client-primary-btn"
                    onClick={() => add({ country_code: '', phone: '' })}
                  >
                    {t('ADD_MORE_PHONE_NUMBERS')}
                  </Button>
                </Col>
              )}
            </>
          )}
          </Form.List>
          <Form.Item
            style={{ margin: '24px 0 40px 0' }}
            label={t('MAILADRESS')}
            name={['client_info', 'common', 'contact_data', 'email']}
            className="custom-required"
            required={false}>
            <Input placeholder={t('ENTER_EMAIL_ADDRESS')} className="custom-input full-width" />
          </Form.Item>

        </>

        }
      <Button
        icon={<Confirm />}
        iconPosition='end'
        type='text'
        className="start-onbording-btn"
        onClick={handleSaveOnbording}
      >
        {t('CONFIRM')}
      </Button>
      </Form>
    </div>
  );
};

export default CategoryClientInformation;

CategoryClientInformation.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
