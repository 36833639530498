import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';

export const LineBasic = ({ data, currentPortfolioData, selectPoint, analyzedPortfolio }) => {
  const newData = data?.map((i, index) => ({ ...i, key: index + 1 }));
  const { t } = useTranslation('');

  const handlePointSelect = function () {
    if (analyzedPortfolio) {
      selectPoint(this);
      console.log('Точка выбрана:', this);
    }
  };

  useEffect(() => {
    console.log('🚀 ~ LIneBase ~ analyzedPortfolio:', analyzedPortfolio);
  }, [analyzedPortfolio]);
  

  const options = {
    chart: {
      type: 'line',
      height: 374,
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      gridLineColor: 'rgba(0, 0, 0, 0)',
      title: {
        text: t('VOLATILITY'),
        style: {
          fontSize: '16px',
          color: '#4D4D4D'
        }
      },
      labels: {
        format: '{text} %',
        style: {
          fontSize: '15px',
          color: '#4D4D4D'
        }
      },
    },
    yAxis: {
      gridLineColor: 'rgba(0, 0, 0, 0)',
      title: {
        text: t('RETURN'),
        style: {
          fontSize: '16px',
          color: '#4D4D4D'
        }
      },
      labels: {
        format: '{text} %',
        style: {
          fontSize: '15px',
          color: '#4D4D4D'
        }
      },
    },
    plotOptions: {
      series: {
        animation: false,

      },
    },
    tooltip: {
      useHTML: true,
      headerFormat: '',
      formatter: function () {
        const returnClass = this.y >= 0 ? 'linebasic-tooltip-positive' : 'linebasic-tooltip-negative';
        const volatilityClass = this.x >= 0 ? 'linebasic-tooltip-positive' : 'linebasic-tooltip-negative';
        const cvarClass = this.cvar >= 0 ? 'linebasic-tooltip-positive' : 'linebasic-tooltip-negative';
        return `
        <div class="toolpit-wrapper">
        <span class="toolpit-title">${t('PORTFOLIO')} ${this.point.key}</span>
        <div class="toolpit-point">${t('RETURN')}: <span class="${returnClass}">${Highcharts.numberFormat(this.y, 1)} %</span></div>
        <div class="toolpit-point">${t('VOLATILITY')}: <span class="${volatilityClass}">${Highcharts.numberFormat(this.x, 1)} %</span></div>
        <div class="toolpit-point">CVaR: <span class="${cvarClass}">${Highcharts.numberFormat(this.point.cvar, 1)} %</span></div>
        </div>
        `;
      }
    },
    series: [
      {
        data: newData,
        color: '#525252',
        allowPointSelect: analyzedPortfolio,
        point: {
          events: {
            select: handlePointSelect,
          },
        },
        marker: {
          enabled: true,
          radius: 5,
          symbol: 'circle',
          fillColor: '#525252', // Обычный цвет маркера
          states: {
            select: {
              fillColor: '#6467C3', // Цвет при выборе
              lineColor: '#6467C3', // Цвет рамки при выборе
              radius: 8, // Увеличенный размер при выборе
            }
          }
        }
      },
      {
        data: currentPortfolioData,
        color: '#FB7B33',
        marker: {
          enabled: true,
          radius: 8,
          symbol: 'circle',
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
            normal: {
              enabled: true,
            }
          }
        }
      }
    ],
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 970
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  };

  return (
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

LineBasic.propTypes = {
  data: PropTypes.array,
  currentPortfolioData: PropTypes.array,
  selectPoint: PropTypes.func,
  analyzedPortfolio: PropTypes.bool,
};
