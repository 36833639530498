/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { Button, Form } from 'antd';

import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';

import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { decimalSeparator } from '../../../helpers/decimalSeparator';
import { NextCategory } from '../data/NextCategory';
import CategorySwitcher from '../CategorySwitcher/CategorySwitcher';

import { ReactComponent as Confirm } from '../img/confirm-icon.svg';
import { FinancialSituation } from '../data/InitCategoryData';

const CategoryFinancialSituation = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const history = useHistory();

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const category = query.get('category');

  const [tabs, setTabs] = useState(0);

  useEffect(() => {
    if (onboardingData) {
      const result = _.merge({}, FinancialSituation, onboardingData.result);
      form.setFieldsValue(result);
    }
  }, [onboardingData]);

  const employmentIncome = Form.useWatch(['fin_state', 'revenue_and_expenditure', 'employment_income'], form);
  const expenditure = Form.useWatch(['fin_state', 'revenue_and_expenditure', 'expenditure'], form);
  const otherIncome = Form.useWatch(['fin_state', 'revenue_and_expenditure', 'other_income'], form);

  useEffect(() => {
    if ((employmentIncome || employmentIncome === 0)) {
      const surplus = employmentIncome + (otherIncome || 0) - (expenditure || 0);
      surplus >= 0 && form.setFieldValue(['fin_state', 'revenue_and_expenditure', 'surplus'], surplus);
    }
  }, [employmentIncome, expenditure, otherIncome]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = () => {
    const allValues = form.getFieldsValue(true);
    const result = _.merge({}, onboardingData.result, allValues);
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
        const path = NextCategory(category);
        history.push(`${pathname}?category=${path}`);
  };
  const names = [
    {tab: 0, label: 'REVENUE_AND_EXPENDITURE'},
    {tab: 1, label: 'FINANCIAL_ASSETS'},
    {tab: 2, label: 'FIXED_ASSETS'},
    {tab: 3, label: 'SHOULDER'},
    {tab: 4, label: 'SCOPE_INVESTABLE_CAPITAL'},
  ];

  return (
    <div className="card-right-wrap"  style={{ paddingTop: '56px', paddingBottom: '24px',}}>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="" >
        <CategorySwitcher
          names={names}
          tabs={tabs}
          setTabs={setTabs}
          t={t}
        />
        {tabs === 0 &&
          <>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'revenue_and_expenditure', 'employment_income']}
              label={t('INCOME_EMPLOYMENT')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                min={0}
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              />
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'revenue_and_expenditure', 'other_income']}
              label={t('DO_YOU_HAVE_OTHER_INCOME')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                min={0}
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              />
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'revenue_and_expenditure', 'expenditure']}
              label={t('EXPENDITURE_QUESTION')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                min={0}
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              />
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'revenue_and_expenditure', 'surplus']}
              label={t('WHAT_YOUR_SURPLUS')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                min={0}
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                disabled/>
            </Form.Item>
            <Form.Item
              name={['fin_state', 'revenue_and_expenditure', 'commentary']}
              className="onboarding-textarea-purpose"
              label={t('COMMENT')}>
              <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} />
            </Form.Item>
          </>
        }
        {tabs === 1 &&
          <>
          <Form.Item
            className='custom-required'
            name={['fin_state', 'financial_assets', 'financial_investments']}
            label={t('YOUR_FINANCIAL_INVESTMENTS')}>
            <CustomInputNumber
              width={'100%'}
              placeholder='0,00'
              formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
              parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              min={0}/>
          </Form.Item>
          <Form.Item
            className='custom-required'
            name={['fin_state', 'financial_assets', 'cash_equivalents']}
            label={t('CASH_EQUIVALENTS')}>
            <CustomInputNumber
              width={'100%'}
              placeholder='0,00'
              formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
              parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              min={0}/>
          </Form.Item>
          <Form.Item
            className='custom-required'
            name={['fin_state', 'financial_assets', 'pension_savings']}
            label={t('PENSION_SAVINGS')}>
            <CustomInputNumber
              width={'100%'}
              placeholder='0,00'
              formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
              parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              min={0}/>
          </Form.Item>
          <Form.Item
            className='custom-required'
            name={['fin_state', 'financial_assets', 'other_assets']}
            label={t('OTHER_ASSETS')}>
            <CustomInputNumber
              width={'100%'}
              placeholder='0,00'
              formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
              parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
              min={0}/>
          </Form.Item>
          <Form.Item
            name={['fin_state', 'financial_assets', 'commentary']}
            className="onboarding-textarea-purpose"
            label={t('COMMENT')}>
            <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} />
          </Form.Item>
          </>
        }
        {tabs === 2 &&
          <>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'fixed_assets', 'housing']}
              label={t('ONB_HOUSING')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                min={0}/>
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'fixed_assets', 'other_fixed_assets']}
              label={t('OTHER_FIXED_ASSETS')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                min={0}/>
            </Form.Item>
            <Form.Item
              name={['fin_state', 'fixed_assets', 'commentary']}
              className="onboarding-textarea-purpose"
              label={t('COMMENT')}>
              <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} />
            </Form.Item>
          </>
        }
        {tabs === 3 &&
          <>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'shoulder', 'housing']}
              label={t('ONB_HOUSING_2')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                min={0}/>
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'shoulder', 'other_liabilities']}
              label={t('OTHER_LIABILITIES')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                min={0} />
            </Form.Item>
            <Form.Item
              name={['fin_state', 'shoulder', 'commentary']}
              className="onboarding-textarea-purpose"
              label={t('COMMENT')}>
              <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_TEXT')} />
            </Form.Item>
          </>
        }
        {tabs === 4 &&
          <>
            <Form.Item
              name={['fin_state', 'investable_capital', 'non_covered_elements']}
              className="onboarding-textarea-purpose"
              label={t('NON_COVERED_ELEMENTS')}>
              <CustomTextarea width={'100%'} rows={4} placeholder={t('YOUR_ANSWER')} />
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'investable_capital', 'unadvised_capital']}
              label={t('CAPITAL_NOT_COVERED_ADVICE')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                min={0}/>
            </Form.Item>
            <Form.Item
              className='custom-required'
              name={['fin_state', 'investable_capital', 'investment_capital']}
              label={t('WHAT_YOUR_INVESTABLE_CAPITAL')}>
              <CustomInputNumber
                width={'100%'}
                placeholder='0,00'
                formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                min={0}/>
            </Form.Item>
          </>
        }
        <Button
          icon={<Confirm />}
          iconPosition='end'
          type='text'
          className="start-onbording-btn"
          onClick={handleSaveOnbording}>
          {t('CONFIRM')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryFinancialSituation;

CategoryFinancialSituation.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
