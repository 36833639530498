import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import {ReactComponent as OrangeDot} from './orange-daot.svg';
import {ReactComponent as BlueDot} from './blue-dot.svg';
import './style.scss';
import { formatPercentage2 } from '../../../helpers/formatPercentage2';

const OptimChartHeader = ({current, optimal }) => {
  const { t } = useTranslation('');
  return (
    <div className='chart-header-wrapper'>
      <div className='chart-header-cell'>
        <div className='chart-header-cell-title'>
          {t('RETURN')}
        </div>
        <div className='chart-header-cell-data'>
          <div><OrangeDot /><span>{formatPercentage2(current?.return)} %</span></div>
          <div><BlueDot /><span>{optimal?.y 
          ? formatPercentage2(optimal?.y)
          : '- - -'} %</span></div>
        </div>

      </div>
      <div className='chart-header-cell'>
        <div className='chart-header-cell-title'>
          {t('VOLATILE')}
        </div>
        <div className='chart-header-cell-data'>
          <div><OrangeDot /><span>{current?.vol
          ? formatPercentage2(current?.vol)
          : '- - -'} %</span></div>
          <div><BlueDot /><span>{formatPercentage2(optimal?.x)} %</span></div>
        </div>
      </div>
      <div className='chart-header-cell'>
        <div className='chart-header-cell-title'>
          {t('CVAR')}
        </div>
        <div className='chart-header-cell-data'>
          <div><OrangeDot /><span>{formatPercentage2(current?.cvar)} %</span></div>
          <div><BlueDot /><span>{optimal?.cvar
          ? formatPercentage2(optimal?.cvar)
          : '- - -'} %</span></div>
        </div>
      </div>
    </div>
  );
};

export default OptimChartHeader;

OptimChartHeader.propTypes = {
    current: PropTypes.object,
    optimal: PropTypes.object,
};