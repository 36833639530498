import React from 'react';

import PropTypes from 'prop-types';

import { Progress } from 'antd';
import { OnboardingQustions } from './data/OnboardingQuestions';

const CategoryItem = ({ t, name, status, fillPoints,selected, category }) => {

const questionLength = OnboardingQustions.find(i => i.order ===category)?.set?.length;
const total = questionLength === fillPoints[1] ? questionLength : fillPoints[1] || questionLength;
    return (
        <div className={selected ? 'category-item selected' : 'category-item'}>
          <div className="category-colunm">
            <div
              className={`category-checkbx ${status === 'partially' ? 'partially' : ''} 
                  ${status === 'full' ? 'full' : ''}`}
            />
            <p className="category-name">{t(name)}</p>
          </div>
          <div className="category-counter">
            {`${fillPoints[0]} / ${total}`}
          </div>
          <Progress 
            className='table-date-progress'
            percent={(fillPoints[0] * 100 / total).toFixed(2)} 
            showInfo={false} 
            strokeColor={'#FB7B34'}
            size={{height: 2}}
          />
        </div>
      );
};

export default CategoryItem;

CategoryItem.propTypes = {
  t: PropTypes.func,
  category: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.string,
  fillPoints: PropTypes.array,
  selected: PropTypes.bool,
};