/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  deletePortfolio,
  getAvailablePortfolioCode,
} from '../../data/store/portfolio/portfolioActions';
import { decimalSeparator } from '../../helpers/decimalSeparator';

import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { getRecommendationMaps } from '../../data/store/instruments/instrumentsActions';

import ModaldeletePortfolio from './ModaldeletePortfolio';
import { ReactComponent as Delete } from '../../assets/delete-round-icon.svg';
import { ReactComponent as Export } from '../../assets/export-round-icon.svg';

import { ColorDataItem } from '../ClientOverview/ClientPortfolioList/ClientPortfolioListItem';
import { numberWithSpaces } from '../../helpers/formatInteger';
import dayjs from 'dayjs';
import StorageService from '../../services/StorageService';
import API from '../../utils/API';
import { API_URLS } from '../../constants/api_urls';

import './style.scss';

const PortfolioEditInf = ({
  form,
  onSubmit,
  dataSourcePortfolio,
  userId,
  portfolioId,
  setNeedSaveInfo,
}) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [portfolioTypeList, setPortfolioTypeList] = useState(null);

  const { recommendation } = useSelector((state) => state.instrumentsReducer);
  const { identification_number, id } = useSelector(
    (state) => state.userReducer?.advisor,
  );
  const {
    // isLoading,
    portfolioById,
    portfolioById: {
      is_locked,
      name,
      total_value,
      portfolio_code,
      created_by,
      created_at,
      updated_at,
    },
    portfolioCodeList,
    isLoadingCode,
  } = useSelector((state) => state.portfolioReducer);
  const [creator, setCreator] = useState();

  const isChecked = Form.useWatch('is_sync_active', form);

  useEffect(() => {
    dispatch(getRecommendationMaps());
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = StorageService.getJWTToken();
        const response = await API.get(`${API_URLS.ADVISOR_V3}/${created_by}`, {
          headers: { Authorization: 'Bearer ' + token },
        });
        setCreator(
          `${response?.data?.first_name} ${response?.data?.last_name}`,
        );
      } catch (error) {
        console.log('Error', error?.response);
      }
    }
    if (created_by) {
      fetchData();
    }
  }, [created_by]);

  useEffect(() => {
    const list = recommendation?.filter((i) => i?.is_complete);
    setPortfolioTypeList(list ?? []);
  }, [recommendation]);

  useEffect(() => {
    console.log('isChecked', isChecked);
    if (!isChecked) {
      form.setFieldValue('portfolio_code', '');
      form.validateFields(['portfolio_code']);
    } else {
      dispatch(getAvailablePortfolioCode({ advisorId: id }));
    }
  }, [isChecked]);


  useEffect(() => {
    form.setFieldsValue(dataSourcePortfolio);
  }, [dataSourcePortfolio]);

  const handleChangeTaxation = (value) => {
    if (+value === 2) {
      form.setFieldValue('tax_rate', '0.888');
    } else {
      form.setFieldValue('tax_rate', '');
    }
  };

  const handleChangeType = (value) => {
    form.setFieldValue('portfolio_type', value);
  };

  const handleChange = (_, allValues) => {
    setNeedSaveInfo(hasChanges(allValues));
  };

  function hasChanges(allValues) {
    const {
      name,
      portfolio_code,
      is_sync_active,
      taxation,
      tax_rate,
      deposit_fee,
      advisory_fee,
      portfolio_type,
    } = allValues;
    if (
      name === portfolioById.name &&
      portfolio_code === portfolioById.portfolio_code &&
      is_sync_active === portfolioById.is_sync_active &&
      Number(taxation) === portfolioById.taxation &&
      Number(tax_rate) === portfolioById.tax_rate &&
      Number(deposit_fee) === portfolioById.deposit_fee &&
      Number(advisory_fee) === portfolioById.advisory_fee &&
      portfolio_type === portfolioById?.portfolio_type
    ) {
      return false;
    } else {
      return true;
    }
  }


  const filterOption = (inputValue, option) => {
    console.log('form', form);

    const value = inputValue.toString();
    if (value?.length > 2) {
      return option?.children
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    } else {
      return option;
    }
  };

  const handleExportPortfolio = () => {
    console.log('Export');
  };

  const handleCloseDelModal = () => {
    setModalDeleteVisible(false);
  };

  const handleDeleteModal = () => {
    setModalDeleteVisible(true);
  };

  const submitDelete = () => {
    dispatch(
      deletePortfolio({
        history,
        portfolioId,
        clientId: +userId,
      }),
    );
    setModalDeleteVisible(false);
  };

  return (
    (
      <>
        <div className="portfolio-info-wrapper">
          <Row justify={'space-between'}>
            <Col span={12}>
              <h3 className="portfolio-info-title">
                {t('PORTFOLIO_INFORMATION')}
              </h3>
            </Col>
            <Col
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '8px',
              }}
            >
              <Button
                type="text"
                className="round-button"
                icon={<Delete />}
                onClick={handleDeleteModal}
              />
              <Button
                type="text"
                className="round-button"
                icon={<Export />}
                onClick={handleExportPortfolio}
              />
            </Col>
          </Row>
          <div className={'client portfolio-card portfolio-info-card'}>
            <div>
              <p className="portfolio-card-name">
                {name}
                <span className="portfolio-card-code">{portfolio_code}</span>
              </p>
            </div>
            <div>
              <p className="portfolio-card-value">{`${numberWithSpaces(Math.round(total_value))} kr`}</p>
              <Row justify={'center'} className="portfolio-card-text">
                <Col className="">
                  <ColorDataItem
                    isSelected={true}
                    text={t('RETURN')}
                    value={portfolioById?.return ?? ''}
                  />
                </Col>
                <Col className="">
                  <ColorDataItem
                    isSelected={true}
                    text={t('VOLATILITY')}
                    value={portfolioById?.vol}
                  />
                </Col>
                <Col className="">
                  <ColorDataItem
                    isSelected={true}
                    text={t('CVaR')}
                    value={portfolioById?.cvar}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="portfolio-creator-info-wrapper">
            <div className="portfolio-creator-info-left">
              <h3 className="details-create">{t('CREATED_ON')}</h3>
              <p className="details-date">
                {dayjs(created_at).format('DD MMM YYYY').toUpperCase()}
              </p>
              <p className="details-createdBy">
                {t('BY')}
                {` ${creator}`}
              </p>
            </div>
            <div className="portfolio-creator-info-right">
              <h3 className="details-create">{t('LAST_OPTIMIZED_ON')}</h3>
              <p className="details-date">
                {dayjs(updated_at).format('DD MMM YYYY').toUpperCase()}
              </p>
              <p className="details-createdBy">
                {t('BY')}
                {` ${creator}`}
              </p>
            </div>
          </div>
          <Form
            id="create-portfolio"
            disabled={
              is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'
            }
            form={form}
            onFinish={onSubmit}
            validateTrigger={['onSubmit', 'onBlur']}
            layout="vertical"
            autoComplete="off"
            onValuesChange={handleChange}
            style={{ paddingTop: '28px' }}
          >
            <Form.Item
              label={t('NAME')}
              name="name"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('PLEASE_ENTER_A_NAME'),
                },
              ]}
            >
              <Input className="custom-input full-width" />
            </Form.Item>
            <Row className="portfolio-form-row wrapper-portfolio-number">
              <div className="portfolio-number portfolio-info">
                <Form.Item
                  label={t('PORTFOLIO_NUMBER')}
                  name="portfolio_code"
                  required={false}
                  rules={
                    isChecked
                      ? [
                          {
                            required: true,
                            message: t('PLEASE_ENTER_PORTFOLIO_NUMBER'),
                          },
                        ]
                      : [
                          {
                            required: false,
                          },
                        ]
                  }
                >
                  <CustomSelect
                    disabled={!isChecked || isLoadingCode}
                    loading={isLoadingCode}
                    showSearch
                    width={'284px'}
                    placeholder={t('PORTFOLIO_NUMBER')}
                    className="custom-select"
                    filterOption={(inputValue, option) =>
                      filterOption(inputValue, option)
                    }
                  >
                    {portfolioCodeList?.map((code, index) => {
                      return (
                        <Select.Option key={index} value={code}>
                          {code}
                        </Select.Option>
                      );
                    })}
                  </CustomSelect>
                </Form.Item>
              </div>
              <div className="portfolio-number-sync portfolio-info">
                <Tooltip
                  placement="top"
                  title={
                    !identification_number ? (
                      <span style={{ color: '#000000' }}>
                        {t('TO_SYNC_PORTFOLIOS')}
                      </span>
                    ) : null
                  }
                  color="white"
                >
                  <Form.Item
                    name="is_sync_active"
                    valuePropName="checked"
                    normalize={(value) => value ?? false}
                  >
                    <Checkbox
                      disabled={!identification_number}
                      className="experience-background-cell center experience-background-checkbox my-details-checkbox"
                    >
                      {t('ACTIVATE_SYNCHRONIZATION')}
                    </Checkbox>
                  </Form.Item>
                </Tooltip>
              </div>
            </Row>
            <div className="portfolio-sync">
              {portfolioById?.is_sync_active &&
              portfolioById?.last_sync_time ? (
                <span style={{ marginBottom: '24px' }}>
                  {t('LAST_SYNC')}:{' '}
                  {new Date(
                    portfolioById?.last_sync_time * 1000,
                  ).toLocaleString()}
                </span>
              ) : (
                ''
              )}
            </div>
            <Row className="portfolio-form-row wrapper-portfolio-tax">
              <div className="portfolio-taxation">
                <Form.Item
                  label={t('TAXATION')}
                  name="taxation"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_SELECT_METHOD_TAXATION'),
                    },
                  ]}
                >
                  <CustomSelect
                    width={'284px'}
                    className="custom-select"
                    onChange={(value) => handleChangeTaxation(value)}
                  >
                    <Select.Option value="1">{t('CAPITAL_GAIN')}</Select.Option>
                    <Select.Option value="2">{t('FLAT_RATE')}</Select.Option>
                  </CustomSelect>
                </Form.Item>
              </div>
              <div className="portfolio-taxrate portfolio-info">
                <Form.Item
                  label={t('TAX_RATE')}
                  name="tax_rate"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_TAX_RATE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width={'100%'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={3}
                    className="custom-inputnumber"
                    min={0}
                    step={0.001}
                    controls={false}
                  />
                </Form.Item>
              </div>
            </Row>
            <Row className="portfolio-form-row">
              <Col style={{ width: '120px' }}>
                <Form.Item
                  label={t('DEPOSIT_FEE')}
                  name="deposit_fee"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_DEPOSIT_FEE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width={'120px'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={2}
                    className="custom-inputnumber"
                    min={0}
                    step={0.01}
                    controls={false}
                  />
                </Form.Item>
              </Col>
              <Col style={{ width: '120px' }}>
                <Form.Item
                  label={t('ADVISORY_FEE')}
                  name="advisory_fee"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_ADVISORY_FEE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width={'120px'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={2}
                    className="custom-inputnumber"
                    min={0}
                    step={0.01}
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('PORTFOLIO_TYPE')}
              name="portfolio_type"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('CHOOSE_PORTFOLIO_TYPE'),
                },
              ]}
            >
              <CustomSelect
                className="custom-select"
                onChange={handleChangeType}
              >
                {portfolioTypeList?.map((el) => (
                  <Select.Option key={el.id} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
              </CustomSelect>
            </Form.Item>
          </Form>
        </div>
        
        <ModaldeletePortfolio
          modalDeleteVisible={modalDeleteVisible}
          handleCloseDelModal={handleCloseDelModal}
          submitDelete={submitDelete}
          name={portfolioById.name}
        />
      </>
    )
  );
};

export default PortfolioEditInf;

PortfolioEditInf.propTypes = {
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  dataSourcePortfolio: PropTypes.object,
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  setNeedSaveInfo: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
