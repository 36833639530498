import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'antd';

import { ReactComponent as AddClient } from '../../assets/orang-pluse-icon.svg';
import { ReactComponent as Pluse } from '../../assets/black-plus-icon.svg';
import './style.scss';

const { Text } = Typography;

const ButtonAdd = ({
  className, width='', height='', title,
  marginTop='', marginBottom='', theme,
  ...restProp
  }) => {
  const { t } = useTranslation('');

  return (
    <Button
      style={{width, height, marginTop, marginBottom}}
      icon={theme === 'light' ? <Pluse />: <AddClient />}
      iconPosition="end"
      className={`add-btn-whith-icon ${className ?? ''} ${theme ?? ''}`}
      {...restProp}
    >
      <Text>{t(title)}</Text>
    </Button>
  );
};

export default ButtonAdd;

ButtonAdd.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  onClick: PropTypes.func,
};