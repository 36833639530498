import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditableContext } from './RelatedPersons';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { textSort } from '../../../helpers/textSort';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';

const EditableCell = ({
  editing,
  children,
  dataIndex,
  record,
  handleSaveList,
  users,
  ...restProps
}) => {
  const form = useContext(EditableContext);
  const { t } = useTranslation('');

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const getRole =(id) => {
    const user = users.find(i => i.id === id)?.role;
    return user === 'CLIENT';
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSaveList({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          valuePropName={dataIndex === 'isOwner' ? 'checked' : 'value'}
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {dataIndex === 'id' && record?.key
            ? (
              <CustomSelect
                showSearch
                placeholder={t('SELECT_OPTION')}
                className="custom-select"
                onSelect={save}
                filterOption={(inputValue, option) => {

                  return inputValue.length > 2
                    ? option.children?.props?.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : true;
                }}
                notFoundContent={<></>}
              >
                {users
                  ?.slice()
                  ?.sort((a, b) =>
                    textSort(a.first_name, b.first_name, 1),
                  )
                  .map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.first_name} ${item.last_name} - ${t(item.role)}`}
                      </Select.Option>
                    );
                  })}
              </CustomSelect>
              )
              : dataIndex === 'access_type' 
              ? (
                <Radio.Group className="checkbox-block account-checkbox" onChange={save}>
                  <Space
                    direction="horizontal"
                    align="start"
                    className="ondoarding-space-wrap">
                    <CustomRadioButtom value={'VIEW'}>
                      {t('READ')}
                    </CustomRadioButtom>
                    <CustomRadioButtom value={'FULL'}>
                      {t('WRITE')}
                    </CustomRadioButtom>
                  </Space>
                </Radio.Group>
              )
              : dataIndex === 'isOwner' && (record?.id && getRole(record?.id) )
                ? (
                  <CustomCheckbox onChange={save}>{t('IS_OWNER')}</CustomCheckbox>
                )
                : (
              <span className='related-table-text'>
                {children}
              </span>
                )
            }
        </Form.Item>
      </td>
    );
  } else {
    return (
      <td {...restProps}>
        {children}
      </td>);
  }
};

export default EditableCell;

EditableCell.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSaveList: PropTypes.func,
  users: PropTypes.array,
};
