import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  useParams,
  useRouteMatch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { getPortfolioById } from '../../data/store/portfolio/portfolioActions';

import { StepTransactions } from './StepTransactions/StepTransactions';
import { StepSettings } from './StepSettings/StepSettings';
import { Col, Layout, Row } from 'antd';

import { getCurrency, getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';
import StepSuggestedPortfolio from './StepSuggestedPortfolio/StepSuggestedPortfolio';

import './styles.scss';
import NewOptimizationStep from './NewOptimizationStep/NewOptimizationStep';

const { Header } = Layout;

export const StepRouter = ({ userId, optAlert, setOptAlert, setOptStart }) => {
  const { t } = useTranslation('');
  const { portfolioId } = useParams();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const currentPortfolio = useSelector((state) => state.portfolioReducer.portfolioById );

  const [pdfSource, setPDFSource] = useState(null);
  const [settings, setSettings] = useState(null);
  const [optimalPortfolio, setOptimalPortfolio] = useState(null);

  useEffect(() => {
    console.log('optimalPortfolio', optimalPortfolio);
  }, [optimalPortfolio]);
  

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
    if (portfolioId) {
      dispatch(getPortfolioById({ portfolioId }));
    }
  }, [portfolioId]);

  return (
    <div className='portfolio-wrapper'>
      <Header className='headerStyle'>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '', padding: '24px' }}
      >
        <Col className='client portfolio-counter-wrap'>
          <h2 className="client settings-title">{t('PORTFOLIOS')}</h2>
          <span className='portfolio-counter'>{currentPortfolio?.name ?? ''}</span>
        </Col>
      </Row>
      </Header>
      <Route exact path={`${url}/`}>
        <Redirect to={`${url}/opt-settings`} />
      </Route>
      <Route exact path={`${url}/opt-settings`}>
        <StepSettings
          id={userId}
          portfolioId={portfolioId}
          settings={settings}
          setSettings={setSettings}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route exact path={`${url}/portfolioOptimization`}>
        <NewOptimizationStep
          userId={userId}
          portfolioId={portfolioId}
          pdfSource={pdfSource}
          setPDFSource={setPDFSource}
          settings={settings}
          setOptimalPortfolio={setOptimalPortfolio}
          optimalPortfolio={optimalPortfolio}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route exact path={`${url}/transactions`}>
        <StepTransactions
          userId={userId}
          portfolioId={portfolioId}
          pdfSource={pdfSource}
          setPDFSource={setPDFSource}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route exact path={`${url}/suggested`}>
        <StepSuggestedPortfolio
          userId={userId}
          portfolioId={portfolioId}
          pdfSource={pdfSource}
          setPDFSource={setPDFSource}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route path='*'>
        <Redirect to={`${url}/opt-settings`} />
      </Route>

    </div>
  );
};

StepRouter.propTypes = {
  userId: PropTypes.string,
  optAlert: PropTypes.object,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
