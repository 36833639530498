import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Button, Form, Row, Table } from 'antd';

import { twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { formatPercentage } from '../../helpers/formatPercentage';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import { textSort } from '../../helpers/textSort';

import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';

import EditableCell from './EditableCell';

import './style.scss';
import CustomSortIcon from '../CustomSortIcon/CustomSortIcon';

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const ExcludeHoldings = ({ instrumentsForExclude, data, setData, handleDelete }) => {
  const { t } = useTranslation('');

  const [tableColumns, setTableColumns] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const availableInstrument = useMemo(() => {
    const array = instrumentsForExclude.filter(i => data?.length ? data?.every(data => i?.id !== data?.id) : true);

    return array;
  }, [data]);

  const getInstrumentInfo = (id) => {
    const instrument = instrumentsForExclude.find(i => i?.id === id);
    return instrument;
  };

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        className: 'exclude-holdings exclude-name',
        dataIndex: 'id',
        width: '20%',
        render: (value) => {
          return getInstrumentInfo(value)?.instrument_name;
        },
        sorter: (a, b) => textSort(getInstrumentInfo(a.id)?.instrument_name, getInstrumentInfo(b.id)?.instrument_name),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('ISIN'),
        className: 'exclude-holdings exclude-isin',
        dataIndex: 'isin',
        align: 'center',
        width: '11%',
        render: (_, record) => {
          return getInstrumentInfo(record.id)?.isin;
        },
        sorter: (a, b) => textSort(getInstrumentInfo(a.id)?.isin, getInstrumentInfo(b.id)?.isin),
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('NO_SHARES'),
        className: 'exclude-holdings exclude-unitsNumber',
        dataIndex: 'units_number',
        align: 'right',
        width: '15%',
        render: (_, record) => {
          return getInstrumentInfo(record.id)?.units_number;
        },
        sorter: (a, b) => getInstrumentInfo(a.id)?.units_number - getInstrumentInfo(b.id)?.units_number,
        sortIcon: (props) => <CustomSortIcon {...props} />,
      },
      {
        title: t('SHARE_PRICE'),
        className: 'exclude-holdings exclude-price',
        dataIndex: 'latest_value_sek',
        align: 'left',
        width: '14%',
        sorter: (a, b) => getInstrumentInfo(a?.id)?.latest_value_sek - getInstrumentInfo(b?.id)?.latest_value_sek,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => {
          return `${twoDecimalWithSpaces(getInstrumentInfo(record?.id)?.latest_value_sek)} kr`;
        },
      },
      {
        title: t('VALUE'),
        className: 'exclude-holdings exclude-value',
        dataIndex: 'value',
        align: 'left',
        width: '16%',
        render: (_, record) => {
          return `${twoDecimalWithSpaces(getInstrumentInfo(record?.id)?.value)} kr`;
        },
        sorter: (a, b) => getInstrumentInfo(a?.id)?.value - getInstrumentInfo(b?.id)?.value,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('FEE'),
        className: 'exclude-holdings exclude-fee holdings-fee',
        dataIndex: 'fee',
        align: 'center',
        width: '7%',
        render: (_, record) => {
          const fee = getInstrumentInfo(record?.id)?.fee;
          return (
            <p>
              {formatPercentage(fee) ||
                `${'0.00'.replace('.', decimalSeparator())} %`}
            </p>
          );
        },
        sorter: (a, b) => getInstrumentInfo(a?.id)?.fee - getInstrumentInfo(b?.id)?.fee,
        sortIcon: (props) => <CustomSortIcon {...props} />,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        className: 'exclude-holdings exclude-assetclass',
        dataIndex: 'asset_class',
        width: '15%',
        sorter: (a, b) => textSort(a.asset_class, b.asset_class),
        sortIcon: (props) => <CustomSortIcon {...props} />,
        render: (_, record) => {
          return (
            <>
              <p className="holdings-assetclass-cell-asset">{getInstrumentInfo(record?.id)?.financial_asset?.name}</p>
              <p className="holdings-assetclass-cell-category">
                {getInstrumentInfo(record?.id)?.financial_asset_category?.name}
              </p>
            </>
          );
        },
      },
      {
        title: '',
        className: 'exclude-holdings exclude-operation',
        dataIndex: 'operation',
        align: 'center',
        width: '2%',
        render: (_, record) => (
          <>
            <Button
              icon={<Delete />}
              type="text"
              onClick={() => handleDelete(record.key)}
            />
          </>
        ),
      },
    ];
    setTableColumns(columns);
  }, [data, instrumentsForExclude]);

  const handleAdd = () => {
    const newData = {
      key: uuidv4(),
      id: null,
    };

    data?.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleSave = (row) => {
    const newData = data.map((item) => (row.key === item.key ? row : item));
    setData(newData);
    setEditingKey('');
  };

  const columns = tableColumns?.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        editing: isEditing(record),
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        availableInstrument,
        instrumentsForExclude
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <Table
        className='exclude-holdings-table'
        components={components}
        rowHoverable={false}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
      <Row className='add-btn-wrapper' justify={'end'}>
        <Button
          type="text"
          className="holdings-add-btn add-instrument"
          onClick={handleAdd}
          icon={<Pluse />}
          iconPosition={'end'}
        >
          {t('ADD')}
        </Button>
      </Row>
    </>
  );
};

export default ExcludeHoldings;

ExcludeHoldings.propTypes = {
  instrumentsForExclude: PropTypes.array,
  data: PropTypes.array,
  setData: PropTypes.func,
  handleDelete: PropTypes.func,
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
