export const ClientInformation = {
  client_info: {
    'common': {
        'contact_data': {
            'phones': [],
            'email': ''
        },
        'main_info': {
            'personal_number': '',
            'country': '',
            'name': '',
            'last_name': '',
            'address': '',
            'zipcode': '',
            'city': ''
        }
    },
  }
};

export const PurposeMatter = {
  invest_target:{
    'additional_questions': {
        'investment_purpose': '',
        'heart_affairs': '',
        'qualitative_goals': '',
        'sustainable_view': '',
        'other_aspects': ''
    }
}
};

export const FinancialSituation = {
  fin_state:{
    'revenue_and_expenditure': {
        'employment_income': '',
        'surplus': '',
        'other_income': '',
        'expenditure': '',
        'commentary': ''
    },
    'financial_assets': {
        'financial_investments': '',
        'cash_equivalents': '',
        'pension_savings': '',
        'other_assets': '',
        'commentary': ''
    },
    'fixed_assets': {
        'housing': '',
        'other_fixed_assets': '',
        'commentary': ''
    },
    'shoulder': {
        'housing': '',
        'other_liabilities': '',
        'commentary': ''
    },
    'investable_capital': {
        'non_covered_elements': '',
        'unadvised_capital': '',
        'investment_capital': ''
    }
}
};

export const BasicPrerequisites = {
  prerequisites: {
    'general_questions': {
        'liquidate_holdings': '',
        'time_horizon': '',
        'future_generation': '',
        'commentary': '',
        'buffer_capital': ''
    }
}
};

export const KYCCategory = {
  kyc: {
    'citizenship_and_residence': {
        'citizenship': '',
        'citizenship_other': '',
        'tax_residence': '',
        'id_doc_number': '',
        'doc_expiry_date': ''
    },
    'abroad': {
        'usa_relationships': '',
        'usa_relationships_type': '',
        'foreign_transactions': '',
        'transactions_amount': ''
    },
    'purpose_of_engagement': {
        'savings_purpose': {},
        'describe_other_purpose': '',
        'behalf': '',
        'describe_other_behalf': ''
    },
    'occupation': {
        'main_occupation': '',
        'describe_other': '',
        'monthly_income': ''
    },
    'capital_transfer': {
        'money_tranfer': '',
        'money_origin': {},
        'bank_capital': {},
        'whose_money': '',
        'transfer_value': '',
        'estimate': ''
    },
    'deposits_withdrawals': {
        'yearly_deposit': '',
        'estimate_deposit': '',
        'deposit_frequency': '',
        'withdrawal_frequency': '',
        'individual_transactions': '',
        'estimate_transactions': ''
    },
    'political_position': {
        'pep_position': '',
        'pep_type': '',
        'pep_relative': '',
        'pep_relative_type': ''
    }
}
};

export const RiskTolerance = {
  risk_tolerance: {
    'expected_return': '',
    'return_target': '',
    'investment_philosophy': '',
    'media_influence': '',
    'negative_news': '',
    'portfolio_value_drop': '',
    'double_value_drop': '',
    'low_value_increase': '',
    'portfolio_evaluation': '',
    'additional_comment': ''
}
};

export const Experience = {
    experience: {
        'risk_of_loss': {
            'loss_risk': true
        },
        'assets_type_knowledge': {
            'asset_type': {
                'listed_shares': {
                    'asset_trained': true,
                    'risk_understanding': false,
                    'deals_number': 0,
                    'asset_experience': 'asset_experience_option1'
                },
                'unlisted_shares': {
                    'asset_trained': true,
                    'risk_understanding': false,
                    'deals_number': 0,
                    'asset_experience': 'asset_experience_option1'
                },
                'ucits_funds': {
                    'asset_trained': false,
                    'risk_understanding': false,
                    'deals_number': 9,
                    'asset_experience': 'asset_experience_option3'
                },
                'special_funds': {
                    'asset_trained': false,
                    'risk_understanding': false
                },
                'listed_bonds': {
                    'asset_trained': false,
                    'risk_understanding': true,
                    'deals_number': 6,
                    'asset_experience': 'asset_experience_option3'
                },
                'unlisted_bonds': {
                    'asset_trained': false,
                    'risk_understanding': false,
                    'deals_number': 0,
                    'asset_experience': 'asset_experience_option1'
                },
                'equity_index_bonds': {
                    'asset_trained': true,
                    'risk_understanding': false,
                    'deals_number': 2,
                    'asset_experience': 'asset_experience_option2'
                },
                'hedge_funds': {
                    'asset_trained': false,
                    'risk_understanding': false,
                    'deals_number': 2,
                    'asset_experience': 'asset_experience_option2'
                },
                'certificate_of_credit': {
                    'asset_trained': true,
                    'risk_understanding': false,
                    'deals_number': 2,
                    'asset_experience': 'asset_experience_option2'
                },
                'autocalls': {
                    'asset_trained': true,
                    'risk_understanding': false,
                    'deals_number': 0,
                    'asset_experience': 'asset_experience_option1'
                },
                'exchange_traded_funds': {
                    'asset_trained': false,
                    'risk_understanding': false
                },
                'derivatives_and_commodity': {
                    'asset_trained': false,
                    'risk_understanding': false
                },
                'aif_funds': {
                    'deals_number': 9,
                    'asset_experience': 'asset_experience_option3'
                },
                'etf': {
                    'deals_number': 0,
                    'asset_experience': 'asset_experience_option1'
                },
                'derivatives': {
                    'deals_number': 5,
                    'asset_experience': 'asset_experience_option2',
                    'asset_trained': false,
                    'risk_understanding': true
                }
            },
            'additional_comment': 'dfgfgdg df gd dg'
        },
        'general_info': {
            'fin_education': 'asd',
            'trading_period': 5
        },
        'portfolio_management': {
            'management_experience': 'asset_experience_option2',
            'portfolio_understanding': true,
            'portfolio_trained': true,
            'additional_comment': 'df z g '
        }
    }
};