/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  getPortfolioById,
  getStepOptimisationPortfolio,
  searchParams,
} from '../../data/store/portfolio/portfolioActions';

import { decimalSeparator } from '../../helpers/decimalSeparator';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/pluse-black-icon.svg';
import { ReactComponent as Question } from '../../assets/icon-question.svg';

import { getAssets, getCurrency, getInstrumentsAll, getRecommendationMaps } from '../../data/store/instruments/instrumentsActions';
import isEqual from 'lodash/isEqual';

import { Button, Col, Form, Input, Row, Select, Spin, Tooltip } from 'antd';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';
import PortfolioFixationItem from './PortfolioFixationItem';
import ExcludeHoldings from '../../components/ExcludeHoldings/ExcludeHoldings';
import ExcludeAssetClassItem from './ExcludeAssetClassItem';

import './style.scss';
import { textSort } from '../../helpers/textSort';

const PortfolioConstraints = ({ form, portfolioId, onSubmit, setNeedSaveConstr, saveAlert,
  setSaveAlert, selectedAssetClass, setSelectedAssetClass, selectedInstruments, setSelectedInstruments,
  dataExclude, setDataExclude }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const maximumCash = Form.useWatch('min_cash', form);

  const initValue = {
    id: '',
    instrument_id: null,
    instrument_name: '',
    financial_asset_id: '',
    financial_asset_name: '- - - - - - - - - ',
    units_number: 0,
    fixed: 0,
    latest_value_sek: 0,
  };

  const [instruments, setInstruments] = useState([]);
  
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const cashesList = useSelector(state => state.instrumentsReducer.cashes.list);
  const { assets, recommendation } = useSelector((state) => state.instrumentsReducer);
  const searchList = useSelector((state) => state.portfolioReducer.instruments.list);
  const searchLoading = useSelector((state) => state.portfolioReducer.isLoading);
  const {
    portfolioById, bias, fixation, metadata,
    portfolioById: { is_locked }, portfolioById: { status }
  } = useSelector(
    (state) => state.portfolioReducer
  );

  const [tabs , setTabs ] = useState(0);

  const handleTabs = (tab) => {
    setTabs(tab);
  };

  const { portfolio_content, unlisted_content } = portfolioById;
  const instrumentsForExclude = portfolio_content?.concat(unlisted_content) || [];

  const reinitValue = useMemo(() => {
    const { portfolio_cashes, portfolio_content, unlisted_content } = portfolioById;
    const allInstruments = portfolio_content?.concat(portfolio_cashes, unlisted_content);
    if (!portfolioById) return [];

    const casheInstr = (id) => {
      const cash = cashesList?.find(i => i.ID === id);
      return ({
        name: cash?.name,
        financialAssetID: cash?.financialAssetID,
        financialAssetName: cash?.financialAsset?.name,
      });
    };
    const getInstrument = (id, instrument_id) => {
      const findInstr = allInstruments?.find(i => i.id === id);
      return ({
        name: findInstr?.instrument_name ?? findInstr?.name ?? casheInstr(instrument_id)?.name,
        financial_asset_id: findInstr?.financial_asset_id ?? casheInstr(instrument_id)?.financial_asset_id,
        financial_asset_name: findInstr?.financial_asset_name ?? findInstr?.financial_asset?.name ?? casheInstr(instrument_id)?.financial_asset_name,
        latest_value_sek: findInstr?.latest_value_sek
      });
    };
    const newFix = fixation?.map(i => {
      return ({
        key: i.internal_id,
        id: i.internal_id,
        instrument_id: i.instrument_id,
        instrument_name: getInstrument(i.internal_id, i.instrument_id)?.name,
        financial_asset_id: getInstrument(i.internal_id, i.instrument_id)?.financial_asset_id,
        financial_asset_name: getInstrument(i.internal_id, i.instrument_id)?.financial_asset_name,
        latest_value_sek: getInstrument(i.internal_id, i.instrument_id)?.latest_value_sek,
        units_number: i.units_number,
        fixed: i.fixed,
      });
    });
    return newFix;
  }, [fixation, cashesList, portfolioById]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const getNameAsset = (id) => assets.find(i => i.ID === id)?.name ?? 'Error asset class!';

  useEffect(() => {
    form.setFieldValue('min_cash', metadata?.min_cash
      ? metadata?.min_cash * 100 / portfolioById?.total_value
      : 0);
    if (metadata?.exclude_instrument) {
      setDataExclude(metadata?.exclude_instrument.map(i => ({ key: i, id: i })));
    } else {
      setDataExclude([]);
    }
    if (metadata?.exclude_asset_class) {
      setSelectedAssetClass(metadata?.exclude_asset_class.map(i => ({ key: i, id: i, label: getNameAsset(i) })));
    } else {
      setSelectedAssetClass([]);
    }
  }, [metadata, portfolioById]);

  useEffect(() => {
    if (bias) {
      form.setFieldValue('investor_bias', bias);
    }
  }, [bias]);

  useEffect(() => {
    setSelectedInstruments(reinitValue);
  }, [fixation, cashesList, portfolioById]);

  const totalCashes = useMemo(() => {
    let persent = 0;
    if (Object.keys(portfolioById || {})?.length && portfolioById?.total_value) {
      if (maximumCash > 0 && maximumCash <= 100) {
        persent = maximumCash;
      }

      return Math.round(portfolioById?.total_value * persent / 100);
    } else {
      return 0;
    }
  }, [portfolioById, maximumCash]);

  useEffect(() => {
    dispatch(getStepOptimisationPortfolio({ portfolioId }));
    dispatch(searchParams('', true));
    dispatch(getAssets());
    dispatch(getCurrency());
    dispatch(getRecommendationMaps());
    if (!portfolioById?.length) dispatch(getPortfolioById({ portfolioId }));
    if (!cashesList?.length) dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' })); 
  }, []);

  const initValues = {
    name: '',
    percentage: '',
    instrument_id: '',
  };

  const debounceFetcher = (value) => {
    dispatch(searchParams(value || '', true));
  };

  const getField = () => {
    const formData = form.getFieldValue('investor_bias');
    const newData = formData.map((i) => {
      return Number(i.percentage);
    });
    return newData?.reduce((acc, el) => acc + el, 0);
  };

  const needUpdateFormData = (allValues) => {
    const data = selectedInstruments
      ?.map(i => {
        const { id, instrument_id, units_number, fixed } = i;
        return (
          {
            instrument_id,
            internal_id: id,
            units_number,
            fixed,
          }
        );
      });
    const { investor_bias, min_cash } = allValues;
    let minCash;
    // eslint-disable-next-line no-const-assign
    if (min_cash === null) {
      minCash = undefined;
    } else {
      minCash = Math.round(portfolioById?.total_value * min_cash / 100);
    }
    const excludeList = dataExclude?.map(i => i.id);
    const excludeAssetsList = selectedAssetClass?.map(i => i.id);

    const isNeedSave = !(isEqual(investor_bias, bias) &&
    (isEqual(dataExclude?.length ? dataExclude?.map(i => i.id) : undefined, metadata?.exclude_instrument) ||
    isEqual(excludeList, metadata?.exclude_instrument)) &&
    (isEqual(selectedAssetClass?.length ? selectedAssetClass?.map(i => i.id) : undefined, metadata?.exclude_asset_class) ||
    isEqual(excludeAssetsList, metadata?.exclude_asset_class)) &&
    isEqual(data, fixation?.map(i => {
      const {is_approved_instrumen, ...rest} = i;
      return rest;
    })));
    
    const isValid = (investor_bias?.length ? investor_bias?.every(i => i?.instrument_id && i?.name && i?.percentage) : true) &&
    (data?.length ? data?.every(i => i?.instrument_id && i?.fixed) : true) &&
    (dataExclude?.length ? dataExclude?.every(i => i?.id) : true) &&
    (selectedAssetClass?.length ? selectedAssetClass?.every(i => i?.id) : true);
    setNeedSaveConstr(isNeedSave && isValid);
    
  };

  const handleValueChange = (_, allValues) => {
    needUpdateFormData(allValues);
  };

  const handleChangeFixation = () => {
    const allValues = form.getFieldsValue();
    needUpdateFormData(allValues);
  };

  useEffect(() => {
    selectedInstruments && handleChangeFixation();
  }, [selectedInstruments]);

  useEffect(() => {
    selectedAssetClass && handleChangeFixation();
  }, [selectedAssetClass]);

  useEffect(() => {
    dataExclude && handleChangeFixation();
  }, [dataExclude]);

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSaveConstr(false);
    dispatch(getStepOptimisationPortfolio({ portfolioId }));
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  useEffect(() => {
    if (Object.keys(portfolioById)?.length) {
      const newListedData = portfolioById?.portfolio_content?.map((i) => {
        return {
          id: i?.id,
          financial_instrument_id: i.financial_instrument_id,
          instrument_name: i.instrument_name,
          financial_asset_id: i.financial_asset_id,
          financial_asset_name: i.financial_asset?.name,
          units_number: i.units_number,
          fixed: 0,
          latest_value_sek: i?.latest_value_sek,
        };
      })?.filter(i => !!i?.financial_asset_id);
      const newUnlistedData = portfolioById?.unlisted_content?.map((i) => {
        console.log('🚀 ~ newUnlistedData ~ i:', i);
        return {
          id: i?.id,
          financial_instrument_id: i.financial_instrument_id,
          instrument_name: i.name,
          financial_asset_id: i.financial_asset_id,
          financial_asset_name: i.financial_asset.name,
          units_number: i.units_number,
          fixed: 0,
          latest_value_sek: i?.latest_value_sek,
        };
      });
      const selectedKey = selectedInstruments?.map(i => i?.id);
      const arrayForSelect = newListedData.concat(newUnlistedData)?.filter(i => !selectedKey?.includes(i?.id));
      setInstruments(arrayForSelect);
    }
  }, [portfolioById]);

  const addInstrument = () => {
    const needAdd = selectedInstruments.every(i => i.id && i.instrument_name);
    if (needAdd) {
      setSelectedInstruments([...selectedInstruments, { ...initValue, key: Date.now() }]);
    }
  };

  const addAssetClass = () => {
    const needAdd = selectedAssetClass.every(i => i.id);
    if (needAdd) {
      setSelectedAssetClass([...selectedAssetClass, { id: '', key: Date.now() }]);
    }
  };

  const handleChangeFix = (value, key) => {
    const newSelect = selectedInstruments.map(i => i.key === key ? { ...i, fixed: value } : i);
    setSelectedInstruments(newSelect);
  };

  const renderSelectOption = useMemo(() => {
    const render = instruments.map(el => {
      return (
        {
          key: el?.id,
          value: el?.id,
          label: el?.instrument_name
        }
      );
    });
    const list = render.filter(i => !selectedInstruments?.some(el => el?.id === i.value));
    const sortList = list?.sort((a, b) => textSort(a.label, b.label));
    return sortList;
  }, [instruments, selectedInstruments]);

  const renderAssetOption = useMemo(() => {
    const recomendMap = recommendation?.find( i => i.id === portfolioById?.portfolio_type);
    let assetClassIDsList = [];
    recomendMap?.asset_recommendation_mappings?.forEach(i => {
      if(!assetClassIDsList.includes(i.asset_class_id)) {
        assetClassIDsList.push(i.asset_class_id);
      }
    });
    const render = assets.map(el => {
      return (
        {
          key: el.ID,
          value: el?.ID,
          label: el?.name
        }
      );
    });
    const list = render.filter(i => !selectedAssetClass?.some(el => el?.id === i.value) && assetClassIDsList.includes(i.key));
    const sortedList = list?.sort((a, b) => textSort(a.label, b.label));
    return sortedList;
  }, [assets, selectedAssetClass, recommendation]);

  const handleSelect = (data, key) => {
    const instrument = instruments?.find(i => i.id === data.value);
    const newData = {
      key,
      id: data.value,
      instrument_id: instrument?.financial_instrument_id,
      instrument_name: instrument?.instrument_name,
      financial_asset_id: instrument?.financial_asset_id,
      financial_asset_name: instrument?.financial_asset_name,
      latest_value_sek: instrument?.latest_value_sek,
      units_number: instrument?.units_number,
      fixed: 0,
    };
    const newSelect = selectedInstruments.map(i => i.key === key ? newData : i);
    setSelectedInstruments(newSelect);
  };

  const handleSelectAssetClass = (data, key) => {
    const newData = {
      key,
      id: data.value,
    };
    const newSelect = selectedAssetClass.map(i => i.key === key ? newData : i);
    setSelectedAssetClass(newSelect);
  };

  const handleDelete = (key) => {
    const newSelect = selectedInstruments.filter(i => i.key !== key);
    setSelectedInstruments(newSelect);
  };

  const handleAssetDelete = (key) => {
    const newSelect = selectedAssetClass.filter(i => i.key !== key);
    setSelectedAssetClass(newSelect);
  };

  const handleDeleteExclude = (key) => {
    const newSelect = dataExclude.filter(i => i.key !== key);
    setDataExclude(newSelect);
  };

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <div>
      <Row justify={'space-between'} style={{padding: '24px'}}>
        <Col>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
            onClick={() => handleTabs(0)}
          >
            {t('INVESTOR_BIAS')}
          </Button>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
            onClick={() => handleTabs(1)}
          >
            {t('FIXATION')}
          </Button>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 2 ? 'selected' : ''}`}
            onClick={() => handleTabs(2)}
          >
            {t('EXCLUDE_HOLDINGS')}
          </Button>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 3 ? 'selected' : ''}`}
            onClick={() => handleTabs(3)}
          >
            {t('EXCLUDE_ASSET_CLASS')}
          </Button>
        </Col>
      </Row>
      <Form
        form={form}
        disabled={is_locked || status === 'ACTIVE_DEPRECATED'}
        className="portfolio-constraints"
        onValuesChange={handleValueChange}
        onFinish={onSubmit}
      >
        {tabs === 0 &&
          <div style={{width: '100%', padding: '0'}}>
            <div className='grey-wrapper'>
              <Row className="table-header-wrapper">
                <Col className="constraints-table-header" span={9}>
                  {t('DESCRIPTION')}
                </Col>
                <Col className="constraints-table-header" span={9}>
                  {t('PRODUCT_NAME')}
                </Col>
                <Col className="constraints-table-header investor-bias-header" span={4}>
                  <span>{t('PERCENTAGE')}, %</span>
                  <Tooltip
                    placement="top"
                    title={<span style={{ color: '#000000' }}>{t('INVESTOR_BIAS_TOOLTIP')}</span>} color='white'>
                    <Question />
                  </Tooltip>
                </Col>
                <Col className="constraints-table-header" span={2}></Col>
              </Row>
            </div>
            <Form.List name="investor_bias">
              {(fields, { add, remove }) => (
                <div className='investor_bias-wrapper'>
                  {fields.map(({ key, name, ...restField }) => (
                    <div className='bias-row-wrapper' key={key}>
                    <Row
                      className="bias-form-row"
                      gutter={32}
                    >
                      <Col span={9}>
                        <Form.Item {...restField} name={[name, 'name']}>
                          <Input
                            className='custom-input full-width'
                            placeholder={t('ENETR_BIAS_NAME')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <Form.Item
                          {...restField}
                          name={[name, 'instrument_id']}
                          validateTrigger={['onBlur', 'onChange']}
                          rules={[
                            {
                              required: true,
                              message: 'Choose product name',
                            },
                          ]}
                        >
                          <CustomSelect
                            showSearch
                            onSearch={(value) => debounceFetcher(value)}
                            onBlur={() => debounceFetcher()}
                            notFoundContent={searchLoading ? <Spin size="small" /> : null}
                            placeholder={t('PRODUCT_NAME_ISIN')}
                            className="custom-select"
                            filterOption={false}
                          >
                              {
                                searchList?.length > 0 &&
                                searchList
                                  .slice()
                                  .sort((a, b) => textSort(a.name, b.name))
                                  .map(({ ID, isin, name }) => (
                                    <Select.Option key={ID} value={ID}>
                                      {`${name} - ${isin}`}
                                    </Select.Option>
                                  ))
                              }
                          </CustomSelect>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'percentage']}
                          validateTrigger="onChange"
                          rules={[
                            {
                              required: true,
                              message: 'Enter percentage',
                            },
                            () => ({
                              validator (_, value) {
                                getField(form.getFieldValue('investor_bias'));
                                if (!value) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject();
                                }
                                if (getField() > 100) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'The maximum value for the sum of all the percentages is 100 %'
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <CustomInputNumber
                            decimalSeparator={decimalSeparator()}
                            precision={2}
                            className='custom-inputnumber bias-percentage'
                            percent={true}
                            min={0.01}
                            step={0.01}
                            controls={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                      <div className='del-btn-wrapper'>
                        <Button
                          type='text'
                          style={{ marginTop: '10px', cursor: 'pointer' }}
                          icon={<Delete />}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </div>
                      </Col>
                    </Row>
                    </div>
                  ))}
                  <Row className='add-btn-wrapper' justify={'end'}>
                    <Button
                      type='text'
                      className="holdings-add-btn add-instrument"
                      onClick={() => add(initValues)}
                      icon={<Pluse />}
                      iconPosition={'end'}
                    >{t('ADD')}</Button>
                  </Row>
                </div>
              )}
            </Form.List>
          </div>
        }
        {tabs === 1 &&
        <div style={{width: '100%', padding: '0 0 24px'}}>
          <div className='grey-wrapper'>
            <Row className="table-header-wrapper">
              <Col style={{ flexBasis: '20%' }} className='fixation-table-header'>
                {t('ASSET_CLASS')}
              </Col>
              <Col style={{ flexBasis: '29.16%' }} className='fixation-table-header'>
                {t('INSTRUMENTS')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '12.33%' }}>
                {t('NO_SHARES')}
              </Col>
              <Col style={{ flexBasis: '13.85%' }} className='fixation-table-header'>
                {t('NO_SHARES_TO_FIX')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '6.33%' }}>
                {t('FIX')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '12%' }}>
                {t('VALUE')}
              </Col>
              <Col style={{ flexBasis: '6.33%' }}>
              {' '}
              </Col>
            </Row>
          </div>
          <div className='investor_bias-wrapper'>
            {selectedInstruments?.map((i) => {
              return (
                <PortfolioFixationItem
                  key={i.key}
                  i={i}
                  selectedInstruments={selectedInstruments}
                  setSelectedInstruments={setSelectedInstruments}
                  handleSelect={handleSelect}
                  renderSelectOption={renderSelectOption}
                  handleChangeFix={handleChangeFix}
                  handleDelete={handleDelete}
                  cashesList={cashesList}
                />
              );
            })
            }

            <Row className='add-btn-wrapper' justify={'end'}>
              <Button
                type='text'
                className="holdings-add-btn add-instrument"
                onClick={() => addInstrument()}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
            </Row>

          </div>
        </div>
        }
        {tabs === 2 &&
          <div style={{width: '100%', padding: '0 0 24px'}}>
            <ExcludeHoldings
              data={dataExclude}
              setData={setDataExclude}
              instrumentsForExclude={instrumentsForExclude}
              handleDelete={handleDeleteExclude}
            />
          </div>
        }
        {tabs === 3 &&
          <div style={{width: '100%', padding: '0 0 24px'}}>
            <div className='investor_bias-wrapper'>
              {selectedAssetClass?.map((i) => {
                return (
                  <ExcludeAssetClassItem
                    key={i.key}
                    i={i}
                    selectedAssetClass={selectedAssetClass}
                    setSelectedInstruments={setSelectedAssetClass}
                    handleSelect={handleSelectAssetClass}
                    renderSelectOption={renderAssetOption}
                    handleDelete={handleAssetDelete}
                  />
                );
              })
              }

              <Row className='add-btn-wrapper' justify={'end'}>
                <Button
                  type='text'
                  className="holdings-add-btn add-instrument"
                  onClick={() => addAssetClass()}
                  icon={<Pluse />}
                  iconPosition={'end'}
                >{t('ADD')}</Button>
              </Row>

            </div>
          </div>
        }
      </Form>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default PortfolioConstraints;

PortfolioConstraints.propTypes = {
  userId: PropTypes.string,
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  selectedAssetClass: PropTypes.array,
  setSelectedAssetClass: PropTypes.func,
  selectedInstruments: PropTypes.array,
  setSelectedInstruments: PropTypes.func,
  dataExclude: PropTypes.array,
  setDataExclude: PropTypes.func,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSaveConstr: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
