/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Col, Divider, Form, Input, Layout, Radio, Row, Space } from 'antd';

import { ReactComponent as Pluse } from '../../../assets/cross-btn-icon.svg';

import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import { organizationNumberRules } from '../../../helpers/validation';
import { createAccount } from '../../../data/store/account/accountActions';

import './style.scss';
import { useDispatch } from 'react-redux';

const { Header } = Layout;

const AccountCreator = () => {
  const { t } = useTranslation('');
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const type = Form.useWatch('type', form);

  useEffect(() => {
    form.setFieldsValue({type: 'private'});
  }, []);

  useEffect(() => {

    form.resetFields();
    if(!type) {
      form.setFieldsValue({type: 'private'});
    } else {
      form.setFieldsValue({type});
    }
      
  }, [type]);
  

  const handleReset = () => {
    history.push('/accounts');
  };

  const onSubmit = (fields) => {
    dispatch(createAccount({ data: fields, history }));
  };

  return (
    <>
      <Header className="headerStyle">
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '', padding: '24px' }}
        >
          <Col className="client portfolio-counter-wrap">
            <h2 className="client settings-title">{t('ACCOUNT_CREATION')}</h2>
          </Col>
          <Col className="top-btn-wrapper">
            <Button
              type="text"
              className="portfolio-cancel-btn"
              icon={<Pluse />}
              iconPosition="end"
              onClick={handleReset}
            >
              {t('CANCEL')}
            </Button>
            <Button onClick={() => form.submit()} className="portfolio-save-btn">
              {t('SAVE')}
            </Button>
          </Col>
        </Row>
      </Header>
        <div className="new-account-wrapper">
          <Form 
            className="account-form" 
            form={form}
            name="client-profile" 
            onFinish={onSubmit}
            layout="vertical"
            >
            <div>
              <div className="new-account-card">
                <p className="new-account-card-title">
                  {t('NEW_ACCOUNT')}
                </p>
                <p className="account-card-promt">
                  {t('PLEASE_PROVIDE_INFORMATION')}
                </p>
              </div>
            </div>
            <Row style={{marginTop: '48px'}}>
              <Col>
                <h2 className="account-card-rb-label">{t('ACCOUNT_TYPE')}</h2>
                <Form.Item name="type" className="">
                  <Radio.Group className="checkbox-block">
                    <Space direction="horizontal" align="center">
                      <CustomRadioButtom value="private">
                        {t('PRIVATE')}
                      </CustomRadioButtom>
                      <CustomRadioButtom value="corporate">
                        {t('CORPORATE')}
                      </CustomRadioButtom>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Divider className='new-account-divider'/>
            {type === 'private' ? (
              <Row className='newaccount-name-row'>
                <Form.Item
                  label={t('NAME')}
                  name="name"
                  className="custom-required full-width"
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_A_NAME'),
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder={t('NAME')} className="custom-input full-width" />
                </Form.Item>
              </Row>
                ) : type === 'corporate' ? (
                  <div>
                    <Form.Item
                      label={t('COMPANY_NAME')}
                      name="company_name"
                      className="custom-required full-width"
                      rules={[
                        {
                          required: true,
                          message: t('PLEASE_ENTER_A_NAME'),
                        },
                      ]}
                      required={false}
                    >
                      <Input
                        placeholder={t('COMPANY_NAME')}
                        className="custom-input full-width"
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('COMPANY_NUMBER')}
                      name="company_number"
                      className="custom-required full-width"
                      rules={organizationNumberRules}
                      required={false}
                    >
                      <Input
                        placeholder={t('COMPANY_NUMBER')}
                        className="custom-input full-width"
                      />
                    </Form.Item>
                  </div>
                ) : null
            }
                  <Form.Item
                    name="description"
                    className="onboarding-textarea-purpose"
                    label={t('DESCRIPTION')}
                  >
                    <CustomTextarea width={'100%'} rows={5} placeholder={t('DESCRIPTION')} />
                  </Form.Item>
          </Form>
        </div>
    </>
  );
};

export default AccountCreator;
