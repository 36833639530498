/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';

import StorageService from '../../services/StorageService';
import AuthRouting from './AuthRoutin';
import MainRouting from './MainRouting';

import SessionService from '../../services/SessionService';
import './style.scss';

const AppRouting = () => {
  const advisor = useSelector((state) => state.userReducer.advisor);
  const [isAuthenticated, setAuthenticated] = useState(
    StorageService.getJWTToken('acc') ||
    SessionService.getJWTToken('acc')
  );
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    if(advisor?.role){
      const defaultTheme = advisor?.role === 'CLIENT_NOT_SECURE' || advisor?.role === 'CLIENT'
      ? 'dark'
      : 'dark';
    setTheme(defaultTheme);
  } else {
    setTheme('dark');
  }
  }, [advisor]);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (event) => {
      console.log('🚀 ~ handleChange ~ event:', event);
      setTheme(event.matches ? 'dark' : 'light');
    };

    darkModeMediaQuery.addEventListener('change', handleChange);
    return () => {
      darkModeMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    document.body.className = theme === 'dark' ? 'dark-theme' : 'light-theme';
    theme && localStorage.setItem('theme', theme);
  }, [theme]);

  // eslint-disable-next-line no-unused-vars
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };


  useEffect(() => {
    const token = StorageService.getJWTToken('acc') || SessionService.getJWTToken('acc');
    if (token) {
      setAuthenticated(token);
    } else {
      setAuthenticated('');
    }
  }, [advisor]);

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#FB7B34',
            colorPrimaryBorder: '#0F0F0F',
            colorBgContainerDisabled: '#FAE5D8'
          },
          components: {
            Layout: {
              headerBg: '#ffffff',
              headerColor: '#3C4043',
              headerHeight: 72,
              siderBg: '#FFFFFF',
              bodyBg: '#F6F7F9',
            },
            Menu: {
              collapsedIconSize: 0,
              darkItemBg: 'transparent',
              darkSubMenuItemBg: 'transparent',
              darkItemColor: '#7E716D',
              // itemSelectedBg: '#FD833A',
              // itemActiveBg: '#F6F7F9',
              itemHeight: 48,
              itemMarginBlock: 8,
            },
            Switch: {
              handleBg: '#0F0F0F',
              trackHeight: 22,
              trackPadding: 1
            },
            Form: {
              labelColor: '#0F0F0F',
              labelFontSize: 14,
              itemMarginBottom: 20
            },
            Progress: {
              defaultColor: '#FB7B34',

            },
            Slider: {
              railSize: 10,
              handleSize: 20,
              handleSizeHover: 20,
              handleColor: '#FB7B34',
            },
            Collapse: {
              headerPadding: '12px 0',
              contentPadding: '0'
            },
          },
        }}
      >
        {isAuthenticated ? <MainRouting theme={theme}/> : <AuthRouting theme={theme}/>}
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default AppRouting;
