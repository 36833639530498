import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Divider, Modal, Row } from 'antd';
import { deleteRelatedPerson } from '../../data/store/account/accountActions';

const DeleteModal = ({modalDeleteModel, handleDeleteClose, accountId}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const relatedUsers = useSelector((state) => state.accountReducer?.relatedUsers);
  const [errorMessage, setErrorMessage ] = useState(false);

  const getUserById = useMemo(() => {
    return relatedUsers?.find(i => i.id === modalDeleteModel);
  }, [modalDeleteModel, relatedUsers]);

  const handleErrorMessage = () => {
    setErrorMessage('Error! Cannot delete user');
    setTimeout(() => {
      setErrorMessage(false);
    }, 2500);
  };

  const handleDelete = () => {
    dispatch(deleteRelatedPerson({accountId, userId: modalDeleteModel, callback: handleDeleteClose, errMessage: handleErrorMessage}));
  };

  return (
    <Modal
        width={700}
        className="modal-content-paper"
        open={modalDeleteModel}
        onCancel={handleDeleteClose}
        destroyOnClose
        footer={null}
        zIndex={1600}>
        <Row justify="start">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Divider className='modal-divider'/>
        <Row justify="start" className="modal-text-wrapper">
          <Col>
            <p className="modal-description">{t('MODAL_DELETE_PERSON')}</p>
            <p className="modal-name">{`${getUserById?.first_name} ${getUserById?.last_name}`}</p>
          </Col>
        </Row>
        {errorMessage && <Row className='error-del-modal'>{errorMessage}</Row>}

        <Row justify="end" style={{marginTop: '50px'}}>
          <Col>
            <Button
              className="modal-action-btn cancel-btn"
              onClick={handleDeleteClose}
            >
              {t('CANCEL')}
            </Button>
            <Button 
              style={{marginLeft: '8px'}}
              className="modal-action-btn delete-btn" 
              onClick={handleDelete}>
              {t('DELETE')}
            </Button>
          </Col>
        </Row>
      </Modal>
  );
};

export default DeleteModal;

DeleteModal.propTypes = {
  accountId: PropTypes.number,
  modalDeleteModel: PropTypes.number,
  handleDeleteClose: PropTypes.func,
};
