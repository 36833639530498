import { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';

export class AccountService {
  static createClientToAccount = async (accoundId, data) => {
    return await axios.post(`${API_URLS.ACCOUNTS_V3}/${accoundId}/user`, data);
  };

  static createAccount = async (data) => {
    return await axios.post(API_URLS.ACCOUNTS_V3, data);
  };

  static getAccountsList = async () => {
    return await axios.get(API_URLS.ACCOUNTS_V3,
      {params: { limit: -1, offset: 0 }}
    );
  };

  static getAccount = async (data) => {
    const { id } = data;
    return await axios.get(
      `${API_URLS.ACCOUNTS_V3}/${id}`,
    );
  };

  static updateAccount = async (payloud) => {
    const { accountId, data } = payloud;
    return await axios.put(`${API_URLS.ACCOUNTS_V3}/${accountId}`, data);
  };

  static addRelatedPerson = async (payload) => {
    const {accountId, data} = payload;
    return await axios.post(
      `${API_URLS.ACCOUNTS_V3}/${accountId}/user/${data.id}/bind`, data
    );
  };

  static updateRelatedPerson = async (payload) => {
    const {accountId, userId, data} = payload;
    return await axios.put(
      `${API_URLS.ACCOUNTS_V3}/${accountId}/user/${userId}`, data
    );
  };

  static getRelatedAccount = async (id) => {
    return await axios.get(
      `${API_URLS.ACCOUNTS_V3}/${id}/user`,
    );
  };

  static deleteRelatedPerson = async ({accountId, userId}) => {
    return await axios.delete(
      `${API_URLS.ACCOUNTS_V3}/${accountId}/user/${userId}`,
    );
  };

  static setClientType = async (data) => {
    const { id, type } = data;
    return await axios.post(`${API_URLS.SET_CLIENT_TYPE}/${id}/type`, {
      ...type,
      client_id: +id,
    });
  };

  static updateClientInfo = async (data) => {
    const { id, client } = data;
    return await axios.put(`${API_URLS.UPDATE_CLIENT}/${id}`, client);
  };

  static addClientPhone = async (data) => {
    const { id, phone } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/phone`, { phone });
  };

  static updateClientPhone = async (data) => {
    const { id, ID, phone } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/phone`, {
      ID,
      phone,
    });
  };

  static deleteClientPhone = async (data) => {
    const { id, phoneID } = data;
    return await axios.delete(`${API_URLS.CLIENT}/${id}/phone/${phoneID}`);
  };

  static getRepresentative = async (clientID) => {
    return await axios.get(`/v1/clients/${clientID}/company/representive`);
  };

  static addRepresentative = async (data) => {
    const { id, representative } = data;
    return await axios.post(
      `/v1/clients/${id}/company/representive`,
      representative,
    );
  };

  static updateRepresentative = async (data) => {
    const { clientID, id, representative } = data;
    return await axios.put(
      `/v1/clients/${clientID}/company/representive/${id}`,
      representative,
    );
  };

  static deleteRepresentative = async (data) => {
    const { id, ID } = data;
    return await axios.delete(
      `${API_URLS.CLIENT}/${id}/company/representive/${ID}`,
    );
  };

  static addRepresentativeDoc = async (data) => {
    const { id, representativeId, file, type } = data;
    const sendData = new FormData(file);
    sendData.append('type', type);
    return await axios.post(
      `/v1/clients/${id}/company/representive/${representativeId}/documents`,
      sendData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  static setClientMaritalStatus = async (data) => {
    const { id, marital } = data;
    return await axios.put(
      `${API_URLS.CLIENT}/${id}/marital-status-and-employment`,
      marital,
    );
  };

  static addClientScore = async (data) => {
    const { id, score } = data;
    return await axios.put(`${API_URLS.CLIENT}/${id}/score`, score);
  };

  static setClientAddress = async (data) => {
    const { id, address } = data;
    return await axios.put(`${API_URLS.CLIENT}/${id}/address`, address);
  };

  static setClientData = async (data) => {
    const { id, fields } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/data`, fields);
  };

  static getClientData = async (data) => {
    const { id, name } = data;
    return await axios.get(`${API_URLS.CLIENT}/${id}/data/${name}`);
  };

  static sendInvitation = async (data) => {
    const { id, email } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/invite`, { email });
  };

  static getInvitationInfo = async (data) => {
    const { inviteHash } = data;
    return await axios.get(`${API_URLS.INVITE}/${inviteHash}`);
  };

  static acceptInvitation = async (data) => {
    const { inviteHash, password } = data;
    return await axios.post(`${API_URLS.INVITE}/${inviteHash}`, { password });
  };

  static setRiskLevel = async (data) => {
    const { id, riskSelected } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/risk`, {
      risk_level: +riskSelected,
    });
  };

  static sendClientApprove = async (data) => {
    const { id, status } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/approve`, { status });
  };

  static setClientFees = async (data) => {
    const { id, fees } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/fees`, fees);
  };

  static getClientFees = async (id) => {
    return await axios.get(`${API_URLS.CLIENT}/${id}/fees`);
  };

  static deleteAccount = async (id) => {
    return await axios.delete(`${API_URLS.ACCOUNTS_V3}/${id}`);
  };

  static getFinPlan = async (id) => {
    return await axios.get(`${API_URLS.CLIENT}/${id}/financialplan`);
  };

  static createFinPlan = async ({ clientId, data }) => {
    return await axios.post(
      `${API_URLS.CLIENT}/${clientId}/financialplan`,
      data,
    );
  };

  static patchFinPlan = async ({ clientId, finplanId, body }) => {
    return await axios.patch(
      `${API_URLS.CLIENT}/${clientId}/financialplan/${finplanId}`,
      body,
    );
  };

  static deleteFinPlan = async ({ clientId, finplanId }) => {
    return await axios.delete(
      `${API_URLS.CLIENT}/${clientId}/financialplan/${finplanId}`,
    );
  };

  static uploadImage = async (file, finplanId, itemId) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await axios.post(
      `${API_URLS.UPLOAD_IMAGE}/${finplanId}/expenses/${itemId}`,
      bodyFormData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  static getSuggestionsPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/suggestions?offset=0&limit=100`,
    );
  };

  static getApprovedPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/approved?offset=0&limit=100`,
    );
  };

  static getExecutedPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/executing?offset=0&limit=100`,
    );
  };

  static getImplementedPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/implemented?offset=0&limit=100`,
    );
  };

  static getAllApprovedPortfolio = async () => {
    const offset = 0;
    const limit = 100;
    return await axios.get(`${API_URLS.ALL_APPROVED}/approved`, {
      params: { limit, offset },
    });
  };

  static getAllExecutedPortfolio = async () => {
    const offset = 0;
    const limit = 100;
    return await axios.get(`${API_URLS.ALL_APPROVED}/executing`, {
      params: { limit, offset },
    });
  };

  static getAllImplementedPortfolio = async () => {
    const offset = 0;
    const limit = 100;
    return await axios.get(`${API_URLS.ALL_APPROVED}/implemented`, {
      params: { limit, offset },
    });
  };

  static approveSuggestions = async ({ userId, candidatId }) => {
    return await axios.post(
      `${API_URLS.CLIENT}/${userId}/candidate/${candidatId}/approve`,
    );
  };

  static deleteSuggestions = async ({ userId, candidatId }) => {
    return await axios.delete(
      `${API_URLS.CLIENT}/${userId}/candidate/${candidatId}`,
    );
  };
  /* eslint-disable camelcase */

  static getCandidatTransactions = async ({ portfolio_id, candidatId }) => {
    return await axios.get(
      `${API_URLS.PORTFOLIO}/${portfolio_id}/candidate/${candidatId}/transactions`,
    );
  };

  // TODO
  static executeApprovedPortfolio = async ({ portfolioId, candidatId }) => {
    return await axios.post(
      `${API_URLS.PORTFOLIO}/${portfolioId}/candidate/${candidatId}/execute`,
    );
  };

  static implementePortfolio = async ({ portfolioId, candidatId }) => {
    return await axios.post(
      `${API_URLS.PORTFOLIO}/${portfolioId}/candidate/${candidatId}/executed`,
    );
  };

  static downloadPdfReport = async ({ lang, type, portfolio_id, client_id }) => {
    return await axios.get(
      `${API_URLS.PORTFOLIO_V3}/${portfolio_id}/candidate/${client_id}/transactionsreport?type=${type}&lang=${lang}`,
      {
        responseType: 'blob',
      },
    );
  };

  static getOnboardingStatus = async ({ clientId }) => {
    return await axios.get(
      `${API_URLS.V2_CLIENT}/${clientId}/onboarding/active`,
    );
  };

  static postOnboardingData = async (data) => {
    const { clientId, ...rest } = data;
    return await axios.post(
      `${API_URLS.V2_CLIENT}/${clientId}/onboarding`,
      rest,
    );
  };

  static rescheduleOnboarding = async (data) => {
    const { clientId, id, ...rest } = data;
    return await axios.put(
      `${API_URLS.V2_CLIENT}/${clientId}/onboarding/${id}`,
      rest,
    );
  };

  static delOnboarding = async (clientId) => {
    return await axios.delete(
      `${API_URLS.V2_CLIENT}/${clientId}/onboarding/active`,
    );
  };

  static postOnboardingAudio = async ({ clientId, file }) => {
    return await axios.post(
      `${API_URLS.V2_CLIENT}/${clientId}/onboarding/active`,
      file,
    );
  };
  
  static setOnboardingVerifed = async ({ userId, id, data }) => {
    return await axios.put(
      `${API_URLS.V2_CLIENT}/${userId}/onboarding/${id}`,
      data,
    );
  };
  
  static uploadOnboardingDocument = async ({ id, docType, files }) => {
    if (!files || files.length === 0) {
      throw new Error('No files provided');
    }
    const filesArray = Array.from(files);
    const bodyFormData = new FormData();
    filesArray.forEach((file, index) => {
      bodyFormData.append(`file${index + 1}`, file);
    });
    return await axios.post(`${API_URLS.ONBOARDING}/${id}/document/${docType}`, bodyFormData );
  };
  
  static getOnboardingDocumentsList = async ({ id }) => {
    return await axios.get(
      `${API_URLS.ONBOARDING}/${id}/document`);
  };
  
  static deleteOnboardingDocument = async ({ docID }) => {
    return await axios.delete(`/v2/document/${docID}`);
  };
    
  static getOnboardingRiskProfile = async ({ userId, id }) => {
    return await axios.get(
      `${API_URLS.V2_CLIENT}/${userId}/onboarding/${id}/risk-profile`);
  };
  
  static uploadOnboardingAudio = async ({ id, file }) => {
    if (!file) {
      throw new Error('No files provided');
    }
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await axios.post(`${API_URLS.ONBOARDING}/${id}/audio`, bodyFormData );
  };
  
  static getGooleClientId = async () => {
    return await axios.get(API_URLS.OAUTH_CONFIG_V3);
  };
}
