import React from 'react';

import PropTypes from 'prop-types';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ClientRouting from './ClientRouting';
import CreateClientAccount from '../Clients/CreateClientAccount';
import Account from '../Account/Account';
import AccountCreator from '../Account/AccountCreator/AccountCreator';


const Routing = ({ setUserId, needSave, setNeedSave, saveAlert, setSaveAlert, optAlert, setOptAlert, setOptStart }) => {
  const { url } = useRouteMatch();
  console.log('🚀 ~ Routing ~ url:', url);

  return (
    <Switch>
      <Route exact path={`${url}`}>
        <div className="portfolio-summary-container"/>
      </Route>
      <Route path={`${url}/new-account`}>
        <AccountCreator />
      </Route>
      <Route path={`${url}/account/:accountId`}>
        <Account />
      </Route>
      <Route path={`${url}/create-client`}>
        <CreateClientAccount />
      </Route>
      
      <Route path={`${url}/create-account`}>
        <div>
          {/* //TODO */}
        </div>
      </Route>
      <Route path={`${url}/:accountId`}>
        <ClientRouting
          setUserId={setUserId}
          needSave={needSave}
          setNeedSave={setNeedSave}
          saveAlert={saveAlert}
          setSaveAlert={setSaveAlert}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
    </Switch>
  );
};
export default Routing;

Routing.propTypes = {
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  optAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
